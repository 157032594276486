<template>
	<div>
		<LoadPanel elementId="#commentsTable" :shading="false" :visible="apiRequest" />
		<div class="d-flex align-items-center justify-content-between mb-3 mt-2">
			<div class="d-flex align-items-center">
				<Icon icon-pack="dx" name="alignleft" class="mr-2" />
				<strong>Activity</strong>
			</div>
			<DxButton :text="showDetails ? 'Hide Details' : 'Show Details'" @click="showDetails = !showDetails" />
		</div>
		<form @submit="submit($event)" class="mb-3">
			<div class="comment-editor-container">
				<UserAvatar :user="{fullName: Current_User_Info.FullName, picture: Current_User_Info.Picture, color: Current_User_Info.Color}" class="mr-2" style="width:40px"  />
				<DxHtmlEditor
					:mentions="mentionableUsers"
					v-model="commentNote.text"
					class="note-editor"
					ref="noteEditor"
					placeholder="Type in resolution."
					height="80px"
					width="calc(100% - 40px)" />
				<DxButton class="editor-send-btn" icon="chevronright" styling-mode="text" :use-submit-behavior="true" />
			</div>
		</form>
		<DxDataGrid
			id="commentsTable"
			ref="commentsTable"
			key-expr="id"
			:data-source="dataSource"
			:show-column-headers="false"
			:show-borders="false"
			:row-alternatin-enabled="false"
			:hover-state-enabled="false"
			:height="height"
			:repaint-changes-only="true">
			<DxEditing :texts="{confirmDeleteMessage:''}" />
			<DxScrolling mode="virtual" row-rendering-mode="virtual" />
			<DxLoadPanel :enabled="true" :allow-column-dragging="false" />
			<DxColumn cell-template="comment"/>
			<template #comment="{ data: note }">
				<div>
					<div class="comment" :class="commentClass(note.data)">
						<div class="d-flex align-items-center justify-content-between mb-2">
							<CommentHeader :user="note.data.user" :timestamp="note.data.timestamp" :text="note.data.isSystemGenerated ? note.data.text : ''" />
							<DxDropDownButton icon="overflow" styling-mode="text" :show-arrow-icon="false" :drop-down-options="{width:'8vw'}" :useItemTextAsTitle="false" v-if="hasPermission(constants.PERMISSION_ARCHIVE_CARD_NOTES) || hasPermission(constants.PERMISSION_DELETE_CARD_NOTES)">
								<DxItem
									:onClick="() => { archiveUpdate(note.data) }"
									:text="note.data.archived ? 'Unarchive' : 'Archive'"
									v-if="hasPermission(constants.PERMISSION_ARCHIVE_CARD_NOTES)"
									icon="bi bi-archive" />
								<DxItem
									:onClick="() => { deleteUpdate(note.data) }"
									text="Delete"
									icon="trash"
									v-if="hasPermission(constants.PERMISSION_DELETE_CARD_NOTES)" />
							</DxDropDownButton>
						</div>
						<div class="pl-45">
							<p class="note-html" v-html="note.data.text" v-if="!note.data.isSystemGenerated"></p>
							<div>
								<div class="comment-attachment-preview" v-for="(file, index) in note.data.files" :key="file + index">
									<img :src="imgSrc(file, note.data)" width="40px" height="40px" @click="view(file)" />
								</div>
							</div>
						</div>
					</div>
					<!-- <CommentReply /> The "comment-reply" component can be used here. -->
				</div>
			</template>
		</DxDataGrid>
		<Popup ref="attachmentPreviewPopup" title="Attachment" height="90%" width="80%">
			<div class="text-center">
				<img :src="attachmentPreview.src" width="100%" v-if="attachmentPreview.type == 'img'">
				<iframe :src="content" v-if="attachmentPreview.type == 'pdf'" width="100%" height="700px"></iframe>
			</div>
		</Popup>
	</div>
</template>

<script>
import { DxScrollView } from 'devextreme-vue/scroll-view';
import CommentHeader from '@/components/CommentHeader.vue'
import { DxHtmlEditor } from 'devextreme-vue/html-editor';
import { DxButton } from 'devextreme-vue/button';
import { AxiosWrapper } from '@/mixins'
import { DxValidator, DxRequiredRule } from 'devextreme-vue/validator';
import DxValidationGroup from 'devextreme-vue/validation-group';
import DxDropDownButton, { DxItem } from 'devextreme-vue/drop-down-button'
import { DxDataGrid, DxColumn, DxLoadPanel, DxScrolling, DxEditing, DxMasterDetail} from 'devextreme-vue/data-grid';
import { authHeader } from '@/_helpers/authHeader.js'
import * as AspNetData from 'devextreme-aspnet-data-nojquery';
import DataGrid from "devextreme/ui/data_grid";
import UserAvatar from '@/components/UserAvatar.vue';
DataGrid.defaultOptions({
    options: {
        scrolling: {
            legacyMode: true
        }
    }
});
function createDataSource(self){
    return AspNetData.createStore({
        key: 'id',
        loadUrl: `${process.env.VUE_APP_BACKEND_URL}/api/notes/${self.entityType}/${self.entityId}`,
        deleteUrl: `${process.env.VUE_APP_BACKEND_URL}/api/notes/updates/delete/`,
		loadMode: 'raw',
        reshapeOnPush: true,
        onBeforeSend: function(method, ajaxOptions) {
            ajaxOptions.headers = authHeader();
            if(method == 'delete'){
                ajaxOptions.url += ajaxOptions.data.key
            }
        },
        onLoaded: function(results){
            self.allUpdates = results
        },
		onRemoved: function(){
			window.showSuccessToast('Comment is deleted.')
		}
    });
}
export default {
    name:'CommentSection',
    mixins:[AxiosWrapper],
    props:['entityId', 'entityType', 'mentionables', 'height'],
	components:{
        DxScrollView,
        CommentHeader,
        DxHtmlEditor,
        DxButton,
        DxValidator,
        DxRequiredRule,
        DxValidationGroup,
        DxDropDownButton,
        DxItem,
		DxDataGrid, DxColumn, DxLoadPanel, DxScrolling, DxEditing, DxMasterDetail
    },
	async created(){
        this.mentionableUsers = !this.mentionables ? await this.getMentions() : this.mentionables
	},
    data(){
        return {
			commentNote: {},
			commentFiles: [],
            attachmentPreview: {src: '', type:''},
            allUpdates: [],
            commentReply: { parentId:'', notes: '' },
            reactionKey: 0,
			content: '',
			dataSource: createDataSource(this),
			apiRequest: null,
			showDetails: false,
			mentionableUsers: []
        }
    },
	methods:{
		submit(e){
			this.apiRequest = true
			e.preventDefault()
			let dxMentions = this.$refs.noteEditor.$el.getElementsByClassName('dx-htmleditor-content')[0].getElementsByClassName('dx-mention')
			let obj = {
				entityType: this.entityType,
				entityId: this.entityId,
				text: this.commentNote.text,
				userId: this.Current_User_ID,
                mentionedUsers: Array.prototype.slice.call(dxMentions).map(mention => mention.getAttribute('data-id'))
			}
            let formData = new FormData();
            formData.append("files", JSON.stringify(this.commentFiles));
            formData.append("object", JSON.stringify(obj));
			this.post('api/notes/post-notes', formData, { headers: { 'Content-Type': 'multipart/form-data' }})
				.then(response =>{
					this.commentNote.text = ''
				})
				.catch((e) =>{
                    console.error(e.message)
				})
				.finally(final => {
					this.apiRequest = false
				})
		},
        showCommentBox(note){
			let update = this.getFeedUpdate(note.id)
			update.showCommentBox = !update.showCommentBox
            update.tempReply = { parentId: note.id, notes: '' }
			this.dataSource.push([{ type: 'update', key: 'id', data: update }])
			//this.$forceUpdate()
        },
        nestedReplies(id){
            return this.allUpdates.filter(s=>s.parentId == id)
        },
        commentClass(note){
            if(note.archived){
                return 'comment-shade-archived'
            }
            if(note.seenBy){
                return note.seenBy.includes(this.Current_User_ID) ? 'comment-shade-normal' : 'comment-shade-unseen'
            }
            return 'comment-shade-unseen'
        },
    	submitReply(e, note){
            e.preventDefault()
			let dxMentions = this.$refs[`reply-of-${note.id}`].$el.getElementsByClassName('dx-htmleditor-content')[0].getElementsByClassName('dx-mention')
            note.tempReply.mentionedUsers = Array.prototype.slice.call(dxMentions).map(mention => mention.getAttribute('data-id'))
			this.apiRequest = true
			let data = {
				id: this.projectId,
				notes: note.tempReply.notes,
				userId: this.$store.state.authentication.details.Id,
				commentParentId: note.tempReply.parentId,
				mentionedUsers: note.tempReply.mentionedUsers,
			}
			this.post('api/notes/update-feed', data)
			let update = this.getFeedUpdate(note.id)
			update.showCommentBox = false
			update.tempReply = {}
			this.dataSource.push([{ type: 'update', key: 'id', data: update }])
        },
		addComment(comment){
			let commentToUpdate = comment
			let type = 'insert'
			if(comment.parentId){
				let update = this.getFeedUpdate(comment.parentId)
				if(update){
					update.replies.unshift(comment)
					commentToUpdate = update
					type = 'update'
				}
			}
			this.dataSource.push([{ type: type, key: 'id', data: commentToUpdate }])
			this.apiRequest = false
		},
		async updateFeed(postData){
			const response = await this.post('api/notes/update-feed', postData)
			return response.data
		},
        archiveUpdate(note){
            this.post('api/notes/toggle-archive/' + note.id).then(() => {
                note.archived = !note.archived
                window.showSuccessToast(`Update is ${note.archived ? 'archived' : 'un-archived'}.`)
            })
        },
        deleteUpdate(note){
            window.showConfirmModal({
				confirmButtonText: 'Delete',
				subTitle: 'Deleting is irreversible',
                showLoader: true,
                mode: 'danger',
				onConfirm : async () => {
					let i = this.$refs['commentsTable'].instance.getRowIndexByKey(note.id)
					if(i > -1){
						this.$refs['commentsTable'].instance.deleteRow(i)
					}
				},
			})
        },
        isReactedByUser(note){
            if(note.reactions){
                return note.reactions.map(s=>s.userId).includes(this.Current_User_ID)
            }
            return false
        },
        reactOnComment(note){
            this.get('api/notes/react-on-update/' + note.id).then(response =>{
                note.reactions = response.data
                this.reactionKey++
            })
        },
        imgSrc(file, note){
            let ext = file.split('.').pop()
            if(ext == 'jpg' || ext == 'png' || ext == 'jpeg' || ext == 'bmp') return note.filePreviews[file]
            else if(ext == 'pdf') return '/pdf.png'
            else if(ext == 'docs' || ext == 'docx') return '/docs.png'
            else if(ext == 'zip') return '/zip.png'
        },
        async view(file){
			try {
				const self = this;
				this.apiRequest = true
				const response = await this.post(`api/notes/files/content`, {"filePath":file})
				this.content = response.data;
			} catch (e){
				console.error(e)
			} finally {
				this.apiRequest = false
			}
            let ext = file.split('.').pop()
            if(ext == 'jpg' || ext == 'png' || ext == 'jpeg'){
                this.attachmentPreview.src = this.content
                this.attachmentPreview.type = 'img'
                this.$refs['attachmentPreviewPopup'].show()
            }
            else if(ext == 'pdf'){
                this.attachmentPreview.src = this.content
                this.attachmentPreview.type = 'pdf'
                this.$refs['attachmentPreviewPopup'].show()
            }
            else{
                window.location.href = window.location.origin + file
            }
        },
		getFeedUpdate(id){
			if(!this.$refs['commentsTable']){
				return undefined
			}
			let i = this.$refs['commentsTable'].instance.getRowIndexByKey(id)
			let update = this.$refs['commentsTable'].instance.getVisibleRows()[i].data
			return update
		}
	},
	watch:{
        'mentionables':{
            handler:function(v){
				this.mentionableUsers = v
				this.$forceUpdate()
			},
			deep:true
        },
		'showDetails'(value){
			this.$refs.commentsTable.instance.filter(['isSystemGenerated', '=', value]);
		}
	},
	computed:{

	},
    mounted (){
		this.$connectionHub.$on('new-note-received', (note) => {
			if(note.entityId == this.entityId && note.entityType == this.entityType){
				this.addComment(update)
			}
		});
		this.$refs.commentsTable.instance.filter(['isSystemGenerated', '=', this.showDetails]);
  	},
}
</script>
<style scoped>
.comment-action-btn{
    color: #0976e2;
    font-weight: 600;
    font-size: 12px;
}
.comment{
    padding: 6px 10px;
    border-radius: 10px;
	margin-bottom: 10px
}
.comment-shade-normal{
    background: #f8f8f8;
}
.comment-shade-unseen{
    background: #c1e0ff;
}
.comment-shade-archived{
    background: #E1AEAD;
}
.comment-attachment-preview{
    border: 1px solid #DDDDDD;
    border-radius: 5px;
    height: 50px;
    text-align: center;
    width: 50px;
    padding: 5px;
    margin: 10px 10px 0 0;
    position: relative;
    display: inline-block;
    cursor: pointer;
}
.pl-45{
    padding-left: 45px;
}
.ml-45{
    margin-left: 45px;
}
.followup-text{
    font-size: 12px;
	margin-bottom: 5px;
    color: var(--danger);
}
.comment-editor-container{
	display: flex;
	align-items: flex-start;
	padding: 5px 0 0 10px;
	position: relative;
}
.comment-editor-container .editor-send-btn{
    position: absolute;
    right: 2px;
    bottom: 2px;
}
</style>
<style>
.comment-action-btn .dx-button-content{
    padding: 5px 0;
}
.comment-action-btn.dx-state-hover,
.comment-action-btn.dx-state-active,
.comment-action-btn.dx-state-focused{
    background: transparent;
}
#commentsTable td[role=gridcell]{
	padding: 0;
}
.comment p{
    margin-bottom: 2px !important;
	text-wrap: wrap;
}
.note-html img,
.note-editor img{
	width: 100%
}
</style>
