<template>
	<div>
		<DxHtmlEditor v-model="editor" height="300px" placeholder="Enter description" class="card-htmleditor">
			<DxMediaResizing :enabled="true" />
			<DxImageUpload file-upload-mode="base64" />
			<DxToolbar :multiline="true">
				<DxItem name="undo" />
				<DxItem name="redo" />
				<DxItem name="separator" />
				<DxItem name="separator" />
				<DxItem name="bold" />
				<DxItem name="italic" />
				<DxItem name="strike" />
				<DxItem name="underline" />
				<DxItem name="separator" />
				<DxItem name="alignLeft" />
				<DxItem name="alignCenter" />
				<DxItem name="alignRight" />
				<DxItem name="alignJustify" />
				<DxItem name="separator" />
				<DxItem name="orderedList" />
				<DxItem name="bulletList" />
				<DxItem name="separator" />
				<DxItem name="separator" />
				<DxItem name="color" />
				<DxItem name="background" />
				<DxItem name="separator" />
				<DxItem name="link" />
				<DxItem name="separator" />
				<DxItem name="clear" />
				<DxItem name="codeBlock" />
				<DxItem name="blockquote" />
				<DxItem name="separator" />
				<DxItem name="insertTable" />
				<DxItem name="deleteTable" />
				<DxItem name="insertRowAbove" />
				<DxItem name="insertRowBelow" />
				<DxItem name="deleteRow" />
				<DxItem name="insertColumnLeft" />
				<DxItem name="insertColumnRight" />
				<DxItem name="deleteColumn" />
			</DxToolbar>
		</DxHtmlEditor>
		<div class="d-flex align-items-center mt-2 justify-content-between" v-if="editing">
			<DxButton text="Cancel" @click="$emit('canceled')" />
			<DxButton text="Save" type="default" @click="$emit('saved')" class="ml-2" />
		</div>
	</div>
</template>
<script>
import { DxHtmlEditor, DxToolbar, DxMediaResizing, DxItem, DxImageUpload } from 'devextreme-vue/html-editor';
export default{
	props:['value', 'editing'],
	components:{
		DxHtmlEditor, DxToolbar, DxMediaResizing, DxItem, DxImageUpload
	},
	computed:{
		editor:{
			get(){
				return this.value
			},
			set(v){
				this.$emit('input',v);
			}
		}
	},

}
</script>
<style>
.card-htmleditor img{
	width: 100%
}
</style>
