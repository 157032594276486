<template>
	<CheckPermission :value="constants.PERMISSION_READ_ROLES_SETTINGS" :access-denied-msg="true">
		<b-card class="h-100 overflow-auto" no-body>
			<b-card-header class="d-flex align-items-center justify-content-between">
				<strong>User Permissions</strong>
				<DxButton @click="$router.push('/settings/users/roles')" icon="arrowleft" />
			</b-card-header>
			<b-card-body class="p-0 h-100">
				<div v-width="'40%'" class="p-3">
					<DxField label="User" css-class="mb-0">
						<UsersLookup v-model="userId" @object-changed="onSelectUser" />
					</DxField>
				</div>
				<div>
					<table class="table table-bordered permissions-table-header mr-1">
						<thead>
							<tr>
								<td width="60%"><strong>Permissions</strong></td>
								<td class="text-center" width="40%"><strong>{{formatSnakeCase(group.name)}}</strong></td>
							</tr>
						</thead>
					</table>
					<div v-slimscroll="{height: 'calc(100vh - 16rem)'}">
						<div :key="key" class="permissions-table-body" v-if="showPermissions">
							<div v-for="groupedPermission in groupedPermissions" :key="'perm-type-' + groupedPermission.type">
								<div class="permissions-header">{{groupedPermission.type}}</div>
								<div v-for="permission in groupedPermission.permissions" :key="permission.name">
									<div class="d-flex">
										<div class="td" v-width="'60%'">
											<DxButton class="pl-0 pt-0" v-if="permission.childPermissions.length > 0"
												styling-mode="text" @click="showChildren(permission)"
												:icon="`chevron${permission.showChildren ? 'down' : 'right'}`" />
											<span>
												{{formatSnakeCase(permission.name)}}
												<small v-if="permission.name == 'read_data_reports' && group.name == 'custom'">
													(Restriction: {{restrictionPolicy(permission.name)}})
													<DxButton
														class="position-relative"
														v-height="'20px'" v-width="'20px'"
														:disabled="!havePermission(permission.name)"
														@click="restrictionPopup(userId, permission.name)">
														<Icon name="dx-icon-edit" icon-pack="dx" left="2px"
															:el-attr="{class:'position-absolute'}" top="2px" />
													</DxButton>
												</small>
											</span>
										</div>
										<div class="td text-center" v-width="'40%'">
											<DxCheckBox
												:element-attr="{id:'checkbox_'+permission.name}"
												:value="havePermission(permission.name)"
												@value-changed="selectPermission(permission.name, $event)"
												:disabled="hasPermission(constants.PERMISSION_UPDATE_ROLES_SETTINGS)" />
										</div>
									</div>
									<div>
										<RecursivePermissions
											v-if="permission.childPermissions.length > 0 && permission.showChildren"
											:permissions="permission.childPermissions"
											:havePermission="havePermission"
											:showChildren="showChildren"
											:selectPermission="selectPermission"
											:iteration="0" />
									</div>
								</div>
							</div>
						</div>
						<div v-height="'100px'" class="position-relative border-bottom" v-else>
							<div class="vertical-center">
								<span>Please select user to manage permissions. <Icon name="slash-circle" icon-pack="bootstrap" /></span>
							</div>
						</div>
					</div>
				</div>
				<RoleWarningPopover v-model="warning" @selectPermission="selectPermission"/>
				<DataRestrictionPermission ref="data-restriction-popup" @update="updateRestrictionPolicy" type="UserPermission" />
			</b-card-body>
		</b-card>
	</CheckPermission>
</template>

<script>
import { DxCheckBox } from 'devextreme-vue/check-box';
import { DxPopover } from 'devextreme-vue/popover';
import { DxButton } from 'devextreme-vue/button'
import { AxiosWrapper } from '../../../mixins'
import { UsersLookup } from '../../../components/lookups'
import RecursivePermissions from './recursive-permissions.vue'
import _ from 'lodash';
import RoleWarningPopover from './role-warning-popover';
import { DxScrollView } from 'devextreme-vue/scroll-view';
import DataRestrictionPermission from './data-restriction-permission'

export default {
    name: 'Roles',
	mixins:[AxiosWrapper],
	components: {
        DxButton,
        DxCheckBox,
        RecursivePermissions,
        UsersLookup,
        DxPopover,
        DxScrollView,
        RoleWarningPopover,
        DataRestrictionPermission
    },
	data(){
		return {
            key: this.generateUUID(),
            apiRequest: false,
            permissions: [],
            userId: '',
            user: {},
            group: {},
            userPermissions: [],
            warning:{
                given: false,
                target: '',
                show: false,
                tempPerm: '',
                event: {}
            },
            showPermissions: false
		}
	},
    async created(){
        await this.getPermissions()
    },
    methods:{
        async getPermissions(){
            this.apiRequest = true
            const response = await this.get('api/settings/permissions')
            this.permissions = JSON.parse(response.data)
            this.apiRequest = false
        },
        async onSelectUser(obj){
            this.user = obj
            const response = await this.get(`api/users/${obj.id}/group`)
            this.group = JSON.parse(response.data.group)
            this.userPermissions = JSON.parse(response.data.userPermissions)
            this.showPermissions = true
            this.warning = { given: false, target: '', show: false, tempPerm: '', event: {} }
        },
        havePermission(permission){
            return this.userPermissions.map(s => s.permissionId).includes(permission)
        },
        showChildren(permission){
            permission.showChildren = !permission.showChildren
            this.$forceUpdate()
            this.key = this.generateUUID()
        },
        selectPermission(permission, e){
            if(!e.event) return;
            if(!this.warning.given && this.group.name != 'custom'){
                this.warning.target = '#checkbox_' + permission;
                this.warning.show = true;
                this.warning.tempPerm = permission;
                this.warning.event = e
                return;
            }
            this.post(`api/users/${this.user.id}/permissions`,{
                userId: this.user.id,
                permissionId: permission
            }).then(response => {
                let msg = this.group.name != 'custom' ?
                        `${this.user.firstName}'s role has changed to Custom and has ${e.value ? 'given' : 'been revoked'} the permission to ${this.formatSnakeCase(permission)}` :
                        `${this.user.firstName} has ${e.value ? 'given' : 'been revoked'} the permission to ${this.formatSnakeCase(permission)}`
                window.showSuccessToast(msg)
                this.group = JSON.parse(response.data.group)
                this.userPermissions = JSON.parse(response.data.userPermissions)
            }).catch(error => {
                e.component.option('value', !e.value)
            })
        },
        restrictionPopup(userId, permissionId){
            this.$refs['data-restriction-popup'].show(userId, permissionId)
        },
        updateRestrictionPolicy(policy){
            let permission = this.userPermissions.find(s => s.permissionId == constants.PERMISSION_READ_DATA_REPORTS)
            permission.reportDataRestriction = policy
            this.$forceUpdate()
        }
    },
    computed:{
        groupedPermissions(){
            return _(this.permissions.filter(s => !s.parentPermissionId))
                .groupBy(x => x.permissionType)
                .map((value, key) => ({type: key, permissions: value}))
                .value();
        },
        restrictionPolicy(){
            return (permissionId) =>{
                let permission = this.userPermissions.find(s => s.permissionId == permissionId)
                if(permission){
                    if(permission.reportDataRestriction == 'MyData') return 'User Data'
                    else if(permission.reportDataRestriction == 'MyDepartment') return 'User Department'
                    else if(permission.reportDataRestriction == 'MyHierarchy') return 'User Hierarchy'
                    else if(permission.reportDataRestriction == 'CustomDepartments') return 'Custom Departments'
                    else if(permission.reportDataRestriction == 'CustomUsers') return 'Custom Users'
                    else return permission.reportDataRestriction
                }
                return 'Off'
            }
        },
    },
}
</script>
<style scoped>
.pl-32{
    padding-left: 32px;
}
.pl-64{
    padding-left: 64px;
}
.vertical-line{
    position: absolute;
    margin: 0;
    width: 22px;
    left: -2px;
    top: 0px;
    rotate: 90deg;
}
.horizontal-line{
    position: absolute;
    width: 20px;
    margin: 0;
    top: 50%;
    left: 9px;
}
.permissions-header{
    border-bottom: 1px solid;
    border-color: #d8dbe0;
    padding: 10px 0 10px 20px;
    font-weight: 600;
}
</style>
<style>
.permissions-table-header{
    border: 0;
    margin-bottom: 0;
}
.permissions-table-header thead td{
    border-left: 0;
    border-bottom-width: 1px;
}
.permissions-table-header tr td:first-child{
    padding-left: 20px !important;
}
.permissions-table-body{
    color: #4f5d73;
}
.permissions-table-body .td{
    padding:10px;
    border-right: 1px solid;
    border-bottom: 1px solid;
    border-color: #d8dbe0;
}
.permissions-table-body .td:first-child{
    padding-left: 30px !important;
}
/* .warning .warning-note{
	font-size: 0.90rem;
    font-weight: 600;
}
.warning .warning-actions{
	float: right;
	margin-top: 15px;
} */
</style>./recursive-roles.vue
