<template>
	<div class="card" id="dashboard-tasks-panel">
		<LoadPanel elementId="#dashboard-tasks-panel" :visible="apiRequest" :shading="false" />
		<div class="card-header d-flex align-items-center justify-content-between">
			<strong>Tasks</strong>
			<div>
				<DxButton icon="add" @click="addTask"  />
			</div>
		</div>
		<div class="card-body p-0">
			<dx-data-grid class="dx-card wide-card header-table" id="tasks-table" :data-source="cards" :show-column-lines="false"
				:show-borders="false" :focused-row-enabled="true" :column-auto-width="true" :column-hiding-enabled="true" key-expr="id"
				:on-row-click="onRowClicked">
				<dx-paging :page-size="10" />
				<dx-pager :show-page-size-selector="true" :show-info="true" />
				<!-- <dx-filter-row :visible="true" /> -->

				<dx-column data-field="users" caption="Task" cell-template="assignedToTemplate" />
				<template #assignedToTemplate="{ data }">
					<div class="d-flex align-items-center">
						<div class="d-flex align-items-center mr-2">
							<UserAvatar v-for="assignedUser in data.value" :user="assignedUser" :key="assignedUser.id" class="user-avatar-row-item"/>
						</div>
						{{ data.data.subject }}
					</div>
				</template>
				<!-- <dx-column data-field="subject" caption="Subject" /> -->
				<dx-column data-field="status" caption="Status" :width="150" cell-template="statusTemplate" />
				<template #statusTemplate="{ data }">
					<CardStatus :value="data.value" />
				</template>
				<dx-column data-field="priority" caption="Priority" :width="150" cell-template="priorityTemplate" />
				<template #priorityTemplate="{ data }">
					<CardPriority :value="data.value" />
				</template>
				<dx-column data-field="dueDate" caption="Due Date" data-type="date" :width="100" />
			</dx-data-grid>
		</div>
	</div>
</template>

<script>
import "devextreme/data/odata/store";
import DxDataGrid, {
	DxColumn,
	DxFilterRow,
	DxPager,
	DxPaging
} from "devextreme-vue/data-grid";
import { AxiosWrapper } from "@/mixins";
import CardStatus from "@/components/CardStatus";
import CardPriority from "@/components/CardPriority";

export default {
	mixins: [AxiosWrapper],
	components: {
		DxDataGrid,
		DxColumn,
		DxFilterRow,
		DxPager,
		DxPaging,
		CardStatus,
		CardPriority
	},
	data() {
		return {
			cards: [],
			apiRequest: false
		};
	},
	created() {
		this.load()
	},
	methods:{
		load(){
			this.apiRequest = true
			this.get('api/cards/my').then(response => {
				this.cards = response.data
			}).finally(() => {
				this.apiRequest = false
			})
		},
		addTask(){
			window.showCardPopup()
		},
		onRowClicked(e){
			window.showCardPopup(e.data.id)
		}
	},
	mounted(){
		this.$connectionHub.$on('new-card-added', () => { this.load() })
	}
};
</script>
<style>
#tasks-table .dx-data-row.dx-state-hover:not(.dx-selection):not(.dx-row-inserted):not(.dx-row-removed):not(.dx-edit-row) > td:not(.dx-focused) {
    cursor: pointer;
}
.user-avatar-row-item{
	margin-left: -8px;
}
.user-avatar-row-item:first-child{
	margin-left: 0;
}
.dx-card .dx-data-row{
	cursor:pointer;
}
</style>
