<template>
	<div class="side-nav-inner-toolbar">
		<dx-drawer class="drawer" position="before" template="menu" :opened.sync="menuOpened"
			:opened-state-mode="drawerOptions.menuMode" :reveal-mode="drawerOptions.menuRevealMode"
			:min-size="drawerOptions.minMenuSize" :shading="drawerOptions.shaderEnabled"
			:close-on-outside-click="drawerOptions.closeOnOutsideClick">
			<div class="dx-container">
				<header-toolbar :title="title" :menu-toggle-enabled="headerMenuTogglerEnabled" :toggle-menu-func="toggleMenu" />
				<dx-scroll-view ref="scrollViewRef" class="layout-body with-footer">
					<slot />
					<slot name="footer" />
				</dx-scroll-view>
			</div>
			<template #menu>
				<side-nav-menu :compact-mode="!menuOpened" @click="handleSideBarClick">
					<dx-toolbar class="bg-black" id="navigation-header">
						<dx-item v-if="!menuOpened" location="before" css-class="menu-button">
							<template #default>
								<dx-button styling-mode="text" @click="toggleMenu" id="hamburger-icon-stack360">
									<img fluid :src="require('../assets/images/icon.png')" style="width:38px"></img>
								</dx-button>
							</template>
						</dx-item>
						<dx-item v-if="menuOpened" location="center" css-class="header-title dx-toolbar-label mw-100">
							<template #default>
								<div class="pointer" @click="$router.push('/home')">
									<img fluid :src="require('../assets/images/sidebar-logo.svg')" style="width:140px"></img>
								</div>
							</template>
						</dx-item>
					</dx-toolbar>
				</side-nav-menu>
			</template>
		</dx-drawer>
	</div>
</template>

<script>
import DxButton from "devextreme-vue/button";
import DxDrawer from "devextreme-vue/drawer";
import DxScrollView from "devextreme-vue/scroll-view";
import DxToolbar, { DxItem } from "devextreme-vue/toolbar";

import HeaderToolbar from "../components/header-toolbar";
import SideNavMenu from "../components/side-nav-menu";
import menuItems from "../router/app-navigation";

export default {
	props: {
		title: String,
		isXSmall: Boolean,
		isLarge: Boolean,
	},
	created(){
		this.startSignalR()
	},
	methods: {
		toggleMenu(e) {
			const pointerEvent = e.event;
			pointerEvent.stopPropagation();
			if (this.menuOpened) {
				this.menuTemporaryOpened = false;
			}
			this.menuOpened = !this.menuOpened;
		},
		handleSideBarClick() {
			if (this.menuOpened === false) this.menuTemporaryOpened = true;
			this.menuOpened = true;
		},
	},
	data() {
		return {
			menuOpened: this.isLarge,
			menuTemporaryOpened: false,
			menuItems,
		};
	},
	computed: {
		drawerOptions() {
			const shaderEnabled = !this.isLarge;
			return {
				menuMode: this.isLarge ? "shrink" : "overlap",
				menuRevealMode: this.isXSmall ? "slide" : "expand",
				minMenuSize: this.isXSmall ? 0 : 60,
				menuOpened: this.isLarge,
				closeOnOutsideClick: shaderEnabled,
				shaderEnabled,
			};
		},
		headerMenuTogglerEnabled() {
			return this.isXSmall;
		},
		scrollView() {
			return this.$refs["scrollViewRef"].instance;
		},
	},
	watch: {
		isLarge() {
			if (!this.menuTemporaryOpened) {
				this.menuOpened = this.isLarge;
			}
		},
		$route() {
			if (this.menuTemporaryOpened || !this.isLarge) {
				this.menuOpened = false;
				this.menuTemporaryOpened = false;
			}
			this.scrollView.scrollTo(0);
		}
	},
	components: {
		DxButton,
		DxDrawer,
		DxScrollView,
		DxToolbar,
		DxItem,
		HeaderToolbar,
		SideNavMenu,
	},
};
</script>

<style lang="scss">
@import "../themes/generated/variables.additional.scss";
.side-nav-inner-toolbar {
	width: 100%;
}

#navigation-header {
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);

	.menu-button .dx-icon {
		color: $base-text-color;
	}

	.screen-x-small & {
		padding-left: 20px;
	}

	.dx-theme-generic & {
		padding-top: 10px;
		padding-bottom: 10px;
	}
}

#hamburger-icon-stack360 .dx-button-content{
	padding-block: 0;
}
</style>
