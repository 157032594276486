<template>
    <div class="card" id="dashboard-department-leaves">
        <div class="card-header"><strong>{{department.name}} Leaves</strong></div>
		<div class="card-body p-0">
			<table class="table mb-0">
				<EmptyRow v-if="leaves.length == 0" :loading="apiRequest" />
				<tr v-for="leave in leaves" :key="leave.id">
					<td width="30%" v-if="leave.dates.length > 0">
						<div>{{formatUtcToLocalDateTime(leave.startDate, 'MM/DD @ hh:mma')}}</div>
						<div>{{formatUtcToLocalDateTime(leave.endDate, 'MM/DD @ hh:mma')}}</div>
					</td>
					<td width="30%" v-else>
						<div>{{leave.dates[0].date | shortDate}}</div>
						<div>{{leave.dates[0].timespanStr}}</div>
					</td>
					<td class="d-flex align-items-center justify-content-between">
						{{leave.user.lexicalName}} ({{leave.type}})
					</td>
				</tr>
			</table>
		</div>
    </div>
</template>

<script>
import { AxiosWrapper } from '@/mixins'
export default {
    mixins:[AxiosWrapper],
    data(){
        return {
            leaves: [],
            department: {},
            apiRequest: false,
        }
    },
    created(){
        this.apiRequest = true
        this.get('api/dashboard/department-leaves').then(response =>{
            this.department = response.data.department
            this.leaves = response.data.leaves
        }).finally(final =>{
            this.apiRequest = false
        })
    }
}
</script>
