<template>
	<CheckPermission :value="constants.PERMISSION_READ_INTEGRATION_SETTINGS" :access-denied-msg="true">
		<b-card no-body>
			<b-card-body class="p-0 h-100">
				<iframe :src="hangfire.url" style="width:100%; height:100%; margin-bottom:-8px"></iframe>
			</b-card-body>
		</b-card>
	</CheckPermission>
</template>

<script>

export default {
	data(){
		return {
            hangfire:{
                showDashboard: false,
                url: process.env.VUE_APP_BACKEND_URL + '/hangfire'
            }
		}
	},
	mounted(){
        this.hangfire.url = `${process.env.VUE_APP_BACKEND_URL}/hangfire?access_token=${this.Current_User_Info.Token}`
	},
};
</script>
