import Home from "../views/home-page";
import Settings from '@/views/settings/settings-page.vue'
import UserManagement from "../views/users/user-management-page.vue";
import DefaultLayout from "../layouts/side-nav-inner-toolbar";
import SimpleLayout from "../layouts/single-card";
import Stack360Holidays from "@/views/settings/stack360-holidays-page.vue";
import LeavePolicies from "@/views/settings/leave-policies-page.vue";
import Departments from "@/views/settings/departments-page.vue";
import Levels from "@/views/settings/levels-page.vue";
import Roles from "@/views/settings/roles/roles-page.vue";
import General from '@/views/settings/general'
import UserPermissions from '@/views/settings/roles/user-permissions'
import ResourceRequest from "@/views/resourcing/ResourceRequest.vue";
import ServiceTypes from '@/views/settings/services/service-types.vue'
import ClientsTable from '@/views/clients/clients-table.vue'
import ServicesTable from '@/views/services/services-table.vue'
import UserLeavesPage from '@/views/leaves/user-leaves-page.vue'
import Resourcing from '@/views/resourcing/Resourcing.vue'
import Assets from '@/views/assets/assets.vue'
import ScheduledTasks from '@/views/settings/scheduled-tasks.vue'
import AttendanceLogs from '@/views/attendance/attendance-logs.vue'
import ExtraDays from '@/views/extra-days/extra-days.vue'

export const allRoutes = [
	{
		path: "/login",
		name: "login",
		meta: { requiresAuth: false },
		components: {
			layout: SimpleLayout,
			content: () => import("../views/login-page"),
		},
		props: {
			layout: { title: "Sign In" },
		},
	},
	{
		path: "/home",
		name: "Dashboard",
		meta: { requiresAuth: true },
		components: { layout: DefaultLayout, content: Home },
	},
	{
		path: "/leaves",
		name: "Leaves",
		meta: { requiresAuth: true },
		components: { layout: DefaultLayout, content: UserLeavesPage },
	},
	{
		path: "/resourcing",
		name: "Resourcing",
		meta: { requiresAuth: true },
		components: { layout: DefaultLayout, content: Resourcing },
	},
	{
		path: "/resourcing/request",
		name: "ResourceRequest",
		meta: { requiresAuth: true },
		components: { layout: DefaultLayout, content: ResourceRequest },
	},
	{
		path: "/clients",
		name: "Clients",
		meta: { requiresAuth: true },
		components: { layout: DefaultLayout, content: ClientsTable },
	},
	{
		path: "/services",
		name: "Services",
		meta: { requiresAuth: true },
		components: { layout: DefaultLayout, content: ServicesTable },
	},
	{
		path: "/attendance-logs",
		name: "AttendanceLogs",
		meta: { requiresAuth: true },
		components: { layout: DefaultLayout, content: AttendanceLogs },
	},
	{
		path: "/extra-days",
		name: "ExtraDays",
		meta: { requiresAuth: true },
		components: { layout: DefaultLayout, content: ExtraDays },
	},
	{
		path: "/settings/all",
		name: "Settings",
		meta: { requiresAuth: true, isSettingsPage: false },
		components: { layout: DefaultLayout, content: Settings },
	},
	{
		path: "/settings/general/default",
		name: 'GeneralSettings',
		meta: { requiresAuth: true, isSettingsPage: true },
		components: { layout: DefaultLayout, content: General },
	},
	{
		path: "/settings/general/departments",
		name: "Departments",
		meta: { requiresAuth: true, isSettingsPage: true },
		components: { layout: DefaultLayout, content: Departments },
	},
	{
		path: "/settings/general/levels",
		name: "Levels",
		meta: { requiresAuth: true, isSettingsPage: true },
		components: { layout: DefaultLayout, content: Levels },
	},
	{
		path: "/settings/general/holidays",
		name: "Holidays",
		meta: { requiresAuth: true, isSettingsPage: true },
		components: { layout: DefaultLayout, content: Stack360Holidays },
	},
	{
		path: "/settings/users/management",
		name: "UserManagement",
		meta: { requiresAuth: true, isSettingsPage: true },
		components: { layout: DefaultLayout, content: UserManagement },
	},
	{
		path: "/settings/users/roles",
		name: "Roles",
		meta: { requiresAuth: true, isSettingsPage: true },
		components: { layout: DefaultLayout, content: Roles },
	},
	{
		path: "/settings/users/roles/permissions",
		name: "Roles",
		meta: { requiresAuth: true, isSettingsPage: true },
		components: { layout: DefaultLayout, content: UserPermissions },
	},
	{
		path: "/settings/users/leave-policies",
		name: "LeavePolicies",
		meta: { requiresAuth: true, isSettingsPage: true },
		components: { layout: DefaultLayout, content: LeavePolicies },
	},
	{
		path: "/settings/services/types",
		name: "ServiceTypes",
		meta: { requiresAuth: true, isSettingsPage: true },
		components: { layout: DefaultLayout, content: ServiceTypes },
	},
	{
		path: "/settings/assets",
		name: "Assets",
		meta: { requiresAuth: true, isSettingsPage: true },
		components: { layout: DefaultLayout, content: Assets },
	},
	{
		path: "/settings/scheduled-tasks",
		name: "Scheduled Tasks",
		meta: { requiresAuth: true, isSettingsPage: true },
		components: { layout: DefaultLayout, content: ScheduledTasks },
	},
	{
		path: "/",
		redirect: "/home",
	},
	{
		path: "/recovery",
		redirect: "/home",
	},
	{
		path: "*",
		redirect: "/home",
	},
];
