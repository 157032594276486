<template>
	<div class="mt-4">
		<div class="d-flex align-items-center justify-content-between mb-3">
			<h4>Resources</h4>
			<div class="d-flex align-items-center">
				<DxButton icon="refresh" class="mr-2 refresh-button" @click="$emit('refresh')" />
				<Lookup
					v-if="searchByDepartment"
					v-model="viewUser"
					label-mode="static" label="User"
					style="width:300px"
					:api="`api/resourcing/departments/users?week=${currentWeek}&departments=${departmentIds.join(',')}`"
					display-expr="userName" value-expr="id"
					:all="true" :show-clear-button="true" />
			</div>
		</div>
		<div id="users-persp-table" :key="tableKey">
			<table class="table table-bordered text-center resources-table-header mb-0">
				<thead>
					<tr>
						<th class="text-left" width="22%"></th>
						<th width="13%">Allocation</th>
						<th width="13%">Monday {{weeklyDate(WeekDays.Monday)}}</th>
						<th width="13%">Tuesday {{weeklyDate(WeekDays.Tuesday)}}</th>
						<th width="13%">Wednesday {{weeklyDate(WeekDays.Wednesday)}}</th>
						<th width="13%">Thursday {{weeklyDate(WeekDays.Thursday)}}</th>
						<th width="13%">Friday {{weeklyDate(WeekDays.Friday)}}</th>
					</tr>
				</thead>
			</table>
			<div>
				<table class="table table-bordered text-center resources-table mb-2">
					<tbody :key="tableBodyKey">
						<EmptyRow v-if="resourceAllocations.length == 0"/>
						<div v-fragments v-for="project in resourcedProjects" :key="project.id">
							<tr :ref="`project-${project.id}`" class="tr-vertical-middle">
								<td class="text-left" width="22%">
									<div class="d-flex align-items-center justify-content-between">
										<div>
											<strong>{{project.name}}</strong>
											<div>
												<DispositionBadge :value="project.projectType" />
												<!-- <ServiceTypeBadge :value="service.serviceType" class="ml-2" /> -->
											</div>
										</div>
										<DxButton
											v-if="hasPermission(constants.PERMISSION_UPDATE_RESOURCING)"
											@click="addUserAllocation(project)"
											:disabled="disableUserAllocation"
											styling-mode="text" class="float-right p-0 m-0">
											<Icon name="plus-circle" icon-pack="bootstrap"/>
										</DxButton>
									</div>
								</td>
								<td width="13%">
									{{projectAllocatedHours(project.id)}}
								</td>
								<td width="13%" v-for="weeklyAllocation in weeklyAllocations(project.id)" :key="`${project.id}-${weeklyAllocation.day}`">
									<span v-if="!weeklyAllocation.isHoliday">{{weeklyAllocation.hours | oneDecimalPlace}}</span>
									<span v-else>Holiday</span>
								</td>
							</tr>
							<tr v-show="project.showUserAllocationForm">
								<td colspan="7">
									<form @submit="saveUserAllocation(project, $event)">
										<div class="d-flex">
											<DxSelectBox v-model="newUserAllocation" v-width="'100%'" :items="selectableUsers" display-expr="fullName">
												<DxValidator> <DxRequiredRule message="User is required"/> </DxValidator>
											</DxSelectBox>
											<DxButton
												class="ml-2" icon="check"
												v-if="hasPermission(constants.PERMISSION_UPDATE_RESOURCING)"
												:use-submit-behavior="true"  />
											<DxButton
												class="ml-2" icon="close"
												v-if="hasPermission(constants.PERMISSION_UPDATE_RESOURCING)"
												@click="cancelUserAllocation(project)" />
										</div>
									</form>
								</td>
							</tr>
							<tr v-for="resourceAllocation in allocatedUsers(project.id)" :key="`${project.id}-${resourceAllocation.id}`">
								<td width="35%" colspan="2" class="text-left indent" :class="allocationTdClass(resourceAllocation)" :id="`id-${project.id}-${resourceAllocation.userId}`">
									<div class="pl-4">{{resourceAllocation.user.fullName}}</div>
									<DxTooltip :target="`#id-${project.id}-${resourceAllocation.userId}`" show-event="mouseenter" hide-event="mouseleave" position="bottom">
										{{resourceAllocation.allocatedHours | twoDecimals}}/{{resourceAllocation.totalHours | twoDecimals}} ({{(resourceAllocation.totalHours > 0 ? resourceAllocation.allocatedHours/resourceAllocation.totalHours : 0) * 100 | twoDecimals}}%)
									</DxTooltip>
								</td>
								<td width="13%" v-for="userProject in userAllocation(resourceAllocation, project.id)" :key="`${project.id}-${userProject.day}`">
									<InlineEdit
										type="number" :min="0"
										v-model="userProject.hours"
										:inside-buttons="true" :slot-element="true"
										@onSave="updateProjectAllocationHours(resourceAllocation, userProject.projectWeeklyAllocation, ...arguments)"
										:disabled="isActionDisabled(userProject, resourceAllocation.user.fullName).disabled"
										v-if="!userProject.isHoliday">
										<div class="d-flex align-items-center justify-content-center" v-if="!userProject.isHoliday">
											<span v-tooltip="{content: isActionDisabled(userProject, resourceAllocation.user.fullName).msg, html:true, classes: 'tooltip-danger text-left'}">
												{{userProject.hours | oneDecimalPlace}}
											</span>
											<UserLeaveBadge
												class="ml-2"
												:userLeaves="userProject.userLeaves"
												:date="userProject.date" />
											<DxButton
												v-if="showFillNextEntryBtn(userAllocation(resourceAllocation, project.id), userProject.projectWeeklyAllocation, project.weekDate)"
												@click.stop="fillNextDayEntry(userAllocation(resourceAllocation, project.id), userProject.projectWeeklyAllocation, project)"
												styling-mode="text" class="ml-2 p-0">
												<Icon name="arrow-right-circle" icon-pack="bootstrap" v-if="hasPermission(constants.PERMISSION_UPDATE_RESOURCING)"/>
											</DxButton>
										</div>
									</InlineEdit>
									<span v-else>Holiday</span>
								</td>
							</tr>
						</div>
						<div v-fragments>
							<tr v-if="resourceAllocations.length > 0">
								<td></td>
								<td class="font-weight-bold" :class="allocationTdClass(summary.total)">
									{{summary.total.allocated | oneDecimalPlace}}/{{summary.total.totalHours | oneDecimalPlace}}
									({{summary.total.percent | twoDecimals}}%)
								</td>
								<td v-for="day in ['Monday','Tuesday','Wednesday','Thursday','Friday']" :key="day+'-summary'" class="font-weight-bold"  :class="allocationTdClass(summary[day])">
									<span v-if="summary[day].isHoliday">Holiday</span>
									<span v-else>
										{{summary[day].allocated | oneDecimalPlace}}/{{summary[day].totalHours | oneDecimalPlace}}
										({{summary[day].percent | twoDecimals}}%)
									</span>
								</td>
							</tr>
						</div>
					</tbody>
				</table>
			</div>
		</div>
	</div>
</template>


<script>
import Resourcing from '@/mixins/views/Resourcing'
import { ProjectLookup, Lookup } from '@/components/lookups'
import { DayOfWeek, WeekDays, Disposition } from '@/_helpers/constants'
import InlineEdit from '@/components/InlineEdit'
import { DxButton } from 'devextreme-vue/button'
import DxValidationGroup from 'devextreme-vue/validation-group';
import { DxTooltip } from 'devextreme-vue/tooltip';
import DxSelectBox from 'devextreme-vue/select-box';
import { DxValidator, DxRequiredRule } from 'devextreme-vue/validator'
const billableProjectsFilter = (p) => [Disposition.External, Disposition.InternalCharge].includes(p.projectType)

export default{
	props: ['value','currentWeek','departmentIds','searchByDepartment','tableRequest'],
	mixins:[Resourcing],
	components:{
		ProjectLookup, Lookup, InlineEdit, DxButton, DxValidationGroup, DxTooltip, DxSelectBox, DxValidator, DxRequiredRule
	},
	data(){
		return {
			viewUser: '',
			tableKey: this.generateUniqueCode(),
			tableBodyKey: this.generateUniqueCode(),
			disableProjectAllocation: false,
			newUserAllocation: {userId: '', user: {}},
			selectableUsers: [],
			summary: { Monday: {}, Tuesday: {}, Wednesday: {}, Thursday: {}, Friday: {}, total: {}},
			disableUserAllocation: false
		}
	},
	methods:{
		projectAllocatedHours(projectId){
			let projectAllocations = this.resourceAllocations.map(ra => ra.projectAllocations.filter(billableProjectsFilter)).flat().filter(s => s.projectId == projectId)
			let hours = projectAllocations.map(s => Number(s.hours)).reduce((a,b) => a + b, 0)
			return hours
		},
		weeklyAllocations(projectId){
			let projectAllocations = this.resourceAllocations.map(ra => ra.projectAllocations.filter(billableProjectsFilter)).flat().filter(s => s.projectId == projectId)
			let dptViewAllocations = WeekDays.WorkingDays().map(day => {
				let dayAllocation = projectAllocations.filter(pa => pa.day == day)
				return {
					isHoliday: dayAllocation.length ? dayAllocation[0].isHoliday : false,
					day: day,
					hours: dayAllocation.length ? dayAllocation.map(s => Number(s.hours)).reduce((a,b) => a + b, 0) : 0
				}
			});
			return dptViewAllocations
		},
		allocatedUsers(projectId){
			return this.resourceAllocations.filter(ra => ra.projectAllocations.some(pa => pa.projectId == projectId)).sortByFn(x=>x.user.fullName.toLowerCase())
		},
		userAllocation(resourceAllocation, projectId){
			let userAllocations = WeekDays.WorkingDays().map(day => {
				let dayAllocation = resourceAllocation.projectAllocations.find(s => s.projectId == projectId && s.day == day)
				let weekAllocation = resourceAllocation.weeklyAllocations.find(s => s.day == day)
				return {
					isHoliday: dayAllocation.isHoliday,
					day: day,
					date: weekAllocation.date,
					hours: Number(dayAllocation.hours),
					projectWeeklyAllocation: dayAllocation,
					//hours: dayAllocation.map(s => Number(s.hours)).reduce((a,b) => a + b, 0),
					userLeaves: weekAllocation.userLeaves,
					isFullDayLeave: weekAllocation.isFullDayLeave
				}
			});
			return userAllocations
		},
		isActionDisabled(obj, user){
			let userProject = obj
			let isDatePast = this.isDatePast(undefined, userProject.day)
			let response = {
				disabled: isDatePast || userProject.isFullDayLeave || !this.hasPermission(constants.PERMISSION_UPDATE_RESOURCING)
			}
			if (response.disabled){
				response.msg = `<strong>Editing hours isn't permitted:</strong> </br>`
				response.msg += userProject.isFullDayLeave ? `• ${user} is on full day leave on ${userProject.day}. </br>` : ''
				response.msg += isDatePast ? `• Hours of dates in past can not be edited.` : ''
			}
			return response
		},
		addUserAllocation(project){
			project.showUserAllocationForm = true
			this.disableUserAllocation = true
			this.selectableUsers = this.cleanSource(this.resourceAllocations.map(s => s.user))
			this.selectableUsers.forEach(user => {
				if(this.allocatedUsers(project.id).map(u => u.userId).some(u => u == user.id)){
					user.disabled = true
				}
			})
			this.$forceUpdate()
		},
		saveUserAllocation(project, e){
			e.preventDefault()
			let allocation = this.resourceAllocations.find(s => s.userId == this.newUserAllocation.id)
			this.$emit('update:tableRequest', true)
			this.post('api/resourcing/project-allocations', {
				allocationId: allocation.id,
				projectId: project.id,
				holidays: allocation.weeklyAllocations.map(wa => wa.isHoliday ? DayOfWeek[wa.day] : undefined).filter(s => s != undefined)
			}).then(response =>{
				allocation.projectAllocations.push.apply(allocation.projectAllocations, response.data)
				this.updateAllocationRow(allocation)
				this.cancelUserAllocation(project)
			}).finally(final =>{
				this.$emit('update:tableRequest', false)
			})
		},
		cancelUserAllocation(project){
			project.showUserAllocationForm = false
			this.disableUserAllocation = false
			this.selectableUsers = []
			this.newUserAllocation = {userId: '', user: {}}
			this.$forceUpdate()
		},
		updateProjectAllocationHours(allocation, projectAllocation, val){
			this.$emit('update:tableRequest', true)
			this.post('api/resourcing/project-allocations/hours',{
				id: projectAllocation.id || projectAllocation.projectWeeklyAllocation.id,
				hours: val || 0,
				day: projectAllocation.day,
				resourceAllocationId: projectAllocation.resourceAllocationId || projectAllocation.projectWeeklyAllocation.resourceAllocationId,
				projectType: projectAllocation.projectType || projectAllocation.projectWeeklyAllocation.projectType
			}).then(response => {
				this.updateAllocationRow(response.data)
				this.$emit('updateAllocationRow', projectAllocation.projectId)
			}).finally(final =>{
				this.$emit('update:tableRequest', false)
			})
		},
		updateAllocationRow(allocation){
			let i = this.resourceAllocations.findIndex(s=>s.id == allocation.id)
			this.resourceAllocations[i] = allocation
			this.tableBodyKey = this.generateUniqueCode()
			this.$emit('input', this.resourceAllocations)
			this.$forceUpdate()
			this.calculateSummary()
		},
		fillNextDayEntry(weeklyAllocationObj, projectAllocation, allocation){
			let nextDay = DayOfWeek.nextDay(projectAllocation.day)
			let nextDayAllocation = weeklyAllocationObj.find(s=>s.day == nextDay)
			this.updateProjectAllocationHours(allocation, nextDayAllocation, Number(projectAllocation.hours))
		},
		calculateSummary(){
			this.summary = { Monday: {}, Tuesday: {}, Wednesday: {}, Thursday: {}, Friday: {}, total: {}}
			if(this.resourceAllocations.length){
				this.summaryDays.forEach(day => {
					let days = this.resourceAllocations.map(s => s.weeklyAllocations).flat().filter(s => s.day == day)
					let totalHours = days.map(s => Number(s.totalHours)).reduce((a,b) => a + b, 0)
					let projectAllocations = this.resourceAllocations.map(ra => ra.projectAllocations.filter(billableProjectsFilter)).flat().filter(s => s.day == day)
					let projectViewAllocatedHours = projectAllocations.map(s => Number(s.hours)).reduce((a,b) => a + b, 0)
					this.summary[day] = {
						totalHours: totalHours,
						allocated: projectViewAllocatedHours,
						percent: totalHours > 0 ? (projectViewAllocatedHours / totalHours) * 100 : 0
					}
				})
				let allProjectAllocations = this.resourceAllocations.map(ra => ra.projectAllocations.filter(billableProjectsFilter)).flat()
				let total = this.resourceAllocations.map(s => Number(s.totalHours)).reduce((a,b) => a+b, 0)
				let projectsViewAllocatedHours = allProjectAllocations.map(s => Number(s.hours)).reduce((a,b) => a + b, 0)
				this.summary.total = {
					totalHours: total,
					allocated: projectsViewAllocatedHours,
					percent: total > 0 ? (projectsViewAllocatedHours / total) * 100 : 0,
				}
			}
		},
	},
	computed:{
		resourceAllocations:{
			get(){
				return (this.value || []).filter(ra => this.viewUser ? ra.userId == this.viewUser : true)
			},
			set(v){
				this.$emit('input', v);
			}
		},
		resourcedProjects:{
			get(){
				let projectAllocations = this.resourceAllocations.map(ra => ra.projectAllocations).flat()
				let uniqueObjArray = [ ...new Map(projectAllocations.map((item) => [item["projectId"], item])).values()]
				let resourcedProjects = uniqueObjArray.map(s => {
					return {
						id: s.project.id,
						name: s.project.name,
						projectType: s.projectType,
						// serviceType: s.project.serviceType
					}
				}).sortByFn(x => x.name.toLowerCase())
				return resourcedProjects
			},
			set(v){
				this.$emit('input', v);
			}
		},
	},
	watch:{
		value(v){
			this.calculateSummary()
			this.tableKey = this.generateUniqueCode()
		}
	},
	mounted(){
		this.calculateSummary()
	}
}
</script>
