export const DefaultNav = [
  {
	id: 0,
    text: "Dashboard",
    path: "home",
    icon: "home",
  },
  {
	id: 1,
    text: "Resourcing",
    path: "resourcing",
    icon: "bi bi-person-lines-fill",
  },
  {
	id: 2,
    text: "Leaves",
    path: "leaves",
    icon: "bi bi-calendar-date",
  },
  {
	id: 3,
    text: "Clients",
    path: "clients",
    icon: "bi bi-people-fill",
  },
  {
	id: 4,
    text: "Services",
    path: "services",
    icon: "bi bi-kanban-fill",
  },
  {
	id: 5,
    text: "Attendance Logs",
    path: "attendance-logs",
    icon: "bi bi-clock",
  },
  {
	id: 6,
    text: "Extra Days",
    path: "extra-days",
    icon: "bi bi-sun",
	permission: window.constants.PERMISSION_READ_ALL_EXTRA_DAY_REQUESTS
  },
];

export const SettingsNav = [
	{ key: 11, text: "Default", path: "settings/general/default", icon: 'bi bi-sliders', permission: window.constants.PERMISSION_READ_GENERAL_SETTINGS },
	{ key: 12, text: "Departments", path: "settings/general/departments", icon: 'bi bi-building', permission: window.constants.PERMISSION_READ_GENERAL_SETTINGS },
	{ key: 13, text: "Levels", path: "settings/general/levels", icon: 'bi bi-person-badge', permission: window.constants.PERMISSION_READ_GENERAL_SETTINGS },
	{ key: 14, text: "Holidays", path: "settings/general/holidays", icon: 'bi bi-calendar-x', permission: window.constants.PERMISSION_READ_GENERAL_SETTINGS },
	{ key: 21, text: "User Management", path: "settings/users/management", icon: 'bi bi-person-check-fill', permission: window.constants.PERMISSION_READ_USER_SETTINGS },
	{ key: 22, text: "Groups", path: "settings/users/roles", icon: 'bi bi-people-fill', permission: window.constants.PERMISSION_READ_ROLES_SETTINGS },
	{ key: 23, text: "Leave Policies", path: "settings/users/leave-policies", icon: 'bi bi-file-earmark-text', permission: window.constants.PERMISSION_READ_USER_SETTINGS },
	{ key: 31, text: "Service Types", path: "settings/services/types", icon: 'bi bi-layers', permission: window.constants.PERMISSION_READ_GENERAL_SETTINGS },
	{ key: 41, text: "Assets", path: "settings/assets", icon: 'bi bi-wallet', permission: window.constants.PERMISSION_READ_MANAGEMENT_SETTINGS },
	{ key: 51, text: "Scheduled Tasks", path: "settings/scheduled-tasks", icon: 'bi bi-clock', permission: window.constants.PERMISSION_READ_INTEGRATION_SETTINGS }
]
