import "devextreme/dist/css/dx.common.css";
// import "./themes/generated/theme.base.css";
// import "./themes/generated/theme.additional.css";
import "./assets/css/fonts.css";
import "./assets/css/stack360.base.css";
import "./dx-styles.scss";
// import "./themes/generated/stack360.additional.css";
import Vue from "vue";
import themes from "devextreme/ui/themes";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import VueSlimScroll from "vue-slimscroll";

import App from "./App";
import PortalVue from "portal-vue";
import router from "./router/router";
import appInfo from "./app-info";
import vSelect from "vue-select";
import Modal from "./components/modals/modal";
import Popup from "./components/ui-components/popup.vue";
import DxField from "./components/ui-components/dx-field.vue";
import spinner from "./components/ui-components/spinner.vue";
import Icon from "./components/ui-components/icon.vue";
import CheckPermission from "./components/check-permission.vue";
import VTooltip from "v-tooltip";
import LoadPanel from "./components/LoadPanel.vue";
import { HelperMethods, Util, Constants, auth } from "./mixins";
import { store } from "./_store";
import ConnectionHub from "./hubs/ConnectionHub";
import ServiceTypeBadge from './components/badges/service-type-badge.vue'
import DispositionBadge from './components/badges/disposition-badge.vue'
import ServiceStatusBadge from './components/badges/service-status-badge.vue'
import UserLeaveBadge from './components/badges/user-leave-badge.vue'
import { Lookup, LookupMultiple } from './components/lookups'
import EmptyRow from './components/EmptyRow.vue'
import Popover from './components/Popover.vue'
import DxButton from 'devextreme-vue/button';
import DxValidationGroup from "devextreme-vue/validation-group";
import { DxValidator, DxRequiredRule, DxCustomRule } from "devextreme-vue/validator";
import { DxCheckBox } from "devextreme-vue/check-box";
import DxTextBox from "devextreme-vue/text-box";
import DxSelectBox from "devextreme-vue/select-box";
import DxTagBox from 'devextreme-vue/tag-box';
import UserAvatar from './components/UserAvatar.vue'
import { DxScrollView } from "devextreme-vue/scroll-view";

//Styles
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "vue-select/dist/vue-select.css";
import "./_helpers/filters";
import notify from "devextreme/ui/notify";

Vue.config.productionTip = false;
Vue.mixin(HelperMethods);
Vue.mixin(Util);
Vue.mixin(Constants);
Vue.mixin(auth);
Vue.component("v-select", vSelect);
Vue.component("Popup", Popup);
Vue.component("DxField", DxField);
Vue.component("Icon", Icon);
Vue.component("Modal", Modal);
Vue.component("CheckPermission", CheckPermission);
Vue.component("spinner", spinner);
Vue.component("LoadPanel", LoadPanel);
Vue.component("ServiceTypeBadge", ServiceTypeBadge);
Vue.component("DispositionBadge", DispositionBadge);
Vue.component("ServiceStatusBadge", ServiceStatusBadge);
Vue.component("UserLeaveBadge", UserLeaveBadge);
Vue.component("Lookup", Lookup);
Vue.component("LookupMultiple", LookupMultiple);
Vue.component("EmptyRow", EmptyRow);
Vue.component("Popover", Popover);
Vue.component("DxButton", DxButton);
Vue.component("DxValidationGroup", DxValidationGroup);
Vue.component("DxValidator", DxValidator);
Vue.component("DxRequiredRule", DxRequiredRule);
Vue.component("DxCustomRule", DxCustomRule);
Vue.component("DxCheckBox", DxCheckBox);
Vue.component("DxTextBox", DxTextBox);
Vue.component("DxSelectBox", DxSelectBox);
Vue.component("DxTagBox", DxTagBox);
Vue.component("UserAvatar", UserAvatar);
Vue.component("DxScrollView", DxScrollView);

Vue.prototype.$appInfo = appInfo;
Vue.use(BootstrapVue);
Vue.use(VueSlimScroll);
Vue.use(PortalVue, {
	portalName: "portal",
	portalTargetName: "portal-target",
});
Vue.use(IconsPlugin);
Vue.use(VTooltip);
Vue.use(ConnectionHub);

// Yeah, I know, "shouldn't modify built-in object prototypes, blah blah blah."
Object.defineProperty(Array.prototype, 'sortByFn', {
    value: function(fn) { return [].concat(this).sort((a, b) => fn(a) > fn(b) ? 1 : (fn(a) == fn(b) ? 0 : -1)); }
});
Object.defineProperty(Array.prototype, 'sortDescByFn', {
    value: function(fn) { return [].concat(this).sort((a, b) => fn(a) > fn(b) ? -1 : (fn(a) == fn(b) ? 0 : 1)); }
});

themes.initialized(() => {
	let app = new Vue({
		el: "#app",
		router,
		components: {
			App,
		},
		store,
		template: '<App ref="app" />',
		methods: {
			updateToasts(toasts) {
				this.toasts = toasts;
			},
			showConfirmModal(options) {
				this.$refs.app.showConfirmModal(options);
			},
			showAlertModal(options) {
				this.$refs.app.showAlertModal(options);
			},
			showActionItemModal(options) {
				this.$refs.app.showActionItemModal(options);
			},
			showLeaveRequestModal() {
				this.$refs.app.showLeaveRequestModal();
			},
			showExtraDayRequestModal() {
				this.$refs.app.showExtraDayRequestModal();
			},
			showCardPopup(options){
				this.$refs.app.showCardPopup(options);
			},
			showAlertMessage(message, color) {
				let self = this;
				this.$store.state.alert.type = color || "danger";
				this.$store.state.alert.message = message;
				setTimeout(function () {
					self.$store.state.alert.message = null;
				}, 7000);
			},
			showToast(options) {
				if (typeof options === "string") {
					options = {
						text: options,
						title: "Alert",
						color: "danger",
					};
				}
				if (
					!Object.prototype.hasOwnProperty.call(options, "autohide")
				) {
					options.autohide = options.title ? 10000 : 3000;
				}
				if (options.color == "error") {
					options.color = "danger";
				}
				this.$bvToast.toast(options.text, {
					title: options.title,
					autoHideDelay: 5000000,
					appendToast: false,
					variant: options.color || 'info'
				})
			},
			doLoginStatusCheck() {
				if (this.$store.state.authentication.details.Token) {
					const parts =
						this.$store.state.authentication.details.Token.split(
							"."
						);
					if (parts.length) {
						const tokeninfo = JSON.parse(atob(parts[1]));
						const expTime = new Date(tokeninfo.exp * 1000);
						const now = new Date();
						const secondsLeft =
							(expTime.getTime() - now.getTime()) / 1000;
						return secondsLeft > 0;
					}
				}
				return false;
			},
		},
	});
	window.showConfirmModal = function (options) {
		app.showConfirmModal(options);
	};
	window.showAlertModal = function (options, text) {
		app.showAlertModal(
			typeof options == "object"
				? options
				: { title: options, text: text }
		);
	};
	window.showAlertMessage = function (message, color) {
		app.showAlertMessage(message, color);
	};
	window.showToast = function (options) {
		app.showToast(options);
	};
	window.showSuccessToast = function (message, title) {
		app.showToast({
			text: message,
			title: title || "Alert",
			color: "success",
		});
	};
	window.showErrorToast = function (message, title) {
		app.showToast({
			text: message,
			title: title || "Alert",
			color: "danger",
		});
	};
	window.showActionItemModal = function (options) {
		app.showActionItemModal(options);
	};
	window.showLeaveRequestModal = function () {
		app.showLeaveRequestModal();
	};
	window.showExtraDayRequestModal = function () {
		app.showExtraDayRequestModal();
	};
	window.showCardPopup = function (options) {
		app.showCardPopup(options);
	};
	window.setInterval(() => {
		if (!app.doLoginStatusCheck()) {
			// window.location.href = "#/login";
		}
	}, 10000);
});
