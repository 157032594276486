<template>
	<CheckPermission :value="constants.PERMISSION_READ_GENERAL_SETTINGS" :access-denied-msg="true">
		<b-card no-body>
			<b-card-header class="d-flex align-items-center justify-content-between">
				<strong>Holidays</strong>
				<div class="d-flex align-items-center">
					<DxSelectBox :width="isMobile ? '75px' : '250px'" v-model="yearFilter" @value-changed="load"
						:data-source="[
							2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018,
							2019, 2020, 2021, 2022, 2023, 2024, 2025, 2026,
							2026, 2028, 2029, 2030, 2031, 2032, 2033, 2034,
							2035,
						]" placeholder="Select Year">
					</DxSelectBox>
					<DxButton icon="plus" @click="holidayModal()" class="ml-2" v-if="hasPermission(constants.PERMISSION_UPDATE_GENERAL_SETTINGS)" />
				</div>
			</b-card-header>
			<spinner v-if="apiRequest" />
			<DxDataGrid v-else class="header-table" :key="tableKey" :data-source="stack360Holidays"
				:show-row-lines="true" :show-column-lines="false" :show-borders="true" :hover-state-enabled="true"
				:row-alternation-enabled="true" ref="holidays-table">
				<DxColumn data-field="name" />
				<DxColumn caption="Dates" cell-template="datesTemplate" :calculate-cell-value="getRowObj" />
				<template #datesTemplate="{ data }">
					<!-- <div v-html="getDays(data.value.date, data.value.endDate)"></div> -->
					<div v-html="data.value.dates.join('</br>')"></div>
				</template>
				<DxColumn width="120px" caption="Actions" data-field="id" alignment="center"
					cell-template="actionsTemplate" v-if="hasPermission(constants.PERMISSION_UPDATE_GENERAL_SETTINGS)" />
				<template #actionsTemplate="{ data }">
					<div>
						<DxButton styling-mode="text" icon="edit" @click="holidayModal(data.value)" />
						<DxButton styling-mode="text" icon="trash" @click="deleteConfirm(data.value)" />
					</div>
				</template>
			</DxDataGrid>

			<Popup ref="holidayPopup" :title="singleHoliday.id ? 'Edit Holiday' : 'Add Holiday'" :key="popupKey"
				@hidden="popupKey = generateUUID()" :destroyOnClose="true" :width="isMobile ? '100%' : '40%'">
				<form ref="holidayForm" @submit="submit($event)">
					<spinner v-if="submitRequest" />
					<DxValidationGroup ref="holidayValidation" v-else>
						<DxField label="Name" :required="true">
							<DxTextBox v-model="singleHoliday.name" placeholder="Name" mode="text">
								<DxValidator>
									<DxRequiredRule message="Name is required" />
									<DxCustomRule :validation-callback="(obj) =>
											validateUniqueProp(
												stack360Holidays,
												singleHoliday.id,
												'name',
												obj.value
											)" message="Holiday with this name already exists." />
									<DxCustomRule :validation-callback="(obj) =>
											validateEmptySpaces(obj.value)" message="Name can not start or end with empty space." />
								</DxValidator>
							</DxTextBox>
						</DxField>
						<DxField :label="singleHoliday.multipleDates ? 'Start Date' : 'Date'" :required="true">
							<DxDateBox v-model="singleHoliday.date" placeholder="Select Date"
								display-format="MM/dd/yyyy"
								invalid-date-message="The date must have the following format: MM/dd/yyyy"
								:min="new Date()">
								<DxValidator>
									<DxRequiredRule message="Date is required" />
									<DxAsyncRule :validation-callback="validateDates"
										message="This date already registered as KDG Holiday." />
								</DxValidator>
							</DxDateBox>
						</DxField>
						<div>
							<DxField label="End Date" :required="true" v-if="singleHoliday.multipleDates">
								<DxDateBox v-model="singleHoliday.endDate" placeholder="Select End Date"
									:min="singleHoliday.date" display-format="MM/dd/yyyy"
									invalid-date-message="The date must have the following format: MM/dd/yyyy">
									<DxValidator>
										<DxRequiredRule message="End Date is required" />
										<DxCustomRule :validation-callback="validateEndDate" message="End date should be after Start Date." />
										<DxAsyncRule :validation-callback="validateDates"
											message="Date(s) conflicts with other Holiday(s), please enter other dates." />
									</DxValidator>
								</DxDateBox>
							</DxField>
						</div>
						<DxField>
							<DxCheckBox class="float-left" :value="singleHoliday.multipleDates"
								@value-changed="isMultiple" text="Multiple Days" />
						</DxField>
						<DxButton class="mt-3 float-right" text="Submit" type="default" :use-submit-behavior="true" />
					</DxValidationGroup>
				</form>
			</Popup>
		</b-card>
	</CheckPermission>
</template>

<script>
import {
	DxDataGrid,
	DxColumn,
	DxToolbar,
	DxItem,
} from "devextreme-vue/data-grid";
import {
	DxValidator,
	DxRequiredRule,
	DxCustomRule,
	DxAsyncRule,
} from "devextreme-vue/validator";
import { DxButton } from "devextreme-vue/button";
import { AxiosWrapper } from "@/mixins";
import { DxCheckBox } from "devextreme-vue/check-box";
import DxSelectBox from "devextreme-vue/select-box";
import DxValidationGroup from "devextreme-vue/validation-group";
import DxTextBox from "devextreme-vue/text-box";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import DxDateBox from "devextreme-vue/date-box";
import moment from "moment";
import Popup from "@/components/ui-components/popup.vue";
import { isMobile } from "@/utils/media-query";

export default {
	name: "Stack360Holidays",
	mixins: [AxiosWrapper],
	components: {
		DxDataGrid,
		DxColumn,
		DxToolbar,
		DxItem,
		DxButton,
		DxSelectBox,
		DxTextBox,
		DxValidator,
		DxRequiredRule,
		DxCustomRule,
		DxAsyncRule,
		DxValidationGroup,
		DxCheckBox,
		DxDateBox,
		Popup,
	},
	data() {
		return {
			tableKey: this.generateUUID(),
			yearFilter: new Date().getFullYear(),
			apiRequest: false,
			submitRequest: false,
			stack360Holidays: [],
			singleHoliday: { multipleDates: false, date: null, endDate: null },
			popupKey: this.generateUUID(),
			allHolidayDays: [],
			isMobile: isMobile(),
		};
	},
	mounted() {
		this.load();
	},
	methods: {
		load() {
			if (!this.yearFilter) this.yearFilter = new Date().getFullYear();
			this.apiRequest = true
			// this.$refs['holidays-table'].instance.beginCustomLoading()
			this.get("api/settings/holidays?year=" + this.yearFilter)
				.then((response) => {
					this.stack360Holidays = response.data;
					this.stack360Holidays.forEach(
						(holiday) =>
						(holiday.dates = this.getDays(
							holiday.date,
							holiday.endDate
						))
					);
					this.allHolidayDays = this.stack360Holidays
						.map((s) => s.dates)
						.flat();
				})
				.catch((error) => {
					window.showToast({
						text: error.message,
						title: "Alert",
						color: "danger",
					});
				})
				.finally(() => {
					this.apiRequest = false
					// this.$refs['holidays-table'].instance.endCustomLoading()
				});
		},
		holidayModal(id) {
			this.singleHoliday = id
				? this.find(this.stack360Holidays, id)
				: { multipleDates: false, date: null, endDate: null };
			this.$refs.holidayPopup.show();
		},
		submit(e) {
			e.preventDefault();
			this.submitRequest = true;
			this.post("api/settings/holidays", this.singleHoliday).then(() => {
				this.load();
				window.showToast({
					text: this.singleHoliday.name + " added!",
					title: "Success",
					color: "success",
				});
				this.$refs.holidayPopup.hide();
			}).catch((error) => {
				window.showToast({
					text: error.message,
					title: "Error",
					color: "danger",
				});
			}).finally(() => {
				this.submitRequest = false;
			});
		},
		getDays(start, end) {
			if (!end) {
				return [moment(start).format("MM/DD/YYYY ddd")];
			} else {
				for (
					var arr = [], dt = new Date(start);
					dt <= new Date(end);
					dt.setDate(dt.getDate() + 1)
				) {
					arr.push(moment(dt).format("MM/DD/YYYY ddd"));
				}
				return arr;
			}
		},
		isMultiple(e) {
			this.singleHoliday.multipleDates = e.value;
			this.$forceUpdate();
		},
		validateEndDate() {
			return this.compareDate(
				this.singleHoliday.endDate,
				">",
				this.singleHoliday.date
			);
		},
		validateDates() {
			if (
				this.singleHoliday.multipleDates &&
				this.singleHoliday.endDate
			) {
				let date = this.singleHoliday.date.toLocaleDateString("en-US");
				let endDate =
					this.singleHoliday.endDate.toLocaleDateString("en-US");
				return new Promise((resolve) => {
					this.get(
						`api/settings/is-holiday-range?startDate=${date}&endDate=${endDate}`
					).then((response) => {
						resolve(!response.data.isHoliday);
					});
				});
			} else {
				let date = this.singleHoliday.date.toLocaleDateString("en-US");
				return new Promise((resolve) => {
					this.get(`api/settings/is-holiday?date=${date}`).then(
						(response) => {
							resolve(!response.data.isHoliday);
						}
					);
				});
			}
		},
		deleteConfirm(id) {
			window.showConfirmModal({
				confirmButtonText: "Delete",
				subTitle: "Deleting is irreversible",
				showLoader: true,
				mode: "danger",
				onConfirm: async () => {
					await this.deleteKDGHoliday(id);
				},
			});
		},
		async deleteKDGHoliday(id) {
			try {
				await this.delete("api/settings/holidays/" + id);
				window.showSuccessToast("Holiday deleted successfully");
				this.tableKey = this.generateUUID();
			} catch (error) {
				console.log(error);
			}
		},
	},
};
</script>
