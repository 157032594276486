//import moment from 'moment'
import { AxiosWrapper } from '../AxiosWrapper'
import { UserLeaveStatus } from '../../_helpers/constants';
export default {
	mixins: [AxiosWrapper],
	data(){
		return {
			UserLeaveStatus: UserLeaveStatus
		}
	},
	methods:{
        leaveTableCellPrepared(e){
            if(e.rowType == 'data' && e.column.dataField == "status"){
				let status = e.data.status
                e.cellElement.classList.add('leave-status-'+status)
            }
			//else if(e.rowType == 'data' && e.column.dataField == "notes"){
			// 	setTimeout(() => {
			// 		e.element.querySelectorAll('[data-type="datetime"]').forEach((element) => {
			// 			let d = String(element.innerHTML).replace(' at', '')
			// 			let momentDate = moment.utc(d).local().format('MM/DD/YYYY hh:mm a')
			// 			//console.log(d, momentDate)
			// 			element.innerHTML = momentDate;
			// 		});
			// }, 100)
			//}
        },
		onLeaveTableCellClick(e){
			if(e.rowType == 'data' && e.column.dataField == 'notes'){
				if(e.data.parentId){
					e.component.navigateToRow(e.data.parentId)
					e.component.selectRows(e.data.parentId, false)
				}
			}
		}
	}
}
