<template>
	<CheckPermission :value="constants.PERMISSION_READ_USER_SETTINGS" :access-denied-msg="true">
		<b-card class="h-100" no-body>
			<b-card-header class="d-flex align-items-center justify-content-between">
				<strong>Leave Policies</strong>
				<DxButton icon="plus" @click="policyModal()" v-if="hasPermission(constants.PERMISSION_UPDATE_USER_SETTINGS)" />
			</b-card-header>
			<spinner v-if="apiRequest" />
			<DxDataGrid v-else class="header-table" :data-source="leavePolicies" :show-row-lines="true"
				:show-column-lines="false" :show-borders="true" :hover-state-enabled="true"
				:row-alternation-enabled="true">
				<DxColumn data-field="name" />
				<DxColumn caption="PTO" data-field="pto" data-type="number" />
				<DxColumn data-field="ptoVesting" caption="PTO Vesting"
					:calculate-display-value="(e) => formatLabel(e.ptoVesting)" />
				<DxColumn caption="Annual PTO Increase" data-type="number" data-field="annualPTOIncrease" />
				<DxColumn caption="Work From Home" data-field="monthlyWFH" data-type="number" />
				<DxColumn width="120px" caption="Actions" data-field="id" alignment="center"
					cell-template="actionsTemplate" v-if="hasPermission(constants.PERMISSION_UPDATE_USER_SETTINGS)" />
				<template #actionsTemplate="{ data }">
					<div>
						<DxButton styling-mode="text" icon="edit" @click="policyModal(data.value)" />
						<DxButton styling-mode="text" icon="trash" @click="confirmDelete(data.value)" />
					</div>
				</template>
			</DxDataGrid>

			<Popup ref="leavePoliciesPopup" :title="leavePolicy.id ? 'Edit Policy' : 'Add Policy'" :destroy-on-close="true">
				<form ref="leavePoliciesForm" @submit="submit($event)">
					<spinner v-if="submitRequest" />
					<DxValidationGroup ref="policiesFormValidation" v-else>
						<DxField label="Name" :required="true">
							<DxTextBox v-model="leavePolicy.name" placeholder="Name" mode="text">
								<DxValidator>
									<DxRequiredRule message="Name is required" />
									<DxCustomRule :validation-callback="(obj) =>
											validateUniqueProp(
												leavePolicies,
												leavePolicy.id,
												'name',
												obj.value
											)"
										message="Policy with this name already exists." />
									<DxCustomRule :validation-callback="(obj) => validateEmptySpaces(obj.value)"
										message="Name can not start or end with empty space." />
								</DxValidator>
							</DxTextBox>
						</DxField>
						<div class="border-top pt-3 pb-2">
							<h5>Paid Time Off</h5>
							<DxField label="Paid Time" :required="true">
								<DxNumberBox v-model="leavePolicy.pto" placeholder="0" :min="0"
									:format="{ type: 'fixedPoint' }" :show-spin-buttons="true">
									<DxValidator>
										<DxRequiredRule message="PTO is required" />
									</DxValidator>
								</DxNumberBox>
							</DxField>
							<DxField label="PTO Vesting" :required="true">
								<DxSelectBox v-model="leavePolicy.ptoVesting" :items="ptoVestings" display-expr="name"
									value-expr="value" placeholder="Full Allocation or Accrual">
									<DxValidator>
										<DxRequiredRule message="PTO Vesting is required" />
									</DxValidator>
								</DxSelectBox>
							</DxField>
							<DxField label="Annual PTO Increase" :required="true">
								<DxNumberBox v-model="leavePolicy.annualPTOIncrease" placeholder="0" :min="0"
									:format="{ type: 'fixedPoint' }" :show-spin-buttons="true">
									<DxValidator>
										<DxRequiredRule message="Annual PTO Increase is required" />
									</DxValidator>
								</DxNumberBox>
							</DxField>
						</div>
						<div class="border-top pt-3 pb-2">
							<h5>Work from home</h5>
							<DxField label="Work from home" :required="true">
								<DxNumberBox v-model="leavePolicy.monthlyWFH" placeholder="0" :min="0"
									:format="{ type: 'fixedPoint' }" :show-spin-buttons="true">
									<DxValidator>
										<DxRequiredRule message="Monthly WFH is required" />
									</DxValidator>
								</DxNumberBox>
							</DxField>
						</div>
						<DxButton class="mt-3 float-right" text="Submit" type="default" :use-submit-behavior="true" />
					</DxValidationGroup>
				</form>
			</Popup>
		</b-card>
	</CheckPermission>
</template>

<script>
import {
	DxDataGrid,
	DxColumn,
	DxToolbar,
	DxItem,
} from "devextreme-vue/data-grid";
import { PTOVesting } from "../../_helpers/constants";
import {
	DxValidator,
	DxRequiredRule,
	DxCustomRule,
} from "devextreme-vue/validator";
import { DxButton } from "devextreme-vue/button";
import { DxNumberBox } from "devextreme-vue/number-box";
import DxTextBox from "devextreme-vue/text-box";
import { AxiosWrapper, HelperMethods, TextFormatter } from "@/mixins";
import DxSelectBox from "devextreme-vue/select-box";
import DxValidationGroup from "devextreme-vue/validation-group";

export default {
	name: "LeavePolicies",
	mixins: [AxiosWrapper, HelperMethods, TextFormatter],
	components: {
		DxDataGrid,
		DxColumn,
		DxToolbar,
		DxItem,
		DxButton,
		DxSelectBox,
		DxTextBox,
		DxNumberBox,
		DxValidator,
		DxRequiredRule,
		DxCustomRule,
		DxValidationGroup,
	},
	data() {
		return {
			apiRequest: false,
			submitRequest: false,
			leavePolicies: [],
			leavePolicy: {},
			ptoVestings: [
				{ name: "Full Allocation", value: PTOVesting.FullAllocation },
				{ name: "Accrual", value: PTOVesting.Accrual },
			],
		};
	},
	created() {
		this.load();
	},
	methods: {
		load() {
			this.apiRequest = true
			this.get("api/settings/leave-policies")
				.then((response) => {
					this.leavePolicies = response.data;
				})
				.catch((error) => {
					window.showToast({
						text: error.message,
						title: "Alert",
						color: "danger",
					});
				})
				.finally(() => {
					this.apiRequest = false;
				});
		},
		policyModal(id) {
			if (id) {
				let policy = this.find(this.leavePolicies, id);
				this.leavePolicy = {
					id: policy.id,
					name: policy.name,
					pto: Number(policy.pto),
					ptoVesting: policy.ptoVesting == "FullAllocation" ? 0 : 1,
					monthlyWFH: Number(policy.monthlyWFH),
					comp: Number(policy.comp),
					annualPTOIncrease: Number(policy.annualPTOIncrease),
				};
			} else {
				this.leavePolicy = {};
			}
			this.$refs.leavePoliciesPopup.show();
		},
		submit(e) {
			e.preventDefault();
			this.submitRequest = true;
			this.post("api/settings/leave-policies", this.leavePolicy).then(() => {
				this.load();
				window.showToast({
					text: this.leavePolicy.name + " policy added!",
					title: "Success",
					color: "success",
				});
				this.$refs.leavePoliciesPopup.hide();
			}).catch((error) => {
				window.showToast({
					text: error.message,
					title: "Error",
					color: "danger",
				});
			}).finally(() => {
				this.submitRequest = false;
			});
		},
		confirmDelete() {
			window.showConfirmModal({
				subTitle: `Deletion is irreversible.`,
				confirmButtonText: "Delete",
				showLoader: true,
				mode: "danger",
				onConfirm: async () => {
					try {
						const response = await this.delete(`api/settings/leave-policies/${id}`)
						window.showSuccessToast("Policy is deleted successfully.");
						this.load();
					} catch (e) {
						console.log(e);
					}
				},
			});
		},
	},
};
</script>
