import * as userService from "../_services";
// import router from "../router/router";

const user = JSON.parse(localStorage.getItem("user"));

const initialState = user ? { status: { loggedIn: true }, user, details: JSON.parse(atob(user)) } : { status: {}, user: null, details: {} };

export const authentication = {
	namespaced: true,
	state: initialState,
	getters: {
		fullname: (state) => state.details.FullName,
	},
	actions: {
		login({ dispatch, commit }, jwt) {
			commit("loginRequest", jwt);
			userService.login(jwt).then(
				(user) => {
					commit("loginSuccess", user);
					// let _user = JSON.parse(atob(user));
					// let redirect_url = localStorage.getItem("redirect_url");
					// if (redirect_url) {
					// 	localStorage.removeItem("redirect_url");
					// 	router.push(redirect_url);
					// } else if (_user.DefaultLoginPage) {
					// 	router.push(_user.DefaultLoginPage);
					// } else {
					// 	router.push("/dashboard");
					// }
				},
				(error) => {
					commit("loginFailure", error);
					dispatch("alert/error", error, { root: true });
				}
			);
		},
		userLoginSuccess({ commit }, user) {
			commit("loginSuccess", user);
			//   let _user = JSON.parse(atob(user));
			//   let redirect_url = localStorage.getItem("redirect_url");
			//   if (redirect_url) {
			//     localStorage.removeItem("redirect_url");
			//     router.push(redirect_url);
			//   } else if (_user.DefaultLoginPage) {
			//     router.push(_user.DefaultLoginPage);
			//   } else {
			//     router.push("/dashboard");
			//   }
		},
		logout({ commit }) {
			userService.logout();
			commit("logout");
		},
	},
	mutations: {
		loginRequest(state) {
			state.status = { loggingIn: true };
			state.user = null;
			state.details = {};
		},
		loginSuccess(state, user) {
			state.status = { loggedIn: true };
			state.user = user;
			// state.details = JSON.parse(atob(user.split('.')[1]))
			state.details = JSON.parse(atob(user));
		},
		loginFailure(state) {
			state.status = {};
			state.user = null;
			state.details = {};
		},
		logout(state) {
			state.status = {};
			state.user = null;
			state.details = {};
		},
	},
};
