import { AxiosWrapper } from '../'
import moment from 'moment'
import { DayOfWeek, ResourcingCellColors, WeekDays } from '../../_helpers/constants'
export default {
	mixins: [AxiosWrapper],
	data(){
		return {
			WeekDays: WeekDays,
			summaryDays: [
				WeekDays.Monday,
				WeekDays.Tuesday,
				WeekDays.Wednesday,
				WeekDays.Thursday,
				WeekDays.Friday
			]
		}
	},
	methods: {
		weeklyDate(day){
			let date = new Date(this.currentWeek)
			let weekDate = this.weekDayOfDate(day, date)
			return moment(weekDate).format('MM/DD')
		},
		isFullDayLeave(userLeave, date){
			if(userLeave){
				if(userLeave.type != 'Flex'){
					var leaveDateDetails = userLeave.dates.find(s=> this.compareDate(s.date, '===', date))
					return leaveDateDetails ? leaveDateDetails.hours >= 8 : false
				}
			}
			return false
		},

		allocationTdClass(allocation){
			let allocated = allocation.allocatedHours ? Number(allocation.allocatedHours) : Number(allocation.allocated)
			let totalHours = Number(allocation.totalHours)

			if(allocation.isHoliday) return ResourcingCellColors.Green
			else if(allocated == 0 && totalHours == 0) return ResourcingCellColors.Green
			else if(allocated >= totalHours) return ResourcingCellColors.Green
			else if(allocated > 0 && allocated < totalHours) return ResourcingCellColors.Yellow
			else if(allocated == 0 && totalHours > 0) return ResourcingCellColors.Red
			return ResourcingCellColors.None
		},
		showFillNextEntryBtn(weeklyAllocationObj, projectAllocation, date){
			date = new Date(date)
			let nextDate = date.setDate(date.getDate() + 1)
			if(this.isDatePast(nextDate, projectAllocation.day)) return false
			if(projectAllocation.isHoliday || Number(projectAllocation.hours) == 0) return false
			let nextDayAllocation = weeklyAllocationObj.find(s=>s.day == DayOfWeek.nextDay(projectAllocation.day))
			if(nextDayAllocation){
				if(nextDayAllocation.isHoliday){
					return false
				}
				else if(nextDayAllocation.isFullDayLeave){
					return false
				}
				return Number(nextDayAllocation.hours) == 0
			}
			return false
		},
		isDatePast(date, day){
			if(!date){
				date = this.weekDayOfDate(day, new Date(this.currentWeek))
			}
			let _date = this.weekDayOfDate(day, new Date(date))
			return this.compareDate(_date, '<', new Date())
		},
	},
	filters:{
		oneDecimalPlace(val){
			if(val) return Number(val).toFixed(1)
			return '0.0'
		},
		resourcingWeek(val){
			if(val) return moment(val).format('MM/DD')
		},
	}
}
