<template>
	<CheckPermission :value="constants.PERMISSION_READ_USER_SETTINGS" :access-denied-msg="true">
		<b-card class="h-100" body-class="p-0 h-100" id="user-management-page">
			<UsersTable
				v-if="!showUserDetails"
				v-model="inactiveUsers"
				@select-user="openEditUser"
				@open-add-user="openAddUser"
				:key="tableKey"
				:users="users" />
			<UserDetailsPage
				v-else
				:key="formKey"
				ref="userDetails"
				:id="userId"
				:showUserDetails.sync="showUserDetails"
				@user-updated="userUpdated"
				@hidden="hidden" />
			<LoadPanel elementId="#user-management-page" :visible="apiRequest" :shading="false" />
		</b-card>
	</CheckPermission>
</template>

<script>
import { AxiosWrapper } from "@/mixins";
import UserDetailsPage from "./user-details-page.vue";
import UsersTable from "./users-table.vue";

export default {
	mixins: [AxiosWrapper],
	data() {
		return {
			formKey: this.generateUniqueCode(),
			tableKey: this.generateUniqueCode(),
			userId: undefined,
			showUserDetails: false,
			users: [],
			apiRequest: false,
			inactiveUsers: false
		};
	},
	mounted(){
		this.load()
	},
	methods: {
		load(){
			this.apiRequest = true
			this.get('api/users/index?isLocked=' + this.inactiveUsers).then(response => {
				this.users = response.data
			}).finally(final => {
				this.apiRequest = false
			})
		},
		openAddUser() {
			this.userId = undefined;
			this.showUserDetails = true
		},
		openEditUser(user) {
			this.userId = user.id
			this.showUserDetails = true
		},
		userUpdated(user) {
			let i = this.users.findIndex(s => s.id == user.id)
			if(i < 0){
				this.users.push(user)
			}
			else{
				this.users[i] = user
			}
			this.tableKey = this.generateUniqueCode()
			this.$refs.userDetails.updateContentKey();
		},
		hidden() {
			this.formKey = this.generateUniqueCode()
		},
	},
	components: {
		UsersTable,
		UserDetailsPage,
	},
	watch: {
		inactiveUsers() {
			this.load();
		},
	},
};
</script>
