<template>
	<div class="row">
		<div class="col-lg-8">
			<h2 class="mt-0">Happy {{todayDay}}, {{username}}!</h2>
			<div>
				<p class="mb-1" v-if="Boolean(holiday)">Should you be working on {{holiday.name}}? The office is closed.</p>
				<p class="mb-1" v-if="isWeekend">Should you be working on {{todayDay}}?</p>
			</div>
			<CardsPanel />
			<ResourcingPanel class="mt-4" />
		</div>
		<div class="col-lg-4">
			<AttendancePanel :holidays="holidays" />
			<DepartmentLeavesPanel class="mt-4" />
			<LeavesApprovalPanel class="mt-4" />
			<ExtraDaysApprovalPanel class="mt-4" />
		</div>
	</div>
</template>

<script>
import { AxiosWrapper } from '@/mixins'
import { DayOfWeek } from '@/_helpers/constants'
import moment from 'moment'
import ResourcingPanel from './resourcing/ResourcingPanel.vue'
import LeavesApprovalPanel from './leaves/leaves-approval-panel.vue'
import DepartmentLeavesPanel from './leaves/department-leaves-panel.vue'
import CardsPanel from './cards/cards-panel.vue'
import AttendancePanel from './attendance/attendance-panel.vue'
import ExtraDaysApprovalPanel from './extra-days/extra-days-approval-panel.vue'

export default {
	mixins: [AxiosWrapper],
	components:{
		LeavesApprovalPanel,
		DepartmentLeavesPanel,
		ResourcingPanel,
		CardsPanel,
		AttendancePanel,
		ExtraDaysApprovalPanel
	},
	data(){
        return {
            today: new Date(),
            holiday: {},
            holidays: [],
            isWeekend: false
        }
    },
	created(){
        this.nextWeekHolidays()
        this.isWeekend = this.today.getDay() == 6 || this.today.getDay() == 7
    },
	methods:{
		nextWeekHolidays(){
            this.get(`api/dashboard/next-week-holidays`).then(response => {
                this.holidays = response.data
                this.holiday = response.data.find(obj => obj.multipleDates ?
                    this.today >= new Date(obj.date) && this.today <= new Date(obj.endDate) :
                    this.compareDate(this.today, '===', obj.date)
                )
            })
        },
		testSignalR(){
			this.$connectionHub.$emit('TestSignalR')
		}
	},
	mounted(){
		this.$connectionHub.$on('test-signalr', () => {
			console.log("Working")
		})
	},
	computed:{
		todayDay(){
            return DayOfWeek.getDayOfWeek(this.today.getDay())
        },
        monthDate(){
            return moment(this.today).format('LL')
        },
        username(){
            return this.Current_User_Info.FirstName ?
                this.Current_User_Info.FirstName :
                this.Current_User_Info.FullName.split(' ')[0]
        },
	}
}
</script>

<style lang="scss">
.logos-container {
  margin: 20px 0 40px 0;
  text-align: center;
  svg {
    display: inline-block;
  }
}

.devextreme-logo {
  width: 200px;
  height: 34px;
  margin-bottom: 17px;
}

.vue-logo {
  width: 180px;
  height: 62px;
}

.plus {
  margin: 20px 10px;
  width: 22px;
  height: 22px;
}

.screen-x-small .logos-container {
  svg {
    width: 100%;
    display: block;
    &.plus {
      margin: 0;
    }
  }
}
</style>
