<template>
	<CheckPermission :value="constants.PERMISSION_READ_USER_SETTINGS" :access-denied-msg="true">
		<UserLeaveForm v-if="showForm" :user="user" :leaves="userLeaves" @hidden="showForm = false" @leave-added="refreshPopup" />
		<div v-else class="contains-leaves-tables">
			<div v-if="user.leaveBank">
				<div class="d-flex mb-3">
					<div v-width="'70%'">
						<h4>Leaves</h4>
						<div>
							<strong>Policy Name: </strong>{{ user.leaveBank.leavePolicy.name }}
						</div>
						<div>
							<strong>PTO: </strong>{{ user.leaveBank.leavePolicy.pto }}/hrs (Policy) +
							{{ user.leaveBank.annualPTOIncrease }} hours (Annual Increase) =
							{{ Number(user.leaveBank.leavePolicy.pto) + Number(user.leaveBank.annualPTOIncrease) }}
							({{ user.leaveBank.leavePolicy.ptoVesting }})
						</div>
						<div>
							<strong>WFH: </strong>{{ user.leaveBank.leavePolicy.monthlyWFH }}/hrs/mo
						</div>
						<div>
							<strong>NPTO: </strong>0 hrs
						</div>
					</div>
					<div v-width="'30%'" class="text-right" v-if="hasPermission(constants.PERMISSION_UPDATE_USER_SETTINGS)">
						<DxButton text="New Entry Period" @click="showForm = true" />
						<br />
						<br />
					</div>
				</div>
				<spinner v-if="apiRequest" />
				<UserLeavesTable v-else
					:userLeaves="userLeaves"
					:leaveBank="user.leaveBank"
					height="55vh"
					actionable-in="UserManagement"
					@refresh="refreshPopup" />
			</div>
		</div>
	</CheckPermission>
</template>

<script>
import { AxiosWrapper } from "@/mixins";
import UserLeaveForm from "./user-leave-form";
import UserLeavesTable from "./user-leaves-table.vue";
import DxButton from "devextreme-vue/button";

export default {
	props: ["user", "departmentLevel"],
	mixins: [AxiosWrapper],
	components: { UserLeaveForm, DxButton, UserLeavesTable },
	data() {
		return {
			userLeaves: [],
			apiRequest: true,
			showForm: false,
		};
	},
	async created() {
		await this.load();
	},
	methods: {
		async load() {
			this.apiRequest = true;
			try {
				var response = await this.get(`api/users/${this.user.id}/leaves`);
				this.userLeaves = response.data;
			} catch (err) {
				console.log(err);
			}
			this.apiRequest = false;
		},
		async refreshPopup() {
			this.load()
			this.showForm = false
			this.$emit("user-updated");
		},
	},
};
</script>
