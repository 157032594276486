<template>
    <div class="card" id="dashboard-resourcing-panel">
        <LoadPanel elementId="#dashboard-resourcing-panel" :visible="apiRequest" :shading="false" />
        <div class="card-header d-flex align-items-center justify-content-between">
            <div class="d-flex align-items-center">
                <div class="mr-4">
                    <h4 class="mb-1"><strong>My Resourcing</strong></h4>
                    <span>{{startDate | shortDate}} - {{endDate | shortDate}}</span>
                </div>
                <span>
                    <DxButton class="no-btn" styling-mode="text" @click="lastWeek" :disabled="!haveLastWeekAllocation"
                    :title="haveLastWeekAllocation ? 'Last week allocation' : 'You are not resourced in the past.'">
                        <Icon name="chevron-compact-left" icon-pack="bootstrap" />
                    </DxButton>
                    <DxButton class="no-btn" styling-mode="text" @click="nextWeek" :disabled="!haveNextWeekAllocation"
                    :title="haveNextWeekAllocation ? 'Next Week Allocation' : 'You are not resourced in the future.'">
                        <Icon name="chevron-compact-right" icon-pack="bootstrap" />
                    </DxButton>
                </span>
            </div>
            <div v-if="allocation" v-width="'11em'">
                <div class="d-flex align-items-center justify-content-between mb-2">
                    <small>Include All</small>
                    <DxSwitch switched-on-text="Yes" switched-off-text="No" v-model="includeAll" />
                </div>
                <div class="d-flex align-items-center justify-content-between text-right">
                    <small>Last Update {{allocation.lastUpdate | elapsedTime}}</small>
                    <DxButton styling-mode="text" class="no-btn" @click="load"><Icon name="arrow-clockwise" icon-pack="bootstrap" /></DxButton>
                </div>
            </div>
        </div>
        <div class="card-body p-0">
            <table class="table mb-0 resourcing-table table-bordered border-0" :key="tableKey">
                <thead>
                    <tr>
                        <th width="72%" class="border-0">Project Name</th>
                        <th width="14%" class="text-center border-0">Allocation</th>
                        <th width="14%" class="text-center border-0">Result</th>
                    </tr>
                </thead>
                <tbody>
                    <EmptyRow v-if="!Boolean(allocation)" :loading="apiRequest"/>
                    <div v-fragments v-for="item in (allocation?.weeklyAllocations || [])" :key="`${item.day}-allocation`" >
                        <tr>
                            <td class="allocation-td">
                                {{item.date | formatDate}}
                                <DxButton
									styling-mode="text"
									class="mt--5"
									@click="collapseAllocation(item)">
                                    <Icon v-if="!item.expandAllocation" name="plus-square" icon-pack="bootstrap" size="0.8" />
                                    <Icon v-else name="dash-square" icon-pack="bootstrap" size="0.8" />
                                </DxButton>
                            </td>
                            <td class="text-center">{{item.allocation | oneDecimal}}</td>
                            <td class="text-center" :class="tdShade(item.result, item.allocation)">{{item.result | oneDecimal}}</td>
                        </tr>
						<tr v-show="item.expandAllocation" v-if="item.userLeaves.length > 0">
                            <td colspan="3" class="text-center td-lightyellow">
								<div v-for="userLeave in item.userLeaves" :key="userLeave.id">
									{{ !!getDateHour(userLeave.dates, item.date) ? getDateHour(userLeave.dates, item.date).timespanStr : null }} -
									{{ userLeave.type }}
									<span class="badge badge-primary" v-if="userLeave.status == 'Pending'">Pending</span>
								</div>
                            </td>
                        </tr>
                        <!-- <tr v-show="item.expandAllocation" v-if="item.userLeave">
                            <td colspan="3" class="text-center td-lightyellow">
                                {{ !!getDateHour(item.userLeave.dates, item.date) ? getDateHour(item.userLeave.dates, item.date).timespanStr : null }}
                                {{ item.userLeave.type }}
                                <span class="badge badge-primary" v-if="item.userLeave.status == 'Pending'">Pending</span>
                            </td>
                        </tr> -->
                        <tr v-show="item.expandAllocation" v-if="item.holiday">
                            <td colspan="3" class="text-center td-lightyellow">
                                {{item.date | MMslashDD}} is {{item.holiday.name}}
                            </td>
                        </tr>
                        <div v-fragments v-for="(project,index) in item.projectAllocations" :key="'project-'+index">
                            <tr v-show="item.expandAllocation">
                                <td class="project-td">
                                    {{project.name}}
                                    <DispositionBadge :type="project.disposition" />
                                    <DxButton styling-mode="text" class="mt--5" @click="project.showTimelogs = !project.showTimelogs">
                                        <Icon v-if="!project.showTimelogs" name="plus-square" icon-pack="bootstrap" size="0.8" />
                                        <Icon v-else name="dash-square" icon-pack="bootstrap" size="0.8" />
                                    </DxButton>
                                </td>
                                <td class="text-center">{{project.allocation | oneDecimal}}</td>
                                <td class="text-center">{{project.result | oneDecimal}}</td>
                            </tr>
                            <tr v-for="timelog in project.timeLogs" :key="timelog.id" v-show="project.showTimelogs">
                                <td class="timelog-td">
                                    {{timelog.taskName}} <span class="font-italic">{{timelog.notes ? ` - ${timelog.notes}` : ''}}</span>
                                </td>
                                <td class="text-center"></td>
                                <td class="text-center">{{timelog.roundedHours | oneDecimal}}</td>
                            </tr>
                        </div>
                    </div>
                    <div v-fragments>
                        <tr v-if="allocation">
                            <td class="text-right"><strong>Totals:</strong></td>
                            <td class="text-center"><strong>{{allocation.allocation}}</strong></td>
                            <td class="text-center position-relative">
                                <strong>{{allocation.result}}</strong>
                                <Icon
									v-if="muscleFlex.show"
									name="trophy"
									icon-pack="bootstrap"
									:tooltip="muscleFlex.tooltip"
									v-color="muscleFlex.color"
                                    :elAttr="{class:'position-absolute'}" right="15px" />
                            </td>
                        </tr>
                    </div>
                </tbody>
            </table>
        </div>
    </div>
</template>


<script>
import { AxiosWrapper } from '@/mixins'
import moment from 'moment'
import { DxSwitch } from 'devextreme-vue/switch';
import DxButton from 'devextreme-vue/button';

export default {
    mixins:[AxiosWrapper],
    components: {DxSwitch, DxButton},
    data(){
        return {
            apiRequest: false,
            tableKey: this.generateUUID(),
            weekDate: new Date(),
            allocation:{ weeklyAllocations:[] },
            haveLastWeekAllocation: false,
            haveNextWeekAllocation: false,
            lastUpdate: null,
            includeAll: false
        }
    },
    created(){
        this.load()
    },
    methods:{
        nextWeek(){
            this.weekDate = this.addDays(this.weekDate, 7);
            this.load()
        },
        lastWeek(){
            this.weekDate = this.addDays(this.weekDate, -7);
            this.load()
        },
        collapseAllocation(item){
            item.expandAllocation = !item.expandAllocation
            if(!item.expandAllocation){
                item.projectAllocations.forEach(project => project.showTimelogs = false)
            }
            this.$forceUpdate()
        },
        load(){
            this.apiRequest = true
            const params = { date: this.weekDate.toLocaleDateString('en-US'), includeAll: this.includeAll };
            this.get('api/dashboard/resourcing', {params} ).then(response => {
                if(response.data.resourceAllocation){
                    response.data.resourceAllocation.weeklyAllocations.forEach(obj =>{
                        obj.expandAllocation = false
                        obj.projectAllocations.forEach(project => project.showTimelogs = false)
                    })
                }
                this.allocation = response.data.resourceAllocation
                this.haveLastWeekAllocation = response.data.haveLastWeekAllocation
                this.haveNextWeekAllocation = response.data.haveNextWeekAllocation
                this.tableKey = this.generateUUID()
            }).finally(final =>{
                this.apiRequest = false
            })
        },
        getDateHour(dates, date){
    		return dates.find(s => Date(s.date) === Date(date))
        },
    },
    filters:{
        formatDate(val){
            if(val) return moment(val).format('dddd DD MMMM, YYYY')
        }
    },
    mounted(){
        this.$connectionHub.$on('resourcing-updated', () => { this.load() })
    },
    watch:{
        includeAll(){
            this.load()
        }
    },
    computed:{
        startDate(){
            return this.weekDayOfDate('monday', this.weekDate)
        },
        endDate(){
            return this.weekDayOfDate('friday', this.weekDate)
        },
        muscleFlex(){
            if(Number(this.allocation.result) > Number(this.allocation.allocation)){
                let everyTargetMet = this.allocation.weeklyAllocations.map(s => s.projectAllocations).flat().every(obj =>{
                    return Number(obj.result) >= Number(obj.allocation)
                })
                if(everyTargetMet){
                    return {
                        show: true,
                        color: 'green',
                        tooltip: 'Congratulations, you crushed your resourcing!'
                    }
                }
                let externalTargetMet = this.allocation.weeklyAllocations.every(obj =>{
                    let externalTarget = Number(obj.externalTarget);
                    let externalResult = obj.projectAllocations.filter(s=>s.disposition == 'External').map(s=>Number(s.result)).reduce((a,b) => a+b, 0)
                    return externalResult >= externalTarget
                })
                if(externalTargetMet){
                    return {
                        show: true,
                        color: 'blue',
                        tooltip: 'Congratulations, you met your external billable goal!'
                    }
                }
                let internalChargeTargetMet = this.allocation.weeklyAllocations.every(obj =>{
                    let internalChargeTarget = Number(obj.internalChargeTarget);
                    let internalChargeResult = obj.projectAllocations.filter(s=>s.disposition == 'Internal Charge').map(s=>Number(s.result)).reduce((a,b) => a+b, 0)
                    return internalChargeResult >= internalChargeTarget
                })
                if(internalChargeTargetMet){
                    return {
                        show: true,
                        color: 'red',
                        tooltip: 'You made your goal, but only due to unscheduled Internal Charge time.'
                    }
                }
            }
            return {show: false}
        }
    },
}
</script>
<style scoped>
.resourcing-table .allocation-td{
    padding-left: 20px !important;
}
.resourcing-table .project-td{
    padding-left: 40px !important;
}
.resourcing-table .timelog-td{
    padding-left: 60px !important;
}
.resourcing-table td:first-child,
.resourcing-table th:first-child{
    border-left: 0 !important;
}
.resourcing-table th:first-child{
    padding-left: 20px !important;
}
.resourcing-table td:last-child,
.resourcing-table th:last-child{
    border-right: 0 !important;
}
.mt--5{
    margin-top: -5px;
}
</style>
