<template>
    <div>
        <div v-for="permission in permissions" :key="permission.name">
            <div class="d-flex">
                <div class="td" v-width="'30%'">
                    <div :class="iteration == 0 ? 'pl-4' : 'pl-5'">
                        <b-button
                            class="pl-0 pt-0 pr-1"
                            v-if="permission.childPermissions.length > 0"
                            color="link"
                            @click="showChildren(permission)">
                            <Icon :name="permission.showChildren ? 'chevron-down' : 'chevron-right'" icon-pack="bootstrap"></Icon>
                        </b-button>
                        <span>{{formatSnakeCase(permission.name)}}</span>
                    </div>
                </div>
                <div class="td text-center" v-width="(70 / roles.length)+'%'" v-for="role in roles" :key="role.name">
                    <DxCheckBox :value="havePermission(role, permission.name)" @value-changed="selectPermission(role, permission, $event)" />
                </div>
            </div>
            <RecursivePermissions
                v-if="permission.childPermissions.length > 0 && permission.showChildren"
                :key="key"
                :roles="roles"
                :permissions="permission.childPermissions"
                :havePermission="havePermission"
                :selectPermission="selectPermission"
                :iteration="iteration + 1" />
        </div>
    </div>
</template>

<script>
import { DxCheckBox } from 'devextreme-vue/check-box';
import RecursivePermissions from './recursive-roles.vue';

export default {
    name: 'RecursivePermissions',
    components: {DxCheckBox},
    props:['permissions','roles','havePermission','showChildren','iteration','selectPermission'],
    data() {
        return {
            key: this.generateUUID()
        }
    },
}

</script>
