<template>
	<div class="card">
		<div class="card-header d-flex align-items-center justify-content-between">
			<h4 class="mb-0 mr-3"><strong>{{monthDate}}</strong></h4>
			<div>
				<DxButton
					v-for="button in buttons"
					:icon="button.icon"
					:key="button.value"
					@click="addLog(button.value)"
					v-b-tooltip="formatLabel(button.value)"
					styling-mode="text"
					v-bg-color="button.bg"
					v-color="button.color"
					class="ml-2 attendance-button" />
			</div>
		</div>
		<div class="card-body p-0">
			<div class="alert alert-warning text-center" v-for="holiday in holidays" :key="holiday.id">
				{{holiday.date | MMM_DD}} <span v-if="holiday.endDate"> - {{holiday.endDate | MMM_DD}}</span>
				is {{holiday.name}}. The office will be closed.
			</div>
			<table class="table mb-0">
				<EmptyRow v-if="logs.length == 0 || apiRequest" :loading="apiRequest" />
				<tr v-for="log in logs" :key="log.id">
					<td class="">
						<div>{{formatUtcToLocalDateTime(log.startTime, 'MM/DD @ hh:mma')}}</div>
					</td>
					<td v-bg-color="bgColor(log.type)" class="text-center">
						{{ formatLabel(log.type) }}
					</td>
					<td class="text-center" width="35%">
						<div>{{formatUtcToLocalDateTime(log.endTime, 'MM/DD @ hh:mma')}}</div>
					</td>
				</tr>
			</table>

		</div>
	</div>
</template>

<script>
import { AxiosWrapper, TextFormatter } from '@/mixins';
import { DxDataGrid, DxColumn } from 'devextreme-vue/data-grid';
import moment from 'moment';
export default{
	mixins: [AxiosWrapper, TextFormatter],
	props:['holidays'],
	components:{
		DxDataGrid, DxColumn
	},
	data(){
		return {
			apiRequest: false,
			logs: [],
			buttons: [],
			checkInButton: {icon: 'bi bi-box-arrow-in-down', value: 'CheckIn', color: '#155724', bg: '#d4edda'},
			checkOutButton: {icon: 'bi bi-box-arrow-left', value: 'CheckOut', color: '#721c24', bg: '#f8d7da'},
			breakStartButton: {icon: 'bi bi-clock', value: 'Break', color: '#0c5460', bg: '#d1ecf1'},
			breakEndButton: {icon: 'bi bi-clock-fill', value: 'CheckIn', color: '#856404', bg: '#fff3cd'},
		}
	},
	created(){
		this.load()
	},
	methods:{
		load(){
			this.apiRequest = true
			this.get('api/attendance/logs').then(response => {
				this.logs = response.data
				switch(response.data[0]?.type || 'Default'){
					case 'CheckOut':
					case 'Default':
						this.buttons = [this.checkInButton]
						break;
					case 'CheckIn':
						this.buttons = [this.breakStartButton, this.checkOutButton]
						break;
					case 'Break':
						this.buttons = [this.breakEndButton, this.checkOutButton]
						break;
				}
			}).finally(() => {
				this.apiRequest = false
			})
		},
		addLog(type){
			this.apiRequest = true
			this.post('api/attendance/add', {
				userId: this.Current_User_ID,
				type: type
			}).then(response => {
				this.load();
			}).finally(() => {
				this.apiRequest = false
			})
		},
		bgColor(type){
			let list = [this.checkInButton, this.checkOutButton, this.breakEndButton, this.breakStartButton]
			return list.find(s => s.value == type).bg
		}
	},
	computed:{
        monthDate(){
            return moment(this.today).format('LL')
        },
	}
}
</script>
<style>
.attendance-button i.dx-icon{
	color: inherit !important;
}
</style>
