<template>
	<div>
		<DxField label="Type" :required="true">
			<DxSelectBox
				v-model="userLeave.type"
				placeholder="Select Type"
				:items="leaveTypes"
				@value-changed="$emit('reevaluate-balance')">
				<DxValidator> <DxRequiredRule message="Type is required"/> </DxValidator>
			</DxSelectBox>
		</DxField>
		<DxField label="Debit" :required="true">
			<DxNumberBox
				v-model="userLeave.debit"
				placeholder="0.0"
				:format="{type:'decimal', precision:2}"
				:min="0"
				:show-spin-buttons="true"
				@value-changed="$emit('reevaluate-balance')">
				<DxValidator> <DxRequiredRule message="Debit is required"/> </DxValidator>
			</DxNumberBox>
		</DxField>
		<DxField label="Credit" :required="true">
			<DxNumberBox
				v-model="userLeave.credit"
				placeholder="0.0"
				:format="{type:'decimal', precision:2}"
				:min="0"
				:show-spin-buttons="true"
				@value-changed="$emit('reevaluate-balance')">
				<DxValidator> <DxRequiredRule message="Credit is required"/> </DxValidator>
			</DxNumberBox>
		</DxField>
	</div>
</template>

<script>
import { DxValidator, DxRequiredRule } from 'devextreme-vue/validator';
import { DxNumberBox } from 'devextreme-vue/number-box';
import { UserLeaveTypes } from '../../_helpers/constants';
import DxSelectBox from 'devextreme-vue/select-box';

export default{
	components:{
		DxValidator,
		DxRequiredRule,
		DxNumberBox,
		DxSelectBox
	},
	props: ['value'],
	data(){
		return {
			leaveTypes: [
				UserLeaveTypes.PTO,
				UserLeaveTypes.WFH,
				UserLeaveTypes.NPTO
			],
		}
	},
	computed:{
		userLeave:{
			get(){
				return this.value
			},
			set(v){
				this.$emit('input', v)
			}
		}
	},
}


</script>
