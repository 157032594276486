<template>
	<div>
		<DxField label="Type" :required="true">
			<DxSelectBox
				v-model="userLeave.type"
				placeholder="Select Type"
				:items="leaveTypes">
				<DxValidator> <DxRequiredRule message="Type is required"/> </DxValidator>
			</DxSelectBox>
		</DxField>
		<DxField label="Start Date" :required="true">
			<div class="d-flex">
				<DxDateBox
					type="datetime"
					v-model="userLeave.startDate"
					ref="startDateSelector"
					placeholder="MM/dd/yyyy hh:mm a"
					display-format="MM/dd/yyyy hh:mm a"
					invalid-date-message="The date must have the following format: MM/dd/yyyy"
					:element-attr="{class:'w-100'}"
					:disabled="!userLeave.type">
					<DxValidator>
						<DxRequiredRule
							message="Start Date is required"/>
						<DxCustomRule
							:reevaluate="true"
							:validation-callback="isDateInPast"
							:message="ActionableIn == 'UserManagement' ? 'You cannot back-date leaves for yourself' : 'Start Date should not be in the past'" />
						<DxAsyncRule
							:reevaluate="true"
							:validation-callback="validateDates"
							validation-message="Please Change Start Date" />
					</DxValidator>
				</DxDateBox>
				<DxButton icon="clock" :element-attr="{class:'ml-2'}" @click="setStartDate" :disabled="!userLeave.type" />
			</div>
		</DxField>
		<DxField label="End Date" :required="true">
			<div class="d-flex">
				<DxDateBox
					type="datetime"
					v-model="userLeave.endDate"
					ref="endDateSelector"
					placeholder="MM/dd/yyyy hh:mm a"
					display-format="MM/dd/yyyy hh:mm a"
					invalid-date-message="The date must have the following format: MM/dd/yyyy"
					:element-attr="{class:'w-100'}"
					:disabled="!userLeave.type">
					<DxValidator ref="endDateValidator">
						<DxRequiredRule
							message="End Date is required"/>
						<DxCustomRule
							:reevaluate="true"
							:validation-callback="() => new Date(userLeave.endDate) > new Date(userLeave.startDate)"
							message="End date should be after Start Date" />
						<DxCustomRule
							:reevaluate="true"
							:validation-callback="isDateInPast"
							:message="ActionableIn == 'UserManagement' ? 'You cannot back-date leaves for yourself' : 'End Date should not be in the past'" />
						<DxAsyncRule
							:reevaluate="true"
							:validation-callback="validateDates"
							validation-message="Please Change End Date"  />
					</DxValidator>
				</DxDateBox>
				<DxButton icon="clock" :element-attr="{class:'ml-2'}" @click="setEndDate" :disabled="!userLeave.type" />
			</div>
		</DxField>
	</div>
</template>

<script>
import { AxiosWrapper } from '../../mixins'
import { DxValidator, DxRequiredRule, DxCustomRule, DxAsyncRule } from 'devextreme-vue/validator';
import DxSelectBox from 'devextreme-vue/select-box';
import DxDateBox from 'devextreme-vue/date-box';
import DxButton from 'devextreme-vue/button';
import { DayOfWeek, UserLeaveTypes } from '../../_helpers/constants';
export default{
	mixins: [AxiosWrapper],
	props: ['value', 'ActionableIn'],
	components:{
		DxValidator,
		DxRequiredRule,
        DxCustomRule,
        DxAsyncRule,
		DxDateBox,
		DxButton,
        DxSelectBox
	},
	data(){
		return {
			leaveTypes: [
				UserLeaveTypes.PTO,
				UserLeaveTypes.WFH,
				UserLeaveTypes.NPTO
			],
		}
	},
	computed:{
		userLeave:{
			get(){
				return this.value
			},
			set(v){
				this.$emit('input', v)
			}
		}
	},
	created(){
		this.userLeave = this.cleanSource(this.value)
	},
	methods:{
		onValueChange(){
			if(this.userLeave.startDate && this.userLeave.endDate && this.userLeave.type){
				this.$refs['endDateValidator'].instance.validate()
			}
		},
		async validateDates(){
			if(this.userLeave.startDate && this.userLeave.endDate){
				this.$emit('reevaluating-start')
				let range = { start: this.userLeave.startDate, end: this.userLeave.endDate }
				const params = {
					range : this.stringifyDateTimeRange(range),
					utcRange: JSON.stringify(range),
					leaveType: this.userLeave.type
				}
				this.$emit('reevaluating-start')
				return new Promise((resolve) => {
					this.get(`api/users/${this.userLeave.userId}/leaves/validate`, { params }).then(response => {
						this.userLeave.credit = Number(response.data.creditHours).toFixed(2);
						this.userLeave.debit = 0;
						this.userLeave.isDailyBreak = response.data.isDailyBreak;
						this.$refs.startDateSelector.instance.option('isValid', response.data.isValid)
						this.$refs.endDateSelector.instance.option('isValid', response.data.isValid)
						this.$emit('validation-response', response.data)
						resolve(response.data.isValid);
					})
				});
			}
			else{
				return new Promise((resolve) => { resolve(true) });
			}
		},
		isDateInPast(e){
			if (this.ActionableIn === 'UserManagement') {
				return true
				// return this.$store.state.authentication.details.Email === this.user.email || this.compareDate(e.value, '>=', new Date().setHours(8, 0, 0, 0));
			} else {
				return this.compareDate(e.value, '>=', new Date().setHours(8, 0, 0, 0));
			}
		},
        setStartDate(){
            this.userLeave.startDate = new Date();
			let numberOfDaysToAdd = 0
			if(this.userLeave.startDate.getDay() == DayOfWeek.Saturday){
				numberOfDaysToAdd = 1
			}
			else if(this.userLeave.startDate.getDay() == DayOfWeek.Sunday){
				numberOfDaysToAdd = 2
			}
			this.userLeave.startDate.setDate(this.userLeave.startDate.getDate() + numberOfDaysToAdd);
            this.userLeave.startDate.setHours(10,30,0,0);
        },
        setEndDate(){
            this.userLeave.endDate = new Date();
			let numberOfDaysToAdd = 0
			if(this.userLeave.endDate.getDay() == DayOfWeek.Saturday){
				numberOfDaysToAdd = 1
			}
			else if(this.userLeave.endDate.getDay() == DayOfWeek.Sunday){
				numberOfDaysToAdd = 2
			}
			this.userLeave.endDate.setDate(this.userLeave.endDate.getDate() + numberOfDaysToAdd);
            this.userLeave.endDate.setHours(19,30,0,0);
        },
	}
}
</script>
