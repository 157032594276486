<template>
  <DxSelectBox
    v-model="selected"
    :search-enabled="true"
    :data-source="list"
    :display-expr="displayExpr"
    :value-expr="valueExpr"
    :placeholder="placeholder"
    :disabled="disabled"
    :read-only="readOnly"
    :label="label"
    :label-mode="labelMode"
    :show-clear-button="showClearButton"
    @value-changed="onValueChanged"
  >
    <DxValidator v-if="required">
      <DxRequiredRule message="Value is required" />
    </DxValidator>
  </DxSelectBox>
</template>

<script>
import DxSelectBox from "devextreme-vue/select-box";
import { AxiosWrapper } from "../../mixins/AxiosWrapper";
import { DxValidator, DxRequiredRule } from "devextreme-vue/validator";
export default {
  props: {
    value: [String, Object],
    required: {
      type: Boolean,
      default: false,
    },
    "display-expr": {
      type: String,
      default: "name",
    },
    "value-expr": {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "Select...",
    },
    api: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    "read-only": {
      type: Boolean,
      default: false,
    },
    "show-clear-button": {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: "",
    },
    "label-mode": {
      type: String,
      default: "hidden",
    },
  },
  components: { DxSelectBox, DxValidator, DxRequiredRule },
  mixins: [AxiosWrapper],
  data() {
    return {
      listRaw: [],
      list: null,
    };
  },
  created() {
    this.list = this.initializeList();
  },
  watch: {
    api() {
      this.list = this.initializeList();
    },
  },
  computed: {
    selected: {
      get() {
        return this.value || undefined;
      },
      set(v) {
        this.$emit("input", v);
      },
    },
    showText() {
      if (this.viewAsText && this.$refs.lookup) {
        return this.$refs.lookup.instance.option("displayExpr");
      }
      return "";
    },
  },
  methods: {
    onValueChanged(e) {
      if (typeof e.value != "object" && this.valueExpr != "") {
        let obj = this.listRaw.find((s) => s[this.valueExpr] == e.value);
        e.object = obj;
      }
      this.$emit("value-changed", e);
    },
    initializeList() {
      return {
        loadMode: "raw",
        load: () => {
          const promise = new Promise((resolve) => {
            if (this.api) {
              this.get(this.api)
                .then((response) => {
                  this.listRaw =
                    typeof response.data == "string"
                      ? JSON.parse(response.data)
                      : response.data;
                  this.$emit("on-load", this.listRaw);
                  resolve(this.listRaw);
                })
                .catch((error) => {
                  console.log(error.message);
                  this.listRaw = [];
                  resolve(this.listRaw);
                });
            } else {
              this.listRaw = [];
              resolve(this.listRaw);
            }
          });
          return promise;
        },
      };
    },
  },
};
</script>
