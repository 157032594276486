<template>
	<Popup ref="servicePopup" title="Service" :native-scroll="true">
		<CheckPermission :value="constants.PERMISSION_UPDATE_SERVICES" :access-denied-msg="true">
			<form ref="serviceForm" @submit="submit($event)" :key="serviceFormKey">
				<spinner v-if="apiRequest" />
				<DxValidationGroup name="serviceValidationGroup" ref="serviceFormValidationGroup" v-else>

					<DxField label="Client" :required="true">
						<ClientLookup
							v-model="service.clientId"
							:required="true"
							:read-only="isEditing" />
					</DxField>

					<DxField label="Service Type" :required="true">
						<ServiceTypeLookup
							v-model="service.serviceTypeId"
							:required="true"
							:read-only="isEditing"
							@service-type-changed="onSelectServiceType" />
					</DxField>

					<DxField label="Status" :required="true">
						<DxSelectBox
							v-model="service.status"
							:items="serviceStatuses"
							display-expr="value"
							value-expr="name"
							:search-enabled="true">
							<DxValidator> <DxRequiredRule message="Status is required" /> </DxValidator>
						</DxSelectBox>
					</DxField>
					<DxField label="Billing Type" :required="true">
						<DxSelectBox
							v-model="service.billingType"
							:items="billingTypes"
							display-expr="value"
							value-expr="name"
							:search-enabled="true">
							<DxValidator> <DxRequiredRule message="Billing Type is required" /> </DxValidator>
						</DxSelectBox>
					</DxField>
					<div>
						<DxField label="Project Name" :required="true" v-if="serviceType.isProjectRequired">
							<DxTextBox v-model="service.project.name" placeholder="Project Name">
								<DxValidator> <DxRequiredRule message="Project Name is required" /> </DxValidator>
							</DxTextBox>
						</DxField>

						<DxField label="Project Type" :required="true" v-if="serviceType.isProjectRequired">
							<DxSelectBox
								v-model="service.project.disposition"
								:items="dispositions"
								display-expr="value"
								value-expr="name"
								:search-enabled="true">
								<DxValidator> <DxRequiredRule message="Project Type is required" /> </DxValidator>
							</DxSelectBox>
						</DxField>
					</div>
					<div class="text-right">
						<DxButton text="Cancel" @click="$refs.servicePopup.hide()" />
						<DxButton class="ml-2" text="Submit" type="default" :use-submit-behavior="true" />
					</div>
				</DxValidationGroup>
			</form>
		</CheckPermission>
	</Popup>
</template>



<script>
import { AxiosWrapper } from '@/mixins';
import DxTextBox from "devextreme-vue/text-box";
import DxValidationGroup from "devextreme-vue/validation-group";
import { DxValidator, DxRequiredRule, DxCustomRule } from "devextreme-vue/validator";
import DxSelectBox from "devextreme-vue/select-box";
import DxColorBox from 'devextreme-vue/color-box';
import { DxCheckBox } from "devextreme-vue/check-box";
import DxTagBox from 'devextreme-vue/tag-box';
import DxButton from "devextreme-vue/button";
import { ClientLookup, ServiceTypeLookup } from '@/components/lookups'
import { ServiceStatus, Disposition, BillingType } from '@/_helpers/constants.js'

const serviceStatuses = Object.entries(ServiceStatus).map(([name, value]) => ({ name, value }));
const dispositions = Object.entries(Disposition).map(([name, value]) => ({ name, value }));
const billingTypes = Object.entries(BillingType).map(([name, value]) => ({ name, value }));

const service = () => {
	return {
		"id": "",
		"clientId": "",
		"serviceTypeId": "",
		"status": "",
		"project":{
			"name": "",
			"disposition": ""
		}
	}
}
export default{
	components:{
		DxTextBox,
		DxValidator,
		DxRequiredRule,
		DxCustomRule,
		DxSelectBox,
		DxValidationGroup,
		DxColorBox,
		DxCheckBox,
		DxTagBox,
		DxButton,
		ClientLookup,
		ServiceTypeLookup
	},
	mixins:[AxiosWrapper],
	data(){
		return {
			serviceStatuses,
			dispositions,
			billingTypes,
			service: service(),
			serviceFormKey: this.generateUniqueCode(),
			apiRequest: false,
			serviceType: {}
		}
	},
	methods:{
		show(row){
			this.service = row ? this.cleanSource(row) : service()
			this.serviceFormKey = this.generateUniqueCode()
			this.serviceType = {}
			this.$refs.servicePopup.show()
		},
		hide(){
			this.$refs.servicePopup.hide()
		},
		onSelectServiceType(e){
			this.serviceType = e
		},
		submit(e){
			e.preventDefault()
			this.apiRequest = true
			let endpoint = this.service.id ? 'update' : 'add'
			this.post(`api/services/${endpoint}`, this.service).then(response => {
				this.$refs.servicePopup.hide()
				this.$emit('service-updated')
			}).finally(() => {
				this.apiRequest = false
			})
		}
	},
	computed:{
        isEditing(){
            return this.service.id != ''
        }
    }
}








</script>
