<template>
	<!-- <CheckPermission :value="constants.PERMISSION_READ_CLIENTS" :access-denied-msg="true"> -->
		<b-card no-body>
			<b-card-header class="d-flex align-items-center justify-content-between">
				<strong>Attendance Logs</strong>
				<div v-if="hasPermission(constants.PERMISSION_READ_ATTENDANCE_LOGS)">
					<UsersLookup v-model="userId" @value-changed="onChangeUser" />
				</div>
			</b-card-header>
			<b-card-body class="p-0">
				<spinner v-if="apiRequest" />
				<DxScheduler
					:editing="false"
					:showCurrentTimeIndicator = "false"
					:data-source="logs"
					:current-date="currentDate"
					:height="600"
					:start-day-hour="10"
					current-view="week"
					:on-option-changed="onOptionChanged"
					appointmentTemplate="appointment"
					v-else>
					<template #appointment="{ data }">
						<div v-bg-color="logBgColor(data.appointmentData.text)" v-color="logColor(data.appointmentData.text)">
							<div>{{ formatLabel(data.appointmentData.text) }}</div>
							<small>
								{{ data.appointmentData.startDate | timeOnly }} -
								{{ data.appointmentData.endDate ? formatDateTime(data.appointmentData.endDate, 'hh:mma') : 'Continue' }}
							</small>
						</div>
					</template>
				</DxScheduler>


			</b-card-body>
		</b-card>
	<!-- </CheckPermission> -->
</template>

<script>
import { AxiosWrapper, TextFormatter } from '@/mixins'
import DxScheduler, { DxSchedulerTypes } from 'devextreme-vue/scheduler';
import UsersLookup from '@/components/lookups/UsersLookup.vue';

export default{
	mixins: [ AxiosWrapper, TextFormatter ],
	components: {
		DxScheduler,
		DxSchedulerTypes,
		UsersLookup
	},
	data(){
		return {
			apiRequest: false,
			currentDate: new Date(),
			logs: [],
			range: {},
			userId: this.Current_User_ID
		}
	},
	created(){
		this.range = {
			start: this.weekDayOfDate('Monday'),
			end: this.weekDayOfDate('Sunday'),
		}
		this.load(this.range)
	},
	methods:{
		load(dateRange){
			this.apiRequest = true
			let range = this.stringifyDateRange(dateRange)
			this.post(`api/attendance/logs`, {
				userId: this.userId || this.Current_User_ID,
				date: range
			}).then(response =>{
				if(response.data){
					this.logs = response.data.map(r => ({
						text: r.type,
						startDate: this.formatUtcToLocalDateTime(r.startTime),
						endDate: r.endTime ? this.formatUtcToLocalDateTime(r.endTime) : null
					}))
				}
				console.log(response.data)
			}).finally(() => {
				this.apiRequest = false
			})
		},
		onChangeUser(e){
			this.load(this.range)
		},
		onOptionChanged(e){
			if(e.name == 'currentDate'){
				this.range = this.stringifyDateRange({
					start: e.component.getStartViewDate(),
					end: e.component.getEndViewDate()
				})
				this.load(range)
			}
		},
		logBgColor(v){
			if(v == 'CheckIn') return '#d4edda'
			else if(v == 'Break') return '#d1ecf1'
			else if(v == 'CheckOut') return '#f8d7da'
		},
		logColor(v){
			if(v == 'CheckIn') return '#155724'
			else if(v == 'Break') return '#0c5460'
			else if(v == 'CheckOut') return '#721c24'
		}
	},
	watch:{
		currentDate(v){
			console.log(v)
		}
	}
}

</script>
