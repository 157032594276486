<template>
	<CheckPermission :value="constants.PERMISSION_READ_RESOURCING" :access-denied-msg="true">
		<LoadPanel :shading="false" :visible=" tableRequestLoad || resourceAllocationLoad || resourcingLoad"  />
		<div class="card h-100">
			<div class="card-body h-100">
				<ResourceRequestPopup ref="requestPopup" :weeks="weeks" @update-resource-request="load" />
				<div ref="top-elements">
					<div class="d-flex align-items-center justify-content-between">
						<form @submit="load($event)">
							<DxValidationGroup>
								<div class="d-flex align-items-center">
									<div style="width:300px" class="mr-3">
										<DepartmentsLookup
											v-model="departments"
											label-mode="static"
											:multiple="true"
											:all="true"
											:show-clear-button="true"
											:required="true"
											@value-changed="load"
											v-height="'42px'"
											v-if="SearchByDepartment" />
										<LookupMultiple
											v-model="users"
											label-mode="static"
											label="User"
											v-height="'42px'"
											:api="`api/resourcing/resourceable-users`"
											display-expr="userName" value-expr="id"
											:all="true"
											:show-clear-button="true"
											apply-value-mode="useButtons"
											@value-changed="load"
											v-else />
									</div>
									<DxSelectBox
										v-model="currentWeek"
										:items="weeks"
										class="mr-3"
										label="Week"
										placeholder="Select Week"
										label-mode="static"
										v-width="'300px'"
										v-height="'42px'"
										:required="true"
										@value-changed="load" />
								</div>
							</DxValidationGroup>
						</form>
						<div>
							<Popover width="400px">
								<template #target>
									<DxButton icon="preferences" />
								</template>
								<template #content>
									<div>
										<DxField label="Resourced through" label-width="40%" value-width="60%" css-class="mb-2">
											<DxToggle class="d-flex" type="default" on-text="Departments" off-text="Users" v-model="SearchByDepartment" button-width="100%" width="5em" />
										</DxField>
										<DxField label="Perspective" label-width="40%" value-width="60%" css-class="mb-0">
											<DxToggle class="d-flex" type="default" on-text="Users" off-text="Projects" v-model="UsersPerspective" button-width="100%" width="5em" @value-changed="perspectiveKey = generateUniqueCode()" />
										</DxField>
									</div>
								</template>
							</Popover>
						</div>
					</div>
					<div class="mt-3">
						<div v-if="resourceRequestLoad" style="height:169.19px;">
							<div class="alert alert-warning d-flex align-items-center justify-content-start" v-width="'40rem'">
								<spinner class="mr-2" :noFullWidth="true"/> Loading...
							</div>
						</div>
						<div v-if="SearchByDepartment">
							<div v-if="departments.length == 0" class="alert alert-primary" v-width="'40rem'">
								Please select department(s) and week to see resource requests.
							</div>
							<div v-if="departments.length != 0 && resourceRequests.length == 0 && !resourceRequestLoad"
								class="alert alert-warning" v-width="'616px'">
								No resource request found for this week.
							</div>
						</div>
						<div v-else>
							<div v-if="users.length == 0" class="alert alert-primary" v-width="'40rem'">
								Please select user(s) and week to see resource requests.
							</div>
							<div v-if="users.length != 0 && resourceRequests.length == 0 && !resourceRequestLoad"
								class="alert alert-warning" v-width="'616px'">
								No resource request found for this week.
							</div>
						</div>
					</div>
					<div class="mt-3" v-if="resourceRequests.length > 0">
						<h4 class="mb-3">Requests</h4>
						<DxScrollView direction="horizontal" width="100%">
							<div class="requests-container mb-2" :key="`requestsContainer-${requestsContainerKey}`">
								<div v-for="resourceRequest in resourceRequestsSorted" :key="resourceRequest.id">
									<ResourceRequestBlock
										:id="`request-${resourceRequest.id}`"
										:resourceRequest="resourceRequest"
										:UsersPerspective="UsersPerspective"
										@startDrag="startDrag"
										@endDrag="endDrag"
										@showRequestEditPopup="$refs.requestPopup.show(...arguments)"
										@confirmDeleteResourceRequest="confirmDeleteResourceRequest(...arguments)" />
								</div>
							</div>
						</DxScrollView>
					</div>
				</div>
				<div :key="perspectiveKey">
					<component
						:is="UsersPerspective ? 'ResourcingUsersPerspective' : 'ResourcingProjectsPerspective'"
						v-model="resourceAllocations"
						:ref="'resourcing-users-perspective'"
						:currentWeek="currentWeek"
						:departmentIds="departmentIds"
						:searchByDepartment="SearchByDepartment"
						:tableRequest.sync="tableRequestLoad"
						@updateAllocationRow="onUpdateAllocationRow"
						@refresh="load" />
				</div>
			</div>
		</div>
	</CheckPermission>
</template>

<script>
import Resourcing from '@/mixins/views/Resourcing'
import { AxiosWrapper, TextFormatter } from '@/mixins'
import DxSelectBox from 'devextreme-vue/select-box';
import moment from 'moment'
import { DxButton } from 'devextreme-vue/button'
import { ServiceLookup, UsersLookup, LookupMultiple, Lookup, DepartmentsLookup } from '@/components/lookups'
import { DayOfWeek } from '@/_helpers/constants'
import { DxScrollView } from 'devextreme-vue/scroll-view';
import { DxTooltip } from 'devextreme-vue/tooltip';
import ResourceRequestPopup from './ResourceRequestPopup'
import { DxSwitch } from 'devextreme-vue/switch';
import DxValidationGroup from 'devextreme-vue/validation-group';
import ResourcingUsersPerspective from './ResourcingUsersPerspective.vue';
import ResourcingProjectsPerspective from './ResourcingProjectsPerspective.vue';
import ResourceRequestBlock from './ResourceRequestBlock'
import DxToggle from '@/components/DxToggle.vue'
import { DateFormats, WeekDays } from '@/_helpers/constants';

//Followed below article for draggable feature
//https://learnvue.co/articles/vue-drag-and-drop

export default {
	mixins: [AxiosWrapper, TextFormatter, Resourcing],
	components:{
		DepartmentsLookup,
		ServiceLookup,
		UsersLookup,
		DxSelectBox,
		DxButton,
		DxScrollView,
		DxTooltip,
		ResourceRequestPopup,
		DxSwitch,
		LookupMultiple,
		Lookup,
		DxValidationGroup,
		DxToggle,
		ResourcingUsersPerspective,
		ResourcingProjectsPerspective,
		ResourceRequestBlock
	},
	data(){
		return {
			departments: [],
			users: [],
			weeks:[],
			resourceRequests: [],
			resourceAllocations: [],
			resourceRequestLoad: false,
			resourcingLoad:false,
			resourceAllocationLoad: false,
			tableRequestLoad: false,
			requestsContainerKey:0,
			tableKey: this.generateUniqueCode(),
			//tableHeight: 'calc(100vh - 345px)', //TODO: Do we need to set this?
			currentWeek:'',
			disableProjectAllocation: false,
			disableUserAllocation: false,
			newProjectAllocation: {projectId: '', project: {}},
			newUserAllocation: {userId: '', user: {}},
			disabledServices: [],
			selectableUsers: [],
			summary: null,
			UsersPerspective: true,
			SearchByDepartment: true,
			perspectiveKey: this.generateUniqueCode()
		}
	},
	created(){
		this.loadWeeks()
	},
	methods:{
		loadWeeks(){
			let previous = new Date()
			previous.setDate(previous.getDate() - previous.getDay() - 6)
			let current = new Date()
			current.setDate(current.getDate() - current.getDay() + 1)
			this.weeks = [this.formatDate(previous, DateFormats.MMDDYYYY), this.formatDate(current, DateFormats.MMDDYYYY)].concat(this.nextWeeks(5))
			this.weeks = ([...new Set(this.weeks)]).sort((a,b)=> new Date(a) - new Date(b));
			this.currentWeek = moment(this.weekDayOfDate(WeekDays.Monday)).format(DateFormats.MMDDYYYY)
		},
		load(){
			if(
				(
					(this.SearchByDepartment && this.departments.length > 0) ||
					(!this.SearchByDepartment && this.users.length > 0)
				) && this.currentWeek
			){
				this.getResourceRequests()
				this.getResourcing()
				this.disableProjectAllocation = false
				this.newProjectAllocation= { projectId: '', project: {} }
			}
			else{
				this.resourceRequests = [];
				this.requestsContainerKey++
				this.resourceAllocations = [];
			}
		},
		getResourceRequests(){
			this.resourceRequestLoad = true
			this.resourceRequests = []
			this.post('api/resourcing/requests/search', {
				searchByDepartment: this.SearchByDepartment,
				users: this.users.map(s => s.id),
				departments: this.departments.map(s => s.id),
				week: new Date(this.currentWeek),
				weekString: this.currentWeek
			}).then(response =>{
				this.resourceRequests = response.data
				this.requestsContainerKey++
			}).finally(final =>{
				this.adjustTableHeight()
				this.resourceRequestLoad = false
			})
		},
		getResourcing(){
			this.resourcingLoad = true
			this.resourceAllocations = []
			this.post('api/resourcing/get', {
				searchByDepartment: this.SearchByDepartment,
				users: this.users.map(s => s.id),
				departments: this.departments.map(s => s.id),
				week: new Date(this.currentWeek),
				weekString: this.currentWeek
			}).then(response => {
				this.resourceAllocations = response.data
					.sortByFn(r => r.user.fullName.toLowerCase())
					.map(r => ({
						...r,
						projectAllocations: r.projectAllocations.sortByFn(a => a.project.name.toLowerCase())
					}))

				let projectAllocations = []
				this.resourceAllocations.forEach(element => {
					element.key = this.generateUUID()
					let requests = this.resourceRequests.filter(s => s.users.map(s=>s.id).includes(element.userId))
					if(requests.length > 0){
						requests.forEach(request => {
							if(!element.projectAllocations.some(s=>s.projectId == request.projectId)){
								projectAllocations.push({allocation: element, projectId: request.projectId, projectType: request.projectType, stage: request.projectStage, notes: request.projectNotes })
								console.log(`${element.user.fullName} => ${request.project.name}`)
							}
						})
					}
				});
				if(projectAllocations.length > 0) this.saveProjectAllocations(projectAllocations)
			}).finally(()=>{
				this.resourcingLoad = false
			})
		},
		saveProjectAllocations(projectAllocations){
			if(this.hasPermission(constants.PERMISSION_UPDATE_RESOURCING)){
				let postData = projectAllocations.map(projectAllocation => {
					let allocationObject = {
						allocationId: projectAllocation.allocation.id,
						projectId: projectAllocation.projectId,
						holidays: projectAllocation.allocation.weeklyAllocations.filter(s=>s.isHoliday).map(s => DayOfWeek[s.day])
					}
					return allocationObject
				})
				this.post('api/resourcing/project-allocations-multiple', postData).then(response =>{
					response.data.forEach(group => {
						group.projectAllocations = group.projectAllocations.map(p => {
							const s = projectAllocations.find(s => s.projectId == p.projectId)
							return {
								...p,
								projectType: s.projectType,
								stage: s.stage,
								inFlightNotes: s.notes
							}
						})
						let allocation = this.resourceAllocations.find(s=>s.id == group.resourceAllocationId)
						allocation.projectAllocations.push.apply(allocation.projectAllocations, group.projectAllocations)
						if(this.UsersPerspective){
							this.usersPerspectiveBlock.updateAllocationRow(allocation)
						}
						else{
							this.projectsPerspectiveBlock.updateAllocationRow(allocation)
						}
					})
				})
			}
		},
		onUpdateAllocationRow(projectId){
			if(this.resourceRequests.some(s=>s.projectId == projectId)){
				this.getResourceRequests()
			}
		},
		resourceRequestBgClr(request){
			if(request.hoursAMAP){
				if(request.hoursAllocated > 0) return 'bg-warning'
				else return 'bg-danger'
			}
			else{
				if(Number(request.hoursAllocated) > 0 && Number(request.hoursAllocated) < Number(request.hours))
					return 'bg-warning'
				else if(Number(request.hoursAllocated) >= Number(request.hours))
					return 'bg-success'
				else return 'bg-danger'
			}
		},
		adjustTableHeight(){
			let topElHeight = this.resourceRequests.length > 0 ? 470 : 345
			this.tableHeight = `calc(100vh - ${topElHeight}px)`
			// this.resourceAllocationLoad = false
		},
		startDrag(evt, item) {
			if(!this.hasPermission(constants.PERMISSION_UPDATE_RESOURCING)){
				evt.cancel = true
			}
			this.usersPerspectiveBlock.startDrag(evt, item)
			//this.$refs['resourcing-users-perspective'].startDrag(evt, item)
		},
		endDrag(evt){
			this.usersPerspectiveBlock.endDrag(evt)
			//this.$refs['resourcing-users-perspective'].endDrag(evt)
		},
		confirmDeleteResourceRequest(resourceRequest){
			window.showConfirmModal({
				subTitle: "Deleting is irreversable",
				confirmButtonText: "Delete",
				showLoader: true,
				mode:'danger',
				onConfirm: async () => { await this.deleteResourceRequest(resourceRequest.id) }
			})
		},
		async deleteResourceRequest(id){
			try{
				const response = await this.delete('api/resourcing/requests/' + id)
				window.showSuccessToast('Resource request deleted successfully.')
				this.getResourceRequests()
			}
			catch(e) {
				console.log(e)
			}
		},
	},
	computed:{
		resourceRequestsSorted(){
			return this.resourceRequests.sort((a,b) => {
				if(a.hoursAMAP && !b.hoursAMAP){
					return -1
				}
				else if (!a.hoursAMAP && b.hoursAMAP) {
					return 1;
				}
				let aPercentage  = Number(a.hoursAllocated) / Number(a.hours)
				let bPercentage = Number(b.hoursAllocated) / Number(b.hours)
				return aPercentage - bPercentage;
			})
		},
		departmentIds(){
			return this.departments.map(d => d.id)
		},
		usersPerspectiveBlock(){
			return this.$refs['resourcing-users-perspective']
		},
		projectsPerspectiveBlock(){
			return this.$refs['resourcing-projects-perspective']
		}
	},
	filters:{
		resourcingWeek(val){
			if(val) return moment(val).format('MM/DD')
		},
		oneDecimalPlace(val){
			if(val) return Number(val).toFixed(1)
			return '0.0'
		}
	},
	watch:{
		SearchByDepartment(){
			this.departments = []
			this.users = []
			this.resourceRequests = []
			this.resourceAllocations = []
		}
	}
}
</script>
<style>
.resources-table-header th{
	border-bottom: 0 !important;
}
.requests-container{
	display: flex;
}
div.custom-table{
	display: table;
	width: 100%;
}
.custom-table div.c-row{
	display: table-row;
	width: 100%;
}
.custom-table div.c-column{
	display: table-cell;
	padding:1em;
	border: 1px solid grey;
}
.resources-table-header th{
	border-bottom: 0
}
.tr-vertical-middle td{
	vertical-align: middle !important;
}
.indent {
	padding-left: 50px !important;
}
#applyButton{
	margin-left: 15px;
	margin-top: 6px
}
#applyButton .dx-button-content{
	height: 42px;
}
.refresh-button{
	height: 42px;
	width: 42px;
	margin-top: 7px;
}
</style>
