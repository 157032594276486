<template>
	<div :class="wrapperClass">
		<b-spinner variant="info" :style="style"/> <span class="ml-2">{{label}}</span>
	</div>
</template>

<style>
.spinner-sm {
	min-height: 22px;
	min-width: 22px;
}
.height-10-custom{
	height: 10rem
}
</style>

<script>
import { DxLoadIndicator } from 'devextreme-vue/load-indicator';
export default {
	name: "spinner",
	props: ["size", "noFullWidth", 'label', 'hideWrapper'],
	components:{
		DxLoadIndicator
	},
	computed: {
		sizeNumber(){
			let size = ''
			if(this.size == 'sm') size = 1
			else if(this.size == 'md') size = 2
			else if(this.size == 'lg') size = 3
			else {
				size = Number(this.size) || 2
			}
			return size
		},
		style() {
			return 'width:'+this.sizeNumber+'rem;height:'+this.sizeNumber+'rem;'
		},
		wrapperClass(){
			return `${this.hideWrapper ? '' : 'spinner-wrapper'} ${this.size == 'sm' ? 'spinner-sm' : ''}`
		}
	},
};
</script>
