<template>
	<div
		class="request position-relative"
		:draggable="hasPermission(constants.PERMISSION_UPDATE_RESOURCING) && UsersPerspective"
		:class="resourceRequestBgClr(resourceRequest)"
		@dragstart="startDrag($event, resourceRequest)"
		@dragend="endDrag($event)">
		<div class="vertical-center w-100">
			<p>{{resourceRequest.hoursAllocated | oneDecimalPlace}}/{{resourceRequest.hoursAMAP ? 'AMAP' : Number(resourceRequest.hours).toFixed(1)}}</p>
		</div>
		<span class="project-name" :title="resourceRequest.service.displayName">{{resourceRequest.service.displayName}}</span>
		<div class="position-absolute" v-top="'5px'" v-right="'5px'" v-if="hasPermission(constants.PERMISSION_UPDATE_RESOURCING)">
			<DxButton
				v-height="'21px'" v-width="'20px'"
				class="position-relative"
				@click="$refs.requestPopup.show(resourceRequest)">
				<Icon name="dx-icon-edit" icon-pack="dx" left="3px" top="2px" :el-attr="{class:'position-absolute'}" />
			</DxButton>
			<DxButton
				v-height="'21px'" v-width="'20px'"
				class="position-relative"
				:disabled="resourceRequest.hoursAllocated > 0"
				:hint="resourceRequest.hoursAllocated > 0 ? 'Hours have been allocated hence request can not be deleted' : ''"
				@click="confirmDeleteResourceRequest(resourceRequest)">
				<Icon name="dx-icon-trash" icon-pack="dx" left="3px" top="2px" :el-attr="{class:'position-absolute'}" />
			</DxButton>
		</div>
		<b-popover :target="`request-${resourceRequest.id}`" triggers="hover">
			<div class="text-center">
				<div class="mb-2">
					<strong>Requestor: </strong>{{isNotNullOrEmpty(resourceRequest.requestedBy) ? resourceRequest.requestedBy.userName : ''}}
				</div>
				<div class="mb-2">
					<strong>Preferred Resources: </strong>
					<div v-for="user in resourceRequest.users" :key="user.id">{{user.userName}}</div>
				</div>
				<div class="mb-2">
					<strong>Resourcing Week: </strong> {{resourceRequest.weekDate | resourcingWeek}}
				</div>
				<div>
					<span class="font-italic">{{resourceRequest.notes}}</span>
				</div>
			</div>
		</b-popover>
	</div>
</template>

<script>
import { AxiosWrapper } from '@/mixins'
import Resourcing from '@/mixins/views/Resourcing'
import { DxButton } from 'devextreme-vue/button'
import moment from 'moment'
import { ResourcingRequestBlockColors } from '@/_helpers/constants'
export default{
	props:['resourceRequest','UsersPerspective'],
	mixins: [AxiosWrapper, Resourcing],
	components:{DxButton},
	methods:{
		showRequestEditPopup(resourceRequest){
			this.$emit('showRequestEditPopup', resourceRequest)
		},
		confirmDeleteResourceRequest(resourceRequest){
			this.$emit('confirmDeleteResourceRequest', resourceRequest)
		},
		resourceRequestBgClr(request){
			let clr = ''
			if(request.hoursAMAP){
				if(request.hoursAllocated > 0) clr = ResourcingRequestBlockColors.Yellow
				else clr = ResourcingRequestBlockColors.Red
			}
			else{
				if(Number(request.hoursAllocated) > 0 && Number(request.hoursAllocated) < Number(request.hours))
					clr = ResourcingRequestBlockColors.Yellow
				else if(Number(request.hoursAllocated) >= Number(request.hours))
					clr = ResourcingRequestBlockColors.Green
				else
					clr = ResourcingRequestBlockColors.Red
			}
			if(this.UsersPerspective){
				clr += ' cursor-all-scroll'
			}
			return clr
		},
		startDrag(evt, item) {
			if(!this.hasPermission(constants.PERMISSION_UPDATE_RESOURCING)){
				evt.cancel = true
			}
			this.$emit('startDrag', evt, item)
		},
		endDrag(evt){
			this.$emit('endDrag', evt)
		},
	},
	filters:{
		resourcingWeek(val){
			if(val) return moment(val).format('MM/DD')
		},
		oneDecimalPlace(val){
			if(val) return Number(val).toFixed(1)
			return '0.0'
		}
	},
}
</script>
<style scoped>
.request{
	margin-right: 20px;
	width: 150px;
	height: 120px;
	text-align: center;
	position: relative;
	color: #fff;
}
.request .vertical-center{
	font-size: 25px;
	font-weight: 600;
	margin: 0;
	position: absolute;
	top: 50%;
	left: 50%;
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
}
.request .project-name{
	font-size: 12px;
	position: absolute;
	width: 100%;
	bottom: 5%;
	left: 50%;
	-ms-transform: translate(-50%, -25%);
	transform: translate(-50%, -25%);
	width: 130px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}</style>
