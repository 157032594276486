<template>
    <DxTagBox
        v-model="selected"
        :search-enabled="true"
        :data-source="list"
        :display-expr="displayExpr"
        :value-expr="valueExpr"
        :placeholder="placeholder"
        :label="label"
        :label-mode="labelMode"
        :multiline="multiline"
        :show-clear-button="showClearButton"
		:apply-value-mode="applyValueMode"
        @value-changed="onValueChanged">
        <DxValidator v-if="required"> <DxRequiredRule message="Value(s) are required"/> </DxValidator>
    </DxTagBox>
</template>

<script>
import DxTagBox from 'devextreme-vue/tag-box';
import { AxiosWrapper } from '../../mixins/AxiosWrapper'
import { DxValidator, DxRequiredRule } from 'devextreme-vue/validator';
export default {
    name:'LookupMultiple',
    props:{
        'value':{
            type: Array,
            default: () => []
        },
        'required':{
            type: Boolean,
            default: false
        },
        'multiline':{
            type: Boolean,
            default: false
        },
        'display-expr':{
            type: String,
            default: 'name'
        },
        'value-expr':{
            type: String,
            default: ''
        },
        'placeholder':{
            type: String,
            default: 'Select...'
        },
        'api':{
            type: String,
            default: ''
        },
        'label':{
            type: String,
            default: ''
        },
        'label-mode':{
            type: String,
            default: 'hidden'
        },
        'all':{
            type: Boolean,
            default: false
        },
        'show-clear-button':{
            type: Boolean,
            default: false
        },
		'apply-value-mode':{
            type: String,
            default: 'instantly'
        },
    },
    components:{ DxTagBox, DxValidator, DxRequiredRule },
    mixins:[ AxiosWrapper ],
    data(){
        return {
            selected: [],
            listRaw:[],
            list: null,
        }
    },
    created(){
        this.selected = this.value.map(s => typeof s == 'string' ? s : s[this.valueExpr]) || []
        this.list = this.initializeList()
    },
    watch:{
        api(){
            this.list = this.initializeList()
        }
    },
    methods:{
        onValueChanged(e){
            if(this.all && e.value.includes('all')){
                this.selected = this.listRaw.map(s => s[this.valueExpr])
                e.value = this.selected
            }
            this.$emit('input', this.listRaw.filter(s => e.value.includes(s[this.valueExpr])))
            this.$emit('value-changed', e)
        },
        initializeList(){
            return {
                loadMode: 'raw',
                load: () => {
                    const promise = new Promise((resolve) => {
                        if(this.api){
                            this.get(this.api).then(response =>{
                                this.listRaw = typeof response.data == 'string' ? JSON.parse(response.data) : response.data
                                if(!this.all || this.listRaw.length > 0){
                                    resolve(this.listRaw);
                                }
                                else{
                                    let allObject = {}
                                    allObject[this.displayExpr] = 'All'
                                    allObject[this.valueExpr] = 'all'
                                    let list = [allObject].concat(this.listRaw)
                                    resolve(list);
                                }
                            })
                        }
                        else{
                            this.listRaw = []
                            resolve(this.listRaw);
                        }
                    });
                    return promise;
                },
            }
        }
    }
}
</script>
