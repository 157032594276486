<template>
	<Popup
        ref="extraDayRequestPopup"
        :destroyOnClose="true"
        :width="'40%'"
        :native-scroll="true"
        :hide-on-outside-click="false"
        @hidden="closePopup"
        :title="`Extra Day Request`">
		<form id="extraDayRequestForm" ref="extraDayRequestForm" @submit="submit($event)">
			<label class="mb-4" v-if="supervisorStatusMsg" v-html="supervisorStatusMsg"></label>
			<DxValidationGroup ref="extraDayValidationGroup" :validation-status="validationStatus">
				<DxField label="Date">
					<DxDateBox v-model="extraDay.date" placeholder="Date" >
						<DxValidator>
							<DxRequiredRule message="Date is required" />
							<DxAsyncRule :reevaluate="true" :validation-callback="searchHoliday" validation-message="" />
						</DxValidator>
					</DxDateBox>
					<div class="alert alert-warning mb-0 mt-2" v-if="holiday"> {{ holiday.name }} </div>
				</DxField>
				<DxField label="Notes">
					<DxTextArea v-model="extraDay.notes" placeholder="Please write the reason..." height="100px">
						<DxValidator> <DxRequiredRule message="Notes is required"/> </DxValidator>
					</DxTextArea>
				</DxField>
                <DxButton class="float-right" type="default" text="Submit" :use-submit-behavior="true" />
			</DxValidationGroup>
		</form>
	</Popup>
</template>

<script>
import { AxiosWrapper } from '@/mixins'
import DxValidationGroup from 'devextreme-vue/validation-group';
import { DxValidator, DxRequiredRule, DxCustomRule, DxAsyncRule } from 'devextreme-vue/validator';
import DxDateBox from 'devextreme-vue/date-box';
import { DxTextArea } from 'devextreme-vue/text-area';

function initialState (){
    return {
        apiRequest: false,
        extraDay: {},
		holiday: null,
        supervisorStatusMsg: '',
		validationStatus: null
    }
}
export default {
	mixins: [AxiosWrapper],
	components: {
		DxValidationGroup,
		DxValidator,
		DxRequiredRule,
        DxCustomRule,
        DxAsyncRule,
		DxDateBox,
		DxTextArea
	},
	data() {
		return initialState();
	},
	methods: {
        async show(){
            this.apiRequest = true
            this.$refs.extraDayRequestPopup.show()
            await this.checkSupervisorStatus()
            this.apiRequest = false
        },
        async checkSupervisorStatus(){
            const response = await this.get(`api/users/leaves/supervisor-leave-status`)
            let status = response.data
            if(status.supervisorNotFound){
                this.supervisorStatusMsg = 'Please submit all of the relavant information for your Extra Day and HR will review.'
            }
			else{
				this.supervisorStatusMsg = `Please submit all of the relavant information for your Extra Day and ${status.immediateSupervisor.fullName} will review.`;
			}
            // else if(!status.immediateSupervisorOnLeave || !status.nextSupervisorExists){
            //     this.supervisorStatusMsg = `Please submit all of the relavant information for your Extra Day and ${status.supervisor.fullName} will review.`;
            // }
            // else if(status.immediateSupervisorOnLeave && status.nextSupervisorExists && !status.nextSupervisorOnLeave){
            //     this.supervisorStatusMsg = `Please submit all of the relavant information for your Extra Day and ${status.supervisor.fullName} will review <span style="color:#DA0063">as ${status.immediateSupervisor.fullName} is currently on leave.</span>`;
            // }
        },
		async searchHoliday(){
			if(this.extraDay.date){
				return new Promise((resolve) => {
					let date = this.extraDay.date.toLocaleDateString("en-US");
					this.get(`api/settings/is-holiday?date=${date}`).then(response => {
						this.holiday = response.data.holiday;
						this.extraDay.holidayId = this.holiday?.id
						resolve(true);
					})
				});
			}
			else{
				return new Promise((resolve) => { resolve(true) });
			}
		},
		submit(e){
			e.preventDefault()
			this.apiRequest = true
			this.post('api/extradays/request', this.extraDay).then(response => {
				this.$connectionHub.$emit("new-extra-day-request");
				window.showSuccessToast('You have requested a Extra Day. Please wait for your supervisor to approve.');
				this.$refs.extraDayRequestPopup.hide()
			}).finally(() => {
				this.apiRequest = false
			})
		},
        closePopup(){
            Object.assign(this.$data, initialState());
            this.$emit('hidden')
        }
	},
	watch: {
    	'validationStatus'(newValue) {
			// Watch the validationStatus property of the validation group
			if (newValue === 'valid') {
			// Form is valid
				console.log('Form is valid');
			} else {
			// Form is invalid
				console.log('Form is invalid');
			}
		}
	},
}
</script>
