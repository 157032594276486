<template>
    <Popup ref="requestPopup" title="New Resource Request" :native-scroll="true" @hidden="$emit('hidden')" :destroyOnClose="true">
        <form @submit="submit($event)">
            <spinner v-if="loading" />
            <DxValidationGroup v-else>
                <DxField label="Client" :required="true">
                    <ClientLookup
                        v-model="resourceRequest.clientId"
                        :required="true"
                        :read-only="isEditing && resourceRequest.hoursAllocated > 0"
                        :hint="isEditing && resourceRequest.hoursAllocated > 0 ? 'Hours have been allocated on this Request hence Client can not be changed' : ''" />
                </DxField>
                <DxField label="Project" :required="true">
                    <ServiceLookup
                        v-model="resourceRequest.serviceId"
                        :disabled="disable"
                        :clientId="resourceRequest.clientId"
                        :require-client="true"
                        :required="true"
                        @on-load="onProjectsLoad"
                        :read-only="isEditing && resourceRequest.hoursAllocated > 0"
                        :hint="isEditing && resourceRequest.hoursAllocated > 0 ? 'Hours have been allocated on this Request hence Project can not be changed' : ''" />
                </DxField>
                <DxField label="Week" :required="true">
                    <DxSelectBox
                        v-model="resourceRequest.weekDate"
                        :data-source="weeks"
                        placeholder="Select Week"
                        :disabled="disable"
                        :read-only="isEditing && resourceRequest.hoursAllocated > 0"
                        :hint="isEditing && resourceRequest.hoursAllocated > 0 ? 'Hours have been allocated on this Request hence week can not be changed' : ''" >
                        <DxValidator> <DxRequiredRule message="Week is required"/> </DxValidator>
                    </DxSelectBox>
                </DxField>
                <DxField label="Users" :required="true">
                    <UsersLookup
                        v-model="resourceRequest.users"
                        :multiple="true"
                        :required="true"
                        :disabled="disable"
                        apply-value-mode="useButtons" />
                </DxField>
                <DxField label="Hours" :required="true">
                    <DxTextBox
                        v-if="resourceRequest.hoursAMAP"
                        value="AMAP"
                        :required="true"
                        :readOnly="true" >
                        <DxValidator> <DxRequiredRule message="Hours are required"/> </DxValidator>
                    </DxTextBox>
                    <DxNumberBox
                        v-else
                        v-model="resourceRequest.hours"
                        placeholder="Enter Hours"
                        :min="1"
                        :show-spin-buttons="true"
                        :disabled="disable">
                        <DxValidator>
                            <DxRequiredRule message="Hours are required"/>
                            <DxCustomRule :validation-callback="() => resourceRequest.hours > 0" message="Hours should be greater than 0"/>
                        </DxValidator>
                    </DxNumberBox>
                    <div class="d-flex align-items-center justify-content-between mt-2">
                        <DxButton text="AMAP" @click="resourceRequest.hoursAMAP = true" :disabled="disable" hint="As Many As Possible" />
                        <DxButton text="40" @click="setHours(40)" :disabled="disable" />
                        <DxButton text="30" @click="setHours(30)" :disabled="disable" />
                        <DxButton text="20" @click="setHours(20)" :disabled="disable" />
                        <DxButton text="10" @click="setHours(10)" :disabled="disable" />
                        <DxButton text="5" @click="setHours(5)" :disabled="disable" />
                    </div>
                </DxField>
                <DxField label="Notes" :required="true">
                    <DxTextArea
                        placeholder="Enter Notes"
                        v-model="resourceRequest.notes"
                        :required="true"
                        :disabled="disable"
                        :height="120">
                        <DxValidator>
                            <DxRequiredRule message="Notes are required"/>
						    <DxCustomRule :validation-callback="() => resourceRequest.notes.length >= 20" message="Please write atleast 20 characters"/>
                        </DxValidator>
                    </DxTextArea>
                </DxField>
                <div><DxField v-if="errorMessage != ''">
                    <p class="text-danger">{{errorMessage}}</p>
                </DxField></div>
                <DxField css-class="text-right">
                    <DxButton :use-submit-behavior="true" text="Submit" type="default" />
                </DxField>
            </DxValidationGroup>
        </form>
    </Popup>
</template>

<script>
// import Popup  from '../../components/generic/Popup';
import { DxButton } from 'devextreme-vue/button'
import { DxValidator, DxRequiredRule, DxCustomRule } from 'devextreme-vue/validator';
import DxTextArea from 'devextreme-vue/text-area';
import DxTextBox from 'devextreme-vue/text-box';
import { AxiosWrapper } from '../../mixins'
import DxSelectBox from 'devextreme-vue/select-box';
import DxValidationGroup from 'devextreme-vue/validation-group';
import { ClientLookup, ServiceLookup, UsersLookup } from '../../components/lookups'
import { DxNumberBox } from 'devextreme-vue/number-box';
import moment from 'moment'

export default {
    props:['weeks','requests'],
    components:{
        // Popup,
        DxButton,
        DxValidator,
        DxRequiredRule,
        DxCustomRule,
        DxTextArea,
        DxTextBox,
        DxSelectBox,
        DxValidationGroup,
        DxNumberBox,
        ClientLookup,
        ServiceLookup,
        UsersLookup
    },
    mixins:[AxiosWrapper],
    data(){
        return{
            loading: false,
            resourceRequest: {
                clientId: '',
                serviceId:'',
                weekDate:this.weeks[1],
                users:[],
                hours: 0,
                hoursAMAP: false,
                notes:''
            },
            errorMessage: ''
        }
    },
    methods:{
        show(resourceRequest){
            if(resourceRequest){
                this.resourceRequest.id = resourceRequest.id
                this.resourceRequest.clientId = resourceRequest.clientId
                this.resourceRequest.serviceId = resourceRequest.serviceId
                this.resourceRequest.weekDate = moment(resourceRequest.weekDate).format('MM/DD/YYYY')
                this.resourceRequest.users = resourceRequest.users
                this.resourceRequest.hours = Number(resourceRequest.hours)
                this.resourceRequest.hoursAMAP = resourceRequest.hoursAMAP
                this.resourceRequest.notes = resourceRequest.notes
                this.resourceRequest.hoursAllocated = resourceRequest.hoursAllocated
            }
            this.$refs.requestPopup.show()
        },
        onProjectsLoad(projects){
            if(projects.length === 1)
                this.resourceRequest.serviceId = projects[0].id
        },
        setHours(hours){
            this.resourceRequest.hoursAMAP = false
            this.resourceRequest.hours += hours
        },
        submit(e){
            e.preventDefault()
            if(this.errorMessage != '') return

            this.post('api/resourcing/requests', {
                id: this.resourceRequest.id,
                serviceId: this.resourceRequest.serviceId,
                users: this.resourceRequest.users,
                hours: this.resourceRequest.hours,
                hoursAMAP: this.resourceRequest.hoursAMAP,
                notes: this.resourceRequest.notes,
                weekDate: new Date(this.resourceRequest.weekDate),
                weekDateString: this.resourceRequest.weekDate,
                requestedBy: {id: this.Current_User_ID}
            }).then(response =>{
                window.showSuccessToast('Resource requested successfully.')
                this.$emit('update-resource-request')
                this.$refs.requestPopup.hide()
                this.$emit('hidden')
            }).catch(error =>{
                window.showErrorToast(error.message)
            })

        }
    },
    watch:{
        resourceRequest:{
            handler(val){
                if(this.isNotNullOrEmpty(val.clientId) && this.isNotNullOrEmpty(val.serviceId) && this.isNotNullOrEmpty(val.weekDate)){
                    this.post('api/resourcing/requests/validate',{
                        id: this.resourceRequest.id || "",
                        clientId: val.clientId,
                        serviceId: val.serviceId,
                        weekDate: new Date(this.resourceRequest.weekDate),
                        weekDateString: this.resourceRequest.weekDate
                    }).then(response =>{
                        if(!response.data.isValid){
                            this.errorMessage = `${response.data.requestedBy} has already requested resourcing for this Project during this week. Please reach out to them if you’d like to modify their request.`
                        }
                        else{
                            this.errorMessage = ''
                        }
                    }).catch(error =>{
                        window.showErrorToast(error.message)
                    })
                }
                else{
                    this.errorMessage = ''
                }
            },
            deep: true
        }
    },
    computed:{
        disable(){
            return this.resourceRequest.clientId == ''
        },
        isEditing(){
            return this.resourceRequest.id != ''
        }
    }
}
</script>
