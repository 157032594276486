const defaultUser = {
	email: localStorage.getItem("userEmail") ?? "",
	password: localStorage.getItem("userPassword") ?? "",
	avatarUrl: "https://js.devexpress.com/Demos/WidgetsGallery/JSDemos/images/employees/06.png",
};

export default {
	_user: defaultUser,
	loggedIn() {
		return (
			this._user &&
			this._user.email === "admin@gmail.com" &&
			this._user.password === "121212"
		);
	},

	async logIn(email, password) {
		try {
			// Send request
			console.log(email, password);
			this._user = { ...defaultUser, email, password };
			localStorage.setItem("userEmail", email);
			localStorage.setItem("userPassword", password);
			return {
				isOk: this.loggedIn,
				data: this._user,
			};
		} catch {
			return {
				isOk: false,
				message: "Authentication failed",
			};
		}
	},

	async logOut() {
		this._user = null;
		localStorage.removeItem("userEmail");
		localStorage.removeItem("userPassword");
	},

	async getUser() {
		try {
			// Send request

			return {
				isOk: true,
				data: this._user,
			};
		} catch {
			return {
				isOk: false,
			};
		}
	},

	// async resetPassword(email) {
	//   try {
	//     // Send request
	//     console.log(email);

	//     return {
	//       isOk: true,
	//     };
	//   } catch {
	//     return {
	//       isOk: false,
	//       message: "Failed to reset password",
	//     };
	//   }
	// },

	// async changePassword(email, recoveryCode) {
	//   try {
	//     // Send request
	//     console.log(email, recoveryCode);

	//     return {
	//       isOk: true,
	//     };
	//   } catch {
	//     return {
	//       isOk: false,
	//       message: "Failed to change password",
	//     };
	//   }
	// },

	// async createAccount(email, password) {
	//   try {
	//     // Send request
	//     console.log(email, password);

	//     return {
	//       isOk: true,
	//     };
	//   } catch {
	//     return {
	//       isOk: false,
	//       message: "Failed to create account",
	//     };
	//   }
	// },
};
