<template>
    <div class="card" v-if="leaves.length > 0">
		<div class="card-header"><strong>Leaves Pending Approval</strong></div>
        <div class="card-body p-0" id="dashboard-pending-leaves">
            <table class="table mb-0">
                <EmptyRow v-if="leaves.length == 0" :loading="apiRequest" />
                <tr v-for="leave in leaves" :key="leave.id">
                    <td width="30%" v-if="leave.dates.length > 0">
                        <div>{{formatUtcToLocalDateTime(leave.startDate, 'MM/DD @ hh:mma')}}</div>
                        <div>{{formatUtcToLocalDateTime(leave.endDate, 'MM/DD @ hh:mma')}}</div>
                    </td>
                    <td width="30%" v-else>
                        <div>{{leave.dates[0].date | shortDate}}</div>
                        <div>{{leave.dates[0].timespanStr}}</div>
                    </td>
                    <td class="d-flex align-items-center justify-content-between">
                        <span>
                            {{leave.user.lexicalName}} ({{leave.type}})
                        </span>
                        <span>
                            <CButton
                                v-bg-color="'#652CB3'"
                                v-color="'#fff'"
                                @click="openModal(leave)">
                                {{leave.status}}
                            </CButton>
                        </span>
                    </td>
                </tr>
            </table>
        </div>
        <LeaveApprovalPopup ref="leaveApprovalPopup" @authorized="load" />
    </div>
</template>

<script>
import { AxiosWrapper } from '@/mixins'
import DxTextArea from 'devextreme-vue/text-area';
import DxButton from 'devextreme-vue/button';
import LeaveApprovalPopup from './leave-approval-popup.vue';

export default {
    mixins:[AxiosWrapper],
    components:{DxButton, DxTextArea, LeaveApprovalPopup},
    data(){
        return {
            leaves: [],
            leaveApproval: {user:{}},
            balanceBefore: 0,
            balanceAfter: 0,
            notes: '',
            apiRequest: false,
            notesError: false,
			subordinates: [],
        }
    },
    created(){
        this.load()
    },
    mounted(){
        this.$connectionHub.$on('reload-approval-panel', () => { this.load() })
    },
    methods:{
        load(){
            this.get('api/users/pending-leaves').then(response =>{
                this.leaves = response.data
            })
			this.get('api/dashboard/subordinates').then(response => {
				this.subordinates = response.data
			})
        },
        openModal(leave){
			this.$refs.leaveApprovalPopup.show(leave)
        },
    },
	computed:{
		showPendingLeaves(){
			return this.subordinates.length ? true : false
		},
	}
}
</script>
