<template>
  <div class="dx-field" :class="cssClass">
    <div
      v-if="showLabel"
      class="dx-field-label"
      ref="dx-label"
      :class="_labelClass"
    >
      <slot name="label" v-if="labelSlot" />
      <span :id="'_' + labelId" class="label-span">
        {{ label }}
      </span>
      <DxTooltip
        v-if="labelTooltip"
        :hide-on-outside-click="false"
        :target="'#_' + labelId"
        show-event="mouseenter"
        hide-event="mouseleave"
        position="right"
        max-width="20em"
        height="auto"
      >
        {{ labelTooltip }}
      </DxTooltip>
      <!-- {{!labelSlot ? label : ''}} -->
    </div>
    <div ref="dx-value" class="dx-field-value" :class="valueClass">
      <slot />
    </div>
  </div>
</template>

<script>
import { DxTooltip } from "devextreme-vue/tooltip";
export default {
  name: "DxField",
  components: { DxTooltip },
  props: {
    required: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: "",
    },
    "label-slot": {
      type: Boolean,
      default: false,
    },
    "css-class": {
      type: String,
      default: "",
    },
    "label-class": {
      type: String,
      default: "",
    },
    "value-class": {
      type: String,
      default: "",
    },
    "label-width": {
      type: String,
      default: "25%",
    },
    "value-width": {
      type: String,
      default: "75%",
    },
    "label-bold": {
      type: Boolean,
      default: true,
    },
    "label-sm": {
      type: Boolean,
      default: false,
    },
    "value-sm": {
      type: Boolean,
      default: false,
    },
    "show-label": {
      type: Boolean,
      default: true,
    },
    "label-tooltip": {
      type: String,
      default: "",
    },
  },
  data() {
    return { labelId: this.generateUUID() };
  },
  computed: {
    _labelClass() {
      let _class = "";
      _class += this.required ? "required " : "";
      _class += this.labelBold ? "dx-field-label-bold " : "";
      _class += this.labelSm ? "dx-field-sm text-gray" : "";
      _class += this.labelClass;
      return _class;
    },
  },
  mounted() {
    if (this.showLabel)
      this.$refs["dx-label"].style.setProperty(
        "width",
        this.labelWidth,
        "important"
      );
    if (this.valueSm) this.$refs["dx-value"].classList.add("dx-field-sm");
    this.$refs["dx-value"].style.setProperty(
      "width",
      this.valueWidth,
      "important"
    );
  },
};
</script>

<style scoped>
.dx-field {
  margin: 0 0 15px;
  display: flex;
  align-items: center;
}
.dx-field-label {
  font-size: 0.875rem;
  padding: 0 10px 0 0;
  white-space: normal;
}
.dx-field-label.required .label-span:after {
  content: " *";
  color: var(--danger);
}
.dx-field-label-bold {
  font-weight: 600;
}
.dx-field-sm {
  font-size: 0.75rem;
  font-weight: 600;
}

.text-gray {
  color: #7a8595;
}

.text-white .label-span {
  color: #fff !important;
}
</style>
