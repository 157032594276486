<template>
	<CheckPermission :value="constants.PERMISSION_READ_LEAVES" :access-denied-msg="true">
		<div class="card h-100">
			<div class="card-body contains-leaves-tables">
				<div class="d-flex align-items-end justify-content-between">
					<div class="d-flex">
						<div class="card-custom pointer" @click="leaveModal">
							<div class="cc-body" v-height="'100%'" v-bg-color="'#fff'">
								<div class="new-leave-text">
									<Icon name="cil-plus" /> Request New Leave
								</div>
							</div>
						</div>
						<div class="card-custom">
							<div class="cc-header">
								PTO
								<Icon name="cid-info-circle" :el-attr="{ id: 'pto-details' }" />
								<b-popover target="#pto-details" triggers="hover" placement="bottom">
									<div>{{ totalLeaveBank.policyPTO | twoDecimals }}/hrs (Policy) + </div>
									<div>{{ totalLeaveBank.annualPTOIncrease | twoDecimals }}/hrs (Annual increase) +
									</div>
									<div>{{ totalLeaveBank.ptoAdjustments | twoDecimals }}/hrs (Adjustments) = </div>
									<div>{{ totalLeaveBank.pto | twoDecimals }}/hrs</div>
								</b-popover>
							</div>
							<div class="cc-body">
								<div class="cc-body-text">
									{{ leaveBank.pto }}/{{ totalLeaveBank.pto }} <span class="sml">hr</span>
								</div>
							</div>
						</div>
						<div class="card-custom">
							<div class="cc-header">WFH</div>
							<div class="cc-body">
								<div class="cc-body-text">
									{{ leaveBank.wfh }}/{{ totalLeaveBank.wfh }} <span class="sml">hr</span>
								</div>
							</div>
						</div>
						<div class="card-custom" v-if="leaveBank.npto != 0">
							<div class="cc-header">NPTO</div>
							<div class="cc-body">
								<div class="cc-body-text">
									{{ leaveBank.npto }}/00 <span class="sml">hr</span>
								</div>
							</div>
						</div>
					</div>
					<DxField label="Year" class="mb-0" label-width="100%" value-width="100%">
						<DxSelectBox :items="userLeaveYears" v-model="selectedYear" @value-changed="getUserLeaves"
							width="100px" />
					</DxField>
				</div>
				<spinner v-if="apiRequest" />
				<UserLeavesTable
					v-else
					id="leavesTable" class="mt-3" ref="userLeaveTableComponent"
					:userLeaves="userLeaves"
					:leaveBank="leaveBank"
					height="calc(100vh - 14.5rem)"
					actionable-in="UserLeavesTab"
					@refresh="load" />
				<!-- <LoadPanel :visible="apiRequest" container="#leavesTable" /> -->
			</div>
		</div>
	</CheckPermission>
</template>

<script>
import { AxiosWrapper } from '../../mixins';
import DxButton from 'devextreme-vue/button';
import DxSelectBox from 'devextreme-vue/select-box';
import UserLeavesTable from './user-leaves-table'
export default {
	name: 'UserLeaves',
	mixins: [AxiosWrapper],
	components: { DxButton, UserLeavesTable, DxSelectBox },
	data() {
		return {
			selectedYear: new Date().getFullYear(),
			userLeaveYears: [new Date().getFullYear()],
			userLeaves: [],
			leaveBank: { pto:0, wfh: 0, leavePolicy: {} },
			totalLeaveBank: {pto:0, wfh: 0},
			apiRequest: false
		}
	},
	async mounted() {
		await this.load()

		//WebSocket hook to reload data if new data is inserted
		this.$connectionHub.$on('new-leave-added', async () => {
			await this.load()
		});
	},
	methods: {
		async load() {
			try{
				this.apiRequest = true
				const response = await this.get(`api/users/${this.Current_User_ID}/leaves/required-info`)
				this.leaveBank = response.data.leaveBank
				this.totalLeaveBank = response.data.totalLeaveBank
				this.userLeaveYears = response.data.years.map(y => Number(y))
				if (this.userLeaveYears.length == 0) {
					this.userLeaveYears = [new Date().getFullYear()]
				}
				this.getUserLeaves()
			}
			catch(e){
				window.showErrorToast(e.message)
			}
			finally{
				this.apiRequest = false
			}
		},
		getUserLeaves() {
			this.apiRequest = true
			this.get(`api/users/${this.Current_User_ID}/leaves/${this.selectedYear}`).then(response => {
				this.userLeaves = response.data
			}).finally(() => {
				this.apiRequest = false
			})
		},
		leaveModal() {
			window.showLeaveRequestModal()
		}
	}
}
</script>


<style scoped>
.card-custom {
	border: 1px solid #323142;
	margin-right: 1rem;
	width: 170px
}

.card-custom .cc-header {
	background: #667CBB;
	padding: 5px 10px;
	color: #fff;
	font-weight: 600;
	text-align: center;
}

.card-custom .cc-body {
	height: 80px;
	position: relative;
	background: #323142;
}

.card-custom .cc-body .cc-body-text {
	margin: 0;
	position: absolute;
	top: 50%;
	left: 50%;
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	font-size: 25px;
	font-weight: 600;
	color: #fff;
}

.card-custom .cc-body .cc-body-text .sml {
	position: absolute;
	font-size: 15px;
	margin-left: 5px;
	top: 3px;
}

.card-custom .new-leave-text {
	font-size: 14px;
	text-align: center;
	position: absolute;
	top: 40%;
	left: 7%;
	font-weight: 600;
}
</style>
