<template>
	<div class="dx-swatch-additional side-navigation-menu" @click="forwardClick">
		<slot />
		<div class="menu-container bg-black" style="height: calc(100% - 60px);">
			 <dx-tree-view
				id="main-menu-nav"
				v-if="!isSettingsPage"
				ref="treeViewRef"
				key-expr="id"
				:items="defaultNav"
				selection-mode="single"
				:animation-enabled="true"
				:focus-state-enabled="true"
				expand-event="click"
				@item-click="handleItemClick"
				width="100%"
			/>
			<DxTreeView
				v-else
				height="100%"
				ref="treeViewRef"
				key-expr="key"
				:items="settingsNav"
				@item-click="handleItemClick"
				:on-content-ready="onItemRendered"
				:animation-enabled="true"
				:focus-state-enabled="true">
			</DxTreeView>
		</div>
	</div>
</template>

<script>
import DxTreeView from "devextreme-vue/tree-view";
import { sizes } from "../utils/media-query";
import { DefaultNav, SettingsNav } from "../router/app-navigation";

const treeViewRef = "treeViewRef";
const isLargeScreen = sizes()["screen-large"];
const defaultNav = DefaultNav.map((item) => {
	return { ...item, expanded: isLargeScreen };
});
const settingsNav = SettingsNav.map((item) => {
	return { ...item, expanded: isLargeScreen };
});

export default {
	props: {
		compactMode: Boolean,
	},
	data() {
		return {
			treeViewRef,
			settingsNav: [],
			defaultNav: [],
			isSettingsPage: this.$route.meta.isSettingsPage
		};
	},
	methods: {
		forwardClick(...args) {
			this.$emit("click", args);
		},

		handleItemClick(e) {
			if(e.node.children.length > 0){
				if(e.node.expanded){
					e.component.collapseItem(e.node.key)
				}
				else{
					e.component.expandItem(e.node.key)
				}
			}

			if (!e.itemData.path || this.compactMode) {
				return;
			}
			this.$router.push('/' + e.itemData.path).catch(() => {});

			const pointerEvent = e.event;
			pointerEvent.stopPropagation();
		},

		updateSelection() {
			if (!this.treeView) {
				return;
			}

			// this.treeView.selectItem(this.$route.path);
			// this.treeView.expandItem(this.$route.path);
		},

		onItemRendered(e) {
			// let key = this.settingsNav.find((obj) => obj.items.find((a) => this.path == a.path));
			// if(key) {
				//e.component.expandItem(key.text);
			//}
		},

		findParentKeyByPath(list, path) {
			for (const section of list) {
				for (const item of section.items) {
					if (item.path === path) {
						return section.key;
					}
				}
			}
			return null; // Return null if the path is not found
		}
	},
	mounted() {
		this.settingsNav = settingsNav.filter(n => n.permission ? this.hasPermission(n.permission) : true);
		this.defaultNav = defaultNav.filter(n => n.permission ? this.hasPermission(n.permission) : true);

		this.treeView = this.$refs[treeViewRef] && this.$refs[treeViewRef].instance;
		this.updateSelection();
		if (this.compactMode) {
			this.treeView.collapseAll();
		}
	},
	watch: {
		'$route'(to, from)  {
			this.updateSelection();
			this.isSettingsPage = Boolean(this.$route.meta.isSettingsPage)
			// if(this.isSettingsPage){
			// 	this.$refs[treeViewRef].instance.collapseAll()
			// 	this.$nextTick(() => {
			// 		let key = this.findParentKeyByPath(this.settingsNav, this.path)
			// 		this.$refs[treeViewRef].instance.expandItem(key)
			// 	})
			// }
		},
		compactMode() {
			if (this.compactMode) {
				this.treeView.collapseAll();
			} else {
				this.updateSelection();
			}
		},
	},
	components: {
		DxTreeView,
	},
	computed:{
		path() {
			return this.$route.path.slice(1);
		},
	}
};
</script>

<style lang="scss">
@import "../dx-styles.scss";
@import "../themes/generated/variables.additional.scss";
.side-navigation-menu {
	display: flex;
	flex-direction: column;
	min-height: 100%;
	height: 100%;
	width: 250px !important;

	.menu-container {
		min-height: 100%;
		// display: flex;
		flex: 1;

		.dx-treeview {
			// ## Long text positioning
			white-space: nowrap;
			// ##

			// ## Icon width customization
			.dx-treeview-item {
				padding: 15px 0;

				.dx-icon {
					width: $side-panel-min-width !important;
					margin: 0 !important;
				}
			}
			// ##

			// ## Arrow customization
			.dx-treeview-node {
				padding: 0 0 !important;
				border-bottom: none !important;

				.dx-treeview-item-content{
					padding: 0 !important;

					.dx-icon.bi{
						margin-top: -5px !important;
					}
				}
			}

			.dx-treeview-toggle-item-visibility {
				right: 10px;
				left: auto;
			}

			.dx-rtl .dx-treeview-toggle-item-visibility {
				left: 10px;
				right: auto;
			}
			// ##

			// ## Item levels customization
			.dx-treeview-node {
				&[aria-level="1"] {
					font-weight: bold;
					border-bottom: 1px solid $base-border-color;
				}

				&[aria-level="2"] .dx-treeview-item-content {
					font-weight: normal;
					padding: 0 $side-panel-min-width;
				}
			}
			// ##
		}

		// ## Selected & Focuced items customization
		.dx-treeview {
			.dx-treeview-node-container {
				.dx-treeview-node {
					&.dx-state-selected:not(.dx-state-focused)
						> .dx-treeview-item {
						background: transparent;
					}

					&.dx-state-selected > .dx-treeview-item * {
						color: $base-accent;
					}

					&:not(.dx-state-focused)
						> .dx-treeview-item.dx-state-hover {
						background-color: lighten($base-bg, 4);
					}
				}
			}
		}

		// .dx-theme-generic .dx-treeview {
		// 	.dx-treeview-node-container .dx-treeview-node.dx-state-selected.dx-state-focused > .dx-treeview-item * {
		// 		color: $base-text-color;
		// 	}
		// }

		.dx-treeview-item * {
			color: $base-text-color;
		}

		.dx-treeview-toggle-item-visibility::before{
			color: $base-text-color;
			top: 75% !important;
		}
		// ##
	}
}
</style>

<style>
#main-menu-nav .dx-item .dx-icon.bi{
	margin-top: -5px !important;
}
</style>
