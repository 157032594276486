<template>
	<div v-bind="_containerProps" class="datepicker-field mb-0">
		<label v-if="label" :class="labelClass">{{label}} <slot name="tooltip"></slot></label>
		<small v-if="description" class="form-text text-muted w-100 mt-0 mb-2">{{description}}</small>
		<div :class="fieldOuterDivClass">
			<div class="d-flex">
				<div v-if="!isInline" class="input-group-prepend pointer">
					<span class="input-group-text rounded-left rounded-0 position-relative" style="width:40px">
						<Icon name="calendar" icon-pack="bootstrap" size="0.8" class="position-absolute" v-top="'3px'" />
					</span>
				</div>
				<v-date-picker
					v-model="selectedDate"
					v-if="!disabled"
					popover-visibility="focus"
					show-caps
					:input-props="_inputProps"
					:timezone="''"
					:mode="mode"
					:min-date="minDate"
					:is-inline="isInline"
					:popover-direction="direction"
					:disabled-dates="disabledDates"
                    :class="((clearButton && selectedDate) || quickSelect) ? 'append-buttons' : 'no-append-buttons'">
					<template #prepend-content><Icon name="calendar" icon-pack="bootstrap"/></template>
				</v-date-picker>
				<input v-if="disabled" disabled="disabled" :value="formattedValue" class="form-control" />
				<div v-if="clearButton && selectedDate && !disabled" class="input-group-append pointer">
					<span class="input-group-text" style="padding:0 9px" id="clearBtn">
						<Icon name="close" icon-pack="dx" @click="clearDate" />
                        <!-- <DxButton :disabled="disabled" @click="clearDate" styling-mode="text" class="dx-btn-small p-0">
                            <Icon name="close" icon-pack="dx" />
                        </DxButton> -->
                        <DxTooltip target="#clearBtn" show-event="mouseenter" hide-event="mouseleave" position="top">Clear</DxTooltip>
                    </span>
				</div>
                <div v-if="quickSelect" class="input-group-append">
					<span class="input-group-text" style="padding:0 9px">
                        <Popover width="263px" ref="quicker-picker-popover">
                            <template #target>
								<Icon name="filter" icon-pack="bootstrap" size="1" id="quickPicker" class="pointer"  />
                                <!-- <DxButton :disabled="disabled" id="quickPicker" styling-mode="text" class="dx-btn-small p-0">
                                    <Icon name="filter" icon-pack="bootstrap" size="1" />
                                </DxButton> -->
                                <DxTooltip target="#quickPicker" show-event="mouseenter" hide-event="mouseleave" position="top">Quick select</DxTooltip>
                            </template>
                            <template #content>
                                <div class="row quick-picker">
                                    <div class="col-6 mb-2">
                                        <p><a @click="today" class="text-dark pointer">Today</a></p>
                                    </div>
                                    <div class="col-6 mb-2">
                                        <p><a @click="yesterday" class="text-dark pointer">Yesterday</a></p>
                                    </div>
                                    <div class="col-6 mb-2">
                                        <h6>Year</h6>
                                        <p><a @click="selectRange('year','last')" class="text-dark pointer">Last Year</a></p>
                                        <p><a @click="selectRange('year','this')" class="text-dark pointer">This Year</a></p>
                                        <p v-if="!hideFutureSelection"><a @click="selectRange('year','next')" class="text-dark pointer">Next Year</a></p>
                                    </div>
                                    <div class="col-6 mb-2">
                                        <h6>Quarter</h6>
                                        <p><a @click="selectRange('quarter','last')" class="text-dark pointer">Last Quarter</a></p>
                                        <p><a @click="selectRange('quarter','this')" class="text-dark pointer">This Quarter</a></p>
                                        <p v-if="!hideFutureSelection"><a @click="selectRange('quarter','next')" class="text-dark pointer">Next Quarter</a></p>
                                    </div>
                                    <div class="col-6">
                                        <h6>Month</h6>
                                        <p><a @click="selectRange('month','last')" class="text-dark pointer">Last Month</a></p>
                                        <p><a @click="selectRange('month','this')" class="text-dark pointer">This Month</a></p>
                                        <p v-if="!hideFutureSelection"><a @click="selectRange('month','next')" class="text-dark pointer">Next Month</a></p>
                                    </div>
                                    <div class="col-6">
                                        <h6>Week</h6>
                                        <p><a @click="selectRange('week','last')" class="text-dark pointer">Last Week</a></p>
                                        <p><a @click="selectRange('week','this')" class="text-dark pointer">This Week</a></p>
                                        <p v-if="!hideFutureSelection"><a @click="selectRange('week','next')" class="text-dark pointer">Next Week</a></p>
                                    </div>
                                </div>
                            </template>
                        </Popover>
                    </span>
				</div>
			</div>
			<div v-if="showRangeLinks" class="formfield-quick-select">
				<span class="small">Quick Select:</span>
				<button type="button" @click="selectThisYear()" class="btn btn-sm btn-light mx-1 my-2">This Year</button>
				<button type="button" @click="selectThisMonth()" class="btn btn-sm btn-light my-2">This Month</button>
			</div>
			<div v-if="!_isValid" class="invalid-feedback" style="display: block;">{{_invalidFeedback}}</div>
			<slot name="description"></slot>
		</div>
	</div>
</template>

<script>
import { setupCalendar, DatePicker } from 'v-calendar'
import 'v-calendar/lib/v-calendar.min.css'
import {TextFormatter} from '@/mixins'
import { DxTooltip } from 'devextreme-vue/tooltip';
import moment from 'moment'

setupCalendar({
	firstDayOfWeek: 2 // Monday
})
export default {
	props: ['value', 'label', 'description', 'placeholder', 'minDate',
		'inputProps', 'mode', 'isInline', 'containerProps', 'direction',
		'disabledDates', 'isValid', 'invalidFeedback', 'clearButton', 'disabled',
        'showRangeLinks','quickSelect', 'hideFutureSelection', 'preSelect'
	],
	components: {'v-date-picker': DatePicker, DxTooltip},
	mixins: [TextFormatter],
	mounted(){
		if(this.preSelect){
			let when = this.preSelect.split(' ')[0]
			let type = this.preSelect.split(' ')[1]
			this.selectRange(type, when)
		}
	},
	computed: {
		_isValid(){
			return this.isValid != null ? this.isValid : true
		},
		_invalidFeedback(){
			return this.invalidFeedback || '';
		},
		labelClass(){
			let val = 'mb-0'
			if (this._containerProps.horizontal){
				val += ' col-form-label col-sm-3'
			}
			return val;
		},
		fieldOuterDivClass(){
			return (this._containerProps.horizontal && this.label) ? 'col-sm-9' : ''
		},
		selectedDate: {
			get() {
				return (this.mode != 'range' && this.value) ? new Date(this.value) : this.value
			},
			set(v) {
				this.$emit('input',v)
				this.$emit('change',v)
			}
		},
		_inputProps() {
			const props = this.inputProps || {
				class: 'form-control mb-0 datepicker-input',
			}
			if (!props.placeholder && this.placeholder){
				props.placeholder = this.placeholder
			}
			if (!this._isValid){
				props.class += ' is-invalid';
			}

			return props
		},
		_containerProps(){
			return this.containerProps || {
				role: "group",
				class: "form-group datepicker-field",
			}
		},
		formattedValue(){
			return this.formatDate(this.value, 'MM/DD/YYYY')
		}
	},
	methods: {
		clearDate(){
			this.selectedDate = null
		},
		selectThisYear() {
			var now = new Date();
			this.selectedDate = {
				start: new Date(now.getFullYear(), 0, 1),
				end: new Date(now.getFullYear(), 11, 31)
			}
            this.$refs['quicker-picker-popover'].close()
		},
		selectThisMonth() {
			var now = new Date();
			this.selectedDate = {
				start: new Date(now.getFullYear(), now.getMonth(), 1),
				end: new Date(now.getFullYear(), now.getMonth() + 1, 0) // using 0 as day returns last day of last month; have to add 1 to month
			}
            this.$refs['quicker-picker-popover'].close()
		},
        today(){
            let now = new Date()
            now.setHours(0,0,0,0)
            this.selectedDate = {
                start: new Date(now),
                end: new Date(now)
            }
            this.$refs['quicker-picker-popover'].close()
        },
        yesterday(){
            let now = new Date()
            now.setDate(now.getDate() - 1)
            this.selectedDate = {
                start: new Date(now),
                end: new Date(now)
            }
            this.$refs['quicker-picker-popover'].close()
        },
        selectRange(type, when){
			var now = new Date();
            now.setHours(0,0,0,0)
            if(type == 'year'){
                if(when == 'this'){
                    this.selectedDate = {
                        start: new Date(now.getFullYear(), 0, 1),
                        end: new Date(now.getFullYear(), 11, 31)
                    }
                }
                else if(when == 'next'){
                    this.selectedDate = {
                        start: new Date(now.getFullYear() + 1, 0, 1),
                        end: new Date(now.getFullYear() + 1, 11, 31)
                    }
                }
                else if(when == 'last'){
                    this.selectedDate = {
                        start: new Date(now.getFullYear() - 1, 0, 1),
                        end: new Date(now.getFullYear() - 1, 11, 31)
                    }
                }
            }
            else if(type == 'quarter'){
                if(when == 'this'){
                    let start = moment().startOf('quarter').format('MM/DD/YYYY')
                    let end = moment().endOf('quarter').format('MM/DD/YYYY')
                    this.selectedDate = {
                        start: new Date(start),
                        end: new Date(end)
                    }
                }
                else if(when == 'next'){
                    let start = moment().quarter(moment().quarter() + 1).startOf('quarter').format('MM/DD/YYYY')
                    let end = moment().quarter(moment().quarter() + 1).endOf('quarter').format('MM/DD/YYYY')
                    this.selectedDate = {
                        start: new Date(start),
                        end: new Date(end)
                    }
                }
                else if(when == 'last'){
                    let start = moment().quarter(moment().quarter() - 1).startOf('quarter').format('MM/DD/YYYY')
                    let end = moment().quarter(moment().quarter() - 1).endOf('quarter').format('MM/DD/YYYY')
                    this.selectedDate = {
                        start: new Date(start),
                        end: new Date(end)
                    }
                }
            }
            else if(type == 'month'){
                if(when == 'this'){
                    let start = moment().startOf('month').format('MM/DD/YYYY')
                    let end = moment().endOf('month').format('MM/DD/YYYY')
                    this.selectedDate = {
                        start: new Date(start),
                        end: new Date(end)
                    }
                }
                else if(when == 'next'){
                    let start = moment().add(1, 'months').startOf('month').format('MM/DD/YYYY')
                    let end = moment().add(1, 'months').endOf('month').format('MM/DD/YYYY')
                    this.selectedDate = {
                        start: new Date(start),
                        end: new Date(end)
                    }
                }
                else if(when == 'last'){
                    let start = moment().subtract(1, 'months').startOf('month').format('MM/DD/YYYY')
                    let end = moment().subtract(1, 'months').endOf('month').format('MM/DD/YYYY')
                    this.selectedDate = {
                        start: new Date(start),
                        end: new Date(end)
                    }
                }
            }
            else if(type == 'week'){
                if(when == 'this'){
                    let start = moment().startOf('isoweek').format('MM/DD/YYYY')
                    let end = moment().endOf('isoweek').format('MM/DD/YYYY')
                    this.selectedDate = {
                        start: new Date(start),
                        end: new Date(end)
                    }
                }
                else if(when == 'next'){
                    let start = moment().add(1, 'weeks').startOf('isoweek').format('MM/DD/YYYY')
                    let end = moment().add(1, 'weeks').endOf('isoweek').format('MM/DD/YYYY')
                    this.selectedDate = {
                        start: new Date(start),
                        end: new Date(end)
                    }
                }
                else if(when == 'last'){
                    let start = moment().subtract(1, 'weeks').startOf('isoweek').format('MM/DD/YYYY')
                    let end = moment().subtract(1, 'weeks').endOf('isoweek').format('MM/DD/YYYY')
                    this.selectedDate = {
                        start: new Date(start),
                        end: new Date(end)
                    }
                }
            }
            this.$refs['quicker-picker-popover'].close()
        }
	}
}
</script>
<style>
.quick-picker p{
    margin-bottom:5px;
    font-size: 13px;
}
.datepicker-input{
	font-size: 14px !important;
	width: 190px !important;
}
</style>
