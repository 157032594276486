<template>
	<CheckPermission :value="constants.PERMISSION_READ_MANAGEMENT_SETTINGS" :access-denied-msg="true">
		<b-card class="h-100 overflow-auto" no-body>
			<b-card-header class="d-flex align-items-center justify-content-between">
				<strong>Assets</strong>
				<DxButton icon="add" @click="addAsset" v-if="hasPermission(constants.PERMISSION_UPDATE_MANAGEMENT_SETTINGS)" />
			</b-card-header>
			<spinner v-if="apiRequest" />
			<DxDataGrid v-else class="header-table" ref="gridRef" :data-source="assets" :show-row-lines="true"
				:show-column-lines="false" :show-borders="true" :hover-state-enabled="true"
				:row-alternation-enabled="false" :on-row-prepared="onAssetRowPrepared">

				<DxColumn data-field="name" />
				<DxColumn data-field="assetCode" />
				<DxColumn data-field="type" />
				<DxColumn data-field="model" />
				<DxColumn data-field="allocation.user.fullName" caption="Assigned To" />
				<DxColumn data-type="date" data-field="lastAssigned" caption="Assigned At" />

				<DxColumn width="120px" caption="Actions" alignment="center" cell-template="actionsTemplate" />
				<template #actionsTemplate="{ data }">
					<DxDropDownButton icon="overflow" styling-mode="text" :show-arrow-icon="false" :drop-down-options="{width:'15vw'}" :useItemTextAsTitle="false" >
                        <DxDropDownButtonItem icon="edit" :onClick="() => { edit(data.data)}" text="Edit" />
                        <DxDropDownButtonItem icon="exportselected" :onClick="() => { allocateAsset(data.data)}" text="Allocate" v-if="data.data.isAssignable && data.data.isAvailable" />
                        <DxDropDownButtonItem icon="info" :onClick="() => { showAllocationHistory(data.data)}" text="Allocation History" />
                        <DxDropDownButtonItem icon="trash" :onClick="() => { askDelete(data.data)}" text="Delete" v-if="data.data.isAvailable" />
                    </DxDropDownButton>
				</template>
			</DxDataGrid>
			<Popup ref="assetPopup" title="Asset" :native-scroll="true">
				<CheckPermission :value="constants.PERMISSION_UPDATE_MANAGEMENT_SETTINGS" :access-denied-msg="true">
					<form ref="assetForm" @submit="submit($event)" :key="assetFormKey">
						<spinner v-if="postRequest" />
						<DxValidationGroup name="assetFormValidationGroup" ref="assetFormValidationGroup" v-else>
							<div class="row">
								<div class="col-6 mb-2">
									<label class="form-label required">Name</label>
									<DxTextBox v-model="asset.name">
										<DxValidator>
											<DxRequiredRule message="Name is required" />
										</DxValidator>
									</DxTextBox>
								</div>
								<div class="col-6 mb-2">
									<label class="form-label required">Code</label>
									<DxTextBox v-model="asset.assetCode">
										<DxValidator>
											<DxRequiredRule message="Code is required" />
										</DxValidator>
									</DxTextBox>
								</div>
								<div class="col-6 mb-2">
									<label class="form-label required">Type</label>
									<DxSelectBox v-model="asset.type" :items="assetTypes" display-expr="value"
										value-expr="name" :search-enabled="true">
										<DxValidator>
											<DxRequiredRule message="Asset Type is required" />
										</DxValidator>
									</DxSelectBox>
								</div>
								<div class="col-6 mb-2">
									<label class="form-label required">Model</label>
									<DxTextBox v-model="asset.model">
										<DxValidator>
											<DxRequiredRule message="Model is required" />
										</DxValidator>
									</DxTextBox>
								</div>
								<div class="col-6 mb-2">
									<label class="form-label">Product ID</label>
									<DxTextBox v-model="asset.productId" />
								</div>
								<div class="col-6 mb-2">
									<label class="form-label">Seriel Number</label>
									<DxTextBox v-model="asset.serielNumber" />
								</div>
								<div class="col-6 mb-2">
									<label class="form-label required">Purchase Date</label>
									<DxDateBox v-model="asset.purchaseDate" placeholder="Purchase Date">
										<DxValidator>
											<DxRequiredRule message="Purchase date is required" />
										</DxValidator>
									</DxDateBox>
								</div>
								<div class="col-6 mb-2">
									<label class="form-label required">Price</label>
									<DxNumberBox v-model="asset.price" placeholder="Price">
										<DxValidator>
											<DxRequiredRule message="Purchase date is required" />
										</DxValidator>
									</DxNumberBox>
								</div>
								<div class="col-12 mb-3 mt-1">
									<DxCheckBox text="Can be assigned?" class="float-left" v-model="asset.isAssignable" />
								</div>
								<div class="col-12 mb-3">
									<label class="form-label required">Description</label>
									<DxHtmlEditor v-model="asset.description" height="300px">
										<DxMediaResizing :enabled="true" />
										<!-- <DxImageUpload :tabs="currentTabs" file-upload-mode="base64" /> -->
										<DxToolbar :multiline="true">
											<DxItem name="undo" />
											<DxItem name="redo" />
											<DxItem name="separator" />
											<DxItem name="size" :accepted-values=" ['8pt', '10pt', '12pt', '14pt', '18pt', '24pt', '36pt']" :options="{ inputAttr: { 'aria-label': 'Font size' } }" />
											<!-- <DxItem name="font" :accepted-values="fontValues" :options="fontFamilyOptions" /> -->
											<DxItem name="separator" />
											<DxItem name="bold" />
											<DxItem name="italic" />
											<DxItem name="strike" />
											<DxItem name="underline" />
											<DxItem name="separator" />
											<DxItem name="alignLeft" />
											<DxItem name="alignCenter" />
											<DxItem name="alignRight" />
											<DxItem name="alignJustify" />
											<DxItem name="separator" />
											<DxItem name="orderedList" />
											<DxItem name="bulletList" />
											<DxItem name="separator" />
											<!-- <DxItem name="header" :accepted-values="headerValues" :options="headerOptions" /> -->
											<DxItem name="separator" />
											<DxItem name="color" />
											<DxItem name="background" />
											<DxItem name="separator" />
											<DxItem name="link" />
											<!-- <DxItem name="image" /> -->
											<DxItem name="separator" />
											<DxItem name="clear" />
											<DxItem name="codeBlock" />
											<DxItem name="blockquote" />
											<DxItem name="separator" />
											<DxItem name="insertTable" />
											<DxItem name="deleteTable" />
											<DxItem name="insertRowAbove" />
											<DxItem name="insertRowBelow" />
											<DxItem name="deleteRow" />
											<DxItem name="insertColumnLeft" />
											<DxItem name="insertColumnRight" />
											<DxItem name="deleteColumn" />
										</DxToolbar>
									</DxHtmlEditor>
								</div>
							</div>
							<div class="col-12 pr-0 text-right">
								<DxButton text="Cancel" @click="$refs.assetPopup.hide()"  />
								<DxButton text="Submit" type="default" :use-submit-behavior="true" class="ml-2" />
							</div>
						</DxValidationGroup>
					</form>
				</CheckPermission>
			</Popup>
			<Popup ref="assetAllocationPopup" title="Asset Allocation" width="40%">
				<CheckPermission :value="constants.PERMISSION_UPDATE_MANAGEMENT_SETTINGS" :access-denied-msg="true">
					<form ref="assetForm" @submit="submitAllocateAsset($event)" :key="allocationFormKey">
						<spinner v-if="postRequest" />
						<DxValidationGroup name="allocationFormValidationGroup" ref="assetFormValidationGroup" v-else>
							<DxField label="Asset" :required="true">
								<DxSelectBox v-model="assetAllocation.assetId" :items="assets" :display-expr="e => e.name + ' - ' + e.assetCode" value-expr="id" :read-only="true">
									<DxValidator>
										<DxRequiredRule message="Asset is required" />
									</DxValidator>
								</DxSelectBox>
							</DxField>
							<DxField label="User" :required="true">
								<UsersLookup v-model="assetAllocation.userId" :required="true" />
							</DxField>
							<DxField label="Assign Date" :required="true">
								<DxDateBox v-model="assetAllocation.assignedAt" placeholder="Assign Date" :min="assetAllocation.lastRevokedAt">
									<DxValidator>
										<DxRequiredRule message="Assign date is required" />
									</DxValidator>
								</DxDateBox>
							</DxField>
							<div class="text-right">
								<DxButton text="Cancel" @click="$refs.assetAllocationPopup.hide()"  />
								<DxButton text="Submit" type="default" :use-submit-behavior="true" class="ml-2" />
							</div>
						</DxValidationGroup>
					</form>
				</CheckPermission>
			</Popup>
			<Popup ref="allocationHistory" title="Allocation History">
				<spinner v-if="historyRequest" />
				<div v-else>
					<div> <strong>Asset: </strong> {{ assetAllocationInfo }} </div>
					<DxDataGrid :data-source="allocationHistory" :show-row-lines="false"
						:show-column-lines="false" :show-borders="true" :hover-state-enabled="true"
						:row-alternation-enabled="true" class="mt-3"
						@row-updating="onUpdatingAllocation"
						@editor-preparing="onEditorPreparing">
						<DxEditing :allow-updating="allowUpdatingAllocation"  />
						<DxColumn data-field="user.fullName" caption="User" :allow-editing="false" :allow-sorting="false" />
						<DxColumn data-field="assignedAt" data-type="date" :sort-index="0" sort-order="desc" :allow-editing="false" :allow-sorting="false" />
						<DxColumn data-field="revokedAt" data-type="date" :allow-sorting="false"/>
					</DxDataGrid>
				</div>
			</Popup>
		</b-card>
	</CheckPermission>
</template>

<script>
import { DxDataGrid, DxColumn, DxPaging, DxEditing, DxTexts } from 'devextreme-vue/data-grid';
import { DxHtmlEditor, DxToolbar, DxMediaResizing, DxItem } from 'devextreme-vue/html-editor';
import { AxiosWrapper } from '@/mixins';
import DxTextBox from "devextreme-vue/text-box";
import DxValidationGroup from "devextreme-vue/validation-group";
import { DxValidator, DxRequiredRule, DxCustomRule } from "devextreme-vue/validator";
import DxSelectBox from "devextreme-vue/select-box";
import DxColorBox from 'devextreme-vue/color-box';
import { DxCheckBox } from "devextreme-vue/check-box";
import DxButton from "devextreme-vue/button";
import {AssetType} from '@/_helpers/constants'
import DxDateBox from 'devextreme-vue/date-box';
import { DxNumberBox } from 'devextreme-vue/number-box';
import UsersLookup from '@/components/lookups/UsersLookup.vue';
import DxDropDownButton, { DxItem as DxDropDownButtonItem } from 'devextreme-vue/drop-down-button'

const asset = () => {
	return {
		id: '',
		type:'',
		name:'',
		model: '',
		productId: '',
		serielNumber: '',
		purchaseDate: null,
		description: '',
		price: 0,
		isAssignable: true
	}
}

const assetAllocation = () => {
	return {
		assetId: '',
		userId: '',
		assignedAt: null,
		revokedAt: null
	}
}

export default {
	mixins:[AxiosWrapper],
	data() {
		return {
			assetFormKey: this.generateUniqueCode(),
			allocationFormKey: this.generateUniqueCode(),
			revokeFormKey: this.generateUniqueCode(),
			asset: asset(),
			assetAllocation: assetAllocation(),
			assets: [],
			apiRequest: true,
			postRequest: false,
			assetTypes: this.objectToList(AssetType),
			allocationHistory: [],
			historyRequest: false,
			currentAsset: {}
		};
	},
	mounted() {
		this.load()
	},
	methods: {
		load(){
			this.apiRequest = true
			this.get('api/assets/all').then(response => {
				this.assets = response.data
			}).finally(() => {
				this.apiRequest = false
			})
		},
		onAssetRowPrepared(e){
            if(e.rowType == 'data'){
				let cls = e.data.isAvailable ? 'bg-green-custom' : 'bg-red-custom'
				e.rowElement.classList.add(cls)
            }
        },
		addAsset() {
			this.asset = asset()
			this.assetFormKey = this.generateUniqueCode()
			this.$refs.assetPopup.show()
		},
		askDelete(row) {
			window.showConfirmModal({
				confirmButtonText: "Delete",
				subTitle: "Deleting is irreversible",
				showLoader: true,
				mode: "danger",
				onConfirm: async () => {
					try {
						await this.delete('api/assets/' + row.id)
						window.showSuccessToast("Asset deleted successfully");
						this.load()
					} catch (error) {
						window.showErrorToast(error.message);
					}
				},
			});
		},
		edit(row) {
			row.price = Number(row.price)
			this.asset = this.cleanSource(row)
			this.assetFormKey = this.generateUniqueCode()
			this.$refs.assetPopup.show()
		},
		submit(e){
			e.preventDefault()
			this.postRequest = true
			let endpoint = this.asset.id ? 'update' : 'add'
			this.post(`api/assets/${endpoint}`, this.asset).then(response => {
				this.$refs.assetPopup.hide()
				this.load()
			}).finally(() => {
				this.postRequest = false
			})
		},
		allocateAsset(asset){
			this.assetAllocation.assetId = asset.id
			this.assetAllocation.lastRevokedAt = asset.lastRevoked
			this.$refs.assetAllocationPopup.show()
		},
		submitAllocateAsset(e){
			e.preventDefault()
			this.postRequest = true
			this.post(`api/assets/allocation`, this.assetAllocation).then(response => {
				this.$refs.assetAllocationPopup.hide()
				this.load()
			}).finally(() => {
				this.postRequest = false
			})
		},
		showAllocationHistory(asset){
			this.$refs.allocationHistory.show()
			if(asset){
				this.currentAsset = asset
			}
			this.historyRequest = true
			this.get(`api/assets/allocationHistory/${this.currentAsset.id}`).then(response => {
				this.allocationHistory = response.data
			}).finally(() =>{
				this.historyRequest = false
			})
		},
		onUpdatingAllocation(e){
			let allocation = {
				id: e.oldData.id,
				assetId: e.oldData.assetId,
				userId: e.oldData.userId,
				assignedAt: e.oldData.assignedAt,
				revokedAt: e.newData.revokedAt
			}
			this.put("api/assets/revoke", allocation).then(() => {
				window.showSuccessToast("Asset revoked.");
				this.load()
				this.showAllocationHistory()
			}).catch((e) => {
				window.showErrorToast(e.message);
			});
		},
		allowUpdatingAllocation(e){
			return e.row.data.revokedAt == null || e.row.data.revokedAt == undefined;
		},
		onEditorPreparing(e) {
			if (e.dataField === 'revokedAt' && e.parentType === 'dataRow') {
				e.editorOptions.min = e.row.data.assignedAt;
			}
		}
	},
	computed:{
		assetAllocationInfo(){
			return this.currentAsset.name + ' - ' + this.currentAsset.assetCode
		}
	},
	components:{
		DxDataGrid,
		DxColumn,
		DxPaging,
		DxEditing,
		DxTexts,
		DxTextBox,
		DxValidator,
		DxRequiredRule,
		DxCustomRule,
		DxSelectBox,
		DxValidationGroup,
		DxColorBox,
		DxCheckBox,
		DxButton,
		DxDateBox,
		DxNumberBox,
		DxHtmlEditor,
		DxToolbar,
		DxMediaResizing,
		DxItem,
		DxDropDownButton,
		DxDropDownButtonItem,
		UsersLookup
	},
};
</script>

