<template>
    <DxSelectBox
        v-model="serviceTypeId"
        :search-enabled="true"
        :data-source="serviceTypes"
        display-expr="name"
        value-expr="id"
        placeholder="Select Service Type"
        :disabled="disabled"
        :read-only="readOnly"
        :hint="hint"
        @value-changed="onSelectServiceType">
        <DxValidator v-if="required"> <DxRequiredRule message="Service Type is required"/> </DxValidator>
    </DxSelectBox>
</template>

<script>
import DxSelectBox from 'devextreme-vue/select-box';
import { AxiosWrapper } from '../../mixins/AxiosWrapper'
import { DxValidator, DxRequiredRule } from 'devextreme-vue/validator';
export default {
    props:{
        'value':{
            type: String,
            default: ''
        },
        'required':{
            type: Boolean,
            default: false
        },
        'disabled':{
            type: Boolean,
            default: false
        },
        'read-only':{
            type: Boolean,
            default: false
        },
        'hint':{
            type: String,
            default: ''
        },
        'map-through':{
            type: String,
            default: ''
        },
        'map-id':{
            type: String,
            default: ''
        },
        'disabled-items':{
            type: Array,
            default: () => []
        },
    },
    components:{ DxSelectBox, DxValidator, DxRequiredRule },
    mixins:[ AxiosWrapper ],
    data(){
        return {
            isLoaded: true,
            serviceTypesRaw:[],
            serviceTypes: {
                loadMode: 'raw',
                load: () => {
                    const promise = new Promise((resolve) => {
                        this.$nextTick(()=>{
                            this.get('api/serviceTypes/get').then(response =>{
                                this.serviceTypesRaw = response.data
                                this.disableServiceTypes()
                                resolve(this.serviceTypesRaw);
                                if(this.value){
                                    let serviceType = this.find(this.serviceTypesRaw, this.value)
                                    this.$emit('service-type-changed', serviceType)
                                }
                                if(this.mapId){
                                    let serviceType = this.serviceTypesRaw.find(c => c[this.mapThrough] == this.mapId)
                                    if(serviceType){
                                        this.serviceTypeId = serviceType.id
                                        this.$emit('service-type-changed', serviceType)
                                    }
                                }
                            })
                        })
                    });
                    return promise;
                },
            },
        }
    },
    methods:{
        onSelectServiceType(e){
            this.$emit('value-changed', e.value)
            let serviceType = e.value ? this.find(this.serviceTypesRaw, e.value) : undefined
            this.$emit('service-type-changed', serviceType)
        },
        disableServiceTypes(){
            this.serviceTypesRaw.forEach(serviceType => {
                if(this.disabledItems.includes(serviceType.id)) serviceType.disabled = true
                else serviceType.disabled = false
            })
        }
    },
    computed:{
        serviceTypeId:{
            get(){ return this.value },
			set(v){ this.$emit('input', v) }
        }
    },
    watch:{
        disabledItems(){
            this.serviceTypes = {
                loadMode: 'raw',
                load: () => {
                    const promise = new Promise((resolve) => {
                        if(this.serviceTypesRaw.length == 0){
                            this.get('api/serviceTypes/get').then(res => {
                                this.serviceTypesRaw = res.data
                                this.disableServiceTypes()
                                resolve(this.serviceTypesRaw);
                            })
                        }
                        else{
                            this.disableServiceType()
                            resolve(this.serviceTypesRaw);
                        }
                    });
                    return promise;
                },
            }
        }
    }
}
</script>
