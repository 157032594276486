/* eslint-disable no-debugger */
import Vue from "vue";
import Router from "vue-router";
import { IsTokenValid, GetDefaultPage } from '../_services/user.service'
import { isMobile } from "../utils/media-query";
import { store } from "../_store";
import { allRoutes } from "./routes";
const formatLabel = function(text){
	if (typeof text !== 'string'){
		if (text && typeof text === 'object' && typeof(text.label) !== 'undefined'){
			return text.label
		}
		return text
	}

	// https://stackoverflow.com/a/7225450
	var result = text
		.replace( /(_\w)/g, function(k) { return k[1].toUpperCase();})
		.replace( /([A-Z])/g, " $1" )
		.replace( /(\/\w)/g, function(k) { return '/'+k[1].toUpperCase();})
	return result.charAt(0).toUpperCase() + result.slice(1)
}


Vue.use(Router);

const router = new Router({
	mode: "history",
	routes: allRoutes,
});

router.beforeEach((to, from, next) => {
	document.title = (formatLabel(to.name + ' - ') || '') + 'Stack360';

	if (to.name === "login" && IsTokenValid()) {
		router.push(GetDefaultPage())
		// next({ name: "home" });
	}
	if (to.matched.some((record) => record.meta.requiresAuth)) {
		if (!IsTokenValid()) {
			next({
				name: "login",
				query: { redirect: to.fullPath },
			});
		} else {
			if (to.path.includes("setting") && isMobile()) {
				store.dispatch("toggleSettingBoard");
			}
			next();
		}
	} else {
		next();
	}
	next();
});

export default router;
