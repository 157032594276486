<template>
    <Popup ref="role-popup" title="Group" width="35%" @hidden="hide" :destroy-on-close="true">
        <form @submit="submit($event)">
            <DxValidationGroup v-if="!apiRequest" ref="validation-group">
                <DxField label="Name" :required="true" label-width="30%" value-width="70%">
                    <DxTextBox v-model="role.name" placeholder="Spaces and dashes are not allowed">
                        <DxValidator> 
                            <DxRequiredRule message="Name is required"/> 
                            <DxCustomRule :validation-callback="testString" message="Only small charactes allowed with no special characters." />
                        </DxValidator>
                    </DxTextBox>
                </DxField>
                <DxField label="Default Login Page" :required="true" label-width="30%" value-width="70%">
                    <DxSelectBox
                        v-model="role.defaultLoginPage"
                        displayExpr="display" 
                        value-expr="value" 
                        :data-source="defaultLogins"
                        placeholder="Select page to route after login">
                        <DxValidator> <DxRequiredRule message="Default page is required"/> </DxValidator>
                    </DxSelectBox>
                </DxField>
                <DxField class="text-right mb-0">
                    <DxButton text="Submit" type="default" class="ml-2" :use-submit-behavior="true" />
                    <DxButton text="Cancel" @click="$refs['role-popup'].hide()" />
                </DxField>
            </DxValidationGroup>
            <spinner v-else />
        </form>
    </Popup>
</template>

<script>
import { AxiosWrapper } from '@/mixins'
import DxValidationGroup from 'devextreme-vue/validation-group';
import DxButton from 'devextreme-vue/button';
import { DxValidator, DxRequiredRule, DxCustomRule } from 'devextreme-vue/validator';
import DxTextBox from 'devextreme-vue/text-box';
import DxSelectBox from 'devextreme-vue/select-box';

export default {
    name: 'RolePopup',
    mixins:[AxiosWrapper],
    components: { 
        DxValidationGroup,
        DxTextBox, DxButton, DxSelectBox,
        DxValidator, DxRequiredRule, DxCustomRule
    },
    data(){
        return {
            apiRequest: false,
            role: {name: '', defaultLoginPage: ''},
            editObj: { isEdit: false },
            defaultLogins:[]
        }
    },
    methods:{
        show(role){
            this.$refs['role-popup'].show()
            if(role){
                this.editObj.name = role.name
                this.editObj.displayName = role.displayName
                this.editObj.isEdit = true
                this.role = {
                    name: role.displayName,
                    defaultLoginPage: role.defaultLoginPage
                } 
                this.defaultLogins = [
                    {display: 'Dashboard', value:'/dashboard', disabled: !role.groupPermissions.some(s => s.permissionId == 'read_dashboard')},
                    {display: 'Emails', value:'/emails', disabled: !role.groupPermissions.some(s => s.permissionId == 'read_emails')},
                    {display: 'Resourcing', value:'/resourcing', disabled: !role.groupPermissions.some(s => s.permissionId == 'read_resourcing')},
                    {display: 'Clients', value:'/clients', disabled: !role.groupPermissions.some(s => s.permissionId == 'read_clients')},
                    {display: 'Services', value:'/services', disabled: !role.groupPermissions.some(s => s.permissionId == 'read_services')},
                    {display: 'In Flight', value:'/in-flight', disabled: !role.groupPermissions.some(s => s.permissionId == 'read_inflight')},
                    {display: 'Leaves', value:'/leaves', disabled: !role.groupPermissions.some(s => s.permissionId == 'read_leaves')},
                ]
            }
            else{
                this.defaultLogins = [
                    {display: 'Dashboard', value:'/dashboard'},
                    {display: 'Emails', value:'/emails'},
                    {display: 'Resourcing', value:'/resourcing'},
                    {display: 'Clients', value:'/clients'},
                    {display: 'Services', value:'/services'},
                    {display: 'In Flight', value:'/in-flight'},
                    {display: 'Leaves', value:'/leaves'},
                ]
            }
        },
        submit(e){
            e.preventDefault()
            this.apiRequest = true
            if(this.editObj.isEdit){
                if(this.editObj.name == this.role.name) this.$refs['role-popup'].hide()
                this.post(`api/settings/roles/${this.editObj.name}/update`, this.role).then(response => {
                    window.showSuccessToast(`Role updated successfully.`)
                    this.$emit('updated', response.data, this.editObj.name)
                    this.$refs['role-popup'].hide()
                }).finally(() => {
                    this.apiRequest = false
                })
            }
            else{
                this.post(`api/settings/roles`, this.role).then(response => {
                    this.$refs['role-popup'].hide()
                    window.showSuccessToast(`New role ${response.data.displayName} added.`)
                    this.$emit('added', response.data)
                }).finally(() => {
                    this.apiRequest = false
                })
            }
        },
        testString(input) {
            const regex = /^[a-zA-Z]+$/;
            return regex.test(input.value);
        },
        hide(){
            this.editObj = { isEdit: false }
            this.role = {name: '', defaultLoginPage: ''}
            this.$emit('hidden')
        }
    }
}
</script>
