<template>
	<div>
		<form @submit="submit($event)" v-if="!card.id" class="p-3">
			<spinner v-if="apiRequest" />
			<DxValidationGroup v-else>
				<DxTextBox v-model="card.subject" label="Title">
					<DxValidator>
						<DxRequiredRule message="Title is required"></DxRequiredRule>
					</DxValidator>
				</DxTextBox>
				<CardTextEditor v-model="card.description" class="mt-2" />
				<div class="row mt-2">
					<div class="col-6">
						<DxSelectBox v-model="card.priority" label="Priority" :items="priorities" display-expr="name" value-expr="value">
							<DxValidator>
								<DxRequiredRule message="Start date is required" />
							</DxValidator>
						</DxSelectBox>
					</div>
					<div class="col-6">
						<DxDateBox v-model="card.dueDate" placeholder="Due Date" label="Due Date">
							<DxValidator>
								<DxRequiredRule message="Due date is required" />
							</DxValidator>
						</DxDateBox>
					</div>
				</div>
				<div class="mt-3 text-right" >
					<DxButton text="Cancel" @click="cancel" />
					<DxButton text="Save" type="default" :use-submit-behavior="true" class="ml-2" />
				</div>
			</DxValidationGroup>
		</form>
		<div v-else>
			<div class="row">
				<div class="col-8 pr-0">
					<h3 class="p-3"> <InlineEdit v-model="card.subject" @onSave="updateCardProp('subject', {value: card.subject})" /> </h3>
					<div class="pr-3 pl-3 pb-3">
						<div class="card-info-container">
							<div class="d-flex align-items-center mb-2">
								<Icon icon-pack="dx" name="aligncenter" class="mr-2" />
								<strong>Description</strong>
								<i class="text-muted ml-2" v-if="!showEditor && !card.description" @click="showEditor = true">Click to edit description</i>
							</div>
							<div
								class="card-description"
								v-html="card.description"
								v-if="!showEditor"
								@click="showEditor = true">
							</div>
							<CardTextEditor
								v-model="card.description"
								class="mt-2"
								@canceled="showEditor = false"
								@saved="updateCardProp('description', {value: card.description})"
								:editing="true"
								v-b-tooltip="'Click to edit'"
								v-if="showEditor" />
							<CommentSection entityType="Cards" :entityId="card.id" />
						</div>
					</div>
				</div>
				<div class="col-4 card-information" v-bg-color="'rgb(248 248 248)'">
					<div class="pt-2">
						<DxField label-width="30%" value-width="70%" label="Code">
							<strong>{{ card.code }}</strong>
						</DxField>
						<DxField label-width="30%" value-width="70%" label="Status">
							<CardStatusButton v-model="card.status" @value-changed="updateCardProp('status', ...arguments)" />
						</DxField>
						<DxField label-width="30%" value-width="70%" label="Priority">
							<CardPriorityButton v-model="card.priority" @value-changed="updateCardProp('priority', ...arguments)" />
						</DxField>
						<DxField label-width="30%" value-width="70%" label="Start Date">
							<DxDateBox v-model="card.startDate" placeholder="Start Date" @value-changed="updateCardProp('startDate', ...arguments)" >
								<DxValidator>
									<DxRequiredRule message="Start date is required" />
								</DxValidator>
							</DxDateBox>
						</DxField>
						<DxField label-width="30%" value-width="70%" label="Due Date">
							<DxDateBox v-model="card.dueDate" placeholder="Due Date" @value-changed="updateCardProp('dueDate', ...arguments)" >
								<DxValidator>
									<DxRequiredRule message="Due date is required" />
								</DxValidator>
							</DxDateBox>
						</DxField>
						<DxField label-width="30%" value-width="70%" label="Assigned">
							<div class="d-flex align-items-center justify-content-between">
								<div class="text-wrap d-flex">
									<Popover class="user-avatar-row-item" v-for="user in card.users" :key="user.id">
										<template #target>
											<UserAvatar :user="user" />
										</template>
										<template #content>
											<DxButton
												type="danger"
												text="Remove"
												@click="removeUserFromCard(user)" />
										</template>
									</Popover>
								</div>
								<Popover>
									<template #target>
										<DxButton icon="add" class="circle-button" />
									</template>
									<template #content>
										<UsersLookup @object-changed="assignUserToCard" :disabled-items="card.users.map(s => s.id)" />
									</template>
								</Popover>
							</div>
						</DxField>
					</div>
				</div>
			</div>
		</div>
	</div>

</template>


<script>
import { AxiosWrapper, TextFormatter } from '@/mixins';
import { DxHtmlEditor, DxToolbar, DxMediaResizing, DxItem, DxImageUpload } from 'devextreme-vue/html-editor';
import InlineEdit from '@/components/InlineEdit.vue'
import { CardStatus, CardPriority } from '@/_helpers/constants'
import CardStatusButton from '@/components/CardStatusButton'
import CardPriorityButton from '@/components/CardPriorityButton'
import DxDateBox from 'devextreme-vue/date-box';
import CommentSection from '@/views/notes/comment-section.vue'
import UserAvatar from '@/components/UserAvatar.vue';
import { UsersLookup } from '@/components/lookups';
import CardTextEditor from './card-texteditor.vue'

export default {
	mixins: [AxiosWrapper, TextFormatter],
	components: {
		CardTextEditor,
		InlineEdit, CardStatusButton, CardPriorityButton, DxDateBox, CommentSection, UserAvatar, UsersLookup},
	props:['value'],
	data(){
		return {
			showEditor: false,
			apiRequest: false,
			statuses: this.objectToList(CardStatus),
			priorities: this.objectToList(CardPriority)
		}
	},
	methods:{
		cancel(){
			this.$emit('canceled')
		},
		submit(e){
			e.preventDefault()
			this.apiRequest = true
			this.post(`api/cards/add`, this.card).then(response => {
				this.$connectionHub.emit('new-card-added')
			}).finally(() => {
				this.apiRequest = false
			})
		},
		updateCardProp(prop, e){
			this.updateProperty({
				prop:prop,
				value:e.value,
				notes:`has changed the ${this.formatLabel(prop)} from ${this.card[prop]} to ${e.value}.`
			})
        },
		updateProperty(post){
			this.apiRequest = true
			post.id = this.card.id
			post.type = post.prop.charAt(0).toUpperCase() + post.prop.slice(1)
			this.post(`api/cards/update-property`, post).then(response => {
				if(post.prop == 'description'){
					this.showEditor = false
				}
				window.showSuccessToast(`${this.formatLabel(post.type)} is updated`)
			}).finally(() => {
				this.apiRequest = false
			})
		},
		removeUserFromCard(user){
			this.post(`api/cards/remove-user`, {
				userId: user.id,
				cardId: this.card.id,
				user: user
			}).then(response => {
				window.showSuccessToast(`User is removed`)
				this.$emit('reload', this.card.id)
			})
		},
		assignUserToCard(user){
			this.post(`api/cards/assign-user`, {
				userId: user.id,
				cardId: this.card.id,
				user: user
			}).then(response => {
				window.showSuccessToast(`User is updated`)
				this.$emit('reload', this.card.id)
			})
		}
	},
	computed:{
		card:{
			get(){
				return this.value
			},
			set(v){
				this.$emit('input', v)
			}
		}
	}

}

</script>

<style>
.card-info-container{
	position: relative;
}
.card-description{
	margin-bottom: 10px;
}
.card-description img{
	width: 100%;
}
.card-information{
	position: fixed !important;
	right: 0;
	height: 100%;
}
.user-avatar-row-item{
	margin-left: -8px;
}
.user-avatar-row-item:first-child{
	margin-left: 0;
}
</style>
