<template>
	<div class="card">
		<div class="card-header">
			<strong>General Settings</strong>
		</div>
		<div class="card-body">
			Coming soon.
		</div>
	</div>
</template>

<script>
export default{
	name: 'GeneralSettings'
}
</script>
