<template>
	<div>
		<div class="pt-5">
			<div :style="{ width: '350px' }" class="m-auto position-relative">
				<DxSelectBox
					@value-changed="selectPage"
					:items="settings"
					class="search-settings-dropdown"
					placeholder="Search Settings"
					item-template="item">
					<template #item="{ data }">
						<div>
							<div class="search-option-page">
								<strong>{{ data.page }}</strong>
							</div>
							<div class="search-option-category">
								{{ data.category }}
							</div>
						</div>
					</template>
					<DxTextBoxButton :options="{icon: 'search', stylingMode:'text', disabled: true}" name="search" location="before" />
				</DxSelectBox>
			</div>
		</div>
		<div class="settings-row">
			<div v-for="(value, key) in settingGroups" :key="key">
				<div class="card setting-tab">
					<div class="card-header"><strong> {{ key }}</strong></div>
					<div class="card-body">
						<div v-for="item in value" :key="item.page">
							<ul v-if="hasPermission(item.permission)">
								<router-link :to="`${item.key}`" tag="li">
									<span
										:class="item.disabled ? 'text-muted' : ''"
										data-toggle="tooltip"
										data-placement="top"
										:title="item.tooltip ? item.tooltip : ''"
										>{{ item.page }}</span
									>
								</router-link>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import _ from "lodash";
import DxSelectBox from 'devextreme-vue/select-box';
import { DxButton as DxTextBoxButton } from 'devextreme-vue/text-box';

export default {
	components: {DxSelectBox, DxTextBoxButton},
	data() {
		return {
			settings: [
				{
					page: "General",
					category: "General",
					key: "general/default",
					permission: window.constants.PERMISSION_READ_GENERAL_SETTINGS
				},
				{
					page: "Levels",
					category: "General",
					key: "general/levels",
					permission: window.constants.PERMISSION_READ_GENERAL_SETTINGS
				},
				{
					page: "Departments",
					category: "General",
					key: "general/departments",
					permission: window.constants.PERMISSION_READ_GENERAL_SETTINGS
				},
				{
					page: "Holidays",
					category: "General",
					key: "general/holidays",
					permission: window.constants.PERMISSION_READ_GENERAL_SETTINGS
				},
				{
					page: "Service Types",
					category: "General",
					key: "services/types",
					permission: window.constants.PERMISSION_READ_GENERAL_SETTINGS
				},
				{
					page: "User Management",
					category: "Users",
					key: "users/management",
					permission: window.constants.PERMISSION_READ_USER_SETTINGS
				},
				{
					page: "Groups",
					category: "Users",
					key: "users/roles",
					permission: window.constants.PERMISSION_READ_ROLES_SETTINGS
				},
				{
					page: "Leave Policies",
					category: "Users",
					key: "users/leave-policies",
					permission: window.constants.PERMISSION_READ_USER_SETTINGS
				},
				{
					page: "Assets",
					category: "Management",
					key: "assets",
					permission: window.constants.PERMISSION_READ_MANAGEMENT_SETTINGS
				},
				{
					page: "Scheduled Tasks",
					category: "Management",
					key: "integrations/scheduled-tasks",
					disabled: true,
					permission: window.constants.PERMISSION_READ_INTEGRATION_SETTINGS
				}
			]
		};
	},
	methods: {
		selectPage(e) {
			if (!e.disabled) this.$router.push(`${e.key}`);
		},
	},
	computed: {
		settingGroups() {
			return _.groupBy(this.settings, "category");
		},
	},
};
</script>
<style scoped>
.search-icon {
	position: absolute;
	top: 10px;
	left: 8px;
	z-index: 1;
}
.settings-row {
	width: 95%;
	margin: auto;
	padding-top: 3rem;
	display: flex;
	flex-wrap: wrap;
}
.setting-tab{
	height: 15.5rem;
	border-radius: 10px;
	width: 200px;
	display: inline-block;
	margin: 15px 37px;
}
.setting-tab .card-header{
	border-top-right-radius: 10px !important;
	border-top-left-radius: 10px !important;
	padding: 10px 20px !important;
}
.setting-tab .card-body{
	padding-top: 12px;
}
.setting-tab ul {
	padding: 0;
	margin: 0;
	margin-bottom: 10px;
	list-style: none;
	list-style-type: none;
}
.setting-tab li {
	cursor: pointer;
}
.setting-tab li:not(:last-child) {
	padding-bottom: 6px;
}
.setting-tab li:hover {
	color: blue;
}
</style>
<style>
.search-settings-dropdown .vs__selected {
	height: 25px;
}
.search-settings-dropdown .vs__dropdown-toggle {
	background: #fff !important;
	padding-left: 24px !important;
	border: 1px solid #cdcdcd;
}
.search-settings-dropdown .search-option-page {
	font-size: 14px;
}
.search-settings-dropdown .search-option-category {
	font-size: 12px;
}
.search-settings-dropdown input::placeholder {
	color: black;
}
</style>
