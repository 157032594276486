<template>
	<div class="d-flex align-items-center">
		<Icon icon-pack="dx" :name="icon()" class="mr-2" v-color="color()" />
		<span>{{ formatLabel(value) }}</span>
	</div>
</template>
<script>
import { TextFormatter } from "@/mixins";
import { CardStatus } from '@/_helpers/constants'
export default {
	mixins:[TextFormatter],
	props: ['value'],
	methods:{
		icon(){
			let obj = CardStatus.Get(this.value)
			return obj.icon
		},
		color(){
			let obj = CardStatus.Get(this.value)
			return obj.color
		}
	}
}
</script>
