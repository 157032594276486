<template>
    <div class="card" v-if="extraDayRequests.length > 0">
		<div class="card-header"><strong>Extra Days Requests</strong></div>
        <div class="card-body p-0" id="dashboard-pending-extra-days">
            <table class="table mb-0">
                <EmptyRow v-if="extraDayRequests.length == 0" :loading="apiRequest" />
                <tr v-for="request in extraDayRequests" :key="request.id">
                    <td>
                        <div>{{formatUtcToLocalDateTime(request.date, 'DD/MM/yyyy')}}</div>
						<small v-if="request.holiday">{{ request.holiday.name }}</small>
                    </td>
                    <td class="d-flex align-items-center justify-content-between">
                        <span>
                            {{request.user.fullName}}
                        </span>
                        <span>
                            <DxButton type="default" v-color="'#fff'" @click="authorize(request)" text="Authorize" />
                        </span>
                    </td>
                </tr>
            </table>
        </div>
    </div>
</template>

<script>
import { AxiosWrapper, TextFormatter } from '@/mixins'

export default {
    mixins:[AxiosWrapper, TextFormatter],
    data(){
        return {
            extraDayRequests: [],
            apiRequest: false,
        }
    },
    created(){
        this.load()
    },
    mounted(){
        this.$connectionHub.$on('reload-extra-days-panel', () => { this.load() })
    },
    methods:{
        load(){
            this.get('api/extradays/pending').then(response =>{
                this.extraDayRequests = response.data
            })
        },
		authorize(request){
			window.showConfirmModal({
				confirmButtonText: "Approve",
				cancelButtonText: "Deny",
				subTitle: `Extra Day request by ${request.user.fullName} on ${this.formatUtcToLocalDateTime(request.date, 'DD/MM/yyyy')}`,
				showLoader: true,
				hideOnOutsideClick: true,
				mode: "danger",
				onConfirm: async () => {
					try {
						request.status = 'Approved'
						await this.post('api/extradays/authorize', request)
						window.showSuccessToast(`${request.user.fullName}'s Extra day is approved`);
						this.load()
					} catch (error) {
						window.showErrorToast(error.message);
					}
				},
				onCancel: async () => {
					try {
						request.status = 'Denied'
						await this.post('api/extradays/authorize', request)
						window.showSuccessToast(`${request.user.fullName}'s Extra day is denied`);
						this.load()
					} catch (error) {
						window.showErrorToast(error.message);
					}
				},
			});
		},
    },
}
</script>
