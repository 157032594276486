import { authHeader } from '@/_helpers/authHeader'
import axios from 'axios'
//import { router } from '../router'

axios.interceptors.request.use(
  config => {
    config.headers = { ...config.headers,...authHeader() }
    return config
  },
  error => Promise.reject(error)
)
axios.interceptors.response.use(
  response => response,
  error => {
    if (!error.response){
        return Promise.reject(error)
    }
    switch(error.response.status)
    {
      case 401:
        // logout()
        // router.push('/login')
        break
      default:
        return Promise.reject(error)
    }
  }
)

export function logout() {
  localStorage.removeItem('user');
}

export function IsTokenValid(){
    let token = undefined
    try{ token = JSON.parse(atob(localStorage.getItem("user").replaceAll('"',''))).Token }
    catch { token = undefined}
    if(!token){
        return false
    }
    let jwt = parseJwt(token)
    let exp = new Date(jwt.exp * 1000)
    if(exp < new Date()){
        localStorage.removeItem("user")
        return false
    }
    return true
}

export function GetDefaultPage(){
    let user = JSON.parse(localStorage.getItem('user'))
    let _user = JSON.parse(atob(user))
    return _user.DefaultLoginPage ? _user.DefaultLoginPage : '/dashboard'
}

function parseJwt (token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
}
