<template>
	<CheckPermission :value="constants.PERMISSION_READ_GENERAL_SETTINGS" :access-denied-msg="true">
		<b-card no-body>
			<b-card-header class="d-flex align-items-center justify-content-between">
				<strong>Departments</strong>
				<DxButton icon="plus" @click="addDepartment" v-if="hasPermission(constants.PERMISSION_UPDATE_GENERAL_SETTINGS)" />
			</b-card-header>
			<div>
				<spinner v-if="apiRequest" />
				<DxDataGrid
					v-else
					class="header-table"
					:key="tableKey"
					:data-source="departments"
					:show-row-lines="true"
					:show-column-lines="false"
					:show-borders="true"
					:hover-state-enabled="true"
					:row-alternation-enabled="true">
					<DxColumn data-field="name" cssClass="data-column-custom" />
					<DxColumn data-field="description" cssClass="data-column-custom" />
					<DxColumn
						caption="Users"
						data-field="departmentLevels"
						cell-template="usersCellTemplate"
						cssClass="data-column-custom"
					/>
					<template #usersCellTemplate="{ data }">
						<div>
							<div
								v-for="item in data.value
									.map((s) => s.users)
									.flat(1)"
								:key="item.id"
							>
								{{ item.userName }}
							</div>
						</div>
					</template>
					<DxColumn
						width="130px"
						caption="Actions"
						:calculate-cell-value="getRowObj"
						alignment="center"
						cell-template="actionsTemplate"
						cssClass="data-column-custom"
						v-if="hasPermission(constants.PERMISSION_UPDATE_GENERAL_SETTINGS)"
					/>
					<template #actionsTemplate="{ data }">
						<div>
							<DxButton
								icon="edit"
								styling-mode="text"
								@click="editDepartment(data.value.id)"
							/>
							<DxButton
								:disabled="data.value.departmentLevels.map((s) => s.users).flat(1).length > 0"
								icon="trash"
								styling-mode="text"
								@click="confirmDelete(data.value.id)"
							/>
						</div>
					</template>
				</DxDataGrid>

				<DxPopup
					v-if="showDptModal"
					:visible="showDptModal"
					:on-hidden="
						() => {
							showDptModal = false;
						}
					"
					:drag-enabled="false"
					:hide-on-outside-click="true"
					:show-close-button="true"
					:show-title="true"
					width="50%"
					height="auto"
					:title="department.id ? 'Edit Department' : 'Add Department'"
				>
					<form ref="departmentForm" @submit="submit($event)">
						<spinner v-if="submitRequest" />
						<DxValidationGroup ref="departmentFormValidation" v-else>
							<DxField label="Name" :required="false">
								<DxTextBox
									v-model="department.name"
									placeholder="Name"
									mode="text"
								>
									<DxValidator>
										<DxCustomRule
											:validation-callback="
												(obj) =>
													validateUniqueProp(
														departments,
														department.id,
														'name',
														obj.value
													)
											"
											message="Department with this name already exists."
										/>
										<DxCustomRule
											:validation-callback="
												(obj) =>
													validateEmptySpaces(obj.value)
											"
											message="Name can not start or end with empty space."
										/>
										<DxRequiredRule
											message="Name is required"
										/>
									</DxValidator>
								</DxTextBox>
							</DxField>
							<DxField label="Description" :required="false">
								<DxTextArea
									placeholder="Description"
									:height="100"
									v-model="department.description"
								>
									<DxValidator>
										<DxRequiredRule
											message="Description is required"
										/>
									</DxValidator>
								</DxTextArea>
							</DxField>
							<div>
								<DxButton
									class="mt-1 float-right"
									text="Submit"
									type="default"
									:use-submit-behavior="true"
								/>
							</div>
						</DxValidationGroup>
					</form>
				</DxPopup>
			</div>
		</b-card>
	</CheckPermission>
</template>

<script>
import {
	DxDataGrid,
	DxColumn,
	DxToolbar,
	DxItem,
} from "devextreme-vue/data-grid";
import { DxPopup } from "devextreme-vue/popup";
import {
	DxValidator,
	DxRequiredRule,
	DxCustomRule,
} from "devextreme-vue/validator";
import { DxButton } from "devextreme-vue/button";
import DxTextArea from "devextreme-vue/text-area";
import DxTextBox from "devextreme-vue/text-box";
import { AxiosWrapper, HelperMethods } from "@/mixins";
import DxValidationGroup from "devextreme-vue/validation-group";
import { isMobile } from "@/utils/media-query";

export default {
	name: "DepartmentsPage",
	mixins: [AxiosWrapper, HelperMethods],
	components: {
		DxDataGrid,
		DxColumn,
		DxToolbar,
		DxItem,
		DxButton,
		DxTextBox,
		DxTextArea,
		DxPopup,
		DxValidator,
		DxRequiredRule,
		DxCustomRule,
		DxValidationGroup,
	},
	data() {
		return {
			apiRequest: false,
			tableKey: 0,
			submitRequest: false,
			departments: [],
			isMobile: isMobile(),
			showDptModal: false,
			department: { id: "", name: "", description: "" },
		};
	},
	created() {
		this.load();
	},
	methods: {
		load() {
			this.apiRequest = true
			this.get('api/settings/departments-index').then(response =>{
				this.departments = response.data
			}).catch(error =>{
				window.showToast({text: error.message, title: 'Alert', color: 'danger'});
			}).finally(final =>{
				this.apiRequest = false
			})
		},
		editDepartment(id) {
			this.department = this.shallowClone(
				this.departments.find((s) => s.id == id)
			);
			this.showDptModal = true;
		},
		addDepartment() {
			this.department = { id: "", name: "", description: "" };
			this.showDptModal = true;
		},
		submit(e) {
			e.preventDefault();
			this.submitRequest = true;
			this.post('api/settings/departments', this.department)
				.then(response =>{
					this.load()
				})
				.catch(error =>{
					window.showToast({text: error.message, title: 'Alert', color: 'danger'});
				})
				.finally(final =>{
					this.submitRequest = false
					this.showDptModal = false
				})
		},
		confirmDelete(id) {
			window.showConfirmModal({
				confirmButtonText: "Delete",
				subTitle: "Deleting is irreversible",
				showLoader: true,
				mode: "danger",
				onConfirm: async () => {
					await this.deleteDepartment(id);
				},
			});
		},
		async deleteDepartment(id) {
			try {
				await this.delete('api/settings/departments/' + id)
				window.showSuccessToast("Department deleted successfully");
				this.departments = this.removeItem(this.departments, id);
				this.tableKey++;
			} catch (error) {
				window.showErrorToast(error.message);
			}
		},
	},
};
</script>
