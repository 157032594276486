<template>
	<b-card no-body>
		<b-card-header class="d-flex align-items-center justify-content-between">
			<strong>Extra Days</strong>
			<DatePickerField mode="range" v-model="dateRange" direction="bottom" :quickSelect="true" pre-select="this month" />
		</b-card-header>
		<b-card-body class="p-0">
			<spinner v-if="apiRequest" />
			<DxDataGrid
				v-else
				ref="gridRef"
				:data-source="extradays"
				:show-row-lines="true"
				:show-column-lines="false"
				:show-borders="true"
				:hover-state-enabled="true"
				:row-alternation-enabled="true"
				:on-cell-prepared="onCellPrepared">

				<DxColumn data-field="user.fullName" caption="Requestor" />
				<DxColumn data-field="status" />
				<DxColumn data-field="date" cell-template="extraDayTemplate" />
				<template #extraDayTemplate="{ data}">
					<div>
						{{ data.value | shortDate }}
						<span v-if="data.data.holiday">({{ data.data.holiday.name }})</span>
					</div>
				</template>
				<DxColumn data-field="timestamp" caption="Requested at" cell-template="dateTemplate" />
				<DxColumn data-field="authorizedBy.fullName" caption="Authorized By" />
				<DxColumn data-field="authorizationTime" caption="Authorization Time" cell-template="dateTemplate" />
				<DxColumn data-field="notes" caption="Notes" css-class="text-wrap" />
				<template #dateTemplate="{data}">
					<div>
						{{ data.value | formatUtcToLocalDateTime }}
					</div>
				</template>
			</DxDataGrid>
		</b-card-body>
	</b-card>
</template>

<script>
import {
	DxDataGrid,
	DxColumn,
	DxPaging,
	DxEditing,
	DxToolbar,
	DxItem,
} from 'devextreme-vue/data-grid';
import { AxiosWrapper } from '@/mixins';
import DxButton from "devextreme-vue/button";
import DatePickerField from '@/components/DatePickerField.vue'

export default {
	components:{
		DxDataGrid,
		DxColumn,
		DxPaging,
		DxEditing,
		DxToolbar,
		DxItem,
		DxButton,
		DatePickerField
	},
	mixins:[AxiosWrapper],
	data() {
		return {
			extradays: [],
			dateRange: {},
			apiRequest: false
		};
	},
	mounted() {
		this.load()
	},
	methods: {
		load(){
			if(this.dateRange.start && this.dateRange.end){
				this.apiRequest = true
				const params = { range: this.stringifyDateRange(this.dateRange) };
				this.get('api/extradays/get', { params }).then(response => {
					this.extradays = response.data
				}).finally(() => {
					this.apiRequest = false
				})
			}
		},
		onCellPrepared(e) {
			if (e.rowType == "data" && e.column.dataField == "status") {
				let status = e.data.status;
				e.cellElement.classList.add("leave-status-" + status);
			}
		},
	},
	watch:{
        'dateRange'(val){
            if(val){
                if(val.start && val.end) this.load()
            }
        }
    },
};
</script>
