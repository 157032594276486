<template>
	<div v-b-tooltip="user.fullName">
		<img v-if="user.picture" :src="user.picture" class="user-profile-picture" :style="style" />
		<div v-else class="initials-container" v-bg-color="userColor" :style="style">
			<div class="vertical-center">{{ initials }}</div>
		</div>
	</div>
</template>
<script>
export default {
	props: ['user', 'size'],
	computed:{
		initials() {
			let name = this.user.fullName || this.user.userName
			// Split the name into words
			const words = name.split(' ');
			// Get the initials from the first two words
			const initials = words.slice(0, 2).map(word => word[0].toUpperCase()).join('');
			return initials;
		},
		userColor(){
			return this.user.color
		},
		randomColor() {
			// Generate random values for red, green, and blue
			const r = Math.floor(Math.random() * 128) + 128; // Ensuring the value is between 128 and 255
			const g = Math.floor(Math.random() * 128) + 128; // Ensuring the value is between 128 and 255
			const b = Math.floor(Math.random() * 128) + 128; // Ensuring the value is between 128 and 255

			// Convert to hexadecimal and pad with zeros if necessary
			const red = r.toString(16).padStart(2, '0');
			const green = g.toString(16).padStart(2, '0');
			const blue = b.toString(16).padStart(2, '0');

			// Combine the values into a hex string
			return `#${red}${green}${blue}`;
		},
		style(){
			return `height: ${this.size || 35}px; width: ${this.size || 35}px`
		}
	}
}
</script>
<style scoped>
.user-profile-picture{
    border-radius: 50%;
}
.initials-container{
    border-radius: 50%;
    position: relative;

}
</style>
