<template>
    <div>
        <div v-for="permission in permissions" :key="permission.name">
            <div class="d-flex">
                <div class="td" v-width="'60%'">
                    <div :class="iteration == 0 ? 'pl-4' : 'pl-5'">
                        <DxButton
							v-if="permission.childPermissions.length > 0"
                            class="pl-0 pt-0"
							styling-mode="text"
							icon="permission.showChildren ? 'chevrondown' : 'chevronright'"
							@click="showChildren(permission)">
                        </DxButton>
                        <span>{{formatSnakeCase(permission.name)}}</span>
                    </div>
                </div>
                <div class="td text-center" v-width="'40%'">
                    <DxCheckBox
                        :element-attr="{id:'checkbox_'+permission.name}"
                        :value="havePermission(permission.name)"
                        @value-changed="selectPermission(permission.name, $event)"
						:disabled="!hasPermission(constants.PERMISSION_UPDATE_USER_SETTINGS)" />
                </div>
            </div>
            <div>
                <RecursivePermissions
                    v-if="permission.childPermissions.length > 0 && permission.showChildren"
                    :permissions="permission.childPermissions"
                    :havePermission="havePermission"
                    :selectPermission="selectPermission"
                    :showChildren="showChildren"
                    :iteration="iteration + 1" />
            </div>
        </div>
    </div>
</template>

<script>
import { DxCheckBox } from 'devextreme-vue/check-box';
import RecursivePermissions from './recursive-permissions.vue'
import RoleWarningPopover from './role-warning-popover.vue';
import { DxButton } from "devextreme-vue/button";

export default {
    components: { DxCheckBox, RecursivePermissions, RoleWarningPopover, DxButton},
    name: 'RecursivePermissions',
    props:['permissions', 'havePermission', 'showChildren', 'selectPermission', 'iteration'],
}
</script>
