<template>
	<span v-if="haveNonBilledHours(projectAllocatedHours || [])">
		<Icon name="dash-circle" class="non-billable-hours-icon" icon-pack="bootstrap" :id="id" />
        <b-popover :target="id" triggers="hover" placement="top">
			<div v-for="nonBillableHoursProject in nonBillableHoursProjects" :key="nonBillableHoursProject.projectId">
				<strong>{{nonBillableHoursProject.projectName}}:</strong> {{nonBillableHoursProject.hours | oneDecimalPlace}} hrs
			</div>
        </b-popover>
    </span>
</template>


<script>
import { Disposition } from '@/_helpers/constants'
import Resourcing from '@/mixins/views/Resourcing'

export default{
	props:['projectAllocatedHours'],
	mixins: [Resourcing],
    data(){
        return{
            id: this.generateUniqueCode()
        }
    },
    methods:{
        haveNonBilledHours(){
			return this.nonBillableHoursProjects.length > 0
        },
    },
	computed:{
		nonBillableHoursProjects(){
			if((this.projectAllocatedHours || []).length > 0){
				return this.projectAllocatedHours.filter(p =>
					(p.projectType == Disposition.Internal || p.projectType == Disposition.None) &&
					Number(p.hours) > 0
				)
			}
			return []
		}
	}
}
</script>
<style scoped>
.non-billable-hours-icon{
	cursor: pointer;
	opacity: 0.8;
	margin-bottom: 2px;
}
</style>
