<template>
	<DxDataGrid ref="usersTable" class="header-table" id="usersGridContainer" :data-source="users"
		:show-row-lines="true" :show-column-lines="false" :show-borders="true" :hover-state-enabled="true"
		:row-alternation-enabled="true" :on-row-click="onRowClicked"
		height="calc(100vh - 105px)">
		<DxScrolling mode="virtual" row-rendering-mode="virtual" />


		<DxColumn width="25%" caption="Username" :calculate-cell-value="calculateUsernamePicture" cell-template="usernameCellTemplate" cssClass="data-column-custom" />
		<template #usernameCellTemplate="{ data }">
			<div class="d-flex align-items-center">
				<UserAvatar :user="data.data" class="mr-2" />
				{{ data.value.fullName }}
			</div>
		</template>
		<DxColumn width="35%" caption="Email" data-field="email" cssClass="data-column-custom" />
		<DxColumn width="15%" caption="Role" data-field="group" cell-template="rolesCellTemplate" cssClass="data-column-custom" />
		<template #rolesCellTemplate="{ data }">
			<span>
				{{ formatSnakeCase(data.value.name) }}
			</span>
		</template>
		<DxColumn width="20%" data-field="lastLogin" caption="Last Login" :format="'MM/dd/yyyy HH:mm a'" data-type="date" cssClass="data-column-custom" />
		<DxToolbar>
			<DxItem location="before" template="headingTemplate" />
			<DxItem v-if="isMobile" location="after" template="settingBoardIcon" />
			<DxItem v-if="!isMobile" location="after" template="userStatusTemplate" />
			<DxItem location="after" name="searchPanel" />
			<DxItem location="after" template="addTemplate" v-if="hasPermission(constants.PERMISSION_UPDATE_USER_SETTINGS)" />
			<DxItem location="after" template="inactiveUsersBtn" />
		</DxToolbar>
		<template #userStatusTemplate>
			<div>
				<span v-if="inactiveUsers" class="badge badge-danger">
					<i> Disabled Users </i></span>
				<span v-else class="badge badge-success">
					<i> Active Users </i></span>
			</div>
		</template>
		<template #headingTemplate>
			<strong :class="{ 'small-text': isMobile }">User Management</strong>
		</template>
		<template #settingBoardIcon>
			<DxButton icon="arrowleft" @click="toggleSettingBoard" />
		</template>
		<DxSearchPanel :visible="true" :width="isMobile ? 120 : 240" placeholder="Search User" />
		<template #addTemplate>
			<DxButton icon="plus" @click="$emit('open-add-user')"> </DxButton>
		</template>
		<template #inactiveUsersBtn>
			<DxButton
				v-if="hasPermission(constants.PERMISSION_UPDATE_USER_SETTINGS)"
				:icon="!inactiveUsers ? 'bi bi-person-dash' : 'bi bi-person-check'"
				:type="inactiveUsers ? 'default' : 'normal'"
				@click="inactiveUsers = !inactiveUsers"
			/>
		</template>
	</DxDataGrid>
</template>

<script>
import {
	DxDataGrid,
	DxColumn,
	DxSearchPanel,
	DxToolbar,
	DxScrolling,
	DxItem,
} from "devextreme-vue/data-grid";
import { DxButton } from "devextreme-vue/button";
import { AxiosWrapper, TextFormatter } from "../../mixins";
import { isMobile } from "@/utils/media-query";


export default {
	name: "UsersTable",
	mixins: [TextFormatter, AxiosWrapper],
	props: ['users', 'value'],
	components: {
		DxDataGrid,
		DxColumn,
		DxSearchPanel,
		DxToolbar,
		DxItem,
		DxScrolling,
		DxButton,
	},
	data() {
		return {
			apiRequest: false,
			//inactiveUsers: false,
			// users: [],
			isMobile: isMobile(),
		};
	},
	mounted() {
		this.load();
	},
	methods: {
		load() {
			// this.$refs['usersTable'].instance.beginCustomLoading()
			// this.get('api/users/index?isLocked=' + this.inactiveUsers).then(response => {
			// 	this.users = response.data
			// }).finally(final => {
			// 	this.$refs['usersTable']?.instance?.endCustomLoading()
			// })
			//this.users = jsonData;
		},
		calculateUsernamePicture(e) {
			return { fullName: e.fullName, picture: e.picture };
		},
		onRowClicked(e){
			this.$emit("select-user", e.data);
		},
		editUserBtn(e) {
			this.$emit("select-user", this.users.find((s) => s.id == e));
		}
	},
	computed:{
		inactiveUsers:{
			get(){
				return this.value
			},
			set(v){
				this.$emit('input', v)
			}
		}
	}
};
</script>

<style scoped>
/* .user-avatar {
	height: 36px;
	width: 36px;
	border-radius: 50%;
	margin-right: 10px;
} */

.dx-toolbar {
	display: flex;
}

.small-text {
	font-size: 11px;
}
</style>
<style>
#usersGridContainer .dx-data-row.dx-state-hover:not(.dx-selection):not(.dx-row-inserted):not(.dx-row-removed):not(.dx-edit-row) > td:not(.dx-focused) {
    cursor: pointer;
}
</style>
