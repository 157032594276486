import { render, staticRenderFns } from "./roles-page.vue?vue&type=template&id=6e4aa91b&scoped=true"
import script from "./roles-page.vue?vue&type=script&lang=js"
export * from "./roles-page.vue?vue&type=script&lang=js"
import style0 from "./roles-page.vue?vue&type=style&index=0&id=6e4aa91b&prod&scoped=true&lang=css"
import style1 from "./roles-page.vue?vue&type=style&index=1&id=6e4aa91b&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6e4aa91b",
  null
  
)

export default component.exports