<template>
    <div>
        <DxButton
            :width="buttonWidth"
            :text="onText"
            :type="isActive ? type : 'normal'"
            :element-attr="{class: 'right-radius-0'}"

            :styling-mode="isActive ? 'contained' : 'outlined'"
            @click="isActive = true" />
        <DxButton
            :width="buttonWidth"
            :text="offText"
            :type="!isActive ? type : 'normal'"
            :element-attr="{class: 'left-radius-0'}"
            :styling-mode="!isActive ? 'contained' : 'outlined'"
            @click="isActive = false" />
    </div>
</template>
<script>
import DxButton from 'devextreme-vue/button';
export default {
    props: {
		'value':{
			type: Boolean,
            default: false
		},
		'on-text':{
			type: String,
            default: 'On'
		},
		'off-text':{
			type: String,
            default: 'Off'
		},
		'button-width':{
			type: String,
            default: 'auto'
		},
		'type':{
			type: String,
			default: 'normal'
		}
	},
    components:{ DxButton },
    computed:{
        isActive: {
            get(){
                return this.value || false
            },
            set(v){
                this.$emit('input', v)
            }
        }
    }
}
</script>
<style>
.left-radius-0{
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
}
.right-radius-0{
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
}
.left-radius-0 .dx-button-content,
.right-radius-0 .dx-button-content{
    padding: 6px 18px;
    font-size: 13px;
}
</style>
