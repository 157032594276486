<template>
	<spinner v-if="saveRequest" />
	<div v-else>
		<div>
			<div class="m-4">
				<DxTextBox
					stylingMode="outlined"
					labelMode="hidden"
					v-model="userNote.title"
					:element-attr="{ class: 'my-3' }"
					placeholder="Title"
				></DxTextBox>
				<DxTextArea
					stylingMode="outlined"
					labelMode="hidden"
					v-model="userNote.content"
					:element-attr="{ class: 'my-3' }"
					placeholder="Content"
				>
				</DxTextArea>
				<div class="d-flex justify-content-end">
					<div class="m-1">
						<DxButton
							icon="attach"
							hint="Attach File"
							type="default"
							@click="attach"
							style="width: 50px"
						/>
					</div>
					<div class="m-1">
						<DxButton
							icon="save"
							hint="Save Note"
							type="default"
							@click="save"
							style="width: 50px"
						/>
					</div>
					<input
						type="file"
						ref="fileInput"
						style="display: none"
						@change="handleFileUpload"
					/>
				</div>
			</div>
			<div v-if="userNotes">
				<div class="m-4" v-for="note in userNotes" :key="note.id">
					<div class="d-flex">
						<div class="flex-fill">
							<b>{{ note.title }}</b>
						</div>
						<div class="flex-fill text-right">
							<i>{{ note.createdBy }}</i>
							<i>{{ formatDate(note.created) }}</i>
						</div>
					</div>
					<div v-if="note.showFullContent">
						<span>
							{{ note.content }}
						</span>
					</div>
					<div v-else>
						<span v-if="note.content && note.content.length > 200">
							{{ note.content.slice(0, 200) }}
							<a href="#" @click="showFullContent(note)"
								>Read More...</a
							>
						</span>
						<span v-else>
							{{ note.content }}
						</span>
					</div>
					<div>
						<a :href="note.attachmentUrl"
							><i>{{ note.attachmentUrl }}</i></a
						>
					</div>
				</div>
			</div>
			<div v-else>
				<h2>No User Notes at the moment.</h2>
			</div>
		</div>
	</div>
</template>
<script>
import { AxiosWrapper } from "../../mixins";
import { DxTextBox } from "devextreme-vue/text-box";
import DxTextArea from "devextreme-vue/text-area";
import DxButton from "devextreme-vue/button";
import moment from "moment";
import { mapGetters } from "vuex";

export default {
	name: "UserNotes",
	props: ["editUser"],
	mixins: [AxiosWrapper],
	components: { DxTextBox, DxTextArea, DxButton },
	data() {
		return {
			tab: "Notes",
			userNote: {
				userId: this.editUser.id,
				title: null,
				content: null,
			},
			userNotes: [
				{
					id: "d7110de2-7919-4c97-b8f8-a44571ee9313",
					userId: "b98d8230-2863-4636-a848-8f53eaeec59b",
					title: "Attachment test",
					content: "This has an attachment with the same file name",
					created: "2023-06-22T13:24:32.024555",
					attachmentUrl: null,
					createdBy: "Dennis Moon",
					_Fillable: [],
				},
			],
			attachment: null,
			saveRequest: null,
		};
	},
	methods: {
		attach() {
			this.$refs.fileInput.click();
		},
		async handleFileUpload() {
			this.$refs.fileInput.click();
			const file = event.target.files[0];
			const formData = new FormData();
			const date = moment().format("MM/DD/YYYY HH:mm:ss");
			const newFileName = `${this.editUser.firstName}_${this.editUser.lastName}_${date}_${file.name}`;
			formData.append("file", file, newFileName);
			this.attachment = formData;
			window.showToast({
				title: "Success!",
				text: "File attached!",
				color: "success",
			});
		},
		async save() {
			try {
				this.saveRequest = true;
				//send user note
				this.userNote.createdBy = this.fullname;
				// const response = await this.post(
				// 	"api/users/user-note",
				// 	this.userNote
				// );
				//send attachment and attach it to user note
				if (this.attachment) {
					// await this.post(
					// 	`api/settings/user-note-attachment/${response.data.id}`,
					// 	this.attachment,
					// 	{ "Content-Type": "multipart/form-data" }
					// );
				}
				window.showToast({
					title: "Success!",
					text: "Note inserted.",
					color: "success",
				});
			} catch (e) {
				window.showToast({
					title: "Alert",
					text: e.message,
					color: "danger",
				});
			}
			this.getUserNotes();
			this.saveRequest = null;
			this.attachment = null;
			this.userNote.title = null;
			this.userNote.content = null;
		},
		async getUserNotes() {
			// const response = await this.get(
			// 	`api/users/user-notes/${this.editUser.id}`
			// );
			// this.userNotes = response.data;
		},
		formatDate(date) {
			return moment(date).format("YYYY-MM-DD HH:mm:ss");
		},
		showFullContent(note) {
			this.$set(note, "showFullContent", true);
		},
	},
	computed: {
		...mapGetters("authentication", ["fullname"]),
	},
	mounted() {
		this.getUserNotes();
	},
};
</script>
