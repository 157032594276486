<template>
	<div id="root">
		<div :class="cssClasses">
			<router-view
				name="layout"
				:title="title"
				:is-x-small="screen.isXSmall"
				:is-large="screen.isLarge">
				<div class="content">
					<router-view name="content" />
				</div>
			</router-view>
			<ConfirmPopup ref="dxConfirmPopup" />
			<AlertPopup ref="dxAlertPopup" />
			<LeaveRequestModal ref="leaveRequestModal" />
			<ExtraDayRequestModal ref="extraDayRequestModal" />
			<CardPopup ref="cardModal" />
		</div>
	</div>
</template>

<script>
// import AppFooter from "./components/app-footer";
import { sizes, subscribe, unsubscribe } from "./utils/media-query";
import AlertPopup from "@/components/modals/dx-alert-popup.vue";
import ConfirmPopup from "@/components/modals/dx-confirm-popup.vue";
import LeaveRequestModal from "@/views/leaves/leave-request-modal.vue"
import ExtraDayRequestModal from "@/views/extra-days/extra-day-request.vue"
import CardPopup from "@/views/cards/card-popup.vue"

function getScreenSizeInfo() {
	const screenSizes = sizes();

	return {
		isXSmall: screenSizes["screen-x-small"],
		isLarge: screenSizes["screen-large"],
		cssClasses: Object.keys(screenSizes).filter((cl) => screenSizes[cl]),
	};
}

export default {
	name: "app",
	data() {
		return {
			title: 'Tayyab',
			screen: getScreenSizeInfo(),
		};
	},
	computed: {
		cssClasses() {
			return ["app"].concat(this.screen.cssClasses);
		},
	},
	methods: {
		screenSizeChanged() {
			this.screen = getScreenSizeInfo();
		},
		showConfirmModal(options) {
			this.$refs.dxConfirmPopup.show(options);
		},
		showAlertModal(options) {
			this.$refs.dxAlertPopup.show(options);
		},
		showCardPopup(options) {
			this.$refs.cardModal.show(options);
		},
		showActionItemModal(options) {
			this.showActionItem = true;
			this.$nextTick(() => {
				this.$refs.actionItemModal.show(options);
			});
		},
		showLeaveRequestModal() {
			this.$refs.leaveRequestModal.show();
		},
		showExtraDayRequestModal() {
			this.$refs.extraDayRequestModal.show();
		},
	},

	mounted() {
		subscribe(this.screenSizeChanged);
	},

	beforeDestroy() {
		unsubscribe(this.screenSizeChanged);
	},

	components: {
		AlertPopup,
		ConfirmPopup,
		LeaveRequestModal,
		ExtraDayRequestModal,
		CardPopup
	},
};
</script>

<style lang="scss">
@import "./themes/generated/variables.base.scss";
html,
body {
	margin: 0px;
	min-height: 100%;
	height: 100%;
	font-family: 'NunitoSans';
}

#root {
	height: 100%;
}

* {
	box-sizing: border-box;
}

.app {
	background-color: $base-bg;
	display: flex;
	height: 100%;
	width: 100%;
}
</style>
<style scoped>
	.content{
		padding: 25px;
    	background: #F2F2F6;
	}
</style>
