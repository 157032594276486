<template>
	<DxSelectBox v-model="state"
				 :search-enabled="true"
				 :items="states"
				 display-expr="name"
				 value-expr="id"
				 placeholder="Select State"
				 @value-changed="onSelectState"
				 :inputAttr="{autocomplete: 'test-autocomplete'}"
				 >
		<DxValidator>
			<DxRequiredRule :validation-callback="isStateValid" message="State is required" />
		</DxValidator>
	</DxSelectBox>
</template>

<script>
	import DxSelectBox from 'devextreme-vue/select-box';
	import { DxValidator, DxRequiredRule } from 'devextreme-vue/validator';

	export default {
		props: ['value'],
		components: { DxSelectBox, DxValidator, DxRequiredRule },
		data() {
			return {
				states: [
					{ id: 'Punjab', name: 'Punjab' },
					{ id: 'Sindh', name: 'Sindh' },
					{ id: 'KPK', name: 'KPK' },
					{ id: 'Balochistan', name: 'Balochistan' },
					{ id: 'AJK', name: 'AJK' },
					{ id: 'GB', name: 'GB' },
					// { id: 'AL', name: 'Alabama' },
					// { id: 'AK', name: 'Alaska' },
					// { id: 'AZ', name: 'Arizona' },
					// { id: 'AR', name: 'Arkansas' },
					// { id: 'CA', name: 'California' },
					// { id: 'CO', name: 'Colorado' },
					// { id: 'CT', name: 'Connecticut' },
					// { id: 'DE', name: 'Delaware' },
					// { id: 'DC', name: 'District Of Columbia' },
					// { id: 'FL', name: 'Florida' },
					// { id: 'GA', name: 'Georgia' },
					// { id: 'HI', name: 'Hawaii' },
					// { id: 'ID', name: 'Idaho' },
					// { id: 'IL', name: 'Illinois' },
					// { id: 'IN', name: 'Indiana' },
					// { id: 'IA', name: 'Iowa' },
					// { id: 'KS', name: 'Kansas' },
					// { id: 'KY', name: 'Kentucky' },
					// { id: 'LA', name: 'Louisiana' },
					// { id: 'ME', name: 'Maine' },
					// { id: 'MD', name: 'Maryland' },
					// { id: 'MA', name: 'Massachusetts' },
					// { id: 'MI', name: 'Michigan' },
					// { id: 'MN', name: 'Minnesota' },
					// { id: 'MS', name: 'Mississippi' },
					// { id: 'MO', name: 'Missouri' },
					// { id: 'MT', name: 'Montana' },
					// { id: 'NE', name: 'Nebraska' },
					// { id: 'NV', name: 'Nevada' },
					// { id: 'NH', name: 'New Hampshire' },
					// { id: 'NJ', name: 'New Jersey' },
					// { id: 'NM', name: 'New Mexico' },
					// { id: 'NY', name: 'New York' },
					// { id: 'NC', name: 'North Carolina' },
					// { id: 'ND', name: 'North Dakota' },
					// { id: 'OH', name: 'Ohio' },
					// { id: 'OK', name: 'Oklahoma' },
					// { id: 'OR', name: 'Oregon' },
					// { id: 'PA', name: 'Pennsylvania' },
					// { id: 'RI', name: 'Rhode Island' },
					// { id: 'SC', name: 'South Carolina' },
					// { id: 'SD', name: 'South Dakota' },
					// { id: 'TN', name: 'Tennessee' },
					// { id: 'TX', name: 'Texas' },
					// { id: 'UT', name: 'Utah' },
					// { id: 'VT', name: 'Vermont' },
					// { id: 'VA', name: 'Virginia' },
					// { id: 'WA', name: 'Washington' },
					// { id: 'WV', name: 'West Virginia' },
					// { id: 'WI', name: 'Wisconsin' },
					// { id: 'WY', name: 'Wyoming' },
				],
			}
		},
		methods: {
			onSelectState(e) {
				this.$emit('value-changed', e.value)
				let state = e.value ? this.find(this.states, e.value) : undefined
				this.$emit('state-changed', state)
			},
			isStateValid() {
				if (e.value != null) {
					return true;
				}
				return false;
			},
		},
		computed: {
			state: {
				get() { return this.value },
				set(v) { this.$emit('input', v) }
			},
		}
	}

</script>
