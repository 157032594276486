<template>
    <div>
        <div class="pointer" v-if="!show" :title="disabled ? '' : 'Click to edit'" style="font-size:inherit">
            <span @click="showEdit()">
                <slot v-if="slotElement" />
                <span v-else>{{value}}</span>
            </span>
			<slot name="icon" />
        </div>
        <form @submit="submit($event)" v-else>
            <DxValidationGroup :name="generateUUID()">
                <div class="position-relative" :class="{'d-flex align-items-center': !insideButtons}">
                    <DxTextBox
                        v-if="type === 'text'"
                        v-model="inlineValue"
                        :placeholder="placeholder"
                        mode="text">
                        <DxValidator v-if="required"> <DxRequiredRule message="Value is required"/> </DxValidator>
                    </DxTextBox>
                    <DxNumberBox
                        v-if="type === 'number'"
                        v-model="inlineValue"
                        :placeholder="placeholder"
                        :format="format"
                        :min="min"
                        :max="max">
                        <DxValidator v-if="required"> <DxRequiredRule message="Value is required"/> </DxValidator>
                    </DxNumberBox>
                    <DxTextArea
                        v-if="type === 'textarea'"
                        v-model="inlineValue"
                        :placeholder="placeholder">
                        <DxValidator v-if="required"> <DxRequiredRule message="Value is required"/> </DxValidator>
                    </DxTextArea>
                    <DxButton
                        v-if="insideButtons"
                        class="position-absolute"
                        v-right="'0'" v-top="'0'"
                        type="normal"
                        styling-mode="text"
                        :use-submit-behavior="true" >
                        <Icon name="check-circle" icon-pack="bootstrap" />
                    </DxButton>
                    <DxButton
                        v-if="!insideButtons"
                        icon="check"
                        type="default"
                        class="mr-2 ml-2"
                        :use-submit-behavior="true" />
                    <DxButton
                        v-if="!insideButtons"
                        icon="close"
                        type="normal"
                        @click="hideEdit" />
                </div>
            </DxValidationGroup>
        </form>
    </div>
</template>

<script>
import DxValidationGroup from 'devextreme-vue/validation-group';
import DxTextBox from 'devextreme-vue/text-box';
import { DxTextArea } from 'devextreme-vue/text-area';
import { DxNumberBox } from 'devextreme-vue/number-box';
import { DxValidator,DxRequiredRule } from 'devextreme-vue/validator';
import { DxButton } from 'devextreme-vue/button'
export default {
    name:'InlineEdit',
    components:{DxValidationGroup,DxTextBox,DxNumberBox,DxValidator,DxRequiredRule,DxButton,DxTextArea},
    props: {
		'value': [Number, String, Date],
		'type': {
			type: String,
			default: 'text'
 		},
        'format': {
			type: Object,
			default:()=>{}
 		},
		'required': {
			type: Boolean,
			default: false
 		},
		'disabled': {
			type: Boolean,
			default: false
 		},
		'onClick':{
			type: Function,
			default:()=>{}
		},
        'placeholder':{
            type: String,
            default: 'Enter value'
        },
        'min':{
            type: Number,
            default: -999999999999999
        },
        'max':{
            type: Number,
            default: 999999999999999
        },
        'inside-buttons':{
            type: Boolean,
			default: false
        },
        'slot-element':{
            type: Boolean,
            default: false
        }
	},
    data() {
        return{
            show:false,
            inlineValue:null
        }
    },
    methods:{
        showEdit(){
			if(this.disabled){
				this.onClick()
			}
			else{
                if(this.type == 'text') this.inlineValue = this.value || ''
                if(this.type == 'number') this.inlineValue = Number(this.value) || 0
                if(this.type == 'date') this.inlineValue = new Date(this.value) || new Date()
                if(this.type == 'textarea') this.inlineValue = this.value || ''

				this.show = true
			}
        },
		hideEdit(){
            this.$emit('input', this.value)
            this.$emit('onCancel');
            this.show = false
		},
        submit(e){
            e.preventDefault()
            this.updateValue()
        },
        updateValue() {
            this.show = false;
            this.$emit('input', this.inlineValue)
            if(this.inlineValue !== this.value) this.$emit('onChange', this.inlineValue, this.value);
            this.$emit('onSave', this.inlineValue);
        }
    }
};
</script>
