<template>
	<span>
		<i
			v-if="iconPack == 'dx'"
			:size="_size"
			:id="elId"
			:class="_name + ' ' + cssClass"
			:title="title"
			v-top="top"
			v-right="right"
			v-left="left"
			v-bottom="bottom"
		>
		</i>
		<b-icon
			v-if="iconPack == 'bootstrap'"
			:icon="_name"
			:font-scale="_size"
			:id="elId"
			:class="cssClass"
			:title="title"
			v-top="top"
			v-right="right"
			v-left="left"
			v-bottom="bottom"
		/>
	</span>
</template>

<script>
export default {
	props: {
		name: {
			type: String,
			default: "",
		},
		size: {
			type: String,
			default: "sm",
		},
		"el-attr": {
			type: Object,
			default: () => {},
		},
		tooltip: {
			type: String,
			default: "",
		},
		title: {
			type: String,
			default: "",
		},
		"icon-pack": {
			type: String,
			default: "coreui",
		},
		top: {
			type: String,
			default: "auto",
		},
		right: {
			type: String,
			default: "auto",
		},
		left: {
			type: String,
			default: "auto",
		},
		bottom: {
			type: String,
			default: "auto",
		},
	},
	data() {
		return {
			id: this.generateUniqueCode(),
		};
	},
	computed: {
		_size() {
			if (this.iconPack == "dx" && this.size == "sm") {
				return "18px";
			}
			return this.size;
		},
		_name(){
			if(this.iconPack == 'dx'){
				return 'dx-icon dx-icon-' + this.name
			}
			else {
				return this.name
			}
		},
		elId() {
			if (this.elAttr) return this.elAttr.id ? this.elAttr.id : this.id;
			return this.id;
		},
		cssClass() {
			if (this.elAttr) return this.elAttr.class ? this.elAttr.class : "";
			return "";
		},
	},
};
</script>
