import Vue from 'vue';
import Vuex from 'vuex';

import { alert } from './alert.module';
import { authentication } from './authentication.module';
import { users } from './users.module';
import { isMobile } from '@/utils/media-query';

Vue.use(Vuex);

export const store = new Vuex.Store({
	modules: {
		alert,
		authentication,
		users,
	},
	state: {
		sidebarOpen: true,
        settingBoardOpen: isMobile ? false : true 
	},
    mutations: {
        toggleSettingBoard(state) {
            state.settingBoardOpen = !state.settingBoardOpen
        }
    },
    actions: {
        toggleSettingBoard({commit}) {
            commit("toggleSettingBoard");
        }
    }
});
