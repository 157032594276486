<template>
	<CheckPermission :value="constants.PERMISSION_READ_USER_SETTINGS" :access-denied-msg="true">
		<div class="card">
			<div class="card-body" id="user-details-page" :key="contentKey">
				<!-- <LoadPanel elementId="#user-details-page" :shading="true" :visible="true" /> -->
				<spinner class="vertical-center" v-if="apiRequest"/>
				<div v-else>
					<div v-if="isEditing">
						<div class="d-flex mb-3">
							<div class="flex-column" v-width="'200px'" v-if="false">
								<div :class="{'user-popup-img d-flex flex-column': isMobile}">
									<img :src="userPictureSource(user.picture)" class="user-profile-picture" @click="openFileDialog" style="max-width: calc(100% - 20px)" />
									<input type="file" ref="fileInput" style="display: none" @change="handleFileUpload" />
								</div>
							</div>
							<div class="flex-column position-relative" v-width="'350px'">
								<div class="">
									<h5 class="mb-3">User Details</h5>
									<DxField label="User" class="mb-0" style="min-height: 25px">
										{{user.title}} {{ user.fullName }}
									</DxField>
									<DxField label="Email" class="mb-0" style="min-height: 25px">
										<div style="width: 12rem; word-break: break-word">{{ user.email }}</div>
									</DxField>
									<DxField label="Department" class="mb-0" v-if="user.departmentLevel" style="min-height: 25px">
										{{ user.departmentLevel.department.name }}
									</DxField>
								</div>
							</div>
							<div class="flex-column position-relative" v-width="'350px'">
								<div class="" v-if="user.leaveBank">
									<h5 class="mb-3">Leave Bank</h5>
									<DxField label="PTO" class="mb-0" style="min-height: 25px">
										{{ user.leaveBank.pto | oneDecimal }} hrs ({{user.leaveBank.ptoVesting}})
									</DxField>
									<DxField label="WFH" class="mb-0" style="min-height: 25px">
										{{ user.leaveBank.wfh | oneDecimal }} hrs/month
									</DxField>
									<DxField label="NPTO" class="mb-0" style="min-height: 25px">
										{{ user.leaveBank.npto | oneDecimal }} hrs
									</DxField>
								</div>
							</div>
						</div>
					</div>
					<div class="w-100">
						<div class="d-flex align-items-start justify-content-between" :class="{ 'overflow-auto': isMobile }">
							<DxTabs :width="400" :data-source="tabs" display-expr="text" :selected-index="0" :on-selection-changed="tabChanged" />
							<DxButton icon="arrowleft" @click="hide" style="position: absolute; right: 10px; top: 10px;" />
						</div>
						<div class="mt-4">
							<div>
								<PersonalForm v-if="currentTab == 'Personal'" :editUser="user" @saved="userSaved('Personal', ...arguments)" />
							</div>
							<div>
								<LeavePolicyForm v-if="currentTab == 'Leave Policy'" :user="user" @saved="userSaved('LeavePolicy', ...arguments)" />
							</div>
							<div>
								<UserTargets v-if="currentTab == 'Targets'" :user="user" @saved="userSaved('Targets', ...arguments)" :departmentLevel="departmentLevel" />
							</div>
							<div>
								<UserLeaves v-if="currentTab == 'Leaves'" :user="user" @user-updated="userSaved('Leaves', ...arguments)" />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</CheckPermission>
</template>

<script>
import DxButton from "devextreme-vue/button";
import { AxiosWrapper } from "../../mixins";
import DxTabs from "devextreme-vue/tabs";
import UserTargets from "./user-targets";
import UserLeaves from "../leaves/user-leaves-tab.vue";
import PersonalForm from "./personal-form";
import LeavePolicyForm from "./leave-policy-form.vue";
import UserNotes from "./user-notes.vue";
import moment from "moment";
import { isMobile } from "@/utils/media-query";

const newUser = () => ({
	firstName: "",
	lastName: "",
	email: "",
	departmentLevel: {},
	supervisor: {},
	leaveBank: { leavePolicy: {} },
	isLocked: false,
	startDate: null,
	terminationDate: null,
});

export default {
	name: "UserPopup",
	props: ["id","showUserDetails"],
	components: {
		DxButton,
		DxTabs,
		UserTargets,
		UserLeaves,
		PersonalForm,
		LeavePolicyForm,
		UserNotes,
	},
	mixins: [AxiosWrapper],
	data() {
		return {
			apiRequest: false,
			user: newUser(),
			contentKey: this.generateUniqueCode(),
			currentTab: "Personal",
			departmentLevel: { target: {}, department: {}, level: {} },
			isMobile: isMobile(),
		};
	},
	async created(){
		await this.setUser(this.id)
	},
	methods: {
		async setUser(id) {
			if(id){
				try{
					this.apiRequest = true
					const response = await this.get(`api/users/${id}`)
					this.user = response.data
					this.departmentLevel = response.data.departmentLevel
				}
				finally{
					this.apiRequest = false
				}
			}
			else{
				this.user = newUser()
			}
		},
		async userSaved(type, user) {
			await this.setUser(user?.id || this.user.id);
			if(type == 'Personal'){
				this.$emit('user-updated', this.user)
			}
		},
		show() {
			this.$emit('update:showUserDetails', true)
		},
		hide() {
			this.$emit('update:showUserDetails', false)
			this.$emit("hidden");
		},
		updateContentKey() {
			this.contentKey = this.generateUniqueCode()
		},
		tabChanged(e) {
			if(e.addedItems.length){
				this.currentTab = e.addedItems[0].text;
			}
		},
		openFileDialog() {
			this.$refs.fileInput.click();
		},
		async handleFileUpload(event) {
			const file = event.target.files[0];
			if(file.size > 512000){
				window.showErrorToast('File is greater than 500kb, please select lower file size.')
				return;
			}
			const formData = new FormData();
			const date = moment().format("MM/DD/YYYY HH:mm:ss");
			const newFileName = `${this.user.firstName}_${this.user.lastName}_${date}_${file.name}`;
			formData.append("file", file, newFileName);
			try {
				var response = await this.post(`api/settings/user-image/${this.user.id}`, formData, { "Content-Type": "multipart/form-data" });
				this.user.picture = response.data;
				// If this is the logged-in user, update the user picture in the header as well.
				if (this.user.id == this.Current_User_Info.Id) {
					this.changedUserPhoto(this.user.picture);
				}
				this.$emit("user-updated", this.user);
				window.showToast({
					title: "Success!",
					text: "Image upload successful.",
					color: "success",
				});
				if (this.user.id === this.Current_User_Info.Id) {
					this.Current_User_Info.Picture = this.user.picture;
				}
			} catch (e) {
				window.showToast({
					title: "Alert",
					text: e.response.data,
					color: "danger",
				});
			}
		},
	},
	computed: {
		tabs() {
			return [
				{ text: "Personal" },
				{ text: "Leave Policy" },
				{ text: "Targets", disabled: !this.isEditing },
				{ text: "Leaves", disabled: !this.isEditing }
			];
		},
		isEditing() {
			return Boolean(this.user.id);
		},
		scrollHeight() {
			return this.currentTab == "Leaves" ? "74vh" : "48vh";
		}
	},
};
</script>

<style>
.image-container {
	width: 200px;
	height: 200px;
	overflow: hidden;
}

.user-profile-picture {
	object-fit: cover;
	height: 150px;
	width: 150px;
	border-radius: 50%;
}

.user-popup-img {
	place-items: center;
}

#user-details-page{
	min-height: calc(100vh - 60px)
}
</style>
