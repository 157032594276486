<template>
	<CheckPermission :value="constants.PERMISSION_READ_CLIENTS" :access-denied-msg="true">
		<b-card no-body>
			<b-card-header class="d-flex align-items-center justify-content-between">
				<strong>Clients</strong>
				<DxButton icon="add" @click="addClient" v-if="hasPermission(constants.PERMISSION_UPDATE_CLIENTS)" />
			</b-card-header>
			<b-card-body class="p-0">
				<spinner v-if="apiRequest" />
				<DxDataGrid
					v-else
					class="header-table"
					ref="gridRef"
					:data-source="clients"
					:show-row-lines="true"
					:show-column-lines="false"
					:show-borders="true"
					:hover-state-enabled="true"
					:row-alternation-enabled="true"
					:selection="{ mode: 'single' }">

					<DxColumn data-field="firstName" />
					<DxColumn data-field="lastName" />
					<DxColumn data-field="email" />
					<DxColumn data-field="companyName" />
					<DxColumn data-field="country" />
					<DxColumn data-field="currency" />

					<DxColumn width="120px" caption="Actions" alignment="center" cell-template="actionsTemplate" />
					<template #actionsTemplate="{ data }">
						<div>
							<DxButton styling-mode="text" icon="edit" @click="edit(data.data)" />
							<DxButton styling-mode="text" icon="trash" @click="askDelete(data.data)" />
						</div>
					</template>
				</DxDataGrid>
				<Popup ref="clientPopup" title="Client" :native-scroll="true">
					<CheckPermission :value="constants.PERMISSION_UPDATE_CLIENTS" :access-denied-msg="true">
						<form ref="clientForm" @submit="submit($event)" :key="clientFormKey">
							<spinner v-if="postRequest" />
							<DxValidationGroup name="clientValidationGroup" ref="clientFormValidationGroup" v-else>
								<div class="row">
									<div class="col-6 mb-3">
										<label class="form-label required">First Name</label>
										<DxTextBox v-model="client.firstName" :validator="requiredRule('First Name')" >
											<DxValidator> <DxRequiredRule message="Start Date is required" /> </DxValidator>
										</DxTextBox>
									</div>
									<div class="col-6 mb-3">
										<label class="form-label">Last Name</label>
										<DxTextBox v-model="client.lastName"></DxTextBox>
									</div>
									<div class="col-6 mb-3">
										<label class="form-label">Email</label>
										<DxTextBox v-model="client.email" />
									</div>
									<div class="col-6 mb-3">
										<label class="form-label required">Company</label>
										<DxTextBox v-model="client.companyName">
											<DxValidator> <DxRequiredRule message="Company is required" /> </DxValidator>
										</DxTextBox>
									</div>
									<div class="col-6 mb-3">
										<label class="form-label required">Country</label>
										<DxSelectBox
											v-model="client.country"
											:items="countries"
											display-expr="value"
											value-expr="name"
											:search-enabled="true">
											<DxValidator> <DxRequiredRule message="Country is required" /> </DxValidator>
										</DxSelectBox>
									</div>
									<div class="col-6 mb-3">
										<label class="form-label required">Currency</label>
										<DxSelectBox
											v-model="client.currency"
											:items="currencies"
											display-expr="value"
											value-expr="name"
											:search-enabled="true">
											<DxValidator> <DxRequiredRule message="Currency is required" /> </DxValidator>
										</DxSelectBox>
									</div>
								</div>
								<div class="text-right">
									<DxButton text="Cancel" @click="$refs.clientPopup.hide()" />
									<DxButton class="ml-2" text="Submit" type="default" :use-submit-behavior="true" />
								</div>
							</DxValidationGroup>
						</form>
					</CheckPermission>
				</Popup>
			</b-card-body>
		</b-card>
	</CheckPermission>
</template>

<script>
import {
	DxDataGrid,
	DxColumn,
	DxPaging,
	DxEditing,
	DxToolbar,
	DxItem,
	DxTexts
} from 'devextreme-vue/data-grid';
import DxSpeedDialAction from 'devextreme-vue/speed-dial-action';
import { AxiosWrapper } from '@/mixins';
import DxTextBox from "devextreme-vue/text-box";
import DxValidationGroup from "devextreme-vue/validation-group";
import {
	DxValidator,
	DxRequiredRule,
	DxCustomRule,
} from "devextreme-vue/validator";
import DxSelectBox from "devextreme-vue/select-box";
import DxColorBox from 'devextreme-vue/color-box';
import { DxCheckBox } from "devextreme-vue/check-box";
import DxTagBox from 'devextreme-vue/tag-box';
import DxButton from "devextreme-vue/button";
import { Currencies, Countries, ClientSource } from '@/_helpers/constants';

const client = () => {
	return {
		"id": "",
		"firstName": "",
		"lastName": "",
		"email": "",
		"company": "",
		"location": ""
	}
}

const countries = Object.entries(Countries).map(([name, value]) => ({ name, value }));
const currencies = Object.entries(Currencies).map(([name, value]) => ({ name, value }));
const clientSource = Object.entries(ClientSource).map(([name, value]) => ({ name, value }));

export default {
	components:{
		DxDataGrid,
		DxColumn,
		DxPaging,
		DxEditing,
		DxSpeedDialAction,
		DxToolbar,
		DxItem,
		DxTexts,
		DxTextBox,
		DxValidator,
		DxRequiredRule,
		DxCustomRule,
		DxSelectBox,
		DxValidationGroup,
		DxColorBox,
		DxCheckBox,
		DxTagBox,
		DxButton
	},
	mixins:[AxiosWrapper],
	data() {
		return {
			clientFormKey: this.generateUniqueCode(),
			client: client(),
			clients: [],
			apiRequest: true,
			postRequest: false,
			currencies,
			countries,
			clientSource
		};
	},
	mounted() {
		this.load()
	},
	methods: {
		load(){
			this.apiRequest = true
			this.get('api/clients/get').then(response => {
				this.clients = response.data
			}).finally(() => {
				this.apiRequest = false
			})
		},
		addClient() {
			this.client = client()
			this.clientFormKey = this.generateUniqueCode()
			this.$refs.clientPopup.show()
		},
		askDelete(row) {
			window.showConfirmModal({
				confirmButtonText: "Delete",
				subTitle: "Deleting is irreversible",
				showLoader: true,
				mode: "danger",
				onConfirm: async () => {
					try {
						await this.delete('api/clients/' + row.id)
						window.showSuccessToast("Client deleted successfully");
						this.load()
					} catch (error) {
						window.showErrorToast(error.message);
					}
				},
			});
		},
		edit(row) {
			this.client = this.cleanSource(row)
			this.clientFormKey = this.generateUniqueCode()
			this.$refs.clientPopup.show()
		},
		submit(e){
			e.preventDefault()
			this.postRequest = true
			let endpoint = this.client.id ? 'update' : 'add'
			this.post(`api/clients/${endpoint}`, this.client).then(response => {
				this.$refs.clientPopup.hide()
				this.load()
			}).finally(() => {
				this.postRequest = false
			})
		}
	},
};
</script>

