<template>
	<Popup
        ref="leaveRequestPopup"
        :destroyOnClose="true"
        :width="'40%'"
        :native-scroll="true"
        :hide-on-outside-click="false"
        @hidden="closePopup"
        :title="`Request Leave`">
		<form id="userLeaveForm" ref="userLeaveForm" @submit="submit($event)">
            <label class="mb-4" v-if="supervisorStatusMsg" v-html="supervisorStatusMsg"></label>
			<DxValidationGroup ref="userLeaveValidationGroup" :validation-status="validationStatus">
				<LeaveFormFields
					v-model="userLeave"
					ActionableIn="LeaveRequest"
					@reevaluating-start="$refs.LeaveInfoAlert.reevaluatingStart()"
					@validation-response="onValidationResponse" />
				<DxField label="Notes" :required="true">
					<DxTextArea placeholder="Enter Notes" height="80" v-model="userLeave.notes">
						<DxValidator> <DxRequiredRule message="Notes are required"/> </DxValidator>
					</DxTextArea>
				</DxField>
				 <LeaveInfoAlert
					ref="LeaveInfoAlert"
					:validationResponse="validationResponse"
					:userLeave="userLeave"
					:username="username"
					:leaveBank="leaveBank" />
                <DxButton class="float-right" type="default" text="Submit" :use-submit-behavior="true" />
			</DxValidationGroup>
		</form>
        <!-- <spinner v-else/> -->
	</Popup>
</template>

<script>
import { AxiosWrapper } from '../../mixins'
import DxValidationGroup from 'devextreme-vue/validation-group';
import { DxValidator, DxRequiredRule, DxCustomRule, DxAsyncRule } from 'devextreme-vue/validator';
// import KDGHolidays from '../../mixins/view-mixins/KDGHolidays'
import DxDateBox from 'devextreme-vue/date-box';
import DxButton from 'devextreme-vue/button';
import { DxNumberBox } from 'devextreme-vue/number-box';
import { DxTextArea } from 'devextreme-vue/text-area';
import DxRadioGroup from 'devextreme-vue/radio-group';
import { DxCheckBox } from 'devextreme-vue/check-box';
import DxSelectBox from 'devextreme-vue/select-box';
import { DxScrollView } from 'devextreme-vue/scroll-view';
import LeaveFormFields from './leave-form-fields.vue';
import LeaveInfoAlert from './leave-info-alert.vue';
import _ from 'lodash';
function initialState (){
    return {
        userLeave: {
            status:'Pending',
            debit:0,
            credit:0,
            requireApproval:true,
            startDate:null,
            endDate:null,
            entryType:'Leave'
        },
        balanceBefore:0,
        balanceAfter:0,
        apiRequest: false,
        message: '',
        isDailyBreak: false,
        datesError: false,
        leaveBank: {},
        supervisorStatusMsg: '',
		validationResponse: null,
		validationStatus: null
    }
}
export default {
	mixins: [AxiosWrapper],
	components: {
		DxValidationGroup,
		DxValidator,
		DxRequiredRule,
        DxCustomRule,
        DxAsyncRule,
		DxDateBox,
		DxButton,
		DxNumberBox,
		DxTextArea,
        DxRadioGroup,
        DxCheckBox,
        DxSelectBox,
		DxScrollView,
		LeaveFormFields,
		LeaveInfoAlert
	},
	data() {
		return initialState();
	},
	methods: {
        async show(){
            this.apiRequest = true
            this.$refs.leaveRequestPopup.show()
            const response = await this.get(`api/users/${this.Current_User_ID}/leave-bank`)
            this.leaveBank = response.data
            await this.checkSupervisorStatus()
			this.userLeave.userId = this.Current_User_ID
            this.apiRequest = false
        },
        async checkSupervisorStatus(){
            const response = await this.get(`api/users/leaves/supervisor-leave-status`)
            let status = response.data
            if(status.supervisorNotFound){
                this.supervisorStatusMsg = 'Please submit all of the relavant information for your leave and HR will review.'
                this.userLeave.requireApproval = false
            }
            else if(!status.immediateSupervisorOnLeave || !status.nextSupervisorExists){
                this.supervisorStatusMsg = `Please submit all of the relavant information for your leave and ${status.supervisor.userName} will review.`;
            }
            else if(status.immediateSupervisorOnLeave && status.nextSupervisorExists && !status.nextSupervisorOnLeave){
                this.supervisorStatusMsg = `Please submit all of the relavant information for your leave and ${status.supervisor.userName} will review <span style="color:#DA0063">as ${status.immediateSupervisor.userName} is currently on leave.</span>`;
            }
        },
		submit(e){
			e.preventDefault()
			this.apiRequest = true
			this.userLeave.userId = this.Current_User_ID
			if(this.compareDate(this.userLeave.startDate, '>=', new Date()) || this.compareDate(this.userLeave.endDate, '>=', new Date())){
				this.post(`api/users/${this.Current_User_ID}/leaves`, this.userLeave).then(response =>{
					this.$connectionHub.$emit("new-leave-added");
					window.showSuccessToast('You have requested a leave. Please wait for your supervisor to approve.');
				}).finally(final =>{
					this.apiRequest = false
					this.$refs.leaveRequestPopup.hide()
					this.closePopup()
				})
			} else {
				this.apiRequest = false
				window.showErrorToast('You cannot submit a leave for dates in the past.')
			}
		},
        resetValues(){
            this.userLeave.credit = 0
            this.userLeave.debit = 0
            this.userLeave.startDate = null
            this.userLeave.endDate = null
        },
        closePopup(){
            Object.assign(this.$data, initialState());
            this.$emit('hidden')
        },
		onValidationResponse(response){
			this.validationResponse = response
			this.$refs.LeaveInfoAlert.evaluateBalance()
		}
	},
    computed:{
        username(){
            return this.Current_User_Info.FirstName ? this.Current_User_Info.FirstName : this.Current_User_Info.FullName?.split(' ')[0]
        }
    },
	watch: {
    	'validationStatus'(newValue) {
			// Watch the validationStatus property of the validation group
			if (newValue === 'valid') {
			// Form is valid
				console.log('Form is valid');
			} else {
			// Form is invalid
				console.log('Form is invalid');
			}
		}
	},
}
</script>
