<template>
	<div>
		<div class="d-flex align-items-center justify-content-between mb-3">
			<strong>{{ userTarget.id ? "Edit Target Period" : "Add Target Period" }}</strong>
			<DxButton icon="arrowleft" @click="$emit('hidden')" />
		</div>
		<form id="userForm" ref="userForm" @submit="submit($event)">
			<spinner v-if="saveRequest" />
			<DxValidationGroup name="userFormValidationGroup" ref="userFormValidationGroup" v-else>
				<div class="row">
					<div class="col-6">
						<DxField :required="true" label="Start Date" label-width="40%" value-width="60%">
							<DxDateBox v-model="userTarget.startDate" :min="min" placeholder="MM/dd/yyyy"
								display-format="MM/dd/yyyy"
								invalid-date-message="The date must have the following format: MM/dd/yyyy">
								<DxValidator>
									<DxRequiredRule message="Start Date is required" />
									<DxAsyncRule :validation-callback="validateDates"
										message="Date range conflicts with other Target, please change dates." />
								</DxValidator>
							</DxDateBox>
						</DxField>
					</div>
					<div class="col-6">
						<DxField :required="true" label="End Date" label-width="40%" value-width="60%">
							<DxDateBox v-model="userTarget.endDate" placeholder="MM/dd/yyyy" display-format="MM/dd/yyyy"
								invalid-date-message="The date must have the following format: MM/dd/yyyy">
								<DxValidator>
									<DxRequiredRule message="End Date is required" />
									<DxCustomRule :validation-callback="() => {
					return compareDate(
						userTarget.endDate,
						'>=',
						userTarget.startDate
					);
				}
				" message="End date should be after Start Date." />
									<DxAsyncRule :validation-callback="validateDates"
										message="Date range conflicts with other Target, please change dates." />
								</DxValidator>
							</DxDateBox>
						</DxField>
					</div>
					<div class="col-6">
						<DxField :required="true" label="Department" label-width="40%" value-width="60%">
							<DepartmentsLookup
								:value="userTarget.department.id"
								:required="true"
								@object-changed="onChangeDepartment"
							/>
						</DxField>
					</div>
					<div class="col-6">
						<DxField :required="true" label="Level" label-width="40%" value-width="60%">
							<Lookup v-model="userTarget.departmentLevelId"
								:api="`api/settings/department-levels?filterBy=Department&id=${userTarget.department.id}`"
								:required="true" display-expr="level.name" value-expr="id" placeholder="Select Level"
								:key="dptLvlKey" />
						</DxField>
					</div>
					<div class="col-6">
						<DxField :required="true" label="Billable Hours" label-width="40%" value-width="60%">
							<DxNumberBox v-model="userTarget.billableHours" placeholder="0.00"
								:format="{ type: 'fixedPoint', precision: 2 }" :precision="2" :min="0" :max="8"
								:show-spin-buttons="true">
								<DxValidator>
									<DxRequiredRule message="Billable Hours are required" />
								</DxValidator>
							</DxNumberBox>
						</DxField>
					</div>
					<div class="col-6">
						<DxField :required="true" label="Average Hourly Rate" label-width="40%" value-width="60%">
							<DxNumberBox v-model="userTarget.avgHourlyRate" placeholder="$0.00"
								:format="{ type: 'currency', precision: 2 }" :precision="2" :min="0"
								:show-spin-buttons="true">
								<DxValidator>
									<DxRequiredRule message="Average Hourly Rate is required" />
								</DxValidator>
							</DxNumberBox>
						</DxField>
					</div>
					<!-- <div class="col-6">
						<DxField :required="true" label="Internal Feedback" label-width="40%" value-width="60%">
							<DxNumberBox v-model="userTarget.internalFeedback" placeholder="0.0"
								:format="{ type: 'fixedPoint', precision: 1 }" :precision="1" :min="0" :max="5"
								:show-spin-buttons="true">
								<DxValidator>
									<DxRequiredRule message="Internal Feedback is required" />
								</DxValidator>
							</DxNumberBox>
						</DxField>
					</div>
					<div class="col-6">
						<DxField :required="true" label="External Feedback" label-width="40%" value-width="60%">
							<DxNumberBox v-model="userTarget.externalFeedback" placeholder="0.0"
								:format="{ type: 'decimal', precision: 1 }" :precision="1" :min="0" :max="5"
								:show-spin-buttons="true">
								<DxValidator>
									<DxRequiredRule message="External Feedback is required" />
								</DxValidator>
							</DxNumberBox>
						</DxField>
					</div> -->
					<div class="col-12">
						<DxField label="Notes" label-width="19%" value-width="81%">
							<DxTextArea placeholder="Enter Notes" height="80" v-model="userTarget.notes">
							</DxTextArea>
						</DxField>
					</div>
					<div class="col-12">
						<DxField v-if="dateError" label-width="19%" value-width="81%">
							<p class="text-danger">
								Date range conflicts with other Target, please
								change dates.
							</p>
						</DxField>
					</div>
				</div>
				<DxButton type="default" text="Submit" class="float-right ml-2" :use-submit-behavior="true" />
				<DxButton v-if="userTarget.id" type="danger" text="Delete" class="float-right ml-2"
					@click="confirmDelete" />
			</DxValidationGroup>
		</form>
	</div>
</template>

<script>
import { AxiosWrapper } from "@/mixins";
import DxValidationGroup from "devextreme-vue/validation-group";
import {
	DxValidator,
	DxRequiredRule,
	DxCustomRule,
	DxAsyncRule,
} from "devextreme-vue/validator";
import DxDateBox from "devextreme-vue/date-box";
import DxButton from "devextreme-vue/button";
import { DxNumberBox } from "devextreme-vue/number-box";
import { DxTextArea } from "devextreme-vue/text-area";
import { DepartmentsLookup, Lookup } from "../../components/lookups";

export default {
	props: ["user", "targets", "departmentLevel", "target"],
	mixins: [AxiosWrapper],
	components: {
		DxValidationGroup,
		DxValidator,
		DxRequiredRule,
		DxCustomRule,
		DxAsyncRule,
		DxDateBox,
		DxButton,
		DxNumberBox,
		DxTextArea,
		DepartmentsLookup,
		Lookup,
	},
	data() {
		return {
			userTarget: { billableHours: 0 },
			saveRequest: false,
			dateError: false,
			dptLvlKey: this.generateUniqueCode(),
		};
	},
	created() {
		if (this.target.id) {
			this.userTarget = this.cleanSource(this.target);
			this.userTarget.department = this.userTarget.departmentLevel.department;
		}
		else {
			//When creating a new Target Period the start date must always be the start date of the user OR the day after the most recent end date, whichever is later
			if (this.targets.length) {
				let targetsEndDate = new Date(Math.max.apply(null, this.targets.map((e) => new Date(e.endDate))));
				this.userTarget.startDate = targetsEndDate > new Date(this.user.startDate) ? targetsEndDate : new Date(this.user.startDate);
				this.userTarget.startDate.setDate(this.userTarget.startDate.getDate() + 1);
			} else {
				this.userTarget.startDate = new Date(this.user.startDate);
			}
			this.userTarget.department = this.departmentLevel?.department || { id: null };
			this.userTarget.departmentLevelId = this.departmentLevel?.id;
			this.userTarget.billableHours = this.departmentLevel?.target ? this.departmentLevel.target.billableHours : 0;
			this.userTarget.avgHourlyRate = this.departmentLevel?.target ? this.departmentLevel.target.avgHourlyRate : 0;
		}
	},
	methods: {
		submit(e) {
			e.preventDefault();
			if (this.dateError) return;
			this.saveRequest = true;
			this.userTarget.userId = this.user.id;
			this.post("api/users/user-target", this.userTarget)
				.then((response) => {
					window.showSuccessToast("User Target is added.");
					this.$emit(this.userTarget.id ? "updated" : "added", response.data);
					if(this.departmentLevel?.id != this.userTarget.departmentLevelId){
						this.$emit("user-updated");
					}
				})
				.finally(() => {
					this.saveRequest = false;
				});
		},
		confirmDelete() {
			window.showConfirmModal({
				subTitle: "Deleting is irreversible.",
				confirmButtonText: "Delete",
				showLoader: true,
				mode: "danger",
				onConfirm: async () => {
					await this.deleteTarget();
				},
			});
		},
		async deleteTarget() {
			try {
				await this.delete(
					"api/users/user-target/" + this.userTarget.id
				);
				window.showSuccessToast("User Target deleted.");
				this.$emit("deleted", this.userTarget.id);
			} catch (error) {
				window.showErrorToast(error.message);
			}
		},
		validateDates() {
			if (this.userTarget.startDate && this.userTarget.endDate) {
				var params = {
					startDate: new Date(
						this.userTarget.startDate
					).toLocaleDateString("en-US"),
					endDate: new Date(
						this.userTarget.endDate
					).toLocaleDateString("en-US"),
					targetId: this.userTarget.id,
					userId: this.user.id,
				};
				return new Promise((resolve) => {
					this.get("api/users/validate-target-dates", {
						params,
					}).then((response) => {
						resolve(response.data);
					});
				});
			} else {
				return new Promise((resolve) => {
					resolve(true);
				});
			}
		},
		onChangeDepartment(obj) {
			this.userTarget.department = obj;
			this.userTarget.departmentLevelId = null;
			this.dptLvlKey = this.generateUniqueCode();
		},
	},
	computed: {
		min() {
			return this.user.startDate
				? new Date(this.user.startDate)
				: new Date(2022, 0, 1);
		},
	},
};
</script>
