<template>
	<div>
		<div class="d-flex align-items-center justify-content-between mb-3">
			<strong>New Leave Entry for {{ user.firstName }}
				{{ user.lastName }}</strong>
			<DxButton icon="arrowleft" @click="$emit('hidden')" />
		</div>
		<form id="userLeaveForm" ref="userLeaveForm" @submit="submit($event)">
			<spinner v-if="saveRequest" />
			<DxValidationGroup ref="userLeaveValidationGroup" v-else>
				<div>
					<label>Are you creating an adjustment to {{ user.firstName }}'s account or submitting leave on their behalf?</label>
					<DxField :show-label="false">
						<DxRadioGroup
							:items="[ LeaveEntryType.Adjustment, LeaveEntryType.Leave]"
							v-model="userLeave.entryType"
							:value="'Adjustment'"
							layout="horizontal"
							@value-changed="resetValues" />
					</DxField>
					<AdjustmentFormFields
						v-if="userLeave.entryType == LeaveEntryType.Adjustment"
						v-model="userLeave"
						@reevaluate-balance=" $refs.LeaveInfoAlert.evaluateBalance()" />
					<LeaveFormFields
						v-else-if="userLeave.entryType == LeaveEntryType.Leave"
						v-model="userLeave"
						ActionableIn="UserManagement"
						@reevaluating-start="$refs.LeaveInfoAlert.reevaluatingStart()"
						@validation-response="onValidationResponse" />
					<DxField label="Notes" :required="true">
						<DxTextArea placeholder="Enter Notes" height="80" v-model="userLeave.notes">
							<DxValidator>
								<DxRequiredRule message="Notes are required" />
							</DxValidator>
						</DxTextArea>
					</DxField>
					<div v-if="userLeave.entryType == LeaveEntryType.Leave && user.supervisor">
						<DxField>
							<DxCheckBox class="float-left" :value="userLeave.requireApproval" @value-changed="(e) => { userLeave.requireApproval = e.value }"
								:text="`Require approval from ${user.supervisor.fullName}`" />
						</DxField>
					</div>
					<DxField :show-label="true">
						<LeaveInfoAlert ref="LeaveInfoAlert" :validationResponse="validationResponse"
							:userLeave="userLeave" :username="username" :leaveBank="user.leaveBank" />
					</DxField>
				</div>
				<DxButton class="float-right" type="default" text="Submit" :use-submit-behavior="true" />
			</DxValidationGroup>
		</form>
	</div>
</template>

<script>
import { AxiosWrapper } from "@/mixins";
import DxValidationGroup from "devextreme-vue/validation-group";
import { DxValidator, DxRequiredRule } from "devextreme-vue/validator";
// import KDGHolidaysMixin from '../../mixins/view-mixins/KDGHolidays'
import DxButton from "devextreme-vue/button";
import { DxTextArea } from "devextreme-vue/text-area";
import DxRadioGroup from "devextreme-vue/radio-group";
import { DxCheckBox } from "devextreme-vue/check-box";
import LeaveFormFields from "./leave-form-fields.vue";
import AdjustmentFormFields from "./adjustment-form-fields.vue";
import LeaveInfoAlert from "./leave-info-alert.vue";
import { LeaveEntryType } from "../../_helpers/constants";

export default {
	props: ["user", "targets", "departmentLevel", "target", "leaves"],
	mixins: [AxiosWrapper],
	components: {
		DxValidationGroup,
		DxValidator,
		DxRequiredRule,
		DxButton,
		DxTextArea,
		DxRadioGroup,
		DxCheckBox,
		LeaveFormFields,
		AdjustmentFormFields,
		LeaveInfoAlert,
	},
	data() {
		return {
			userLeave: {
				status: "Pending",
				debit: 0,
				credit: 0,
				requireApproval: false,
				startDate: null,
				endDate: null,
				entryType: "Adjustment",
			},
			LeaveEntryType: LeaveEntryType,
			balanceBefore: 0,
			balanceAfter: 0,
			saveRequest: false,
			validationResponse: {},
			alertBannerKey: this.generateUniqueCode(),
		};
	},
	created() {
		this.userLeave.userId = this.user.id;
	},
	methods: {
		submit(e) {
			e.preventDefault();
			this.saveRequest = true;
			this.userLeave.userId = this.user.id;
			this.post(`api/users/${this.user.id}/leaves`, this.userLeave)
				.then((response) => {
					this.$emit("leave-added", response.data);
				})
				.finally(() => {
					this.saveRequest = false;
				});
		},
		resetValues() {
			this.userLeave.credit = 0;
			this.userLeave.debit = 0;
			this.userLeave.startDate = null;
			this.userLeave.endDate = null;
		},
		onValidationResponse(response) {
			this.validationResponse = response;
			this.$refs.LeaveInfoAlert.evaluateBalance();
		},
	},
	computed: {
		username() {
			return this.user.firstName
				? this.user.firstName
				: this.user.fullName.split(" ")[0];
		},
	},
};
</script>
