<template>
	<CheckPermission :value="constants.PERMISSION_READ_ROLES_SETTINGS" :access-denied-msg="true">
		<b-card class="h-100 overflow-auto" no-body>
			<b-card-header class="d-flex align-items-center justify-content-between">
				<strong>Groups</strong>
				<div>
					<DxButton @click="$router.push('/settings/users/roles/permissions')" class="ml-2" icon="user" hint="User Permissions" />
					<DxButton @click="$refs['role-popup'].show()" class="ml-2" icon="add" hint="Add Role" v-if="hasPermission(constants.PERMISSION_UPDATE_ROLES_SETTINGS)" />
				</div>
			</b-card-header>
			<b-card-body class="p-0 h-100">
				<div :key="key">
					<table class="table-fixed table-bordered roles-table-header mr-1">
						<thead>
							<tr>
								<td width="30%">
									<strong>Permissions</strong>
								</td>
								<td v-for="role in roles" class="role-td" :width="70 / roles.length + '%'"
									:key="role.name">
									<div class="position-relative">
										<strong @click="$refs['role-popup'].show(role)" class="pointer"
											title="Click to edit">
											{{ role.displayName }}
										</strong>
										<DxButton
											class="dx-btn-small position-absolute"
											v-top="'0px'" v-right="'0px'"
											:disabled="!role.deletable"
											@click="onDeleteRole(role.name)"
											icon="trash"
											v-if="hasPermission(constants.PERMISSION_UPDATE_ROLES_SETTINGS)">
										</DxButton>
									</div>
								</td>
							</tr>
						</thead>
					</table>
					<div>
						<div :key="table" class="roles-table-body" v-if="!apiRequest">
							<div v-for="groupedPermission in groupedPermissions"
								:key="'perm-type-' + groupedPermission.type">
								<div class="permissions-header">
									{{ groupedPermission.type }}
								</div>
								<div v-for="permission in groupedPermission.permissions" :key="permission.key">
									<div class="d-flex">
										<div class="td" v-width="'30%'">
											<DxButton class="mr-1" v-if="permission.childPermissions.length > 0" styling-mode="text" @click="showChildren(permission)">
												<Icon :name="`chevron-${permission.showChildren? 'down': 'right'}`" :el-attr="{class: 'text-dark'}" class="text-dark" icon-pack="bootstrap"></Icon>
											</DxButton>
											<span>{{formatSnakeCase(permission.name)}}</span>
										</div>
										<div class="td text-center" v-width="70 / roles.length + '%'"
											v-for="role in roles" :key="role.name">
											<DxCheckBox :disabled="!hasPermission(constants.PERMISSION_UPDATE_ROLES_SETTINGS)" :value="havePermission(role,permission.name)" @value-changed="selectPermission(role,permission,$event)" />
											<div>
												<small v-if="permission.name =='read_data_reports'">
													(Restriction:
													{{restrictionPolicy(role)}})
													<DxButton class="position-relative" v-height="'20px'"
														v-width="'20px'" :disabled="!havePermission(role,permission.name)" @click="restrictionPopup(role)">
														<Icon name="dx-icon-edit" icon-pack="dx" left="2px" :el-attr="{class: 'position-absolute',}" top="2px" />
													</DxButton>
												</small>
											</div>
										</div>
									</div>
									<RecursivePermissions
										v-if="permission.childPermissions.length > 0 && permission.showChildren"
										:roles="roles"
										:permissions="permission.childPermissions"
										:havePermission="havePermission"
										:showChildren="showChildren"
										:selectPermission="selectPermission"
										:iteration="0" />
								</div>
							</div>
						</div>
						<spinner v-else />
					</div>
				</div>
				<RolePopup ref="role-popup" @added="onAddRole" @updated="onUpdateRole" />
				<DataRestrictionPermission ref="data-restriction-popup" @update="updateRestrictionPolicy" type="Role" />
			</b-card-body>
		</b-card>
	</CheckPermission>
</template>

<script>
import { DxCheckBox } from "devextreme-vue/check-box";
import { DxButton } from "devextreme-vue/button";
import { AxiosWrapper } from "@/mixins";
import RecursivePermissions from "./recursive-roles.vue";
import RolePopup from "./role-popup.vue";
import _ from "lodash";
import DataRestrictionPermission from "./data-restriction-permission.vue";

export default {
	name: "Groups",
	mixins: [AxiosWrapper],
	components: {
		DxButton,
		DxCheckBox,
		RecursivePermissions,
		RolePopup,
		DataRestrictionPermission,
	},
	data() {
		return {
			key: this.generateUUID(),
			table: this.generateUUID(),
			apiRequest: false,
			allRoles: [],
			permissions: [],
			userId: "",
			showUserSelection: false,
		};
	},
	async created() {
		this.apiRequest = true;
		await this.getRoles();
		await this.getPermissions();
		this.apiRequest = false;
	},
	methods: {
		async getRoles() {
			const response = await this.get("api/settings/roles");
			this.allRoles = response.data;
		},
		async getPermissions() {
			const response = await this.get("api/settings/permissions");
			this.permissions = response.data;
		},
		havePermission(role, permission) {
			return role.groupPermissions
				.map((s) => s.permissionId)
				.includes(permission);
		},
		showChildren(permission) {
			permission.showChildren = !permission.showChildren;
			this.$forceUpdate();
			this.table = this.generateUUID();
		},
		selectPermission(role, permission, e) {
			if (
				!this.permissionDefaultPageValidation(role, permission, e.value)
			) {
				e.component.option("value", true);
				return;
			}
			if (
				role.groupPermissions
					.map((s) => s.permissionId)
					.includes(permission.name) != e.value
			) {
				this.post("api/settings/roles/permissions", {
					permissionId: permission.name,
					groupId: role.name,
				}).then((response) => {
					let i = this.allRoles.findIndex((s) => s.name == role.name);
					if (i > -1) {
						this.allRoles[i].groupPermissions = response.data;
						// this.key = this.generateUUID()
						permission.key = this.generateUUID();
						this.$forceUpdate();
					}
					window.showSuccessToast(
						`${this.formatSnakeCase(
							role.name
						)}'s permission updated.`
					);
				});
			}
		},
		onAddRole(role) {
			this.allRoles.push(role);
			this.key = this.generateUUID();
		},
		onUpdateRole(updatedRole, oldRoleName) {
			let i = this.allRoles.findIndex((s) => s.name == oldRoleName);
			if (i > -1) {
				this.allRoles[i].name = updatedRole.name;
				this.allRoles[i].displayName = updatedRole.displayName;
				this.allRoles[i].defaultLoginPage =
					updatedRole.defaultLoginPage;
				this.key = this.generateUUID();
				this.$forceUpdate();
			}
		},
		onDeleteRole(name) {
			window.showConfirmModal({
				subTitle: "Deleting is irreversible.",
				confirmButtonText: "Delete",
				showLoader: true,
				mode: "danger",
				onConfirm: async () => {
					await this.confirmDeletion(name);
				},
			});
		},
		async confirmDeletion(name) {
			const response = await this.delete(`api/settings/roles/${name}`);
			this.allRoles = this.allRoles.filter((s) => s.name != name);
			this.key = this.generateUUID();
		},
		permissionDefaultPageValidation(role, permission, value) {
			if (!value) {
				if (
					(role.defaultLoginPage == "/dashboard" &&
						permission.name == "read_dashboard") ||
					(role.defaultLoginPage == "/emails" &&
						permission.name == "read_emails") ||
					(role.defaultLoginPage == "/resourcing" &&
						permission.name == "read_resourcing") ||
					(role.defaultLoginPage == "/clients" &&
						permission.name == "read_clients") ||
					(role.defaultLoginPage == "/services" &&
						permission.name == "read_services") ||
					(role.defaultLoginPage == "/in-flight" &&
						permission.name == "read_inflight") ||
					(role.defaultLoginPage == "/leaves" &&
						permission.name == "read_leaves")
				) {
					window.showErrorToast(`This is the default login page for ${role.displayName}.
                        Please change default login page before revoking this permission.`);
					return false;
				}
			}
			return true;
		},
		restrictionPopup(role) {
			this.$refs["data-restriction-popup"].showForRole(role);
		},
		updateRestrictionPolicy(role) {
			let i = this.allRoles.findIndex((s) => s.name == role.name);
			this.allRoles[i].reportDataRestriction = role.reportDataRestriction;
			this.allRoles[i].restrictedId = role.restrictedId;
			this.$forceUpdate();
		},
	},
	computed: {
		groupedPermissions() {
			return _(this.permissions.filter((s) => !s.parentPermissionId))
				.groupBy((x) => x.permissionType)
				.map((value, key) => ({
					type: key,
					permissions: value.map((val) => {
						val[key] = this.generateUUID();
						return val;
					}),
				}))
				.value();
		},
		roles() {
			return this.allRoles.filter((s) => s.name != "custom");
		},
		restrictionPolicy() {
			return (group) => {
				let role = this.allRoles.find((s) => s.name == group.name);
				if (role) {
					if (role.reportDataRestriction == "MyData")
						return "User Data";
					else if (role.reportDataRestriction == "MyDepartment")
						return "User Department";
					else if (role.reportDataRestriction == "MyHierarchy")
						return "User Hierarchy";
					else if (role.reportDataRestriction == "CustomDepartments")
						return "Custom Departments";
					else if (role.reportDataRestriction == "CustomUsers")
						return "Custom Users";
					else return role.reportDataRestriction;
				}
				return "Off";
			};
		},
	},
};
</script>
<style scoped>
.pl-32 {
	padding-left: 32px;
}

.pl-64 {
	padding-left: 64px;
}

.vertical-line {
	position: absolute;
	margin: 0;
	width: 22px;
	left: -2px;
	top: 0px;
	rotate: 90deg;
}

.horizontal-line {
	position: absolute;
	width: 20px;
	margin: 0;
	top: 50%;
	left: 9px;
}

.permissions-header {
	border-bottom: 1px solid;
	border-color: #d8dbe0;
	padding: 10px 0 10px 20px;
	font-weight: 600;
}

.role-td {
	text-align: center;
}

.role-td .dx-button {
	display: none;
}

.role-td:hover .dx-button {
	display: inline-table;
}
</style>
<style>
.roles-table-header {
	width: 100%;
	border: 0;
	margin-bottom: 0;
}

.roles-table-header thead td {
	border-top: 0;
	border-left: 0;
	border-bottom-width: 1px;
	padding: 10px;
	border-bottom: 0;
}

.roles-table-header tr td:first-child {
	padding-left: 20px !important;
}

.roles-table-body {
	color: #4f5d73;
}

.roles-table-body .td {
	padding: 10px;
	border-right: 1px solid;
	border-bottom: 1px solid;
	border-color: #d8dbe0;
}

.roles-table-body .td:first-child {
	padding-left: 30px !important;
}
</style>./recursive-roles.vue
