<template>
    <span class="badge" v-bg-color="StatusColors[value]" v-color="'#fff'">
        {{ formatLabel(value) }}
    </span>
</template>

<script>
import { TextFormatter } from '../../mixins'
export default {
    mixins: [TextFormatter],
    props: ['value'],
	data(){
		return {
			StatusColors: {
				Live: "#28a745",         // Green
				Unpaid: "#dc3545",       // Red
				Cancelled: "#6c757d",    // Grey
				Expired: "#343a40",      // Dark Grey
				Trial: "#17a2b8",        // Light Blue
				TrialExpired: "#ffc107", // Yellow
				Paused: "#fd7e14"        // Orange
			}
		}
	}
}
</script>
