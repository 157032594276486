<template>
	<div>
		<spinner v-if="saveRequest" />
		<form ref="personalForm" @submit="submit($event)" v-else>
			<DxValidationGroup name="personalValidationGroup" ref="personalValidationGroup">
				<div class="row">
					<div class="col-6">
						<DxField label="First Name" :required="true">
							<DxTextBox v-model="user.firstName" placeholder="First Name" mode="text">
								<DxValidator>
									<DxRequiredRule message="First Name is required" />
									<DxPatternRule pattern="^[^\s].*[^\s]$|^[^\s]{0,1}$" message="First Name should not start or end with a space." />
								</DxValidator>
							</DxTextBox>
						</DxField>
					</div>
					<div class="col-6">
						<DxField label="Last Name" :required="true">
							<DxTextBox v-model="user.lastName" placeholder="Last Name" mode="text">
								<DxValidator>
									<DxRequiredRule message="Last Name is required" />
									<DxPatternRule pattern="^[^\s].*[^\s]$|^[^\s]{0,1}$" message="First Name should not start or end with a space." />
								</DxValidator>
							</DxTextBox>
						</DxField>
					</div>
					<div class="col-6">
						<DxField label="Email" :required="true">
							<DxTextBox v-model="user.email" placeholder="Email" mode="text">
								<DxValidator>
									<DxRequiredRule message="Email is required" />
									<DxPatternRule :pattern="emailPattern" message="Email is invalid" />
									<DxAsyncRule :validation-callback="emailValidation" message="Email already exists." />
								</DxValidator>
							</DxTextBox>
						</DxField>
					</div>
					<div class="col-6">
						<DxField label="Personal Email" :required="true">
							<DxTextBox v-model="user.personalEmail" placeholder="Personal Email" mode="text">
								<DxValidator>
									<DxRequiredRule message="Email is required" />
									<DxPatternRule :pattern="emailPattern" message="Email is invalid" />
									<DxAsyncRule :validation-callback="emailValidation" message="Email already exists." />
								</DxValidator>
							</DxTextBox>
						</DxField>
					</div>
					<div class="col-6">
						<DxField label="Start Date" :required="true">
							<DxDateBox v-model="user.startDate" placeholder="Select Start Date"
								display-format="MM/dd/yyyy"
								invalid-date-message="The date must have the following format: MM/dd/yyyy">
								<DxValidator>
									<DxRequiredRule message="Start Date is required" />
								</DxValidator>
							</DxDateBox>
						</DxField>
					</div>
					<div class="col-6">
						<DxField label="Termination Date">
							<DxDateBox v-model="user.terminationDate" placeholder="Select Termination Date"
								display-format="MM/dd/yyyy"
								invalid-date-message="The date must have the following format: MM/dd/yyyy">
								<DxValidator>
									<DxCustomRule :validation-callback="validateTerminationDate" message="Termination date should be after Start Date." />
								</DxValidator>
							</DxDateBox>
						</DxField>
					</div>
					<div class="col-6">
						<DxField label="Supervisor">
							<UsersLookup v-model="user.supervisorId" @object-changed="user.supervisor = $event"
								:disabled-items="[user.id]" :show-clear-button="true" />
						</DxField>
					</div>
					<div class="col-6">
						<DxField label="Group" :required="true">
							<Lookup v-model="user.groupId" :api="`api/users/permissionGroups`" :required="true"
								display-expr="displayName" value-expr="name" placeholder="Select Group" />
						</DxField>
					</div>
				</div>
				<div class="row" v-if="isEditing">
					<div class="col-6">
						<DxField label="Address 1" :required="true">
							<DxTextBox v-model="user.userAddress.street" placeholder="Enter Address 1" mode="text">
								<DxValidator>
									<DxRequiredRule message="Address 1 is required" />
								</DxValidator>
							</DxTextBox>
						</DxField>
					</div>
					<div class="col-6">
						<DxField label="Address 2">
							<DxTextBox v-model="user.userAddress.street2" placeholder="Enter Address 2" mode="text" />
						</DxField>
					</div>
					<div class="col-6">
						<DxField label="City" :required="true">
							<DxTextBox v-model="user.userAddress.city" placeholder="Enter City" mode="text">
								<DxValidator>
									<DxRequiredRule message="City is required" />
								</DxValidator>
							</DxTextBox>
						</DxField>
					</div>
					<div class="col-6">
						<DxField label="State" :required="true">
							<StateLookup v-model="user.userAddress.state" />
						</DxField>
					</div>
					<div class="col-6">
						<DxField label="Zip" :required="true">
							<DxTextBox v-model="user.userAddress.zip" :required="true"
								placeholder="Enter 5 digit Zip Code" mode="text" :max-length="5">
								<DxValidator>
									<DxRequiredRule message="Zip is required" />
								</DxValidator>
							</DxTextBox>
						</DxField>
					</div>
					<div class="col-6">
						<DxField label="Date of Birth">
							<DxDateBox v-model="user.dateOfBirth" placeholder="Select DOB"
								display-format="MM/dd/yyyy" :min="minDate" :max="now"
								invalid-date-message="The date must have the following format: MM/dd/yyyy">
								<DxValidator>
									<DxRequiredRule message="Birth Date is required" />
								</DxValidator>
							</DxDateBox>
						</DxField>
					</div>
				</div>
				<div class="row">
					<div class="col-6">
						<DxField label="Locked Out">
							<div class="text-left pt-2">
								<DxSwitch switched-on-text="Yes" switched-off-text="No" v-model="user.isLocked" />
							</div>
						</DxField>
					</div>
				</div>
				<div v-if="isEditing">
					<DxDataGrid id="userFamilyInfoGrid" ref="userFamilyInfoGridRef"
						:data-source="userFamilyInfos" :show-borders="true" key-expr="id"
						@row-inserting="onInsertUserFamilyInfo" @row-updating="onUpdateUserFamilyInfo"
						@row-removing="onRemoveUserFamilyInfo" :remote-operations="true"
						style="margin-bottom: 20px">
						<DxToolbar>
							<DxItem location="before" template="headingTemplate" />
							<DxItem location="after" name="addRowButton" />
						</DxToolbar>
						<DxEditing :allow-updating="true" :allow-deleting="true" :allow-adding="true"
							mode="row" />
						<DxColumn data-field="name" caption="Name" />
						<DxColumn data-field="relationship" caption="Relationship" />
						<DxColumn data-field="dateOfBirth" caption="Date Of Birth" data-type="date"
							edit-cell-template="datebox" />
						<DxColumn data-field="phone" caption="Phone" />
						<DxColumn data-field="email" caption="Email">
							<DxRequiredRule />
							<DxPatternRule :pattern="emailPattern" message="Email is invalid" />
						</DxColumn>
						<DxColumn data-field="notes" caption="Notes" />
						<DxColumn data-field="isEmergencyContact" caption="Emergency Contact"
							edit-cell-template="checkbox" />
						<template #headingTemplate>
							<strong>User Family Info</strong>
						</template>
						<template #checkbox="{ data: cellInfo }">
							<DxCheckBox :value="cellInfo.value !== null && cellInfo.value !== undefined ? cellInfo.value : false" :on-value-changed="(c) => cellInfo.setValue(c.value)" :data-source="userFamilyInfos" />
						</template>
						<template #datebox="{ data: cellInfo }">
							<DxDateBox :value="cellInfo.value" :on-value-changed="(date) => cellInfo.setValue(formatDate(date))" :data-source="userFamilyInfos" />
						</template>
					</DxDataGrid>
				</div>
				<DxField v-if="hasPermission(constants.PERMISSION_UPDATE_USER_SETTINGS)">
					<DxButton type="default" text="Save" :use-submit-behavior="true" />
				</DxField>
			</DxValidationGroup>
		</form>
	</div>
</template>

<script>
import DxValidationGroup from "devextreme-vue/validation-group";
import DxCheckBox from "devextreme-vue/check-box";
import {
	DxValidator,
	DxRequiredRule,
	DxCustomRule,
	DxAsyncRule,
	DxPatternRule,
} from "devextreme-vue/validator";
import {
	DxDataGrid,
	DxColumn,
	DxEditing,
	DxToolbar,
	DxItem,
} from "devextreme-vue/data-grid";
import DxTextArea from "devextreme-vue/text-area";
import DxTextBox from "devextreme-vue/text-box";
import DxDateBox from "devextreme-vue/date-box";
import DxButton from "devextreme-vue/button";
import { DxSwitch } from "devextreme-vue/switch";
import { AxiosWrapper } from "../../mixins";
import { Lookup, UsersLookup, StateLookup } from "../../components/lookups";
import DxSelectBox from 'devextreme-vue/select-box';
import { emailRegex } from "../../mixins/Validations";

export default {
	name: "PersonalForm",
	props: ["editUser"],
	components: {
		DxValidationGroup,
		DxValidator,
		DxRequiredRule,
		DxCustomRule,
		DxAsyncRule,
		DxTextBox,
		DxDateBox,
		DxButton,
		DxSwitch,
		DxTextArea,
		Lookup,
		UsersLookup,
		StateLookup,
		DxDataGrid,
		DxColumn,
		DxEditing,
		DxCheckBox,
		DxToolbar,
		DxItem,
		DxPatternRule,
		DxSelectBox
	},
	mixins: [AxiosWrapper],
	data() {
		return {
			tab: "Personal",
			saveRequest: null,
			responseError: "",
			user: this.editUser,
			userFamilyInfo: {
				name: null,
				relationship: null,
				dateOfBirth: null,
				phone: null,
				email: null,
				notes: null,
			},
			userFamilyInfos: [],
			minDate: new Date(1900, 0, 1),
			now: Date(),
			emailPattern: emailRegex,
		};
	},
	created() {
		this.user = this.cleanSource(this.editUser);
		if (this.user.id) {
			if (!this.user.userAddress) {
				this.user.userAddress = {};
				this.user.userAddress.street = "";
				this.user.userAddress.street2 = "";
				this.user.userAddress.city = "";
				(this.user.userAddress.state = ""),
					(this.user.userAddress.zip = "");
			}
			if (this.user.departmentLevel) {
				this.user.department = this.user.departmentLevel.department;
				this.user.departmentLevel = null;
			} else {
				this.user.departmentLevelId = null;
				this.user.department = { id: null };
			}
		} else {
			// this.user.departmentLevelId = null;
			// this.user.department = { id: null };
		}
	},
	methods: {
		show() {
			this.$refs.userPopup.show();
		},
		hide() {
			this.$refs.userPopup.hide();
			this.$emit("hidden");
		},
		emailValidation(params) {
			return new Promise((resolve) => {
				let id = this.user.id ? this.user.id : "";
				this.get(`api/users/email-validation?id=${id}&email=${params.value}`).then((response) => {
					resolve(response.data);
				});
			});
		},
		async submit(e) {
			e.preventDefault();
			this.saveRequest = true
			try {
				let postUser = !this.user.id ? this.getNewUserParam() : this.user
				const response = await this[this.submitMethod](this.submitUrl, postUser);
				if (this.user.id)
				{
					window.showSuccessToast(`${this.user.firstName} updated successfully.`,"User Updated");
				}
				else{
					window.showSuccessToast(`${this.user.firstName} added successfully.`,"User Added");
				}
				this.$emit("saved", response.data);
				this.$emit("input", null);
			}
			finally {
				this.saveRequest = null;
			}
		},
		getNewUserParam(){
			return {
				"firstName": this.user.firstName,
				"lastName": this.user.lastName,
				"email": this.user.email,
				"groupId": this.user.groupId,
				"isLocked": this.user.isLocked,
				"startDate": this.user.startDate,
				"terminationDate": this.user.terminationDate,
				"supervisorId": this.user.supervisor.id || null
			}
		},
		validateTerminationDate() {
			if (this.user.terminationDate)
				return this.compareDate(
					this.user.terminationDate,
					">",
					this.user.startDate
				);
			else return true;
		},
		async getUserFamilyInfo(id) {
			try {
				const response = await this.get(
					"api/userfamilyinfos/" + id + "/user"
				);
				this.userFamilyInfos = response.data;
			} catch (e) {
				console.log(e);
			}
		},
		async refreshUserFamilyInfo(id) {
			try {
				const response = await this.get(
					"api/userfamilyinfos/" + id + "/user"
				);
				this.$set(this, "userFamilyInfos", response.data);
			} catch (e) {
				console.log(e);
			}
		},
		onInsertUserFamilyInfo(e) {
			if (
				this.countEmergencyContacts() >= 2 &&
				e.data.isEmergencyContact
			) {
				e.cancel = true;
				window.showToast({
					text: "User cannot have more than two Emergency Contacts set.",
					title: "Emergency Contacts",
				});
			} else {
				let payload = {
					userId: this.user.id,
					name: e.data.name,
					relationship: e.data.relationship,
					dateOfBirth: e.data.dateOfBirth,
					phone: e.data.phone,
					email: e.data.email,
					notes: e.data.notes,
					isEmergencyContact: Object.prototype.hasOwnProperty.call(
						e.data,
						"isEmergencyContact"
					)
						? e.data.isEmergencyContact
						: false,
				};

				this.post("api/userfamilyinfos/insert", payload)
					.then(() => {
						window.showSuccessToast("User Family Info added.");
						this.$emit("user-updated", this.user);
					})
					.catch((e) => {
						window.showToast({
							text: e.message,
							title: "Alert",
							color: "danger",
						});
					});
				//this.$refs['userFamilyInfoGridRef'].instance.refresh()
			}
		},
		onUpdateUserFamilyInfo(e) {
			if (
				this.countEmergencyContacts() >= 2 &&
				e.newData.isEmergencyContact
			) {
				e.cancel = true;
				window.showToast({
					text: "User cannot have more than two Emergency Contacts set.",
					title: "Emergency Contacts",
				});
			} else {
				let payload = { ...e.oldData, ...e.newData };
				if (
					Object.prototype.hasOwnProperty.call(
						payload,
						"isEmergencyContact"
					)
				) {
					// eslint-disable-next-line no-self-assign
					payload.isEmergencyContact = payload.isEmergencyContact;
				} else {
					payload.isEmergencyContact = false;
				}
				payload.userId = this.user.id;

				this.put("api/userfamilyinfos/update", payload)
					.then(() => {
						window.showSuccessToast("User Family Info updated.");
						this.$emit("user-updated", this.user);
					})
					.catch((e) => {
						window.showToast({
							text: e.message,
							title: "Alert",
							color: "danger",
						});
					});
			}
		},
		onRemoveUserFamilyInfo(e) {
			this.delete(`api/userfamilyinfos/${e.key}`)
				.then(() => {
					window.showSuccessToast("User Family Info deleted.");
					this.$emit("user-updated", this.user);
				})
				.catch((e) => {
					window.showToast({
						text: e.message,
						title: "Alert",
						color: "danger",
					});
				});
		},
		formatDate(date) {
			return date ? new Date(date.value) : null;
		},
		countEmergencyContacts() {
			return this.userFamilyInfos.filter(
				(info) => info.isEmergencyContact
			).length;
		},
	},
	computed: {
		submitMethod() {
			return this.user.id ? "put" : "post";
		},
		submitUrl() {
			return "/api/users/" + (this.user.id ? this.user.id : "create");
		},
		title() {
			return (this.user && this.user.id ? "Edit" : "Add") + " User";
		},
		isEditing() {
			return Boolean(this.user.id);
		},
	},
	mounted() {
		this.responseError = "";
		if (this.isEditing) {
			this.getUserFamilyInfo(this.user.id);
		}
	},
};
</script>
<style>
.flex-column.nav {
	background: #f7f7f7;
	border: 1px solid #ddd;
}

.nav .nav-item {
	line-height: 1.8;
}

a.nav-link.active {
	background: #fff;
}

a.nav-link {
	color: #333;
}
</style>
