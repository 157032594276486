<template>
    <Popup ref="data-restriction-popup" title="Restrict User Report Data" width="35%">
        <form @submit="submit($event)">
            <DxValidationGroup v-if="!apiRequest">
                <DxField label="Restrict Data">
                    <DxSelectBox
                        v-model="permission.reportDataRestriction"
                        :items="restrictions"
                        display-expr="display"
                        value-expr="value"
                        @value-changed="permission.restrictedId = []">
                        <DxValidator> <DxRequiredRule message="Type is required"/> </DxValidator>
                    </DxSelectBox>
                </DxField>
                <div>
                    <DxField label="Departments" v-if="permission.reportDataRestriction == 'CustomDepartments'">
                        <DepartmentsLookup
                            v-model="permission.restrictedId"
                            :multiple="true"
                            :required="true" />
                    </DxField>
                    <DxField label="Users" v-if="permission.reportDataRestriction == 'CustomUsers'">
                        <UsersLookup
                            v-model="permission.restrictedId"
                            :multiple="true"
                            :required="true" />
                    </DxField>
                </div>
                <DxField class="text-right mb-0">
                    <DxButton text="Submit" type="default" class="ml-2" :use-submit-behavior="true" />
                    <DxButton text="Cancel" @click="$refs['data-restriction-popup'].hide()" />
                </DxField>
            </DxValidationGroup>
            <spinner v-else />
        </form>
    </Popup>
</template>

<script>
import { AxiosWrapper } from '../../../mixins'
import DxValidationGroup from 'devextreme-vue/validation-group';
import DxButton from 'devextreme-vue/button';
import { DxValidator, DxRequiredRule } from 'devextreme-vue/validator';
import DxSelectBox from 'devextreme-vue/select-box';
import { DepartmentsLookup, UsersLookup } from '../../../components/lookups'

export default {
    name: 'DataRestrictionPermission',
    props: ['type'],
    mixins:[AxiosWrapper],
    components: {
        DxValidationGroup,
        DxSelectBox, DxButton,
        DxValidator, DxRequiredRule,
        DepartmentsLookup, UsersLookup
    },
    data(){
        return {
            apiRequest: false,
            permission:{},
            role: {},
            restrictions:[]
        }
    },
    methods:{
        show(userId, permissionId){
            this.$refs['data-restriction-popup'].show()
            this.apiRequest = true
            this.get(`api/users/${userId}/permission/${permissionId}`).then(response =>{
                this.restrictions = [
                    {display: 'Off', value: 'Off'},
                    {display: 'User Data', value: 'MyData'},
                    {display: `User Department (${response.data.department})`, value: 'MyDepartment'},
                    {display: 'User Hierarchy', value: 'MyHierarchy'},
                    {display: 'Custom Departments', value: 'CustomDepartments'},
                    {display: 'Custom Users', value: 'CustomUsers'}
                ]
                this.permission = response.data.permission
                if(this.permission.restrictedId){
                    this.permission.restrictedId = this.permission.restrictedId.map(s => { return { id: s } })
                }
                else{
                    this.permission.restrictedId = []
                }
                this.apiRequest = false
            })
        },
        showForRole(role){
            this.permission = this.cleanSource(role)
            this.$refs['data-restriction-popup'].show()
            this.restrictions = [
                {display: 'Off', value: 'Off'},
                {display: 'User Data', value: 'MyData'},
                {display: `User Department`, value: 'MyDepartment'},
                {display: 'User Hierarchy', value: 'MyHierarchy'},
                {display: 'Custom Departments', value: 'CustomDepartments'},
                {display: 'Custom Users', value: 'CustomUsers'}
            ]
            if(this.permission.restrictedId){
                this.permission.restrictedId = this.permission.restrictedId.map(s => { return { id: s } })
            }
            else{
                this.permission.restrictedId = []
            }
        },
        submit(e){
            e.preventDefault()
            this.apiRequest = true
            if(this.permission.restrictedId){
                this.permission.restrictedId = this.permission.restrictedId.map(s => s.id)
            }
            if(this.type == 'UserPermission'){
                this.post('api/users/permission', this.permission).then(() => {
                    window.showSuccessToast('Users data restriction applied.')
                    this.$refs['data-restriction-popup'].hide()
                    this.$emit('update', this.permission.reportDataRestriction)
                }).finally(() => {
                    this.apiRequest = false
                })
            }
            else if(this.type == 'Role'){
                this.post('api/settings/roles/update', this.permission).then(() => {
                    window.showSuccessToast('Group restriction applied.')
                    this.$refs['data-restriction-popup'].hide()
                    this.$emit('update', this.permission)
                }).finally(() => {
                    this.apiRequest = false
                })
            }
        }
    }
}
</script>
