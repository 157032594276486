<template>
    <span class="badge" :class="{
        'badge-success': value == 'External',
        'badge-info': value == 'InternalCharge',
        'badge-warning': value == 'Internal',
        'badge-secondary': value == 'None'}">
        {{ formatLabel(value) }}
    </span>
</template>

<script>
import { TextFormatter } from '../../mixins'
export default {
    mixins: [TextFormatter],
    props: ['value']
}
</script>
