<template>
<CheckPermission :value="constants.PERMISSION_RESOURCING_REQUEST" :access-denied-msg="true">
    <div class="card h-100">
        <div class="card-body">
            <spinner v-if="apiRequest" />
            <DxDataGrid v-else
                class="header-table resourcing-table"
                :key="tableKey"
                :data-source="resourceRequests"
                :show-row-lines="true"
                :show-column-lines="false"
                :show-borders="true"
                :hover-state-enabled="true"
                :row-alternation-enabled="true">

                <DxColumn caption="Project" data-field="service.project.name" />
                <DxColumn caption="Requested Allocation" :calculate-cell-value="getRowObj" cell-template="hoursTemplate" />
                <template #hoursTemplate="{ data }">
                    <div>
                        <span v-if="data.value.hoursAMAP">AMAP</span>
                        <span v-else>{{data.value.hours}}</span>
                    </div>
                </template>
                <DxColumn css-class="wrap-text" caption="Requested Resources" :calculate-cell-value="getRowObj" cell-template="usersTemplate" />
                <template #usersTemplate="{ data }">
                    <div>{{data.value.users.map(s=>s.fullName).join(', ')}}</div>
                </template>
                <DxColumn css-class="wrap-text" caption="Notes" data-field="notes" />
                <DxColumn caption="Requested By" :calculate-cell-value="getRowObj" cell-template="requestedByTemplate" />
                <template #requestedByTemplate="{ data }">
                    <span>
                        {{`${isNotNullOrEmpty(data.value.requestedBy) ? data.value.requestedBy.lastName: ''},
                        ${isNotNullOrEmpty(data.value.requestedBy) ? data.value.requestedBy.firstName: ''}` }}
                    </span>
                </template>
                <DxColumn alignment="center" caption="Actions" :calculate-cell-value="getRowObj" cell-template="actionsTemplate" />
                <template #actionsTemplate="{ data }">
                    <div>
                        <DxButton v-if="data.value.requestedBy.id == Current_User_ID"
                            icon="edit" @click="edit(data.value)" styling-mode="text" />
                        <DxButton v-if="data.value.requestedBy.id == Current_User_ID && data.value.hoursAllocated == 0"
                            icon="trash" @click="confirmDelete(data.value)" styling-mode="text" />
                    </div>
                </template>


                <DxToolbar>
                    <DxItem location="before" template="weekTemplate" />
                    <DxItem location="after" template="addTemplate" />
                </DxToolbar>
                <template #weekTemplate>
                    <DxSelectBox
                        v-model="currentWeek"
                        :data-source="weeks"
                        placeholder="Week"
                        @value-changed="load">
                    </DxSelectBox>
                </template>
                <template #addTemplate>
                    <DxButton text="New Request" @click="addRequest" />
                </template>
            </DxDataGrid>
            <ResourceRequestPopup
                :key="'resource-request-'+popupKey"
                ref="requestPopup"
                :weeks="weeks"
                :requests="resourceRequests"
                @update-resource-request="load"
                @hidden="popupKey++" />
        </div>
    </div>
</CheckPermission>
</template>

<script>
import {
  DxDataGrid,
  DxColumn,
  DxToolbar,
  DxItem
} from 'devextreme-vue/data-grid';
import { DxButton } from 'devextreme-vue/button'
import ResourceRequestPopup from './ResourceRequestPopup'
import { AxiosWrapper } from '@/mixins'
import DxSelectBox from 'devextreme-vue/select-box';
import moment from 'moment'

export default {
    mixins:[AxiosWrapper],
    components: {
        DxDataGrid,
        DxColumn,
        DxToolbar,
        DxItem,
        DxButton,
        ResourceRequestPopup,
        DxSelectBox
    },
    data(){
        return {
            apiRequest: false,
            popupLoader: false,
            resourceRequests: [],
            currentWeek:null,
            popupKey:0,
            tableKey:0,
        }
    },
    created(){
        this.currentWeek = this.weeks[0]
        this.load()
    },
    methods:{
        load(){
            this.apiRequest = true
            this.get('api/resourcing/requests?week='+this.currentWeek).then(response =>{
                this.resourceRequests = response.data
                this.tableKey++
            }).finally(final =>{
                this.apiRequest = false
            })
        },
        addRequest(){
            this.$refs.requestPopup.show();
        },
        edit(resourceRequest){
            this.$refs.requestPopup.show(resourceRequest);
        },
        confirmDelete(resourceRequest){
            window.showConfirmModal({
                subTitle: "Deleting is irreversable",
                confirmButtonText: "Delete",
                showLoader: true,
                mode:'danger',
                onConfirm: async () => { await this.deleteResourceRequest(resourceRequest.id) }
            })
        },
        async deleteResourceRequest(id){
            try{
                const response = await this.delete('api/resourcing/requests/' + id)
                window.showSuccessToast('Resource request deleted successfully.')
                this.resourceRequests = this.resourceRequests.filter(s => s.id != id)
                this.tableKey++
            }
            catch(e){
                window.showErrorToast(e.message)
            }
        }
    },
    computed:{
        weeks(){
            return this.nextWeeks(12)
        }
    }
}
</script>
<style>
.resourcing-table .dx-datagrid-header-panel .dx-toolbar{
    padding: 0 0 10px 0;
}
</style>
