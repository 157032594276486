<template>
    <span>
        <DxTagBox
            v-if="multiple"
            v-model="selectedDepartmentsMultiple"
            :search-enabled="true"
            displayExpr="name"
            value-expr="id"
            :data-source="departments"
            placeholder="Select Departments"
            :disabled="disabled"
            :read-only="readOnly"
            :key="key"
            :label="label"
            :label-mode="labelMode"
            :show-clear-button="showClearButton"
            :multiline="multiline"
            @value-changed="onValueChanged">
            <DxValidator v-if="required"> <DxRequiredRule message="Departments are required"/> </DxValidator>
        </DxTagBox>
        <DxSelectBox
            v-else
            v-model="selectedDepartmentSingle"
            :search-enabled="true"
            displayExpr="name"
            value-expr="id"
            :data-source="departments"
            placeholder="Select Departments"
            :disabled="disabled"
            :read-only="readOnly"
            :show-clear-button="showClearButton"
            :label="label"
            :label-mode="labelMode"
            @value-changed="onValueChanged">
            <DxValidator v-if="required"> <DxRequiredRule message="Department is required"/> </DxValidator>
        </DxSelectBox>
    </span>
</template>

<script>
import DxTagBox from 'devextreme-vue/tag-box';
import DxSelectBox from 'devextreme-vue/select-box';
import { AxiosWrapper } from '../../mixins/AxiosWrapper'
import { DxValidator, DxRequiredRule } from 'devextreme-vue/validator';
export default {
    name:'DepartmentsLookup',
    props:{
        'value': [String, Array],
        'required':{
            type: Boolean,
            default: false
        },
        'disabled':{
            type: Boolean,
            default: false
        },
        'multiline':{
            type: Boolean,
            default: false
        },
        'read-only':{
            type: Boolean,
            default: false
        },
        'multiple':{
            type: Boolean,
            default: false
        },
        'all':{
            type: Boolean,
            default: false
        },
        'show-clear-button':{
            type: Boolean,
            default: false
        },
        'label':{
            type: String,
            default: 'Departments'
        },
        'label-mode':{
            type: String,
            default: 'hidden'
        }
    },
    components:{ DxTagBox, DxSelectBox, DxValidator, DxRequiredRule },
    mixins:[ AxiosWrapper ],
    data(){
        return {
            key: this.generateUUID(),
            selectedDepartments: [],
            departmentsRaw:[],
            departments: {
                loadMode: 'raw',
                load: () => {
                    const promise = new Promise((resolve) => {
                        this.get('api/settings/departments').then(response =>{
                            this.departmentsRaw = response.data
                            if(!this.all){
                                resolve(this.departmentsRaw);
                            }
                            else{
                                let list = [{id: 'all', name:'All'}].concat(this.departmentsRaw)
                                resolve(list);
                            }
                        })
                    });
                    return promise;
                },
            },
        }
    },
    created(){
        if(this.multiple){
            this.selectedDepartmentsMultiple = this.value.map(s => typeof s == 'string' ? s : s.id) || []
        }
        else{
            this.selectedDepartmentSingle = this.value || ''
        }
    },
    methods:{
        onValueChanged(e){
            if(this.multiple){
                if(this.all && e.value.includes('all')){
                    this.selectedDepartmentsMultiple = this.departmentsRaw.map(s=>s.id)
                    this.key = this.generateUUID()
                    e.value = this.selectedDepartmentsMultiple
                    this.$emit('input', this.departmentsRaw.filter(s => e.value.includes(s.id)))
                    this.$emit('value-changed', e)
                    return;
                }
                this.$emit('input', this.departmentsRaw.filter(s => e.value.includes(s.id)))
            }
            else{
                this.$emit('input', e.value)
                this.$emit('object-changed', this.departmentsRaw.find(s => s.id == e.value))
            }
            this.$emit('value-changed', e)
        },
    }
}
</script>
