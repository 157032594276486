import { render, staticRenderFns } from "./CardPriorityButton.vue?vue&type=template&id=22de9089"
import script from "./CardPriorityButton.vue?vue&type=script&lang=js"
export * from "./CardPriorityButton.vue?vue&type=script&lang=js"
import style0 from "./CardPriorityButton.vue?vue&type=style&index=0&id=22de9089&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports