<template>
	<Popup ref="leaveApprovalPopup" :title="`Leave Approval for ${leaveApproval.user.fullName}`" width="40%">
		<spinner v-if="apiRequest" />
		<div v-else>
			<p>The following leave is requested by {{leaveApproval.user.fullName}}.</p>
			<div>
				<div><strong>Type:</strong> {{leaveApproval.type}}</div>
				<div><strong>Start Date:</strong> {{leaveApproval.startDate | timeOnly}} {{leaveApproval.startDate | shortDate}}</div>
				<div><strong>End Date:</strong> {{leaveApproval.endDate | timeOnly}} {{leaveApproval.endDate | shortDate}}</div>
				<div><strong>Notes:</strong> {{leaveApproval.notes}}</div>
			</div>
			<div class="mt-3">
				<p class="mb-1">Before this leave {{leaveApproval.user.firstName}}'s {{leaveApproval.type}}'s balance is {{balanceBefore | oneDecimal}}</p>
				<p class="mb-1">After this leave {{leaveApproval.user.firstName}}'s {{leaveApproval.type}}'s balance will be {{balanceAfter | oneDecimal}}</p>
			</div>
			<form class="mt-3">
				<DxTextArea
					v-model="notes"
					placeholder="Approver notes..."
					:height="100">
				</DxTextArea>
				<div class="alert alert-danger mt-2" v-if="notesError">Notes are required.</div>
				<div class="mt-3 d-flex align-items-center justify-content-between">
					<DxButton text="Deny" type="danger" @click="deny()" />
					<DxButton text="Approve" type="success" @click="accept('Approved')" />
				</div>
			</form>
		</div>
	</Popup>
</template>


<script>
import { AxiosWrapper } from '@/mixins'
import DxTextArea from 'devextreme-vue/text-area';
import DxButton from 'devextreme-vue/button';
export default {
	mixins:[AxiosWrapper],
    components:{DxButton, DxTextArea},
	data(){
        return {
            leaveApproval: {user:{}},
            balanceBefore: 0,
            balanceAfter: 0,
            notes: '',
            apiRequest: false,
            notesError: false,
			subordinates: [],
        }
    },
	methods:{
		show(leave){
			this.apiRequest = true
			this.notes = ''
			this.notesError = false
			this.$refs.leaveApprovalPopup.show()
			this.get('api/users/leaves/' + leave.id).then(response => {
				this.leaveApproval = response.data
				let type = this.leaveApproval.type.toLowerCase()
				this.balanceBefore = response.data.user.leaveBank[type]
				this.balanceAfter = Number(response.data.user.leaveBank[type]) + Number(response.data.debit) - Number(response.data.credit)
			}).finally(() => {
				this.apiRequest = false
			})
        },
		deny(){
			if(this.notes.length == 0){
				this.notesError = true
				setTimeout(() => { this.notesError = false }, 3000);
				return;
			}
			this.accept('Denied')
		},
		accept(status){
			this.leaveApproval.status = status
			this.leaveApproval.notes = this.notes
			this.leaveApproval.authorizedBy = { id: this.Current_User_ID };
			this.apiRequest = true
			this.post(`api/users/leaves/update-status`, this.leaveApproval).finally(final => {
				this.apiRequest = false
				if(status == 'Approved') window.showSuccessToast('You have approved the leave request.')
				else if(status == 'Denied') window.showSuccessToast('You have denied the leave request.')
			}).finally(() => {
				this.$refs.leaveApprovalPopup.hide()
				this.$emit('authorized')
			})
		}
	}
}
</script>
