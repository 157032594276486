<template>
	<DxSelectBox
		ref="selectBox"
		v-model="priority"
		:items="priorities"
		display-expr="text"
		value-expr="value"
		drop-down-button-template="customIcon"
		:class="`priority-select ${currentPriority(priority).color}`"
		@value-changed="valueChanged">
		<template #customIcon>
			<i :class="`dx-icon dx-icon-${currentPriority(priority).icon}`"></i>
		</template>
	</DxSelectBox>
</template>

<script>
import { CardPriority } from '@/_helpers/constants'

export default{
	props:['value', 'disabled'],
	data(){
		return {
			priorities: CardPriority.Get()
		}
	},
	computed:{
		currentPriority(){
			return (v) => {
				let result = this.priorities.find(s => s.value == v)
				return result
			}
		},
		priority:{
			get(){
				return this.value
			},
			set(v){
				this.$emit('input', v)
			}
		}
	},
	methods:{
		valueChanged(v){
			this.$emit('value-changed', v)
			let priority = this.currentPriority(v.value)
      		const inputElement = v.component._input().get(0);
			inputElement.style.color = priority.color
			let i = v.element.querySelector('i')
			if(i){
				i.classList.remove(...i.classList);
				i.classList.add('dx-icon', 'dx-button-content-custom', 'dx-icon-' + priority.icon)
				i.style.color = priority.color
			}
		}
	}
}
</script>

<style lang="scss">
.priority-dropdown-button .dx-icon.bi{
    font-size: 15px;
}
.priority-select.red{
	.dx-texteditor-input-container input{
		color: red
	}
	.dx-texteditor-buttons-container i{
		color: red
	}
}
.priority-select.green{
	.dx-texteditor-input-container input{
		color: green
	}
	.dx-texteditor-buttons-container i{
		color: green
	}
}
.priority-select.orange{
	.dx-texteditor-input-container input{
		color: orange
	}
	.dx-texteditor-buttons-container i{
		color: orange
	}
}
.priority-select .dx-dropdowneditor-button .dx-icon{
	flex: 1 1 auto;
    height: 100%;
    max-height: 100%;
    display: inherit;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
    line-height: 1.35715;
}
</style>
