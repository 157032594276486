<template>
	<DxDataGrid key-expr="id" ref="userLeavesGrid" :height="height" :show-row-lines="true" :show-column-lines="true"
		:show-borders="true" :hover-state-enabled="true" :row-alternation-enabled="true" :data-source="userLeaves"
		:on-cell-prepared="leaveTableCellPrepared" :on-cell-click="onLeaveTableCellClick" :sorting="{
			ascendingText: 'Ascending',
			clearText: 'Clear sorting',
			descendingText: 'Dscending',
			mode: 'none',
			showSortIndexes: true,
		}">
		<DxGrouping :auto-expand-all="true" />
		<DxScrolling />
		<DxColumn group-cell-template="groupTypeTemplate" alignment="center" data-field="type" caption="Type"
			:group-index="0" />
		<DxColumn width="12%" alignment="center" data-field="timestamp" caption="Submitted" cell-template="dateTemplate"
			data-type="date" />
		<DxColumn width="12%" alignment="center" data-field="status" cell-template="statusTemplate"
			:calculate-cell-value="getRowObj" css-class="position-relative" />
		<DxColumn width="12%" alignment="center" data-field="startDate" cell-template="dateTemplate" data-type="date" />
		<DxColumn width="12%" alignment="center" data-field="endDate" cell-template="dateTemplate" data-type="date" />
		<DxColumn width="10%" alignment="center" data-field="credit" data-type="number"
			:format="{ type: 'fixedPoint', precision: 2 }" />
		<DxColumn width="10%" alignment="center" data-field="debit" data-type="number"
			:format="{ type: 'fixedPoint', precision: 2 }" />
		<DxColumn width="10%" alignment="center" data-field="balance" data-type="number"
			:format="{ type: 'fixedPoint', precision: 2 }" />
		<DxColumn css-class="wrap-text" data-field="notes" cell-template="notesTemplate" />
		<template #notesTemplate="{ data }">
			<div class="leave-notes">
				<span v-html="data.value"></span>
			</div>
		</template>
		<template #statusTemplate="{ data }">
			<div class="vertical-center pointer">
				<span :id="'status' + data.value.id" class="text-white">{{data.value.status}}</span>
				<b-popover :target="'status' + data.value.id" :triggers="'hover'" :placement="'bottom'">
					<div v-if="data.value.status == UserLeaveStatus.Pending && data.value.user.supervisor">
						<div>
							<strong>Supervisor: </strong>{{ data.value.user.supervisor.userName }}
						</div>
						<div v-if="allowLeaveToWithdraw(data.value)" class="text-right mt-3">
							<DxButton type="warning" text="Withdraw" @click="updateStatus(data.value, 'Withdrawn', $event)" />
						</div>
					</div>
					<div v-if="data.value.status == UserLeaveStatus.System">
						<div>
							<strong>Policy: </strong>{{ leaveBank.leavePolicy.name }}
						</div>
						<div v-if="data.value.authorizedBy">
							<strong>Authorized By: </strong>{{ data.value.authorizedBy.userName }}
						</div>
					</div>
					<div v-if="data.value.status == UserLeaveStatus.Approved">
						<div v-if="data.value.authorizedBy">
							<strong>Approved By: </strong>{{ data.value.authorizedBy.userName }}
						</div>
						<div>
							<strong>Time: </strong>{{ data.value.authorizationTime | shortDate }}
						</div>
						<div v-if="allowLeaveToWithdraw(data.value)" class="text-right mt-3">
							<DxButton type="warning" text="Withdraw" @click="updateStatus(data.value, 'Withdrawn', $event)" />
						</div>
					</div>
					<div v-if="data.value.status == UserLeaveStatus.Denied">
						<div>
							<strong>Denied By: </strong>{{ data.value.authorizedBy.userName }}
						</div>
						<div>
							<strong>Time: </strong>{{ data.value.authorizationTime | shortDate }}
						</div>
					</div>
					<div v-if="data.value.status == UserLeaveStatus.Withdrawn">
						<div>
							<strong>Withdrawn By: </strong>{{ data.value.authorizedBy.userName }}
						</div>
						<div>
							<strong>Time: </strong>{{ data.value.authorizationTime | shortDate }}
						</div>
					</div>
				</b-popover>
			</div>
		</template>
		<template #dateTemplate="{ data }">
			<div class="text-center">
				<div>{{ data.value | shortDateLocal }}</div>
				<div>{{ data.value | timeOnlyLocal }}</div>
			</div>
		</template>
		<template #groupTypeTemplate="{ data }">
			<div>{{ data.displayValue }}</div>
		</template>
	</DxDataGrid>
</template>

<script>
import moment from "moment";
import {
	DxDataGrid,
	DxColumn,
	DxGrouping,
	DxScrolling,
} from "devextreme-vue/data-grid";
import { UserLeavesMixin } from "../../mixins/views";
import DxButton from "devextreme-vue/button";

export default {
	props: ["height", "userLeaves", "leaveBank", "actionableIn"],
	mixins: [UserLeavesMixin],
	components: { DxDataGrid, DxColumn, DxButton, DxGrouping, DxScrolling },
	methods: {
		allowLeaveToWithdraw(e) {
			let allowAccess = false;
			switch (this.actionableIn) {
				case "UserManagement":
					allowAccess = this.hasPermission(constants.PERMISSION_UPDATE_USER_SETTINGS)
					break;
				case "UserLeavesTab":
					allowAccess = this.isInFutureOrToday(e);
					break;
				default:
					allowAccess = false;
					break;
			}
			return allowAccess;
		},
		isInFutureOrToday(e) {
			let date = new Date(e.startDate);
			var today = new Date();
			// Set hours, minutes, seconds, and milliseconds to 0 for accurate comparison
			today.setHours(0, 0, 0, 0);
			date.setHours(0, 0, 0, 0);

			// Compare and return the parameter date with the current date
			return date >= today;
		},
		leaveTableCellPrepared(e) {
			if (e.rowType == "data" && e.column.dataField == "status") {
				let status = e.data.status;
				e.cellElement.classList.add("leave-status-" + status);
			} else if (e.rowType == "data" && e.column.dataField == "notes") {
				setTimeout(() => {
					e.element
						.querySelectorAll('[data-type="datetime"]')
						.forEach((element) => {
							let d = String(element.innerHTML).replace(
								" at",
								""
							);
							let momentDate = moment
								.utc(d)
								.local()
								.format("MM/DD/YYYY hh:mm a");
							element.innerHTML = momentDate;
						});
				}, 100);
			}
		},
		onLeaveTableCellClick(e) {
			if (e.rowType == "data" && e.column.dataField == "notes") {
				if (e.data.parentId) {
					e.component.navigateToRow(e.data.parentId);
					e.component.selectRows(e.data.parentId, false);
				}
			}
		},
		async updateStatus(userLeave, status) {
			userLeave.authorizedBy = { id: this.Current_User_ID };
			userLeave.status = status;
			await this.post(`api/users/leaves/update-status`, userLeave);
			this.$emit("refresh");
		},
	},
};
</script>
