<template>
    <DxPopover
        v-if="!warning.given"
        :height="125"
        :width="300"
        :hide-on-outside-click="false"
        :visible="warning.show"
        :target="warning.target"
        position="bottom"
        :shading="true"
        shading-color="rgba(0, 0, 0, 0.5)">
        <div class="warning">
            <div class="warning-note">Updating user's permission will change the user's group to Custom.</div>
            <div class="warning-actions">
                <DxButton
                    text="Cancel"
                    :styling-mode="'outlined'"
                    @click="cancel" />
                <DxButton
                    text="Okay"
                    :styling-mode="'contained'"
                    type="default"
                    @click="okay" />
            </div>
        </div>
    </DxPopover>
</template>

<script>
import { DxPopover } from 'devextreme-vue/popover';
import { DxButton } from 'devextreme-vue/button';

export default {
    name: 'RoleWarningPopover',
    props:['value'],
    components: {DxPopover, DxButton },
    computed:{
        warning: {
            get(){
                return this.value
            },
            set(v){
                this.$emit('input', v)
            }
        }
    },
    methods:{
        cancel(){
            this.warning.show = false;
            this.warning.given = false;
            this.warning.event.component.option('value', !this.warning.event.value)
        },
        okay(){
            this.warning.show = true;
            this.warning.given = true;
            this.$emit('selectPermission', this.warning.tempPerm, this.warning.event)
        }
    }
}
</script>
<style scoped>
.warning .warning-note{
	font-size: 0.90rem;
    font-weight: 600;
}
.warning .warning-actions{
	float: right;
	margin-top: 15px;
}
</style>
