<template>
	<CheckPermission :value="constants.PERMISSION_READ_GENERAL_SETTINGS" :access-denied-msg="true">
		<b-card class="h-100 overflow-auto" no-body>
			<b-card-header class="d-flex align-items-center justify-content-between">
				<strong>Service Types</strong>
				<DxButton icon="add" @click="addServiceType" v-if="hasPermission(constants.PERMISSION_UPDATE_GENERAL_SETTINGS)"/>
			</b-card-header>
			<spinner v-if="apiRequest" />
			<DxDataGrid
				v-else
				class="header-table"
				ref="gridRef"
				:data-source="serviceTypes"
				:show-row-lines="true"
				:show-column-lines="false"
				:show-borders="true"
				:hover-state-enabled="true"
				:row-alternation-enabled="true"
				:selection="{ mode: 'single' }">

				<DxColumn data-field="name" />
				<DxColumn data-field="abbreviation" cell-template="abbTemplate" />
				<template #abbTemplate="{ data }">
					<ServiceTypeBadge :value="data.data" />
				</template>
				<DxColumn data-field="allowedBillingTypes" caption="Allowed Billing Types" css-class="text-wrap" :calculate-display-value="v => v.allowedBillingTypes.map(a => formatLabel(a)).join(', ')" />
				<DxColumn data-field="isProjectRequired" caption="Project Required" data-type="boolean" cell-template="projectRequiredTemplate" />
				<template #projectRequiredTemplate="{ data }">
					<div>
						<i class="bi bi-check-circle-fill text-success" icon-pack="bootstrap" v-if="data.value"></i>
						<i class="bi bi-x-circle-fill text-danger" icon-pack="bootstrap" v-else></i>
					</div>
				</template>
				<DxColumn width="120px" caption="Actions" alignment="center" cell-template="actionsTemplate" v-if="hasPermission(constants.PERMISSION_UPDATE_GENERAL_SETTINGS)" />
				<template #actionsTemplate="{ data }">
					<div>
						<DxButton styling-mode="text" icon="edit" @click="edit(data.data)" />
						<DxButton styling-mode="text" icon="trash" @click="askDelete(data.data)" />
					</div>
				</template>
			</DxDataGrid>
			<Popup ref="serviceTypePopup" title="Service Type" :native-scroll="true">
				<form ref="serviceTypeForm" @submit="submit($event)" :key="serviceTypeFormKey">
					<spinner v-if="postRequest" />
					<DxValidationGroup name="serviceTypeFormValidationGroup" ref="serviceTypeFormValidationGroup" v-else>
						<DxField label="Name" :required="true">
							<DxTextBox v-model="serviceType.name">
								<DxValidator>
									<DxRequiredRule message="Name is required"/>
								</DxValidator>
							</DxTextBox>
						</DxField>
						<DxField label="Color" :required="true">
							<DxColorBox v-model="serviceType.color">
								<DxValidator>
									<DxRequiredRule message="Color is required"/>
								</DxValidator>
							</DxColorBox>
						</DxField>
						<DxField label="Allowed Billing Types" :required="true">
							<DxTagBox
								v-model="serviceType.allowedBillingTypes"
								:search-enabled="true"
								:items="allowedBillingTypes"
								display-expr="value"
								value-expr="name"
								placeholder="Select Billing Types"
								:multiline="true">
								<DxValidator> <DxRequiredRule message="Departments are required"/> </DxValidator>
							</DxTagBox>
						</DxField>
						<DxField label="Project Required">
							<DxCheckBox class="float-left" v-model="serviceType.isProjectRequired" />
						</DxField>
						<DxField>
							<DxButton class="float-right" text="Submit" type="default" :use-submit-behavior="true" />
						</DxField>
					</DxValidationGroup>
				</form>
			</Popup>
		</b-card>
	</CheckPermission>
</template>

<script>
import {
	DxDataGrid,
	DxColumn,
	DxPaging,
	DxEditing,
	DxToolbar,
	DxItem,
	DxTexts
} from 'devextreme-vue/data-grid';
import DxSpeedDialAction from 'devextreme-vue/speed-dial-action';
import { BillingType } from '../../../_helpers/constants.js'
import { AxiosWrapper, TextFormatter } from '@/mixins';
import DxTextBox from "devextreme-vue/text-box";
import DxValidationGroup from "devextreme-vue/validation-group";
import {
	DxValidator,
	DxRequiredRule,
	DxCustomRule,
} from "devextreme-vue/validator";
import DxSelectBox from "devextreme-vue/select-box";
import DxColorBox from 'devextreme-vue/color-box';
import { DxCheckBox } from "devextreme-vue/check-box";
import DxTagBox from 'devextreme-vue/tag-box';
import DxButton from "devextreme-vue/button";

const serviceType = () => {
	return {
		"id": "",
		"name": "",
		"allowedBillingTypes": [],
		"color": "",
		"isProjectRequired": true,
		"abbreviation": ""
	}
}

export default {
	components:{
		DxDataGrid,
		DxColumn,
		DxPaging,
		DxEditing,
		DxSpeedDialAction,
		DxToolbar,
		DxItem,
		DxTexts,
		DxTextBox,
		DxValidator,
		DxRequiredRule,
		DxCustomRule,
		DxSelectBox,
		DxValidationGroup,
		DxColorBox,
		DxCheckBox,
		DxTagBox,
		DxButton
	},
	mixins:[AxiosWrapper, TextFormatter],
	data() {
		return {
			serviceTypeFormKey: this.generateUniqueCode(),
			serviceType: serviceType(),
			serviceTypes: [],
			apiRequest: true,
			postRequest: false,
			allowedBillingTypes: this.objectToList(BillingType)
		};
	},
	mounted() {
		this.load()
	},
	methods: {
		load(){
			this.apiRequest = true
			this.get('api/serviceTypes/get').then(response => {
				this.serviceTypes = response.data
			}).finally(() => {
				this.apiRequest = false
			})
		},
		addServiceType() {
			this.serviceType = serviceType()
			this.serviceTypeFormKey = this.generateUniqueCode()
			this.$refs.serviceTypePopup.show()
		},
		askDelete(row) {
			window.showConfirmModal({
				confirmButtonText: "Delete",
				subTitle: "Deleting is irreversible",
				showLoader: true,
				mode: "danger",
				onConfirm: async () => {
					try {
						await this.delete('api/serviceTypes/' + row.id)
						window.showSuccessToast("Service Type deleted successfully");
						this.load()
					} catch (error) {
						window.showErrorToast(error.message);
					}
				},
			});
		},
		edit(row) {
			this.serviceType = this.cleanSource(row)
			this.serviceTypeFormKey = this.generateUniqueCode()
			this.$refs.serviceTypePopup.show()
		},
		submit(e){
			e.preventDefault()
			this.postRequest = true
			let endpoint = this.serviceType.id ? 'update' : 'add'
			this.post(`api/serviceTypes/${endpoint}`, this.serviceType).then(response => {
				this.$refs.serviceTypePopup.hide()
				this.load()
			}).finally(() => {
				this.postRequest = false
			})
		}
	},
};
</script>

