<template>
	<div class="mt-4">
		<div class="d-flex align-items-center justify-content-between mb-3">
			<h4>Resources</h4>
			<div class="d-flex align-items-center">
				<DxButton icon="refresh" class="mr-2 refresh-button" @click="$emit('refresh')" />
				<LookupMultiple
					v-if="searchByDepartment"
					v-model="resources"
					label-mode="static" label="User"
					style="width:300px"
					:api="`api/resourcing/departments/users?week=${currentWeek}&departments=${departmentIds.join(',')}`"
					display-expr="fullName" value-expr="id"
					:all="true" :show-clear-button="true"
					@value-changed="tableKey = generateUniqueCode()" />
			</div>
		</div>
		<div id="users-persp-table" :key="tableKey">
			<table class="table table-bordered text-center resources-table-header mb-0">
				<thead>
					<tr>
						<th class="text-left" width="22%"></th>
						<th width="13%">Allocation</th>
						<th width="13%">Monday {{weeklyDate(WeekDays.Monday)}}</th>
						<th width="13%">Tuesday {{weeklyDate(WeekDays.Tuesday)}}</th>
						<th width="13%">Wednesday {{weeklyDate(WeekDays.Wednesday)}}</th>
						<th width="13%">Thursday {{weeklyDate(WeekDays.Thursday)}}</th>
						<th width="13%">Friday {{weeklyDate(WeekDays.Friday)}}</th>
					</tr>
				</thead>
			</table>
			<div>
				<table class="table table-bordered text-center resources-table mb-2">
					<tbody :key="tableBodyKey">
						<EmptyRow v-if="resourceAllocations.length == 0"/>
						<div v-fragments v-for="allocation in resourceAllocations" :key="`allocation-${allocation.id}`">
							<tr :ref="`allocation-${allocation.id}`">
								<td
									width="22%"
									:class="dropZone(allocation)"
									:data-id="allocation.id"
									@drop="onDrop($event)"
									@dragenter.prevent
									@dragover.prevent
									@dragenter="dragEnter($event)"
									@dragleave="drageLeave($event)">
									<div class="d-flex align-items-center justify-content-between">
										<strong class="float-left">{{allocation.user.fullName}}</strong>
										<div>
											<DxButton
												v-if="hasPermission(constants.PERMISSION_UPDATE_RESOURCING)"
												@click="copyPreviousWeekAllocations(allocation)"
												:disabled="disableProjectAllocation"
												styling-mode="text"
												class="float-right p-0 ml-1">
												<Icon name="calendar4" icon-pack="bootstrap" v-tooltip="{content: `Replicate allocation from the preceding week to this week for ${allocation.user.userName}.`}" />
											</DxButton>
											<DxButton
												v-if="hasPermission(constants.PERMISSION_UPDATE_RESOURCING)"
												@click="addProjectAllocation(allocation)"
												:disabled="disableProjectAllocation"
												styling-mode="text"
												class="float-right p-0 m-0">
												<Icon name="plus-circle" icon-pack="bootstrap"/>
											</DxButton>
										</div>
									</div>
								</td>
								<!-- <td :class="allocatedHoursTdClass(allocation)"> -->
								<td width="13%" :class="allocationTdClass(allocation)">
									{{allocation.allocatedHours | oneDecimalPlace}}/{{allocation.totalHours | oneDecimalPlace}}
								</td>
								<td width="13%" v-for="weeklyAllocation in allocation.weeklyAllocations" :key="weeklyAllocation.id" :class="allocationTdClass(weeklyAllocation)">
									<div class="position-relative m-auto" v-width="'70px'" v-if="!weeklyAllocation.isHoliday">
										{{weeklyAllocation.allocated | oneDecimalPlace}}/{{weeklyAllocation.totalHours | oneDecimalPlace}}
										<UserLeaveBadge class="position-absolute" v-left="'70px'" :userLeaves="weeklyAllocation.userLeaves" :date="weeklyAllocation.date" />
										<NonBillableHoursBadge :projectAllocatedHours="weeklyAllocation.projectAllocatedHours" />
									</div>
									<span v-else>Holiday</span>
								</td>
							</tr>
							<tr v-show="allocation.showProjectAllocationForm">
								<td colspan="7">
									<form @submit="saveProjectAllocation(allocation, $event)">
										<DxValidationGroup class="d-flex" v-if="allocation.showProjectAllocationForm">
											<ProjectLookup
												v-model="newProjectAllocation.projectId"
												v-width="'100%'"
												@project-changed="onProjectChanged"
												:disabled-items="disabledProjects" />
											<DxButton
												class="ml-2" icon="check"
												v-if="hasPermission(constants.PERMISSION_UPDATE_RESOURCING)"
												:use-submit-behavior="true" />
											<DxButton
												class="ml-2" icon="close"
												v-if="hasPermission(constants.PERMISSION_UPDATE_RESOURCING)"
												@click="cancelProjectAllocation(allocation)"  />
										</DxValidationGroup>
									</form>
								</td>
							</tr>
							<tr v-for="(projectAlloctionObj, i) in groupByProjects(allocation.projectAllocations)" :key="i" class="tr-vertical-middle">
								<td colspan="2" class="indent">
									<div class="d-flex align-items-center justify-content-between">
										<div class="text-left2">
											<div>
												<span>{{projectAlloctionObj[0].project.name}}</span>
												<DispositionBadge :value="projectAlloctionObj[0].projectType" class="ml-2" />
												<!-- <ServiceTypeBadge :value="projectAlloctionObj[0].service.serviceType" class="ml-2" /> -->
											</div>
										</div>
										<DxButton
											v-if="hasPermission(constants.PERMISSION_UPDATE_RESOURCING) && !projectHasAllotedHours(projectAlloctionObj)"
											@click="removeProjectAllocation(allocation, projectAlloctionObj[0].projectId)"
											:disabled="disableProjectAllocation"
											styling-mode="text"
											class="float-right p-0 m-0">
											<Icon name="x-circle" icon-pack="bootstrap"/>
										</DxButton>
									</div>
								</td>
								<td v-for="projectWeeklyAllocation in projectAlloctionObj" :key="projectWeeklyAllocation.id">
									<div>
										<div v-if="!projectWeeklyAllocation.isHoliday" class="position-relative">
											<InlineEdit
												type="number"
												v-model="projectWeeklyAllocation.hours"
												:min="0"
												:inside-buttons="true"
												:slot-element="true"
												:disabled="isActionDisabled(projectWeeklyAllocation, allocation.user.fullName).disabled"
												@onSave="updateProjectAllocationHours(allocation, projectWeeklyAllocation, ...arguments)">
												<div class="position-relative m-auto" v-width="'30px'">
													<span v-tooltip="{content: isActionDisabled(projectWeeklyAllocation, allocation.user.fullName).msg, html:true, classes: 'tooltip-danger text-left'}">
														{{ projectWeeklyAllocation.hours | oneDecimalPlace}}
													</span>
												</div>
												<DxButton
													v-if="showFillNextEntryBtn(projectAlloctionObj, projectWeeklyAllocation, allocation.weekDate) && hasPermission(constants.PERMISSION_UPDATE_RESOURCING)"
													@click.native.stop="fillNextDayEntry(projectAlloctionObj, projectWeeklyAllocation, allocation)"
													icon="plus"
													styling-mode="text"
													v-left="'65%'"
													class="vertical-center p-0">
													<Icon name="arrow-right-circle" icon-pack="bootstrap" v-if="hasPermission(constants.PERMISSION_UPDATE_RESOURCING)"/>
												</DxButton>
												<DxButton
													v-if="projectWeeklyAllocation.hours > 0 && hasPermission(constants.PERMISSION_UPDATE_RESOURCING)"
													@click.native.stop="updateProjectAllocationHours(allocation, projectWeeklyAllocation, 0)"
													icon="bi bi-x"
													styling-mode="text"
													v-left="'80%'"
													class="vertical-center p-0">
													<Icon name="x-circle" icon-pack="bootstrap" v-if="hasPermission(constants.PERMISSION_UPDATE_RESOURCING)"/>
												</DxButton>
											</InlineEdit>
										</div>
										<div v-else>
											Holiday
										</div>
									</div>
								</td>
							</tr>
						</div>
						<div v-fragments>
							<tr v-if="resourceAllocations.length > 0">
								<td></td>
								<td class="font-weight-bold" :class="allocationTdClass(summary.total)">
									{{summary.total.allocated | oneDecimalPlace}}/{{summary.total.totalHours | oneDecimalPlace}}
									({{summary.total.percent | twoDecimals}}%)
								</td>
								<td v-for="day in ['Monday','Tuesday','Wednesday','Thursday','Friday']" :key="day+'-summary'" class="font-weight-bold" :class="allocationTdClass(summary[day])">
									<span v-if="summary[day].isHoliday">Holiday</span>
									<span v-else>
										{{summary[day].allocated | oneDecimalPlace}}/{{summary[day].totalHours | oneDecimalPlace}}
										({{summary[day].percent | twoDecimals}}%)
									</span>
								</td>
							</tr>
						</div>
					</tbody>
				</table>
			</div>
		</div>
	</div>
</template>

<script>
import Resourcing from '@/mixins/views/Resourcing'
import { ProjectLookup, LookupMultiple } from '@/components/lookups'
import { DayOfWeek, WeekDays } from '@/_helpers/constants'
import InlineEdit from '@/components/InlineEdit'
import { DxButton } from 'devextreme-vue/button'
import DxValidationGroup from 'devextreme-vue/validation-group';
import NonBillableHoursBadge from './NonBillableHoursBadge.vue'
export default{
	props: ['value','currentWeek','departmentIds','searchByDepartment','tableRequest'],
	mixins:[Resourcing],
	components:{
		ProjectLookup, LookupMultiple, InlineEdit, DxButton, DxValidationGroup, NonBillableHoursBadge
	},
	data(){
		return {
			resources: [],
			tableKey: this.generateUniqueCode(),
			tableBodyKey: this.generateUniqueCode(),
			disableProjectAllocation: false,
			newProjectAllocation: {projectId: '', project: {}},
			disabledProjects: [],
			summary: { Monday: {}, Tuesday: {}, Wednesday: {}, Thursday: {}, Friday: {}, total: {}}
		}
	},
	methods:{
		copyPreviousWeekAllocations(allocation){
			this.$emit('update:tableRequest', true)
			this.post('api/resourcing/copy-previous-project-allocations',allocation).then(response =>{
				this.updateAllocationRow(response.data)
			}).finally(final =>{
				this.$emit('update:tableRequest', false)
			})
		},
		updateAllocationRow(allocation){
			let i = this.resourceAllocations.findIndex(s=>s.id == allocation.id)
			this.resourceAllocations[i] = allocation
			this.tableBodyKey = this.generateUniqueCode()
			this.$emit('input', this.resourceAllocations)
			this.$forceUpdate()
			this.calculateSummary()
		},
		calculateSummary(){
			this.summary = { Monday: {}, Tuesday: {}, Wednesday: {}, Thursday: {}, Friday: {}, total: {}}
			if(this.resourceAllocations.length){
				this.summaryDays.forEach(day => {
					let days = this.resourceAllocations.map(s => s.weeklyAllocations).flat().filter(s => s.day == day)
					let allocated = days.map(s => Number(s.allocated)).reduce((a,b) => a + b, 0)
					let totalHours = days.map(s => Number(s.totalHours)).reduce((a,b) => a + b, 0)
					this.summary[day] = {
						isHoliday: days[0].isHoliday,
						allocated: allocated,
						totalHours: totalHours,
						percent: totalHours > 0 ? (allocated / totalHours) * 100 : 0
					}
				})
				let allocatedHours = this.resourceAllocations.map(s => Number(s.allocatedHours)).reduce((a,b) => a+b, 0)
				let total = this.resourceAllocations.map(s => Number(s.totalHours)).reduce((a,b) => a+b, 0)
				this.summary.total = {
					allocated: allocatedHours,
						totalHours: total,
						percent: total > 0 ? (allocatedHours / total) * 100 : 0,
				}
			}
		},
		addProjectAllocation(allocation){
			allocation.showProjectAllocationForm = true
			this.disableProjectAllocation = true
			this.disabledProjects = this.groupByProjects(allocation.projectAllocations).map(s=>s[0]).map(s=>s.projectId)
			this.$forceUpdate()
		},
		saveProjectAllocation(allocation, e){
			if(e) e.preventDefault()
			this.$emit('update:tableRequest', true)
			this.post('api/resourcing/project-allocations',{
				allocationId: allocation.id,
				projectId: this.newProjectAllocation.projectId,
				holidays: allocation.weeklyAllocations.map(wa => wa.isHoliday ? DayOfWeek[wa.day] : undefined).filter(s => s != undefined)
			}).then(response =>{
				allocation.projectAllocations.push.apply(allocation.projectAllocations, response.data)
				this.updateAllocationRow(allocation)
				this.cancelProjectAllocation(allocation)
			}).finally(final =>{
				this.$emit('update:tableRequest', false)
			})
		},
		removeProjectAllocation(allocation, projectId){
			this.$emit('update:tableRequest', true)
			this.post('api/resourcing/project-allocations/delete',{
				allocationId: allocation.id,
				projectId: projectId
			}).then(response =>{
				this.updateAllocationRow(response.data)
				this.$emit('updateAllocationRow', projectId)
			}).finally(final =>{
				this.$emit('update:tableRequest', false)
			})
		},
		cancelProjectAllocation(allocation){
			allocation.showProjectAllocationForm = false
			this.disableProjectAllocation = false
			this.newProjectAllocation = {projectId: '', project: {}}
			this.$forceUpdate()
		},
		onProjectChanged(project){
			this.newProjectAllocation.project = project
		},
		groupByProjects(projectAllocations){
			let groups = _(projectAllocations)
					.groupBy(item => item.projectId)
					.sortBy(group => projectAllocations.indexOf(group[0]))
					.value()
			let sorted = groups.sort(function(a,b) {
				if(a[0].project.name < b[0].project.name) { return -1; }
				if(a[0].project.name > b[0].project.name) { return 1; }
				return 0;
			});
			return sorted
		},
		projectHasAllotedHours(obj){
			let totalHours = obj.map(s => Number(s.hours)).reduce((a,b) => a+b ,0)
			return totalHours > 0
		},
		isActionDisabled(obj, user){
			let projectWeeklyAllocation = obj
			let isDatePast = this.isDatePast(undefined, projectWeeklyAllocation.day)
			let response = {
				disabled: projectWeeklyAllocation.isHoliday || projectWeeklyAllocation.isFullDayLeave || isDatePast || !this.hasPermission(constants.PERMISSION_UPDATE_RESOURCING)
			}
			if (response.disabled){
				response.msg = `<strong>Editing hours isn't permitted:</strong> </br>`
				response.msg += projectWeeklyAllocation.isFullDayLeave ? `• ${user} is on full day leave on ${projectWeeklyAllocation.day}. </br>` : ''
				response.msg += isDatePast ? `• Hours of dates in past can not be edited.` : ''
			}
			return response
		},
		updateProjectAllocationHours(allocation, projectAllocation, val){
			this.$emit('update:tableRequest', true)
			this.post('api/resourcing/project-allocations/hours',{
				id: projectAllocation.id || projectAllocation.projectWeeklyAllocation.id,
				hours: val || 0,
				day: projectAllocation.day,
				resourceAllocationId: projectAllocation.resourceAllocationId || projectAllocation.projectWeeklyAllocation.resourceAllocationId,
				projectType: projectAllocation.projectType || projectAllocation.projectWeeklyAllocation.projectType
			}).then(response => {
				this.updateAllocationRow(response.data)
				this.$emit('updateAllocationRow', projectAllocation.projectId)
			}).finally(final =>{
				this.$emit('update:tableRequest', false)
			})
		},
		startDrag(evt, item) {
			if(!this.hasPermission(constants.PERMISSION_UPDATE_RESOURCING)){
				evt.cancel = true
			}
			this.newProjectAllocation.projectId = item.projectId
			evt.dataTransfer.dropEffect = 'move'
			evt.dataTransfer.effectAllowed = 'move'
			evt.currentTarget.classList.add("item-hovering")
			Array.prototype.forEach.call(document.getElementsByClassName('drop-zone'), (el) => el.classList.add('drop-enabled'));
		},
		endDrag(evt){
			this.newProjectAllocation.projectId = ''
			evt.currentTarget.classList.remove("item-hovering");
			Array.prototype.forEach.call(document.getElementsByClassName('drop-zone'), (el) => el.classList.remove('drop-enabled'));
		},
		dragEnter(evt){
			evt.currentTarget.classList.add('hovering')
		},
		drageLeave(evt){
			evt.currentTarget.classList.remove('hovering')
		},
		onDrop(evt) {
			Array.prototype.forEach.call(document.getElementsByClassName('request'), (el) => el.classList.remove('hovering'));
			let allocation = this.resourceAllocations.find(s => s.id == evt.currentTarget.dataset.id)
			if(allocation.projectAllocations.some(s=>s.projectId == this.newProjectAllocation.projectId)) return
			this.saveProjectAllocation(allocation);
		},
		fillNextDayEntry(weeklyAllocationObj, projectAllocation, allocation){
			let nextDay = DayOfWeek.nextDay(projectAllocation.day)
			let nextDayAllocation = weeklyAllocationObj.find(s=>s.day == nextDay)
			this.updateProjectAllocationHours(allocation, nextDayAllocation, Number(projectAllocation.hours))
		},
	},
	computed:{
		resourceAllocations:{
			get(){
				this.tableKey = this.generateUniqueCode()
				return (this.value || []).filter(ra => {
					if(this.resources.length == 0){
						return true
					}
					return this.resources.some(r => r.id == ra.userId)
				})
			},
			set(v){
				this.$emit('input', v);
			}
		},
		dropZone(){
			return (allocation) => {
				let unavailable = allocation.projectAllocations.some(s=>s.projectId == this.newProjectAllocation.projectId)
				return !unavailable ? 'drop-zone' : ''
			}
		},
	},
	watch:{
		value(v){
			this.calculateSummary()
			this.tableKey = this.generateUniqueCode()
		}
	},
	mounted(){
		this.calculateSummary()
	}
}
</script>
<style>
.drop-zone.drop-enabled{
	width: max-content;
	background: linear-gradient(90deg, var(--warning) 50%, transparent 50%), linear-gradient(90deg, var(--warning) 50%, transparent 50%), linear-gradient(0deg, var(--warning) 50%, transparent 50%), linear-gradient(0deg, var(--warning) 50%, transparent 50%);
	background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
	background-size: 15px 4px, 15px 4px, 4px 15px, 4px 15px;
	animation: border-dance 4s infinite linear;
}
.drop-zone.hovering{
	width: max-content;
	background: linear-gradient(90deg, var(--success) 50%, transparent 50%), linear-gradient(90deg, var(--success) 50%, transparent 50%), linear-gradient(0deg, var(--success) 50%, transparent 50%), linear-gradient(0deg, var(--success) 50%, transparent 50%);
	background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
	background-size: 15px 4px, 15px 4px, 4px 15px, 4px 15px;
	animation: border-dance 4s infinite linear;
}
@keyframes border-dance {
  0% {
    background-position: 0 0, 100% 100%, 0 100%, 100% 0;
  }
  100% {
    background-position: 100% 0, 0 100%, 0 0, 100% 100%;
  }
}
</style>
