<template>
	<header class="header-component bg-black">
		<dx-toolbar class="header-toolbar bg-black">
			<!-- <dx-item :visible="menuToggleEnabled" location="before" css-class="menu-button">
				<template #default>
					<div>
						<dx-button icon="menu" styling-mode="text" @click="toggleMenuFunc" />
					</div>
				</template>
			</dx-item> -->

			<dx-item :visible="true" location="before" css-class="menu-button">
				<template #default>
					<div>
						<dx-button icon="menu" styling-mode="text" @click="toggleMenuFunc" />
					</div>
				</template>
			</dx-item>

			<dx-item location="before" template="weekTemplate" css-class="header-title dx-toolbar-label">
				<template #default>
					<div class="text-light" :key="headingKey" style="width: 100%">{{heading}} </div>
				</template>
			</dx-item>

			<dx-item location="after" locate-in-menu="auto" menu-item-template="menuUserItem">
				<template #default>
					<div class="d-flex align-items-center row mb-0 p-0">
						<div class="mr-2">
							<portal-target name="fullScreenToggle">
								<!-- Full Screen Toggle in Settings Board goes here. -->
							</portal-target>
						</div>
						<dx-button class="user-button authorization" height="100%" styling-mode="text">
							<user-panel :user="user" :menu-items="userMenuItems" menu-mode="context" />
						</dx-button>
					</div>
				</template>
			</dx-item>
			<template #menuUserItem>
				<user-panel :user="user" :menu-items="userMenuItems" menu-mode="list" />
			</template>
		</dx-toolbar>
	</header>
</template>

<script>
import DxButton from "devextreme-vue/button";
import DxToolbar, { DxItem } from "devextreme-vue/toolbar";
import auth from "../auth";
import UserPanel from "@/views/users/user-panel.vue";
import { TextFormatter } from "@/mixins";
import { logout } from '../_services/user.service'

export default {
	mixins:[TextFormatter],
	props: {
		menuToggleEnabled: Boolean,
		title: String,
		toggleMenuFunc: Function,
		logOutFunc: Function,
	},
	created() {
		auth.getUser().then((e) => (this.user = e.data));
	},
	data() {
		return {
			heading: '',
			user: {},
			headingKey: this.generateUniqueCode(),
			userMenuItems: [
				{
					text: "Apply Leave",
					icon: "bi bi-box-arrow-right",
					onClick: this.onClickRequestLeave,
				},
				{
					text: "Extra Day Request",
					icon: "bi bi-list-task",
					onClick: this.onClickRequestExtraDay,
				},
				{
					text: "Resource Request",
					icon: "bi bi-person-lines-fill",
					onClick: this.onClickResourceRequest,
				},
				{
					text: "Settings",
					icon: "preferences",
					onClick: () => {
						this.$router.push({path: "/settings/all", query: { redirect: this.$route.path },}).catch(() => {
							console.log("Error while accessing route");
						})
					},
				},
				{
					text: "Logout",
					icon: "runner",
					onClick: this.onLogoutClick,
				},
			],
		};
	},
	methods: {
		onLogoutClick() {
			logout();
			this.$router.push({path: "/login", query: { redirect: this.$route.path },}).catch(() => {
				console.log("Error while accessing route");
			});
		},
		onClickResourceRequest(){
			this.$router.push({path: "/resourcing/request", query: { redirect: this.$route.path },}).catch(() => {
				console.log("Error while accessing route");
			});
		},
		onClickRequestLeave() {
			window.showLeaveRequestModal()
		},
		onClickRequestExtraDay(){
			window.showExtraDayRequestModal()
		}
	},
	components: {
		DxButton,
		DxToolbar,
		DxItem,
		UserPanel,
	},
	watch:{
		'$route' (to, from) {
			this.heading = this.formatLabel(to.name);
			this.headingKey = this.generateUniqueCode()
		}
	},
};
</script>

<style lang="scss">
@import "../themes/generated/variables.base.scss";
@import "../dx-styles.scss";

.header-component {
	flex: 0 0 auto;
	z-index: 1;
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);

	.dx-toolbar .dx-toolbar-item.menu-button > .dx-toolbar-item-content .dx-icon {
		color: #fff;
	}

	.dx-toolbar .dx-toolbar-item.dx-toolbar-button.menu-button{
		width: 40px !important;
		padding-right: 10px !important;
	}

	.header-toolbar {
		.dx-toolbar-items-container {
			.dx-toolbar-before {
				width: 20%
			}
		}
	}
}

.dx-toolbar.header-toolbar .dx-toolbar-items-container .dx-toolbar-after {
	padding: 0 40px;

	.screen-x-small & {
		padding: 0 20px;
	}
}

.dx-toolbar .dx-toolbar-item.dx-toolbar-button.menu-button {
	width: $side-panel-min-width;
	text-align: center;
	padding: 0;
}

.header-title .dx-item-content {
	padding: 0;
	margin: 0;
}

.dx-theme-generic {
	.dx-toolbar.header-toolbar {
		padding: 10px 0;
	}

	.user-button > .dx-button-content {
		padding: 3px;
	}
}
</style>
