<template>
    <DxSelectBox
        v-model="projectId"
        :search-enabled="true"
        :data-source="projects"
        display-expr="name"
        value-expr="id"
        placeholder="Select Project"
        :disabled="disabled"
        :read-only="readOnly"
        :hint="hint"
        @value-changed="onSelectProject"
        item-template="item">
        <template #item="{ data }">
            <div>
                {{ data.name }}
                <DispositionBadge :value="data.disposition" class="ml-2" />
            </div>
        </template>
        <DxValidator v-if="required"> <DxRequiredRule message="Project is required"/> </DxValidator>
    </DxSelectBox>
</template>
<script>
import DxSelectBox from 'devextreme-vue/select-box';
import { AxiosWrapper } from '../../mixins/AxiosWrapper'
import { DxValidator, DxRequiredRule } from 'devextreme-vue/validator'
export default {
    props:{
        'required':{
            type: Boolean,
            default: false
        },
        'value':{
            type: String,
            default: ''
        },
        'clientId':{
            type: String,
            default: ''
        },
        'disabled':{
            type: Boolean,
            default: false
        },
        'require-client':{
            type: Boolean,
            default: false
        },
        'disabled-items':{
            type: Array,
            default: () => []
        },
        'read-only':{
            type: Boolean,
            default: false
        },
        'hint':{
            type: String,
            default: ''
        }
    },
    components: { DxSelectBox, DxValidator, DxRequiredRule },
    mixins: [ AxiosWrapper ],
    data(){
        return {
            isLoaded: true,
            projectsRaw:[],
            projects: null,
        }
    },
    methods:{
        onSelectProject(e){
            this.$emit('value-changed', e.value)
            let project = e.value ? this.find(this.projectsRaw, e.value) : undefined
            this.$emit('project-changed', project)
        },
        intiliazeProjectsList(){
            return {
                loadMode: 'raw',
                load: () => {
                    const promise = new Promise((resolve) => {
                        let queryParam = this.clientId ? '?clientId='+this.clientId : ''
                        this.get('api/projects/lookup' + queryParam).then(response => {
                            let projects = response.data;
                            projects.forEach(project => {
                                if(this.disabledItems.includes(project.id)) project.disabled = true
                            })
                            this.$emit('on-load', projects)
                            this.projectsRaw = projects
                            resolve(this.projectsRaw);
                        })
                    });
                    return promise;
                },
            }
        }
    },
    created(){
        if((this.requireClient && this.clientId) || !this.requireClient)
            this.projects = this.intiliazeProjectsList()
    },
    watch:{
        clientId(val){
            this.projectId = ''
            this.$forceUpdate()
            if(this.requireClient){
                if(val) this.projects = this.intiliazeProjectsList()
            }
            else{
                this.projects = this.intiliazeProjectsList()
            }
        },
        disabledItems(){
            this.projects = {
                loadMode: 'raw',
                load: () => {
                    const promise = new Promise((resolve) => {
                        this.projectsRaw.forEach(project => {
                            if(this.disabledItems.includes(project.id)) project.disabled = true
                            else project.disabled = false
                        })
                        resolve(this.projectsRaw);
                    });
                    return promise;
                },
            }
        }
    },
    computed:{
        projectId:{
            get(){ return this.value || '' },
			set(v){ this.$emit('input', v) }
        }
    }
}
</script>
