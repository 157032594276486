var render = function render(){var _vm=this,_c=_vm._self._c;return _c('CheckPermission',{attrs:{"value":_vm.constants.PERMISSION_READ_GENERAL_SETTINGS,"access-denied-msg":true}},[_c('b-card',{attrs:{"no-body":""}},[_c('b-card-header',{staticClass:"d-flex align-items-center justify-content-between"},[_c('strong',[_vm._v("Departments")]),(_vm.hasPermission(_vm.constants.PERMISSION_UPDATE_GENERAL_SETTINGS))?_c('DxButton',{attrs:{"icon":"plus"},on:{"click":_vm.addDepartment}}):_vm._e()],1),_c('div',[(_vm.apiRequest)?_c('spinner'):_c('DxDataGrid',{key:_vm.tableKey,staticClass:"header-table",attrs:{"data-source":_vm.departments,"show-row-lines":true,"show-column-lines":false,"show-borders":true,"hover-state-enabled":true,"row-alternation-enabled":true},scopedSlots:_vm._u([{key:"usersCellTemplate",fn:function({ data }){return [_c('div',_vm._l((data.value
								.map((s) => s.users)
								.flat(1)),function(item){return _c('div',{key:item.id},[_vm._v(" "+_vm._s(item.userName)+" ")])}),0)]}},{key:"actionsTemplate",fn:function({ data }){return [_c('div',[_c('DxButton',{attrs:{"icon":"edit","styling-mode":"text"},on:{"click":function($event){return _vm.editDepartment(data.value.id)}}}),_c('DxButton',{attrs:{"disabled":data.value.departmentLevels.map((s) => s.users).flat(1).length > 0,"icon":"trash","styling-mode":"text"},on:{"click":function($event){return _vm.confirmDelete(data.value.id)}}})],1)]}}])},[_c('DxColumn',{attrs:{"data-field":"name","cssClass":"data-column-custom"}}),_c('DxColumn',{attrs:{"data-field":"description","cssClass":"data-column-custom"}}),_c('DxColumn',{attrs:{"caption":"Users","data-field":"departmentLevels","cell-template":"usersCellTemplate","cssClass":"data-column-custom"}}),(_vm.hasPermission(_vm.constants.PERMISSION_UPDATE_GENERAL_SETTINGS))?_c('DxColumn',{attrs:{"width":"130px","caption":"Actions","calculate-cell-value":_vm.getRowObj,"alignment":"center","cell-template":"actionsTemplate","cssClass":"data-column-custom"}}):_vm._e()],1),(_vm.showDptModal)?_c('DxPopup',{attrs:{"visible":_vm.showDptModal,"on-hidden":() => {
						_vm.showDptModal = false;
					},"drag-enabled":false,"hide-on-outside-click":true,"show-close-button":true,"show-title":true,"width":"50%","height":"auto","title":_vm.department.id ? 'Edit Department' : 'Add Department'}},[_c('form',{ref:"departmentForm",on:{"submit":function($event){return _vm.submit($event)}}},[(_vm.submitRequest)?_c('spinner'):_c('DxValidationGroup',{ref:"departmentFormValidation"},[_c('DxField',{attrs:{"label":"Name","required":false}},[_c('DxTextBox',{attrs:{"placeholder":"Name","mode":"text"},model:{value:(_vm.department.name),callback:function ($$v) {_vm.$set(_vm.department, "name", $$v)},expression:"department.name"}},[_c('DxValidator',[_c('DxCustomRule',{attrs:{"validation-callback":(obj) =>
												_vm.validateUniqueProp(
													_vm.departments,
													_vm.department.id,
													'name',
													obj.value
												),"message":"Department with this name already exists."}}),_c('DxCustomRule',{attrs:{"validation-callback":(obj) =>
												_vm.validateEmptySpaces(obj.value),"message":"Name can not start or end with empty space."}}),_c('DxRequiredRule',{attrs:{"message":"Name is required"}})],1)],1)],1),_c('DxField',{attrs:{"label":"Description","required":false}},[_c('DxTextArea',{attrs:{"placeholder":"Description","height":100},model:{value:(_vm.department.description),callback:function ($$v) {_vm.$set(_vm.department, "description", $$v)},expression:"department.description"}},[_c('DxValidator',[_c('DxRequiredRule',{attrs:{"message":"Description is required"}})],1)],1)],1),_c('div',[_c('DxButton',{staticClass:"mt-1 float-right",attrs:{"text":"Submit","type":"default","use-submit-behavior":true}})],1)],1)],1)]):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }