<template>
	<Popup
		ref="cardPopup"
        :destroyOnClose="true"
        :width="card.id ? '60%' : '40%'"
		:height="card.id ? '90%' : 'auto'"
        :native-scroll="true"
        :hide-on-outside-click="false"
        @hidden="closePopup"
		:noPadding="true"
        :title="card.code || 'Create Card'">
		<spinner v-if="apiRequest" />
		<CardDetails v-model="card" @canceled="$refs.cardPopup.hide()" @reload="load(...arguments)" v-else />
	</Popup>
</template>

<script>
import { AxiosWrapper } from '@/mixins'
import CardDetails from './card-details.vue'
function card (){
	return {
		id: '',
		text: '',
		description: '',
		code: '',
		status:'Pending',
		priority:'Medium',
		startDate: new Date()
	}
}
function initialState (){
    return {
        card: card(),
        apiRequest: false,
    }
}
export default {
	mixins: [AxiosWrapper],
	components: {
		CardDetails
	},
	data() {
		return initialState();
	},
	methods:{
		show(id){
			if(id){
				this.$refs.cardPopup.show()
				this.load(id)
			}
			else{
				this.card = card()
				this.$refs.cardPopup.show()
			}
        },
        closePopup(){
            Object.assign(this.$data, initialState());
            this.$emit('hidden')
        },
		load(id){
			this.apiRequest = true
			this.get(`api/cards/${id}`).then(response => {
				this.card = response.data
			}).finally(() => {
				this.apiRequest = false
			})
		}
	}

}

</script>
