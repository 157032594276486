<template>
	<CheckPermission :value="constants.PERMISSION_READ_GENERAL_SETTINGS" :access-denied-msg="true">
		<b-card no-body>
			<b-card-header class="d-flex align-items-center justify-content-between">
				<strong>Levels</strong>
				<DxButton icon="plus" @click="addLevel" v-if="hasPermission(constants.PERMISSION_UPDATE_GENERAL_SETTINGS)"> </DxButton>
			</b-card-header>
			<spinner v-if="apiRequest" />
			<DxDataGrid v-else class="header-table" :data-source="levels" :show-row-lines="true"
				:show-column-lines="false" :show-borders="true" :hover-state-enabled="true"
				:row-alternation-enabled="true" :key="tableKey">
				<DxColumn data-field="name" cssClass="data-column-custom" />
				<DxColumn data-field="description" cssClass="data-column-custom" />
				<DxColumn caption="Departments" :calculate-cell-value="getDepartmentLevels"
					cell-template="departmentsCellTemplate" cssClass="data-column-custom" />
				<template #departmentsCellTemplate="{ data }">
					<div>
						<div v-for="item in data.value.map((s) => s.department)" :key="item.id">
							{{ item.name }}
						</div>
					</div>
				</template>
				<DxColumn caption="Users" :calculate-cell-value="getDepartmentLevels" cell-template="usersCellTemplate"
					cssClass="data-column-custom" />
				<template #usersCellTemplate="{ data }">
					<div>
						<div v-for="item in data.value.map((s) => s.users).flat(1)" :key="item.id">
							{{ item.userName }}
						</div>
					</div>
				</template>
				<DxColumn width="120px" caption="Actions" data-field="id" alignment="center"
					cell-template="actionsTemplate" cssClass="data-column-custom" v-if="hasPermission(constants.PERMISSION_UPDATE_GENERAL_SETTINGS)" />
				<template #actionsTemplate="{ data }">
					<div>
						<DxDropDownButton :items="dropdownOptions(data.value)" icon="overflow" displayExpr="name"
							:show-arrow-icon="false" styling-mode="text" @item-click="(e) => { e.itemData.method(data.value); }" :drop-down-options="{ width: '12vw' }">
						</DxDropDownButton>
					</div>
				</template>
			</DxDataGrid>

			<Popup ref="levelModal" :title="level.id ? 'Edit Level' : 'Add Level'" :destroy-on-close="true">
				<form ref="levelForm" @submit="submit($event)">
					<spinner v-if="submitRequest" />
					<DxValidationGroup ref="levelFormValidation" v-else>
						<DxField label="Name" :required="false">
							<DxTextBox v-model="level.name" placeholder="Name" mode="text">
								<DxValidator>
									<DxCustomRule :validation-callback="(obj) => validateUniqueProp(levels, level.id, 'name', obj.value)" message="Level with this name already exists." />
									<DxCustomRule :validation-callback="(obj) => validateEmptySpaces(obj.value)" message="Name can not start or end with empty space." />
									<DxRequiredRule message="Name is required" />
								</DxValidator>
							</DxTextBox>
						</DxField>
						<DxField label="Description" :required="false">
							<DxTextArea placeholder="Description" :height="100" v-model="level.description">
								<DxValidator>
									<DxRequiredRule message="Description is required" />
								</DxValidator>
							</DxTextArea>
						</DxField>
						<div>
							<DxButton class="mt-1 float-right" text="Submit" type="default"
								:use-submit-behavior="true" />
						</div>
					</DxValidationGroup>
				</form>
			</Popup>
			<Popup ref="departmentModal" :title="level.name + ' Departments'" width="40%"
				:destroy-on-close="true">
				<div>
					<spinner v-if="dptLevlRequest" />
					<DxField label="Departments" class="align-items-baseline" :required="false" v-else>
						<DxSelectBox ref="departmentLevelSelectBox" placeholder="Select Department To Associate"
							:items="departmentsFiltered" displayExpr="name" @value-changed="onSelectDepartment">
						</DxSelectBox>
						<div class="mt-4">
							<span class="badge bg-grey text-white mr-1 associated-dpt-badge mb-2" v-for="dptLvl in level.departmentLevels"
								:key="dptLvl.id" v-b-tooltip="dptLvl.users.length > 0 ? 'User(s) attached. Department can not be disassociated' : ''">
								{{ dptLvl.department.name }}
								<i v-if="!dptLvl.users.length" @click="deleteDptLvl(dptLvl.id)" class="dx-icon-close pointer pl-1 remove-dpt-btn">
								</i>
							</span>
						</div>
					</DxField>
				</div>
			</Popup>
			<Popup width="55%" ref="targetsModal" title="Default Targets" :destroy-on-close="true" @hidden="onHiddenTargetModal">
				<div>
					<spinner v-if="submitRequest" />
					<div v-else>
						<DxField label="Level">
							<DxTextBox :value="level.name" :disabled="true">
							</DxTextBox>
						</DxField>
						<DxField label="Department">
							<DxSelectBox v-model="departmentLevel" placeholder="Select Department"
								:items="level.departmentLevels" displayExpr="department.name"
								@value-changed="onSelectDepartmentLevel">
							</DxSelectBox>
						</DxField>
						<form ref="targetForm" @submit="submitTarget($event)" v-if="target">
							<div class="d-flex align-items-center justify-content-between mb-3 mt-3">
								<h5>Set Default Target</h5>
								<span class="badge badge-warning" v-if="!Boolean(target.id)">not set</span>
							</div>
							<DxValidationGroup ref="targetFormValidation">
								<div class="row">
									<div class="col-6">
										<DxField label="Billable Hours" label-width="50%" value-width="50%">
											<DxNumberBox v-model="target.billableHours" :format="twoDecimalFixedPoint" :precision="2" :min="0" :show-spin-buttons="true">
											</DxNumberBox>
										</DxField>
									</div>
									<!-- <div class="col-6">
										<DxField label="Average Hourly Rate" label-width="50%" value-width="50%">
											<DxNumberBox v-model="target.avgHourlyRate" :format="currencyFormat" :precision="2" :min="0" :show-spin-buttons="true">
											</DxNumberBox>
										</DxField>
									</div>
									<div class="col-6">
										<DxField label="Internal Feedback" label-width="50%" value-width="50%">
											<DxNumberBox v-model="target.internalFeedback" :format="oneDecimalFixedPoint" :precision="1" :min="0" :max="5" :show-spin-buttons="true">
											</DxNumberBox>
										</DxField>
									</div>
									<div class="col-6">
										<DxField label="External Feedback" label-width="50%" value-width="50%">
											<DxNumberBox v-model="target.externalFeedback" :format="oneDecimalFixedPoint" :precision="1" :min="0" :max="5" :show-spin-buttons="true">
											</DxNumberBox>
										</DxField>
									</div> -->
									<div class="col-6">
										<DxField label-width="50%" value-width="50%" :label-slot="true">
											<template #label>
												30 Day Handicap
												<Icon name="cid-info-circle" class="ml-2"
													tooltip="This is the percentage by which the user's target is reduced." />
											</template>
											<DxNumberBox v-model="target.handicap30Day" format="fixedPoint"
												:precision="2" :min="0" :max="100" :show-spin-buttons="true">
											</DxNumberBox>
										</DxField>
									</div>
									<div class="col-6">
										<DxField label-width="50%" value-width="50%" :label-slot="true">
											<template #label>
												60 Day Handicap
												<Icon name="cid-info-circle" class="ml-2"
													tooltip="This is the percentage by which the user's target is reduced." />
											</template>
											<DxNumberBox v-model="target.handicap60Day" format="fixedPoint"
												:precision="2" :min="0" :max="100" :show-spin-buttons="true">
											</DxNumberBox>
										</DxField>
									</div>
									<div class="col-6">
										<DxField label-width="50%" value-width="50%" :label-slot="true">
											<template #label>
												90 Day Handicap
												<Icon name="cid-info-circle" class="ml-2"
													tooltip="This is the percentage by which the user's target is reduced." />
											</template>
											<DxNumberBox v-model="target.handicap90Day" format="fixedPoint"
												:precision="2" :min="0" :max="100" :show-spin-buttons="true">
											</DxNumberBox>
										</DxField>
									</div>
									<div class="col-12 text-right">
										<DxButton :use-submit-behavior="true" icon="arrowright" />
									</div>
								</div>
							</DxValidationGroup>
						</form>
					</div>
				</div>
			</Popup>
		</b-card>
	</CheckPermission>
</template>

<script>
import {
	DxDataGrid,
	DxColumn,
	DxToolbar,
	DxItem,
} from "devextreme-vue/data-grid";
import { DxButton } from "devextreme-vue/button";
import {
	DxValidator,
	DxRequiredRule,
	DxCustomRule,
} from "devextreme-vue/validator";
import DxSelectBox from "devextreme-vue/select-box";
import DxTextArea from "devextreme-vue/text-area";
import DxTextBox from "devextreme-vue/text-box";
import DxValidationGroup from "devextreme-vue/validation-group";
import { AxiosWrapper, HelperMethods } from "@/mixins";
import { DxNumberBox } from "devextreme-vue/number-box";
import DxDropDownButton from "devextreme-vue/drop-down-button";
import { isMobile } from "@/utils/media-query";
export default {
	name: "LevelsPage",
	mixins: [AxiosWrapper, HelperMethods],
	components: {
		DxDataGrid,
		DxColumn,
		DxToolbar,
		DxItem,
		DxButton,
		DxSelectBox,
		DxTextBox,
		DxNumberBox,
		DxTextArea,
		DxValidator,
		DxRequiredRule,
		DxCustomRule,
		DxValidationGroup,
		DxDropDownButton,
	},
	data() {
		return {
			apiRequest: false,
			tableKey: 0,
			submitRequest: false,
			levels: [],
			departments: [],
			isDepartmentsLoaded: false,
			showDepartmentsModal: false,
			dptLevlRequest: false,
			level: { id: "", name: "", description: "", departmentLevels: [] },
			departmentLevel: null,
			target: null,
			isMobile: isMobile(),
		};
	},
	async created() {
		this.load();
	},
	methods: {
		load() {
			this.apiRequest = true;
			this.get("api/settings/levels").then((response) => {
				this.levels = response.data;
			}).catch((error) => {
				window.showErrorToast(error.message);
			}).finally(() => {
				this.apiRequest = false;
			});
		},
		editLevel(id) {
			this.level = this.shallowClone(this.levels.find((s) => s.id == id));
			this.$refs["levelModal"].show();
		},
		addLevel() {
			this.level = {
				id: "",
				name: "",
				description: "",
				departmentLevels: [],
			};
			this.$refs["levelModal"].show();
		},
		submit(e) {
			e.preventDefault();
			this.submitRequest = true;
			this.post("api/settings/levels", this.level)
				.then(() => {
					window.showSuccessToast("Record updated successfully.");
					this.load();
				})
				.catch((error) => {
					window.showErrorToast(error.message);
				})
				.finally(() => {
					this.submitRequest = false;
					this.$refs["levelModal"].hide();
				});
		},
		async openDepartmentLevelsModal(id) {
			if (!this.isDepartmentsLoaded) {
				const response = await this.get("api/settings/departments");
				this.departments = response.data;
				this.isDepartmentsLoaded = true;
			}
			this.level = this.shallowClone(this.levels.find((s) => s.id == id));
			this.$refs["departmentModal"].show();
		},
		onSelectDepartment(e) {
			if (!e.value) return;
			this.dptLevlRequest = true;
			this.$refs["departmentLevelSelectBox"].instance.beginUpdate();
			let postData = {
				department: { id: e.value.id },
				level: { id: this.level.id },
			};
			this.post("api/settings/department-level", postData)
				.then((response) => {
					this.level.departmentLevels = JSON.parse(response.data);
					this.load();
				})
				.catch((error) => {
					window.showToast({
						text: error.message,
						title: "Alert",
						color: "danger",
					});
				})
				.finally(() => {
					this.dptLevlRequest = false;
				});
		},
		deleteDptLvl(id) {
			this.dptLevlRequest = true;
			this.get("api/settings/department-level/delete/" + id)
				.then(() => {
					this.level.departmentLevels =
						this.level.departmentLevels.filter((s) => s.id != id);
					this.dptLevlRequest = false;
					this.load();
				})
				.catch((error) => {
					window.showToast({
						text: error.message,
						title: "Alert",
						color: "danger",
					});
				});
		},
		getDepartmentLevels(e) {
			return e.departmentLevels;
		},
		showTargetModal(id) {
			this.level = this.find(this.levels, id);
			this.$refs.targetsModal.show();
		},
		onHiddenTargetModal() {
			this.level = {
				id: "",
				name: "",
				description: "",
				departmentLevels: [],
			};
			this.target = null;
			this.departmentLevel = null;
		},
		onSelectDepartmentLevel(e) {
			if (e.value) {
				this.target = e.value.target;
				if (this.target) {
					this.target.billableHours = Number(
						this.target.billableHours
					);
					this.target.avgHourlyRate = Number(
						this.target.avgHourlyRate
					);
					this.target.internalFeedback = Number(
						this.target.internalFeedback
					);
					this.target.externalFeedback = Number(
						this.target.externalFeedback
					);
					this.target.handicap30Day = Number(
						this.target.handicap30Day
					);
					this.target.handicap60Day = Number(
						this.target.handicap60Day
					);
					this.target.handicap90Day = Number(
						this.target.handicap90Day
					);
				} else {
					this.target = {
						billableHours: 0,
						avgHourlyRate: 0,
						internalFeedback: 0,
						externalFeedback: 0,
						handicap30Day: 0,
						handicap60Day: 0,
						handicap90Day: 0,
					};
				}
			}
		},
		submitTarget(e) {
			e.preventDefault();
			this.submitRequest = true;
			let post = {
				id: this.departmentLevel.id,
				department: { id: this.departmentLevel.department.id },
				level: { id: this.level.id },
				target: this.target,
			};
			this.post("api/settings/department-level/update", post)
				.then((response) => {
					let i = this.level.departmentLevels.findIndex(
						(s) => s.id == this.departmentLevel.id
					);
					this.level.departmentLevels[i].target = JSON.parse(
						response.data
					);
					this.target = JSON.parse(response.data);
					this.$forceUpdate();
					window.showToast({
						text: "Target succesfully set!",
						title: "Alert",
						color: "success",
					});
				})
				.catch((error) => {
					window.showToast({
						text: error.message,
						title: "Alert",
						color: "danger",
					});
				})
				.finally((final) => {
					this.submitRequest = false;
				});
		},
		confirmDeleteLevel(id) {
			window.showConfirmModal({
				confirmButtonText: "Delete",
				subTitle: "Deleting is irreversible",
				showLoader: true,
				mode: "danger",
				onConfirm: async () => {
					await this.deleteLevel(id);
				},
			});
		},
		async deleteLevel(id) {
			try {
				await this.delete("api/settings/levels/" + id);
				window.showSuccessToast("Level deleted successfully");
				this.levels = this.removeItem(this.levels, id);
				this.tableKey++;
			} catch (error) {
				window.showErrorToast(error.message);
			}
		},
		dropdownOptions(id) {
			let haveUsers =
				this.find(this.levels, id)
					.departmentLevels.map((s) => s.users)
					.flat(1).length > 0;
			return [
				{ name: "Edit", method: this.editLevel },
				{
					name: "Associate Department",
					method: this.openDepartmentLevelsModal,
				},
				{ name: "Default Targets", method: this.showTargetModal },
				{
					name: "Delete",
					method: this.confirmDeleteLevel,
					disabled: haveUsers,
				},
			];
		},
	},
	computed: {
		departmentsFiltered() {
			let toRemove = this.level.departmentLevels
				.map((s) => s.department)
				.map((s) => s.id);
			return this.departments.filter((s) => !toRemove.includes(s.id));
		},
	},
};
</script>
<style>
.bg-grey {
	background: grey;
}
.associated-dpt-badge{
	position: relative;
    padding: 8px 23px 8px 8px !important;
}
.remove-dpt-btn{
	position: absolute;
    right: 4px;
    top: 6px;
	cursor: pointer;
}
</style>
