<template>
	<CheckPermission :value="constants.PERMISSION_READ_SERVICES" :access-denied-msg="true">
		<b-card no-body>
			<b-card-header class="d-flex align-items-center justify-content-between">
				<strong>Services</strong>
				<DxButton icon="add" @click="addService" v-if="hasPermission(constants.PERMISSION_UPDATE_SERVICES)" />
			</b-card-header>
			<b-card-body class="p-0">
				<spinner v-if="apiRequest" />
				<DxDataGrid
					v-else
					ref="gridRef"
					:data-source="services"
					:show-row-lines="true"
					:show-column-lines="false"
					:show-borders="true"
					:hover-state-enabled="true"
					:row-alternation-enabled="true">

					<DxColumn data-field="client.companyName" caption="Client" />
					<DxColumn data-field="displayName" caption="Name" />
					<DxColumn data-field="serviceType" :group-index="0" group-cell-template="serviceTypeTemplate" cell-template="serviceTypeTemplate" />
					<template #serviceTypeTemplate="{ data }">
						<ServiceTypeBadge :value="data.value" />
					</template>
					<DxColumn data-field="project.disposition" cell-template="dispositionTemplate" />
					<template #dispositionTemplate="{ data }">
						<DispositionBadge :value="data.value" />
					</template>
					<DxColumn data-field="status" cell-template="statusTemplate" />
					<template #statusTemplate="{ data }">
						<ServiceStatusBadge :value="data.value" />
					</template>

					<DxColumn width="120px" caption="Actions" alignment="center" cell-template="actionsTemplate" />
					<template #actionsTemplate="{ data }">
						<div>
							<DxButton styling-mode="text" icon="edit" @click="edit(data.data)" />
							<DxButton styling-mode="text" icon="trash" @click="askDelete(data.data)" :disabled="true
							" />
						</div>
					</template>
				</DxDataGrid>
				<ServicePopup ref="servicePopup" @service-updated="load" />
			</b-card-body>
		</b-card>
	</CheckPermission>
</template>

<script>
import {
	DxDataGrid,
	DxColumn,
	DxPaging,
	DxEditing,
	DxToolbar,
	DxItem,
} from 'devextreme-vue/data-grid';
import { AxiosWrapper } from '@/mixins';
import DxButton from "devextreme-vue/button";
import ServicePopup from './service-popup.vue';

export default {
	components:{
		DxDataGrid,
		DxColumn,
		DxPaging,
		DxEditing,
		DxToolbar,
		DxItem,
		DxButton,
		ServicePopup
	},
	mixins:[AxiosWrapper],
	data() {
		return {
			services: [],
			apiRequest: true
		};
	},
	mounted() {
		this.load()
	},
	methods: {
		load(){
			this.apiRequest = true
			this.get('api/services/get').then(response => {
				this.services = response.data
			}).finally(() => {
				this.apiRequest = false
			})
		},
		addService() {
			this.$refs.servicePopup.show()
		},
		askDelete(row) {
			window.showConfirmModal({
				confirmButtonText: "Delete",
				subTitle: "Deleting is irreversible",
				showLoader: true,
				mode: "danger",
				onConfirm: async () => {
					try {
						await this.delete('api/services/' + row.id)
						window.showSuccessToast("Service deleted successfully");
						this.load()
					} catch (error) {
						window.showErrorToast(error.message);
					}
				},
			});
		},
		edit(row) {
			this.$refs.servicePopup.show(row)
		}
	},
};
</script>
