<template>
	<form ref="policyForm" @submit="submit($event)">
		<DxValidationGroup name="policyValidationGroup" ref="policyValidationGroup" v-if="!saveRequest">
			<div v-height="'23rem'">
				<DxField label="Leave Policy" :required="true" label-width="30%" value-width="70%">
					<Lookup v-model="userLeaveBank.leavePolicyId" api="api/settings/leave-policies" :required="true"
						display-expr="name" value-expr="id" placeholder="Select Leave Policy"
						@on-load="onLoadLeavePolicies" @value-changed="onSelectPolicy" />
				</DxField>
                <div v-if="userLeaveBank.leavePolicy">
                    <div v-if="userLeaveBank.leavePolicy.ptoVesting === 'FullAllocation' && Number(userLeaveBank.leavePolicy.pto) > 0">
                        <label>
                            Would you like {{user.firstName}} to receive the full allocation of {{userLeaveBank.leavePolicy.pto}}
                            PTO hours upon their start date of {{user.startDate | shortDate}} or prorate for {{new Date().getFullYear()}}?
                        </label>
                        <DxField :show-label="false">
                            <DxRadioGroup
                                v-model="userLeaveBank.ptoVesting"
                                :items="ptoVestings"
								display-expr="name"
								value-expr="enumValue"
                                layout="horizontal">
                                <DxValidator> <DxRequiredRule message="PTO Vesting is required" /> </DxValidator>
                            </DxRadioGroup>
                        </DxField>
                    </div>
                    <div v-if="userLeaveBank.leavePolicy.annualPTOIncrease > 0">
                        <DxField label="Max Annual PTO" :required="true" label-width="30%" value-width="70%">
                            <DxNumberBox
                                v-model="userLeaveBank.maxAnnualPTO"
                                :placeholder="'Should be greater than ' + (userLeaveBank.leavePolicy.pto + userLeaveBank.leavePolicy.annualPTOIncrease)"
                                :min="userLeaveBank.leavePolicy.pto + userLeaveBank.leavePolicy.annualPTOIncrease"
                                :show-spin-buttons="true">
                                <DxValidator> <DxRequiredRule message="Max Annual PTO is required" /> </DxValidator>
                            </DxNumberBox>
                        </DxField>
                    </div>
                </div>
				<DxField v-if="hasPermission(constants.PERMISSION_UPDATE_USER_SETTINGS)">
					<DxButton type="default" text="Save" :use-submit-behavior="true" />
				</DxField>
			</div>
		</DxValidationGroup>
		<spinner v-else />
	</form>
</template>

<script>
import DxValidationGroup from "devextreme-vue/validation-group";
import { DxValidator, DxRequiredRule } from "devextreme-vue/validator";
import DxButton from "devextreme-vue/button";
import { AxiosWrapper } from "../../mixins";
import DxRadioGroup from "devextreme-vue/radio-group";
import { DxNumberBox } from "devextreme-vue/number-box";
import { Lookup } from "../../components/lookups";
import moment from "moment";
import { PTOVesting } from "../../_helpers/constants";

export default {
	name: "LeavePolicyForm",
	props: ["user"],
	components: {
		DxValidationGroup,
		DxValidator,
		DxRequiredRule,
		DxButton,
		DxRadioGroup,
		DxNumberBox,
		Lookup,
	},
	mixins: [AxiosWrapper],
	data() {
		return {
			saveRequest: false,
			leavePolicies: [],
			userLeaveBank: {},
			ptoVestings: [
			  {
			    name: "Full Allocation",
			    enumValue: "FullAllocation",
			    value: PTOVesting.FullAllocation,
			  },
			  { name: "Prorate", enumValue: "Prorate", value: PTOVesting.Prorate },
			],
		};
	},
	created() {
		if (this.user.leaveBank) {
			this.userLeaveBank = this.user.leaveBank;
			this.userLeaveBank.maxAnnualPTO = Number(this.userLeaveBank.maxAnnualPTO);
			this.userLeaveBank.leavePolicy.pto = Number(
				this.userLeaveBank.leavePolicy.pto
			);
			this.userLeaveBank.leavePolicy.annualPTOIncrease = Number(
				this.userLeaveBank.leavePolicy.annualPTOIncrease
			);
		} else {
			this.userLeaveBank = { leavePolicyId: "" };
		}
	},
	methods: {
		onSelectPolicy(e) {
			if (e.event) {
				let policy = this.find(this.leavePolicies, e.value);
				if (this.userLeaveBank.id) {
					let difference = moment(new Date()).diff(
						this.user.startDate,
						"years"
					);
					let increase = Number(difference) * Number(policy.annualPTOIncrease);
					let title = `By policy, ${this.user.firstName} will be granted ${policy.pto
						} hours of PTO plus an additional ${increase} hours for being active over ${difference} calendar years for a total of ${Number(policy.pto) + increase
						} PTO hours. Please confirm.`;
					window.showConfirmModal({
						subTitle: title,
						width: 400,
						height: 220,
						confirmButtonText: "Yes",
						onConfirm: () => {
							this.userLeaveBank.leavePolicy = policy;
						},
						onCancel: () => {
							this.userLeaveBank.leavePolicyId = e.previousValue;
							this.userLeaveBank.leavePolicy = this.leavePolicies.find(
								(s) => s.id == e.previousValue
							);
						},
					});
				} else {
					this.userLeaveBank.leavePolicy = policy;
				}
			}
		},
		async submit(e) {
			e.preventDefault();
			try {
				this.saveRequest = true;
				const response = await this.post(
					`/api/users/${this.user.id}/set-leave-bank`,
					this.userLeaveBank
				);
				window.showSuccessToast(
					`${this.user.firstName}'s Leave Policy Updated.`
				);
				this.$emit("saved", response.data);
			} finally {
				this.saveRequest = false;
			}
		},
		onLoadLeavePolicies(response) {
			this.leavePolicies = response.map((p) => {
				(p.annualPTOIncrease = Number(p.annualPTOIncrease)),
					(p.pto = Number(p.pto)),
					(p.comp = Number(p.comp)),
					(p.monthlyWFH = Number(p.monthlyWFH));
				return p;
			});
		},
	},
};
</script>
