import $ from 'jquery'
export const Util = {
	methods: {
        showSpinnerInElement(conf){
            let _conf = {
                element: typeof (conf) == 'string' ? conf : conf.element,
                size: conf.size == undefined ? '20px' : conf.size,
                margin: conf.margin == undefined ? '10px' : conf.margin,
                top: conf.top == undefined ? '-1px' : conf.top
            }
            $(_conf.element).find('.spinner-border').remove();
            $(_conf.element).append(`<span class="spinner-border" style="position: relative; top:${_conf.top}; margin: 0 ${_conf.margin}; width:${_conf.size}; height:${_conf.size}"></span>`);
        },
        hideSpinnerInElement(element){
            let PL = $(element).find('.spinner-border');
            PL.remove();
        },
        showSpinnerInButton(el){
            $(el).append(`<span class="spinner-border" style="position: relative; top:-1px; margin: 0 5px; width:17px; height:17px"></span>`);
            $(el).attr('disabled','disabled');
        },
        hideSpinnerInButton(el){
            let PL =  $(el).find('.spinner-border');
            PL.remove();
            $(el).removeAttr('disabled');
        },
        convertToDate(date){
            return new Date(date).toLocaleString([], { day: '2-digit', month: '2-digit', year: 'numeric', week: '4-digit' });
        },
		convertToDateTime(date){
            return new Date(date).toLocaleString([], { day: '2-digit', month: '2-digit', year: 'numeric', week: '4-digit', hour: '2-digit', minute: '2-digit' });
        },
        cleanSource(source) {
            // using native JSON functions removes reactivity
            // so we can clone an object without mutating the original source
            return JSON.parse(JSON.stringify(source));
        },
        emptyIfNull(str){
            if(str == null || str == undefined) return ''
        },
        sleep(ms) {
            return new Promise(resolve => setTimeout(resolve, ms));
        },
        prependToList(value, array) {
            var newArray = array.slice();
            newArray.unshift(value);
            return newArray;
        },
        html2clipboard(html, el) {
            var tmpEl;
            if (typeof el !== "undefined") {
                // you may want some specific styling for your content - then provide a custom DOM node with classes, inline styles or whatever you want
                tmpEl = el;
            } else {
                // else we'll just create one
                tmpEl = document.createElement("div");
                // since we remove the element immedeately we'd actually not have to style it - but IE 11 prompts us to confirm the clipboard interaction and until you click the confirm button, the element would show. so: still extra stuff for IE, as usual.
                tmpEl.style.opacity = 0;
                tmpEl.style.position = "absolute";
                tmpEl.style.pointerEvents = "none";
                tmpEl.style.zIndex = -1;
            }
            // fill it with your HTML
            tmpEl.innerHTML = html;
            // append the temporary node to the DOM
            document.body.appendChild(tmpEl);
            // select the newly added node
            var range = document.createRange();
            range.selectNode(tmpEl);
            window.getSelection().addRange(range);
            // copy
            document.execCommand("copy");
            // and remove the element immediately
            document.body.removeChild(tmpEl);
        },
        getMimeType(file, fallback = null) {
            const byteArray = (new Uint8Array(file)).subarray(0, 4);
            let header = '';
            for (let i = 0; i < byteArray.length; i++) {
               header += byteArray[i].toString(16);
            }
            switch (header) {
                case "89504e47":
                    return "image/png";
                case "47494638":
                    return "image/gif";
                case "ffd8ffe0":
                case "ffd8ffe1":
                case "ffd8ffe2":
                case "ffd8ffe3":
                case "ffd8ffe8":
                    return "image/jpeg";
                default:
                    return fallback;
            }
        },
        downloadFileFromBlob(blob, filename) {
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', filename); //or any other extension
            document.body.appendChild(link);
            link.click();
        },
		validateEmail(email){
			return (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email))
		},
		deleteQueryParam(router,route,param){
            let query = Object.assign({}, route.query);
            delete query[param];
            router.replace({ query });
        },
		propsAsString(obj) {
            return Object.keys(obj).map(function(k) { return k }).join(" AND ")
        },
		mapByString(o, s) {
            s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
            s = s.replace(/^\./, '');           // strip a leading dot
            var a = s.split('.');
            for (var i = 0, n = a.length; i < n; ++i) {
                var k = a[i];
				if(o){
					if (k in o) {
						o = o[k];
					} else {
						return;
					}
				}
            }
            return o;
        },
        getRowObj(obj){
            return obj;
        },
        lowerFirstLetter(string) {
            return string.charAt(0).toLowerCase() + string.slice(1);
        },
        generateUUID() { // Public Domain/MIT
            var d = new Date().getTime();//Timestamp
            var d2 = ((typeof performance !== 'undefined') && performance.now && (performance.now()*1000)) || 0;//Time in microseconds since page-load or 0 if unsupported
            return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
                var r = Math.random() * 16;//random number between 0 and 16
                if(d > 0){//Use timestamp until depleted
                    r = (d + r)%16 | 0;
                    d = Math.floor(d/16);
                } else {//Use microseconds since page-load if supported
                    r = (d2 + r)%16 | 0;
                    d2 = Math.floor(d2/16);
                }
                return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
            });
        },
        toggleSettingBoard() {
			this.$store.dispatch("toggleSettingBoard");
		},
        consoleData(v){
            console.log(v)
        }
	}
}
