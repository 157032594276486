<template>
    <span>
        <DxTagBox
            v-if="multiple"
            v-model="selectedUsers"
            :search-enabled="true"
            display-expr="fullName"
            value-expr="id"
            :data-source="users"
            placeholder="Select Users"
            :disabled="disabled"
            :read-only="readOnly"
			:label="label"
			:label-mode="labelMode"
            :apply-value-mode="applyValueMode"
			v-height="height"
            @value-changed="onValueChanged">
            <DxValidator v-if="required"> <DxRequiredRule message="User(s) are required"/> </DxValidator>
        </DxTagBox>
        <DxSelectBox
            v-else
            v-model="userId"
            :search-enabled="true"
            :data-source="users"
            display-expr="fullName"
            value-expr="id"
            placeholder="Select User"
            :disabled="disabled"
            :read-only="readOnly"
			:label="label"
			:label-mode="labelMode"
            :show-clear-button="showClearButton"
			v-height="height"
			:multiline="multiline"
            @value-changed="onValueChanged">
            <DxValidator v-if="required"> <DxRequiredRule message="User is required"/> </DxValidator>
        </DxSelectBox>
    </span>
</template>

<script>
import DxTagBox from 'devextreme-vue/tag-box';
import DxSelectBox from 'devextreme-vue/select-box';
import { AxiosWrapper } from '../../mixins/AxiosWrapper'
import { DxValidator, DxRequiredRule } from 'devextreme-vue/validator';
export default {
    props:{
        'value': [String, Array],
        'required':{
            type: Boolean,
            default: false
        },
        'disabled-items':{
            type: Array,
            default: () => []
        },
        'multiple':{
            type: Boolean,
            default: false
        },
		'multiline':{
            type: Boolean,
            default: false
        },
        'disabled':{
            type: Boolean,
            default: false
        },
        'read-only':{
            type: Boolean,
            default: false
        },
        'showClearButton':{
            type: Boolean,
            default: false
        },
        'apply-value-mode':{
            type: String,
            default: 'instantly'
        },
		'label':{
            type: String,
            default: 'Users'
        },
		'label-mode':{
            type: String,
            default: 'hidden'
        },
		'height':{
            type: String,
            default: 'auto'
        },
    },
    components:{ DxSelectBox, DxValidator, DxRequiredRule, DxTagBox },
    mixins:[ AxiosWrapper ],
    data(){
        return {
            userId: '',
            selectedUsers:[],
            isLoaded: true,
            usersRaw:[],
            users: null
        }
    },
    created(){
        if(this.multiple){
            this.selectedUsers = this.value.map(s => typeof s == 'string' ? s : s.id) || []
        }
        else{
            this.userId = this.value || ''
        }
        this.users = this.initliazeUsers()
    },
    methods:{
        onValueChanged(e){
            if(this.multiple){
                this.$emit('input', this.usersRaw.filter(s => e.value.includes(s.id)))
            }
            else{
                this.$emit('input', e.value)
                let user = e.value ? this.usersRaw.find(s => s.id == e.value) : {}
                this.$emit('object-changed', user)
            }
            this.$emit('value-changed', e)
        },
        initliazeUsers(){
            return {
                loadMode: 'raw',
                load: () => {
                    const promise = new Promise((resolve) => {
                        this.get('api/users/lookup').then(response =>{
                            let users = response.data;
                            users.forEach(user => {
                                if(this.disabledItems.includes(user.id)) user.disabled = true
                                else user.disabled = false
                            })
                            this.$emit('on-load', users)
                            this.usersRaw = users
                            resolve(this.usersRaw);
                        })
                    });
                    return promise;
                },
            }
        }
    },
    watch:{
        disabledItems(){
            this.services = {
                loadMode: 'raw',
                load: () => {
                    const promise = new Promise((resolve) => {
                        this.usersRaw.forEach(user => {
                            if(this.disabledItems.includes(user.id)) user.disabled = true
                            else user.disabled = false
                        })
                        resolve(this.usersRaw);
                    });
                    return promise;
                },
            }
        }
    }
}
</script>
