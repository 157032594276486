<template>
	<div>
		<UserTargetForm v-if="showForm" :user="user" :targets="targets" :departmentLevel="departmentLevel"
			:target="userTarget" @hidden="showForm = false" @added="targetAdded" @updated="targetUpdated"
			@deleted="targetDeleted" @user-updated="userUpdated" />
		<div v-else>
			<div class="text-right mb-3" v-if="hasPermission(constants.PERMISSION_UPDATE_USER_SETTINGS)">
				<DxButton text="New Target Period" @click="showTargetForm" />
			</div>
			<spinner v-if="apiRequest" />
			<DxDataGrid v-else :key="tableKey" :data-source="targets" :show-row-lines="true" :show-column-lines="false"
				:show-borders="true" :hover-state-enabled="true" :row-alternation-enabled="true"
				@rowDblClick="rowDblClick">
				<DxColumn data-field="startDate" caption="Start Date" format="MM/dd/yyyy" data-type="date" width="10%" />
				<DxColumn data-field="endDate" caption="End Date" format="MM/dd/yyyy" data-type="date" width="10%" />
				<DxColumn caption="Billable Hours" data-type="number" :calculate-cell-value="getRowObj" cell-template="billableHoursTemplate" width="10%">
					<DxFormat type="decimal" :precision="2" />
				</DxColumn>
				<template #billableHoursTemplate="{ data }">
					<div>
						{{ data.value.billableHours }}
						<span :id="'targethours' + data.rowIndex">
							<Icon v-if="isShowHandicapTooltip(data.value)" name="info-circle" icon-pack="bootstrap" class="ml-2" />
						</span>
						<b-popover :target="'targethours' + data.rowIndex" :triggers="'hover'" :placement="'bottom'">
							<div>
								<strong>30 Day Handicap: </strong>{{ user.handicap30Day }}
							</div>
							<div>
								<strong>60 Day Handicap: </strong>{{ user.handicap60Day }}
							</div>
							<div>
								<strong>90 Day Handicap: </strong>{{ user.handicap90Day }}
							</div>
						</b-popover>
					</div>
				</template>
				<DxColumn data-field="avgHourlyRate" data-type="number" caption="Avg Rate" width="10%">
					<DxFormat type="currency" :precision="2" />
				</DxColumn>
				<DxColumn data-field="departmentLevel.departmentName" caption="Department" width="15%" />
				<DxColumn data-field="departmentLevel.levelName" caption="Level" width="15%" />
				<DxColumn data-field="notes" css-class="wrap-text" />
			</DxDataGrid>
		</div>
	</div>
</template>

<script>
import { AxiosWrapper } from "@/mixins";
import UserTargetForm from "./user-target-form.vue";
import DxButton from "devextreme-vue/button";
import { DxDataGrid, DxColumn, DxFormat } from "devextreme-vue/data-grid";
export default {
	props: ["user", "departmentLevel"],
	mixins: [AxiosWrapper],
	components: {
		UserTargetForm,
		DxDataGrid,
		DxColumn,
		DxFormat,
		DxButton,
	},
	data() {
		return {
			targets: [],
			showArchived: false,
			apiRequest: false,
			showForm: false,
			userTarget: {},
			tableKey: this.generateUniqueCode(),
		};
	},
	async created() {
		await this.load();
	},
	methods: {
		async targetAdded() {
			this.showForm = false;
			await this.load();
		},
		async targetUpdated() {
			this.showForm = false;
			await this.load();
		},
		async load() {
			this.apiRequest = true;
			var response = await this.get(`api/users/${this.user.id}/targets`);
			this.targets = JSON.parse(response.data);
			this.apiRequest = false;
			this.reflectChanges();
		},
		isShowHandicapTooltip(target) {
			//If the period spans within the users first 90 days hovering over this tooltip will show the handicap values from the Level/Department combination AT THE TIME THE USER WAS CREATED
			if (!this.user.startDate) return true;
			let date = new Date(this.user.startDate);
			date.setDate(date.getDate() + 90);
			return new Date(target.endDate) < date;
		},
		rowDblClick(e) {
			if (e.rowIndex != 0) return;
			if (!this.showArchived) {
				this.userTarget = e.data;
				this.showForm = true;
			}
		},
		showTargetForm() {
			this.userTarget = {};
			this.showForm = true;
		},
		targetDeleted(id) {
			this.targets = this.targets.filter((s) => s.id != id);
			this.$forceUpdate();
			this.showForm = false;
			this.reflectChanges();
		},
		reflectChanges() {
			this.tableKey = this.generateUniqueCode();
		},
		submitDptLvl(e) {
			e.preventDefault();
			if (
				this.targets.some(
					(s) =>
						new Date(s.endDate) > new Date() &&
						s.departmentLevelId == this.user.departmentLevelId
				)
			) {
				window.showConfirmModal({
					confirmButtonText: "Yes",
					title: "Cut the targets?",
					subTitle:
						"User have billable hours targets to achieve, changing the department will cut the targets till today and further targets can be added with new department.",
					mode: "warning",
					height: 220,
					onConfirm: () => {
						this.saveDptLvl();
					},
				});
			} else {
				this.saveDptLvl();
			}
		},
		userUpdated() {
			this.$emit("saved", this.user);
		},
	},
};
</script>
